import { PageMetaData } from '../../../shared/global/types';
import { apiFunctions } from '@/helpers/functions';
import FALL_BACK_META_DATA from '@/constants/fallbackMetadata';

class PageMetaDataApi {
  public async loadPageMetaData(
    slug: string | null | undefined
  ): Promise<PageMetaData> {
    const res = await apiFunctions.axios.get(
      `/pages/${slug?.replace(/^\//, '')}`,
      { cache: true }
    );
    return res.data.data;
  }

  public async loadPageMetaDataWithFallback(
    slug: string | undefined | null
  ): Promise<PageMetaData> {
    try {
      const data = await this.loadPageMetaData(slug);
      return data || FALL_BACK_META_DATA;
    } catch (error) {
      return FALL_BACK_META_DATA;
    }
  }
}

export const getPageMetaData = (
  pageMetaData: PageMetaData | undefined,
  defaultCanonical = ''
) => ({
  title: pageMetaData?.Title,
  meta: [
    ...(pageMetaData?.Description
      ? [
          {
            hid: 'description',
            name: 'description',
            content: pageMetaData?.Description,
          },
        ]
      : []),
    ...(pageMetaData?.Keywords
      ? [
          {
            name: 'keywords',
            content: pageMetaData?.Keywords,
          },
        ]
      : []),
    {
      name: 'robots',
      content: pageMetaData?.NoIndex ? 'noindex' : 'index,follow',
    },
    ...getSocialMediaMetaDataWithPageMetaData(pageMetaData),
  ],
  link: [
    // TODO: use relative links, or make the domain dependent on the environment, or introduce a base link explicitly
    {
      rel: 'canonical',
      href: `https://easyauto123.com.au${
        pageMetaData?.Canonical ?? defaultCanonical
      }`,
    },
  ],
});

export const getSocialMediaMetaData = ({
  title,
  url,
  description,
  imageURL,
}: {
  keywords?: string;
  title?: string;
  url?: string;
  description?: string;
  imageURL?: string;
}) => {
  const metaData = [
    {
      name: 'og:site_name',
      content: 'easyauto123',
    },
    {
      name: 'og:title',
      content: title,
    },
    {
      name: 'og:url',
      content: imageURL,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:image',
      content: imageURL,
    },
    {
      name: 'twitter:url',
      content: url,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: imageURL,
    },
  ];
  return metaData.filter(({ content }) => !!content);
};

export const getSocialMediaMetaDataWithPageMetaData = (
  pageMetaData: PageMetaData | undefined
) =>
  getSocialMediaMetaData({
    description: pageMetaData?.Description,
    title: pageMetaData?.Title,
    url: pageMetaData?.Slug
      ? `https://easyauto123.com.au/${pageMetaData?.Slug}`
      : undefined,
  });

export const pageMetaDataApi = new PageMetaDataApi();
