import { Module, VuexModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import * as typ from '../features/sell/types';

const name = 'sell';

@Module({ stateFactory: true })
export default class Sell extends VuexModule {
  public ReviewOptions: typ.ReviewOptions = typ.reviewOptions;
}

export const SellModule = namespace(name);
