import overlayable from 'vuetify/lib/mixins/overlayable';
import VDialog from 'vuetify/lib/components/VDialog';
import { addSafeEventListener } from '@/helpers/events';

const showScroll = overlayable.extendOptions.methods.showScroll;
const dialogHideScroll = VDialog.extendOptions.methods.hideScroll;

overlayable.extendOptions.methods.showScroll = function (this: any) {
  const dialogCounter = document.querySelectorAll(
    '.v-dialog__content--active'
  ).length;
  showScroll.call(this);
  if (dialogCounter > 1) {
    document.documentElement.classList.add('overflow-y-hidden');
  }
};

VDialog.extendOptions.methods.hideScroll = function (this: any) {
  document.documentElement.classList.add('overflow-y-hidden');
  dialogHideScroll.call(this);
};

let savedScrollbarWidth = 0;

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  return scrollbarWidth;
}

function calculateScrollbarWidth() {
  const scrollbarWidth = getScrollbarWidth();
  if (savedScrollbarWidth !== scrollbarWidth) {
    savedScrollbarWidth = scrollbarWidth;
    const newCss =
      '\n' +
      `html.overflow-y-hidden {padding-right: ${scrollbarWidth}px;}\n` +
      `html.overflow-y-hidden .v-app-bar {padding-right: ${scrollbarWidth}px;}\n`;
    const scrollLockCss = document.head.querySelector('#scroll-lock');
    if (scrollLockCss) {
      scrollLockCss.innerHTML = newCss;
    } else {
      const el = document.createElement('style');
      el.setAttribute('type', 'text/css');
      el.setAttribute('id', '#scroll-lock');
      el.innerHTML = newCss;
      document.head.appendChild(el);
    }
  }
}

if (process.client) {
  addSafeEventListener(window, 'resize', calculateScrollbarWidth, {
    passive: true,
  });
  calculateScrollbarWidth();
}
