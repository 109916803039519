import { allowSell } from '../types';
import { RouteNames } from '@/router/route-consts';

export interface Cta {
  CtaID: string;
  Image: string;
  Title: string;
  Text: string;
  Link: string;
  LinkText: string;
}

export interface CtaOptions {
  Cta: Cta[];
}

const _ctaOptions: CtaOptions = {
  Cta: [
    {
      CtaID: 'FlexibleFinance',
      Image: '/img/cta/favourite-empty-state.png',
      Title: 'Perfectly matched finance? We’ll put the wheels in motion',
      Text: 'Getting into your next car has never been easier. 10 minutes of your time is all it takes to get pre-qualified.',
      Link: '/buy/finance',
      LinkText: 'Get Finance',
    },
  ],
};

if (allowSell) {
  _ctaOptions.Cta.splice(0, 0, {
    CtaID: 'Sell',
    Image: '/img/cta/SellLge–Lines.svg',
    Title: 'Sell or trade with us',
    Text: 'Avoid expensive advertising costs, unwanted calls, low-ballers/time wasters and sitting around waiting for someone to test-drive your car.',
    Link: RouteNames.SellRouteName,
    LinkText: 'Sell my car',
  });
}

export const ctaOptions = _ctaOptions;
