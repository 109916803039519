import { Inventory } from '../../../shared/inventory';
import { GetFinalPrice } from '@/features/inventory/helpers/getters';

export interface VehicleDetails {
  vehicleId?: string;
  vehicleLocation?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleYear?: string;
  vehiclePrice?: string;
  vehicleCategory?: string;
}

type formType = 'buy' | 'sell' | 'mailingList' | 'finance';
export type formEventType = 'start' | 'step' | 'complete';
export type formNameType =
  | 'buyEnquire'
  | 'buyReserve'
  | 'buyMessage'
  | 'buyFinance'
  | 'buyPriceMatch'
  | 'vehicleNotInStock'
  | 'sellAppraisal'
  | 'finance'
  | 'buyVDPFloating'
  | 'buyVDPMain'
  | 'buyVDPQuestion'
  | 'locations'
  | 'buySearch'
  | 'personalisedFinance';

export type leadTypeSubCategoryType =
  | 'buy'
  | 'finance'
  | 'trade'
  | 'sell'
  | 'general'
  | 'notInStock';

export interface TagFormNavigateEvent extends VehicleDetails {
  event: 'formNavigate';
  formEvent: formEventType;
  formName: formNameType;
  formType: formType;
  formStep?: number;
  formStepName?: string;
  leadTypeSubCategory?: leadTypeSubCategoryType[];
}

export interface TagFormErrorEvent {
  event: 'formError';
  formName: formNameType;
  errorName: string;
}

export type toolEventType = 'start' | 'cta' | 'complete';

export type toolInteractionNameType = 'enquire' | 'preapproval' | null;

type toolNameType = 'compareTool' | 'financeCalculator' | 'setLocation';

export interface TagToolNavigateEvent extends VehicleDetails {
  event: 'toolNavigate';
  toolEvent: toolEventType;
  toolName: toolNameType;
}

export interface TagToolInteractEvent extends VehicleDetails {
  event: 'toolInteract';
  toolEvent: toolEventType;
  toolInteractionName: toolInteractionNameType;
  toolName: toolNameType;
}

export interface TagToolErrorEvent {
  event: 'toolError';
  toolName: toolNameType;
  errorName: string;
}

export interface TagClickToCallEvent extends VehicleDetails {
  event: 'clickToCall';
  formName: formNameType;
  leadTypeSubCategory?: leadTypeSubCategoryType[];
}

export interface TagLikeButtonEvent extends VehicleDetails {
  event: 'likeButton';
}

type sharePlatformType = 'email' | 'facebook' | 'instagram';

export interface TagShareButtonEvent extends VehicleDetails {
  event: 'shareButton';
  sharePlatform: sharePlatformType;
}

export type contentInteractionType =
  | 'vehicleImageCarousel'
  | 'lifestyleCarousel'
  | 'enquireButton'
  | 'reserveButton'
  | 'getPreapprovedButton';

export interface TagContentInteractionEvent extends VehicleDetails {
  event: 'contentInteraction';
  contentInteractionType: contentInteractionType;
}

type searchEventType = 'search' | 'refinement' | 'sort';

export interface TagInternalSearchEvent extends VehicleDetails {
  event: 'internalSearch';
  searchEvent: searchEventType;
  searchTerm: string;
}

export function populateVehicleDetails(
  vehicle: VehicleDetails,
  vehicleId?: string,
  inventory?: Inventory
) {
  if (vehicleId) {
    vehicle.vehicleId = vehicleId.toLowerCase();
  }

  if (
    inventory?.GlassVehicle?.VehicleDetails?.Make &&
    vehicleId === inventory?._id
  ) {
    vehicle.vehicleCategory =
      inventory.Search.ProductCategory?.[0].toLowerCase();
    vehicle.vehicleLocation = inventory.Search.LocationCode?.toLowerCase();
    vehicle.vehicleMake =
      inventory.GlassVehicle.VehicleDetails.Make?.toLowerCase();
    vehicle.vehicleModel =
      inventory.GlassVehicle.VehicleDetails.Model?.toLowerCase();
    vehicle.vehicleYear = `${inventory.GlassVehicle.VehicleDetails.ReleaseYear}`;
    vehicle.vehiclePrice = `${GetFinalPrice(inventory)}`;
  }
}

export function ClickToCall(
  form: formNameType,
  lead: leadTypeSubCategoryType[],
  inv?: Inventory
) {
  const ctcData: TagClickToCallEvent = {
    event: 'clickToCall',
    formName: form,
    leadTypeSubCategory: lead,
  };

  if (inv) {
    populateVehicleDetails(ctcData, inv._id, inv);
  }

  dataLayerAdd(ctcData);
}

export function trackLikeButton(inv?: Inventory) {
  const likeData: TagLikeButtonEvent = {
    event: 'likeButton',
  };

  if (inv) {
    populateVehicleDetails(likeData, inv._id, inv);
  }

  dataLayerAdd(likeData);
}

export function trackShareButton(inv?: Inventory) {
  const shareData: TagShareButtonEvent = {
    event: 'shareButton',
    sharePlatform: 'email',
  };

  if (inv) {
    populateVehicleDetails(shareData, inv._id, inv);
  }

  dataLayerAdd(shareData);
}

export function ContentInteraction(
  contentInteraction: contentInteractionType,
  inv?: Inventory
) {
  const contentInteractionData: TagContentInteractionEvent = {
    event: 'contentInteraction',
    contentInteractionType: contentInteraction,
  };

  if (inv) {
    populateVehicleDetails(contentInteractionData, inv._id, inv);
  }

  dataLayerAdd(contentInteractionData);
}

export function InternalSearch(
  searchEvent: searchEventType,
  searchTerm: string,
  inv?: Inventory
) {
  const searchEventData: TagInternalSearchEvent = {
    event: 'internalSearch',
    searchEvent,
    searchTerm,
  };

  if (inv) {
    populateVehicleDetails(searchEventData, inv._id, inv);
  }

  dataLayerAdd(searchEventData);
}

export function dataLayerAdd(
  event:
    | TagFormNavigateEvent
    | TagFormErrorEvent
    | TagToolNavigateEvent
    | TagToolInteractEvent
    | TagToolErrorEvent
    | TagClickToCallEvent
    | TagLikeButtonEvent
    | TagShareButtonEvent
    | TagInternalSearchEvent
    | TagContentInteractionEvent
) {
  if ((globalThis as any).dataLayer) {
    (globalThis as any).dataLayer.push(event);
    // tslint:disable-next-line: no-console
    // console.log(JSON.stringify(event));
  }
}
