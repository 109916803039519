import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router/types';
import Logo from './Logo';
import { RouteNames } from '@/router/route-consts';
import { AppModule } from '@/store/app';
import AppVersion from '@/features/site-footer/AppVersion';

@Component({
  components: {
    Logo,
    AppVersion,
  },
  data: () => ({
    footerColumns: [
      {
        columnNumber: 1,
        itemGroups: [
          {
            groupTitle: 'Shop',
            groupItems: [
              {
                title: 'View all our used cars',
                target: '/buy/used-cars',
              },
              {
                title: 'Find a superstore',
                target: '/locations',
              },
              {
                title: 'Used cars under $10,000',
                target: '/buy/used-cars/under-10000',
              },
              {
                title: 'Used cars under $15,000',
                target: '/buy/used-cars/under-15000',
              },
              {
                title: 'Used cars under $20,000',
                target: '/buy/used-cars/under-20000',
              },
              {
                title: 'Buy used cars online',
                target: '/buy-used-cars-online',
              },
              {
                title: 'Car insurance',
                target: '/car-insurance',
              },
            ],
          },
        ],
      },
      {
        columnNumber: 2,
        itemGroups: [
          {
            groupTitle: 'Shop by location',
            groupItems: [
              {
                title: 'Used cars Sydney',
                target: '/buy/used-cars/city-sydney/state-nsw',
              },
              {
                title: 'Used cars Newcastle',
                target: '/buy/used-cars/city-newcastle/state-nsw',
              },
              {
                title: 'Used cars Maitland',
                target: '/buy/used-cars/city-maitland/state-nsw',
              },
              {
                title: 'Used cars Brisbane',
                target: '/buy/used-cars/city-brisbane/state-qld',
              },
              {
                title: 'Used cars Adelaide',
                target: '/buy/used-cars/city-adelaide/state-sa',
              },
              {
                title: 'Used cars Hobart',
                target: '/buy/used-cars/city-hobart/state-tas',
              },
              {
                title: 'Used cars Melbourne',
                target: '/buy/used-cars/city-melbourne/state-vic',
              },
              {
                title: 'Used cars Perth',
                target: '/buy/used-cars/city-perth/state-wa',
              },
            ],
          },
        ],
      },
      {
        columnNumber: 3,
        itemGroups: [
          {
            groupTitle: 'Sell',
            groupItems: [
              {
                title: 'Schedule an appraisal',
                target: '/sell',
              },
            ],
          },
          {
            groupTitle: 'Finance',
            groupItems: [
              {
                title: 'How it works',
                target: '/buy/finance',
              },
            ],
          },
          {
            groupTitle: 'News',
            groupItems: [
              {
                title: 'Blog',
                target: '/articles',
              },
            ],
          },
        ],
      },
      {
        columnNumber: 4,
        itemGroups: [
          {
            groupTitle: 'Service',
            groupItems: [
              {
                title: 'Book a service',
                target: '/service',
              },
            ],
          },
        ],
      },
      {
        columnNumber: 5,
        itemGroups: [
          {
            groupTitle: 'About',
            groupItems: [
              {
                title: 'Careers',
                target: '/about-us/our-people-and-values/careers',
              },
              {
                title: 'FAQs and support',
                target: '/faqs',
              },
            ],
          },
        ],
      },
    ],
  }),
  methods: {
    onClick() {
      // hack to force the external blog to re-render upon any navigation to its page
      if (this.$router.currentRoute.name === RouteNames.BlogRouteName) {
        window.location.reload();
      }
    },
  },
})
export default class SiteFooter extends Vue {
  @Prop({ default: false }) public isLandingPage?: boolean;
  @AppModule.State public MainVersion!: string;

  public SearchRoute: Location = { name: RouteNames.SearchRouteName };
  public SellRoute: Location = { name: RouteNames.SellRouteName };
  public LocationsRoute: Location = { name: RouteNames.LocationsRouteName };
  public FinanceRoute: Location = { name: RouteNames.FinanceRouteName };
  public ServiceRoute: Location = { name: RouteNames.ServiceRouteName };
  public CareersRoute: Location = { name: RouteNames.CareersRouteName };
  public isAppVersionModalVisible: boolean = false;

  public showAppVersionModal() {
    this.isAppVersionModalVisible = true;
  }
}
