import { InventorySearchConfig } from '../../../../../shared/inventory/search';
import { RangeOptions } from '../../filter/types';

export function FilterSeats(
  filter: InventorySearchConfig,
  filterKey: string,
  seats: RangeOptions
): boolean {
  if (filterKey.startsWith('Seats.')) {
    if (!filter.Search.Seats) {
      filter.Search.Seats = {};
    }
    if (seats.MinValue) {
      const minValue = seats.Criteria.Values?.[seats.MinValue];
      if (minValue) {
        const filterValue = minValue.Value as number;
        if (Number.isInteger(filterValue)) {
          filter.Search.Seats.MinValue = filterValue;
        }
      }
    }
    if (
      seats.Criteria.Values &&
      seats.MaxValue < seats.Criteria.Values.length - 1
    ) {
      const maxValue = seats.Criteria.Values?.[seats.MaxValue];
      if (maxValue) {
        const filterValue = maxValue.Value as number;
        if (Number.isInteger(filterValue)) {
          filter.Search.Seats.MaxValue = filterValue;
        }
      }
    }
    return true;
  }
  return false;
}
