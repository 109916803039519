import VueRouter from 'vue-router';
import { Location } from 'vue-router/types';
import { sessionWrapper } from '@/tools/session';

export interface RouterHistoryWrapper {
  CurrentTitle?: string;
  PreviousTitle?: string;
  PreviousRoute: Location | null;
  safeNavigateBack: (
    validRoutes: string[],
    failover: () => Location | '' | undefined | null
  ) => void;
  $router?: VueRouter;
}

export const routerHelper: RouterHistoryWrapper = {
  PreviousRoute: null,
  safeNavigateBack(
    validRoutes: string[],
    failover: () => Location | '' | undefined | null
  ) {
    if (!this.$router) {
      location.href = '/';
      return;
    }
    const previousRoute = routerHelper.PreviousRoute;
    if (validRoutes.includes(previousRoute?.name || '')) {
      this.$router.go(-1);
    } else {
      const route = failover();
      if (route) {
        this.$router.push(route);
      } else {
        this.$router.push('404');
      }
    }
  },
};

const routerSavedValue =
  sessionWrapper.Read<RouterHistoryWrapper>('routerHelper');
if (routerSavedValue) {
  routerHelper.PreviousRoute = routerSavedValue.PreviousRoute;
  routerHelper.PreviousTitle = routerSavedValue.PreviousTitle;
}
