import { getModule } from 'vuex-module-decorators';
import Location from './location';
import { Selections } from '@/features/filter/types';
import Filter from '@/store/filter';
import InventoryStore from '@/store/inventoryStore';

function RefreshFilteredInventory($store: any) {
  const locationStore = getModule(Location, $store);
  // TODO: something bad is happening inside the filter store that I cannot define module name to get as the line above.
  const filterStore = $store.state.filter;

  const selections: Selections = {
    filters: filterStore.SelectedFilter,
    seats: filterStore.SeatsOptions,
    years: filterStore.YearOptions,
    price: filterStore.PriceOptions,
    odometer: filterStore.KilometresOptions,
    fuelEcon: filterStore.FuelEconomyOptions,
    preferredLocation: locationStore.selectedLocation,
    sort: filterStore.SortOptions,
    finance: filterStore.RepaymentOptions,
  };

  $store.dispatch('inventoryStore/FilterInventory', selections);
}

function RefreshCriteria($store: any) {
  const inventoryStore = $store.state.inventoryStore as InventoryStore;
  $store.commit(
    'filter/UpdateStockNumberCriteria',
    inventoryStore.InventoryList
  );
  $store.commit(
    'filter/UpdateRegistrationCriteria',
    inventoryStore.InventoryList
  );
}

function refreshLocations($store: any) {
  const filterStore = $store.state.filter as Filter;
  $store.commit('location/loadLocations', filterStore.LocationOptions);
}

function RefreshFinanceOptions($store: any) {
  const filterStore = $store.state.filter as Filter;
  $store.commit('finance/UpdateFinanceOptions', filterStore.FinanceOptions);
}

function refreshCaches($store: any) {
  $store.dispatch('filter/LoadSearchCriteria');
  $store.dispatch('inventoryStore/LoadFeaturedInventory');
  $store.dispatch('inventoryStore/FetchAll');
  $store.dispatch('personalisedFinance/LoadAnonymousPricing');
  $store.dispatch('personalisedFinance/LoadEligibilityCriteria');
  $store.dispatch('reserve/LoadPaymentsConfig');
  RefreshFilteredInventory($store);
  refreshLocations($store);
}

if (process.client) {
  (window as any).onNuxtReady(({ $store }: any) => {
    $store.commit('inventoryStore/Init');
    $store.commit('filter/Init');

    $store.subscribe((mutation: any) => {
      const filterUpdateNeeded =
        mutation.type === 'filter/ToggleFilter' ||
        mutation.type === 'filter/ClearFilters' ||
        mutation.type === 'filter/UpdateSeats' ||
        mutation.type === 'filter/UpdateYears' ||
        mutation.type === 'filter/UpdateRepaymentFilter' ||
        mutation.type === 'filter/UpdateKm' ||
        mutation.type === 'filter/UpdateFuelEcon' ||
        mutation.type === 'filter/UpdatePrice' ||
        mutation.type === 'filter/UpdatePriceOptions' ||
        mutation.type === 'filter/UpdateSortOptions' ||
        mutation.type === 'filter/RevertSortOptions' ||
        mutation.type === 'location/UpdateLocation';
      if (filterUpdateNeeded) {
        RefreshFilteredInventory($store);
        return;
      }
      const criteriaUpdateNeeded = mutation.type === 'inventoryStore/AddList';
      if (criteriaUpdateNeeded) {
        RefreshCriteria($store);
        return;
      }
      const searchCriteriaUpdated =
        mutation.type === 'filter/SetSearchCriteria';
      if (searchCriteriaUpdated) {
        refreshLocations($store);
        RefreshFinanceOptions($store);
      }
    });

    refreshCaches($store);
  });
}

// if strict, vuex turns very slow: https://github.com/nuxt/nuxt.js/issues/6279
export const strict = false;
