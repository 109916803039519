import { Component, Vue } from 'vue-property-decorator';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
@Component({
  name: 'optional-benefits',
  components: {
    SectionWithContainer,
  },

  data: () => ({
    optionalBenefits: [
      {
        title: 'Optional benefits you can add',
        content: [
          'Rental car following accident, where available - $100 per day limit, up to 30 days<sup>4</sup>',
          'Removal of basic excess for windscreen claims if the broken windscreen is the only damage to your vehicle - 1 replacement and 2 repairs in any period of insurance',
          'Cover for tools of trade - Up to $3,000 for any one incident',
        ],
      },
      {
        title: 'Options you can select to decrease your premium',
        content: ['Named driver option<sup>1</sup'],
      },
    ],
  }),
})
export default class OptionalBenefits extends Vue {
  public isDarkBackground(index: number): boolean {
    return index % 2 !== 0;
  }
}
