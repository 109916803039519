import Vue from 'vue';
import { VNode } from 'vue/types/umd';
const name = 'line-separator';

export default Vue.extend({
  name,
  props: {
    color: String,
  },

  render(h): VNode {
    return h('div', {
      staticClass: this.color || 'ink-low',
      staticStyle: {
        height: '1px',
        width: '100%',
      },
    });
  },
});
