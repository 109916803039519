import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IFaqsDetails } from '@/constants/Faqs';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
@Component({
  name: 'faqs',
  components: {
    SectionWithContainer,
  },
})
export default class extends Vue {
  @Prop() public faqs!: IFaqsDetails[];
  @Prop() public isTextCenter!: boolean;
  @Prop() public halfTopPadding!: boolean;
  @Prop({ default: 'Frequently asked questions' }) public title!: string;
  @Prop({ default: true }) public showSeeMoreFaqs!: boolean;
  @Prop() public subTitle!: string;
  @Prop() public titleHeadingTag!: string;
  @Prop({ default: false }) public isOneColumn!: boolean;
  @Prop({ default: false }) public isPanelOpened!: number[];

  public openPanel: number[] = !this.isPanelOpened
    ? [-1]
    : this.faqs.map((_, index) => index);

  public isEvenIndex(index: number) {
    return index % 2 === 0;
  }

  @Watch('faqs') public faqsChanged() {
    this.$nextTick(() => {
      this.openPanel = this.faqs.map((_, index) => index);
    });
  }
}
