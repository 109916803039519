import { Component } from 'vue-property-decorator';

import { PagesWithMetaTagsFromTheServer } from '@/components/pages-with-metatags-from-the-server/PagesWithMetaTagsFromTheServer';
import SimplrHeader from '@/features/simplr-landing-page/SimplrHeader';
import CarSubscriptionBenefits from '@/features/simplr-landing-page/CarSubscriptionBenefits';
import OwnershipAndLeasing from '@/features/simplr-landing-page/OwnershipAndLeasing';
import MonthlySubscription from '@/features/simplr-landing-page/MonthlySubscription';
import SubscriptionCars from '@/features/simplr-landing-page/SubscriptionCars';
import SubscriptionSteps from '@/features/simplr-landing-page/SubscriptionSteps';
import SubscriptionFaqs from '@/features/simplr-landing-page/SubscriptionFaqs';
import PopularCars from '@/features/home-page/PopularCars';
import ShopByType from '@/features/home-page/ShopByType';
import { FilterModule } from '@/store/filter';
import { VehicleType } from '~/../shared/inventory/search';

@Component({
  name: 'simplr-landing-page',
  components: {
    SimplrHeader,
    CarSubscriptionBenefits,
    OwnershipAndLeasing,
    MonthlySubscription,
    SubscriptionCars,
    SubscriptionSteps,
    SubscriptionFaqs,
    PopularCars,
    ShopByType,
  },
})
export default class SimplrLandingPage extends PagesWithMetaTagsFromTheServer {
  @FilterModule.State public vehicleTypes!: VehicleType[];

  public get mainVehicleTypes() {
    return this.vehicleTypes.filter((item) => item.isMain);
  }

  public get hasItems(): boolean {
    return this.mainVehicleTypes.length !== 0;
  }
}
