import { AxiosRequestConfig, CancelTokenSource } from 'axios';
import * as Sentry from '@sentry/browser';
import { Dictionary } from '../../../../shared/global/types';
import {
  ActiveQuote,
  EbsExtractionRequest,
  EbsExtractionResponse,
  PrequalifiedApplicationResult,
  SubmitFinancialsResponse,
} from '../../../../shared/personalised-finance/types';
import {
  PreApproval,
  FindCustomerInput,
  MobileVerification,
  Financials,
  IncomeExpenses,
  TaurusData,
} from '../../../../shared/personalised-finance/types/pre-approval';
import {
  AnonymousPricing,
  ConfigParams,
  ConsentItem,
  EligibilityCriteria,
} from '../../../../shared/personalised-finance/types/pre-approval/config';
import { apiFunctions } from '@/helpers/functions';

export async function VerifyCode(value: MobileVerification): Promise<boolean> {
  const callUrl = 'taurus/verifyMobile';
  try {
    await apiFunctions.axios.post(callUrl, value, { withCredentials: true });
    return true;
  } catch (e) {
    return false;
  }
}

export async function CreateCustomer(
  value: PreApproval
): Promise<TaurusData | null> {
  const callUrl = 'taurus/createCustomer';
  try {
    const response = await apiFunctions.axios.post(callUrl, value, {
      withCredentials: true,
      validateStatus: (status) => {
        return status === 422 || (status >= 200 && status < 400); // include 422 for Taurus Unprocessable Entity errors
      },
    } as AxiosRequestConfig);
    return response.data as TaurusData;
  } catch (e) {
    return null;
  }
}

export async function FindCustomer(
  value: FindCustomerInput
): Promise<PreApproval> {
  const callUrl = 'taurus/findCustomer';
  try {
    const response = await apiFunctions.axios.post(callUrl, value);
    return response.data as PreApproval;
  } catch (e) {
    return {} as PreApproval;
  }
}

export async function GetAnonymousPricing(): Promise<AnonymousPricing> {
  const callUrl = 'taurus/getPublicConfig';
  try {
    const params: ConfigParams = { Config: 'AnonymousPricing' };
    const config: AxiosRequestConfig = { params };
    const response = await apiFunctions.axios.get(callUrl, config);
    return response.data as AnonymousPricing;
  } catch (error) {
    Sentry.setContext('message', {
      message: 'Error while fetching taurus anonymous pricing public config',
    });
    Sentry.captureException(error);
    return {} as AnonymousPricing;
  }
}

export async function GetConsents(): Promise<Dictionary<ConsentItem>> {
  const callUrl = 'taurus/getPublicConfig';
  try {
    const params: ConfigParams = { Config: 'Consents', Condition: 'quote' };
    const config: AxiosRequestConfig = { params };
    const response = await apiFunctions.axios.get(callUrl, config);
    return response.data as Dictionary<ConsentItem>;
  } catch (error) {
    Sentry.setContext('message', {
      message: 'Error while fetching taurus consents public config',
    });
    Sentry.captureException(error);
    return {} as Dictionary<ConsentItem>;
  }
}

export async function GetEligibilityCriteria(): Promise<EligibilityCriteria> {
  const callUrl = 'taurus/getPublicConfig';
  try {
    const params: ConfigParams = { Config: 'EligibilityCriteria' };
    const config: AxiosRequestConfig = { params };
    const response = await apiFunctions.axios.get(callUrl, config);
    return response.data as EligibilityCriteria;
  } catch (error) {
    Sentry.setContext('message', {
      message: 'Error while fetching taurus eligibility criteria public config',
    });
    Sentry.captureException(error);
    return {} as EligibilityCriteria;
  }
}

export async function GetEbsExtractionUrl(
  ebsExtractionRequest: EbsExtractionRequest
): Promise<EbsExtractionResponse> {
  const callUrl = 'taurus/ebsExtraction';
  try {
    const response = await apiFunctions.axios.post(
      callUrl,
      ebsExtractionRequest,
      {
        withCredentials: true,
      }
    );
    return response.data as EbsExtractionResponse;
  } catch (e) {
    return {} as EbsExtractionResponse;
  }
}

export async function GetFinanceChoices(): Promise<ActiveQuote> {
  const callUrl = 'taurus/getFinanceChoices';
  const response = await apiFunctions.axios.post(callUrl, undefined, {
    withCredentials: true,
  });
  return response.data as ActiveQuote;
}

export async function SubmitFinanceChoices(
  value: ActiveQuote
): Promise<ActiveQuote> {
  const callUrl = 'taurus/submitFinanceChoices';
  try {
    const response = await apiFunctions.axios.post(callUrl, value, {
      withCredentials: true,
    });
    return response.data as ActiveQuote;
  } catch (e) {
    return {} as ActiveQuote;
  }
}

export async function UpdateFinanceChoices(
  value: ActiveQuote,
  tokenSource: CancelTokenSource
): Promise<ActiveQuote> {
  const callUrl = 'taurus/submitFinanceChoices';
  const response = await apiFunctions.axios.put(callUrl, value, {
    cancelToken: tokenSource.token,
    withCredentials: true,
  });
  return response.data as ActiveQuote;
}

export async function GetFinancials(): Promise<IncomeExpenses> {
  const callUrl = 'taurus/getFinancials';
  try {
    const response = await apiFunctions.axios.post(callUrl, undefined, {
      withCredentials: true,
    });
    return response.data as Financials;
  } catch (e) {
    return {} as Financials;
  }
}

export async function SubmitFinancials(
  value: Financials
): Promise<SubmitFinancialsResponse> {
  const callUrl = 'taurus/submitFinancials';
  try {
    const response = await apiFunctions.axios.post(callUrl, value, {
      withCredentials: true,
    });
    return response.data as SubmitFinancialsResponse;
  } catch (e) {
    return {} as SubmitFinancialsResponse;
  }
}

export async function GetPrequalifiedStatus(): Promise<PrequalifiedApplicationResult> {
  const callUrl = 'taurus/getPrequalifiedStatus';
  try {
    const response = await apiFunctions.axios.post(callUrl, undefined, {
      withCredentials: true,
    });
    return response.data as PrequalifiedApplicationResult;
  } catch (e) {
    return {} as PrequalifiedApplicationResult;
  }
}
