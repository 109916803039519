import { Module, VuexModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import * as typ from '@/features/cta-blocks/types';

const name = 'cta';

@Module({ stateFactory: true })
export default class Cta extends VuexModule {
  public CtaOptions: typ.CtaOptions = typ.ctaOptions;
}

export const CtaModule = namespace(name);
