import Vue from 'vue';
import { VNode } from 'vue/types/umd';

const name = 'centred-container';

export default Vue.extend({
  name,

  render(h): VNode {
    return h(
      'div',
      {
        staticClass: name,
        staticStyle: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        },
      },
      this.$slots.default
    );
  },
});
