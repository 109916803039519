import { Component } from 'vue-property-decorator';
import { Dictionary } from '../../../../shared/global/types';
import { AppModule } from '@/store/app';
import { InventoryModule } from '@/store/inventoryStore';
import BaseDialogWithTitle from '@/components/dialog/BaseDialogWithTitle';
import { BaseDialog } from '@/components/dialog/base-dialog';
import {
  TestImageCapability,
  images,
  webpSupport,
  WebpSupport,
} from '@/components/api-features/webp-support';

@Component({
  name: 'app-version',
  components: {
    BaseDialogWithTitle,
  },
})
export default class extends BaseDialog {
  @AppModule.State public FullVersion!: string;
  @AppModule.State public MainVersion!: string;
  @AppModule.State public Standalone!: boolean;
  @AppModule.State private WebpSupport!: WebpSupport;
  @AppModule.State private IntersectSupported!: boolean;
  @InventoryModule.State public LocalStorageSize!: number;

  public origin: string = '';

  public get webpSupportLabel(): string {
    return this.WebpSupport?.webp ? 'present' : 'absent';
  }

  public get intersectSupportLabel(): string {
    return this.IntersectSupported ? 'present' : 'absent';
  }

  public mounted() {
    this.origin = window?.location.origin;
    this.$store.commit(
      'app/updateIntersectSupported',
      window?.IntersectionObserver
    );

    for (const image of images) {
      TestImageCapability(image, (imageType: string, supported: boolean) => {
        (webpSupport as any as Dictionary<boolean>)[imageType] = supported;
      });
    }

    this.$store.commit('app/updateWebSupport', webpSupport);
  }
}
