let passiveSupported = false;
try {
  if (typeof window !== 'undefined') {
    const testListenerOpts = Object.defineProperty({}, 'passive', {
      get: () => {
        passiveSupported = true;
      },
    });

    // tslint:disable-next-line: no-empty
    window.addEventListener('load', () => {}, testListenerOpts);
    // tslint:disable-next-line: no-empty
    window.removeEventListener('load', () => {}, testListenerOpts);
  }
} catch (e) {
  // So, passive events are not supported... get a browser man!
}

export function addSafeEventListener<K extends keyof WindowEventMap>(
  el: EventTarget,
  type: K,
  listener: (this: EventTarget, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void {
  if (!options) {
    el.addEventListener(type, listener as any);
  } else if (options === true) {
    el.addEventListener(type, listener as any, true);
  } else {
    el.addEventListener(
      type,
      listener as any,
      passiveSupported ? options : false
    );
  }
}
