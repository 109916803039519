import { Vue, Component } from 'vue-property-decorator';
import Mark from 'mark.js';

import PopularQuestionsFaqs, { IFaqsDetails } from '@/constants/Faqs';
@Component({
  name: 'find-a-service',
  data: () => ({
    services: [
      {
        title: 'Buying a car',
        key: 'Buying a car',
        image: '/img/home-page/highest-quality-used-cars.svg',
        description:
          'Everything you need to know about buying a car online or in-store.',
      },
      {
        title: 'Selling a car',
        key: 'Selling a used car',
        image: '/img/home-page/sell-your-old-car.svg',
        description:
          'Got a question about trading in or selling your car to us?',
      },
      {
        title: 'Finance for a car',
        key: 'Finance a used car',
        image: '/img/home-page/pre-qualified-finance.svg',
        description: 'We make used car finance easy. Find out how.',
      },
      {
        title: 'Car care and accessories',
        key: 'Car care and accessories',
        image: '/img/home-page/accessories.svg',
        description:
          'We have a great range of accessories and protection products available.',
      },
      {
        title: 'Servicing and owning',
        key: 'Servicing and Owning',
        image: '/img/home-page/service-your-car.svg',
        description: 'Find out more about servicing your car here.',
      },
      {
        title: 'Protection Plans and other',
        key: 'Protection Plans and other',
        image: '/img/home-page/best-car-guarantee.svg',
        description:
          'Want to know more about your statutory warranty or protection plan?',
      },
    ],
  }),
})
export default class FindAService extends Vue {
  public searchKeyword: string = '';
  public searchResults: IFaqsDetails[] = [];
  public showSearchResult: boolean = false;
  public keyword: string = '';

  public scrollTo(id: string) {
    const top = (document.getElementById(id) as HTMLElement).offsetTop;
    window.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    });
  }

  public get popularQuestionsFaqs() {
    return PopularQuestionsFaqs;
  }

  public get title() {
    if (this.searchResults.length === 0) {
      return `Sorry, we couldn't find any results matching your search. Please try a different search term.`;
    }
    return `Search results for "${this.keyword}"`;
  }

  public isLastItem(index: number): boolean {
    return index === Object.keys(PopularQuestionsFaqs).length - 1;
  }

  public isFirstItem(index: number) {
    return index === 0;
  }

  public className(index: number): string {
    if (index === 0) {
      return 'first-item';
    }
    if (index !== Object.keys(PopularQuestionsFaqs).length - 1) {
      return 'middle-item';
    }

    return 'last-item';
  }

  public get searchResultKeyword() {
    return `Search results for ${this.searchKeyword}`;
  }

  public performMark(keyword: string) {
    const markInstance = new Mark('.context');
    markInstance.unmark({
      done() {
        markInstance.mark(keyword, { exclude: ['h3'] });
      },
    });
  }

  public goBack() {
    this.searchKeyword = '';
    this.showSearchResult = false;
  }

  public submit() {
    this.showSearchResult = !!this.searchKeyword;
    this.keyword = this.searchKeyword;

    const filteredTitle = Object.keys(PopularQuestionsFaqs).filter(
      (key) =>
        key.toLowerCase()?.search(this.searchKeyword.toLowerCase()) !== -1
    );

    if (filteredTitle.length !== 0) {
      this.searchResults = filteredTitle
        .map((value) => PopularQuestionsFaqs[value])
        .flat();
    } else {
      this.searchResults = Object.values(PopularQuestionsFaqs)
        .flat()
        .filter(
          (faq) =>
            faq.title
              .toLowerCase()
              ?.search(this.searchKeyword.toLowerCase()) !== -1 ||
            faq.description
              .join(' ')
              .toLowerCase()
              .search(this.searchKeyword.toLowerCase()) !== -1
        );
    }
    this.$nextTick(() => {
      this.performMark(this.searchKeyword);
    });
  }
}
