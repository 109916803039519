/* eslint-disable */
interface String {
  toProperCase(this: string, splitString?: string): string;
  toNoBreak(this: string): string;
  toMoney(this: string, showDecimals?: boolean): string;
}

String.prototype.toProperCase = function (
  this: string,
  splitString?: string
): string {
  let parts: string[] = [this];

  if (splitString != null) {
    parts = this.split(splitString);
  }

  parts = parts.map((x): string => {
    return x.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  });

  return parts.join(splitString);
};

String.prototype.toNoBreak = function (this: string): string {
  let parts: string[] = [this];

  parts = this.split(' ');

  return parts.join('\xA0');
};

String.prototype.toMoney = function (
  this: string,
  showDecimals: boolean
): string {
  // just some safeguards because we don't know how toMoney is being called
  if (
    !this ||
    this === 'null' ||
    this === 'undefined' ||
    typeof this !== 'string' ||
    this.length == 0
  ) {
    return '';
  }

  const parts = this.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (parts.length > 1 && parts[1].length > 2) {
    parts[1] = parts[1].substr(0, 2);
  }

  if (showDecimals) {
    return parts.join('.');
  } else {
    return parts[0];
  }
};

interface Array<T> {
  asStringArray(this: string[]): string[] | null;
}

Array.prototype.asStringArray = function (this: string[]): string[] | null {
  let isValid = true;
  if (this && this.constructor === Array) {
    if (this.length > 0) {
      isValid = this.every(
        (element) => element && element.constructor === String
      );
    }
  }

  if (isValid) {
    return this;
  }

  return null;
};
