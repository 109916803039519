import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { BaseDialog } from './base-dialog';

@Component
export default class BaseDialogWithTitle extends BaseDialog {
  @Prop() public Title!: string;
  @Prop({ default: '96' }) public MobileLineHeight!: string;
  @Prop({ default: '78' }) public TabletLineHeight!: string;
  @Prop({ default: '52' }) public MobileLargeLineHeight!: string;

  @Watch('value') public valueChanged(value: boolean) {
    if (value) {
      this.$nextTick(this.UpdateThreshold);
    }
  }

  private UpdateThreshold() {
    const vue = this.$refs.cardElement as Vue;

    if (!vue) {
      this.$nextTick(this.UpdateThreshold);
      return;
    }

    const element = vue.$el as HTMLElement;

    element.style.setProperty(
      '--mobile-line-height',
      `${this.MobileLineHeight}px`
    );
    element.style.setProperty(
      '--tablet-line-height',
      `${this.TabletLineHeight}px`
    );
    element.style.setProperty(
      '--mobile-large-line-height',
      `${this.MobileLargeLineHeight}px`
    );
  }
}
