import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterVariants(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Variant.')) {
    const makeIndex = filterKey.indexOf('.');
    const make = filterKey.substr(makeIndex + 1);
    const modelIndex = make.indexOf('.');
    const model = make.substr(modelIndex + 1);
    const variantIndex = model.indexOf('.');
    const variant = model.substr(variantIndex + 1);
    if (!filter.Search.Variant) {
      filter.Search.Variant = [variant];
    } else {
      filter.Search.Variant.push(variant);
    }
    return true;
  }
  return false;
}
