import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { FinanceOptions } from '../../../shared/inventory/search';
import { FinanceChoices } from '../../../shared/finance';
import { financeOptions } from '@/features/filter/types';
import { BannerOptions, bannerOptions } from '@/features/finance/types';

const name = 'finance';

@Module({ stateFactory: true })
export default class Finance extends VuexModule {
  public BannerOptions: BannerOptions = bannerOptions;
  public FinanceOptions: FinanceOptions = { ...financeOptions };
  public FinanceChoices: FinanceChoices = {
    InterestRate: financeOptions.DefaultInterestRate,
    PeriodType: 3,
    TermYears: 5,
    LoanAmount: 0,
  };

  @Mutation public UpdateFinanceOptions(value: FinanceOptions) {
    this.FinanceOptions = { ...this.FinanceOptions, ...value };
  }

  @Mutation public SetFinanceChoices(value: FinanceChoices) {
    this.FinanceChoices = { ...this.FinanceChoices, ...value };
  }
}

export const FinanceModule = namespace(name);
