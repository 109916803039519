import { ModelOptions } from './types';

export const modelOptions: ModelOptions = {
  Top: 8,
  Makes: {
    FORD: [
      {
        Name: 'ECOSPORT',
        Variants: [],
      },
      {
        Name: 'ENDURA',
        Variants: [],
      },
      {
        Name: 'ESCAPE',
        Variants: [],
      },
      {
        Name: 'EVEREST',
        Variants: [],
      },
      {
        Name: 'FALCON',
        Variants: [],
      },
      {
        Name: 'FIESTA',
        Variants: [],
      },
      {
        Name: 'FOCUS',
        Variants: [],
      },
      {
        Name: 'FPV',
        Variants: [],
      },
      {
        Name: 'G6',
        Variants: [],
      },
      {
        Name: 'KUGA',
        Variants: [],
      },
      {
        Name: 'MONDEO',
        Variants: [],
      },
      {
        Name: 'MUSTANG',
        Variants: [],
      },
      {
        Name: 'RANGER',
        Variants: [],
      },
      {
        Name: 'TERRITORY',
        Variants: [],
      },
      {
        Name: 'TRANSIT',
        Variants: [],
      },
      {
        Name: 'TRANSIT CUSTOM',
        Variants: [],
      },
    ],
    HOLDEN: [
      {
        Name: 'ACADIA',
        Variants: [],
      },
      {
        Name: 'ASTRA',
        Variants: [],
      },
      {
        Name: 'BARINA',
        Variants: [],
      },
      {
        Name: 'BARINA SPARK',
        Variants: [],
      },
      {
        Name: 'CALAIS',
        Variants: [],
      },
      {
        Name: 'CAPRICE',
        Variants: [],
      },
      {
        Name: 'CAPTIVA',
        Variants: [],
      },
      {
        Name: 'CASCADA',
        Variants: [],
      },
      {
        Name: 'COLORADO',
        Variants: [],
      },
      {
        Name: 'COLORADO 7',
        Variants: [],
      },
      {
        Name: 'COMMODORE',
        Variants: [],
      },
      {
        Name: 'CRUZE',
        Variants: [],
      },
      {
        Name: 'EQUINOX',
        Variants: [],
      },
      {
        Name: 'INSIGNIA',
        Variants: [],
      },
      {
        Name: 'MALIBU',
        Variants: [],
      },
      {
        Name: 'SPARK',
        Variants: [],
      },
      {
        Name: 'TRAILBLAZER',
        Variants: [],
      },
      {
        Name: 'TRAX',
        Variants: [],
      },
      {
        Name: 'UTE',
        Variants: [],
      },
      {
        Name: 'VOLT',
        Variants: [],
      },
    ],
    HYUNDAI: [
      {
        Name: 'ACCENT',
        Variants: [],
      },
      {
        Name: 'ELANTRA',
        Variants: [],
      },
      {
        Name: 'GENESIS',
        Variants: [],
      },
      {
        Name: 'IONIQ',
        Variants: [],
      },
      {
        Name: 'KONA',
        Variants: [],
      },
      {
        Name: 'SANTA FE',
        Variants: [],
      },
      {
        Name: 'SONATA',
        Variants: [],
      },
      {
        Name: 'TUCSON',
        Variants: [],
      },
      {
        Name: 'VELOSTER',
        Variants: [],
      },
      {
        Name: 'i20',
        Variants: [],
      },
      {
        Name: 'i30',
        Variants: [],
      },
      {
        Name: 'i40',
        Variants: [],
      },
      {
        Name: 'iLOAD',
        Variants: [],
      },
      {
        Name: 'iMAX',
        Variants: [],
      },
      {
        Name: 'iX35',
        Variants: [],
      },
    ],
    MAZDA: [
      {
        Name: 'BT-50',
        Variants: [],
      },
      {
        Name: 'CX-3',
        Variants: [],
      },
      {
        Name: 'CX-5',
        Variants: [],
      },
      {
        Name: 'CX-8',
        Variants: [],
      },
      {
        Name: 'CX-9',
        Variants: [],
      },
      {
        Name: 'MAZDA2',
        Variants: [],
      },
      {
        Name: 'MAZDA3',
        Variants: [],
      },
      {
        Name: 'MAZDA6',
        Variants: [],
      },
      {
        Name: 'MX-5',
        Variants: [],
      },
    ],
    MITSUBISHI: [
      {
        Name: 'ASX',
        Variants: [],
      },
      {
        Name: 'CHALLENGER',
        Variants: [],
      },
      {
        Name: 'ECLIPSE CROSS',
        Variants: [],
      },
      {
        Name: 'EXPRESS',
        Variants: [],
      },
      {
        Name: 'LANCER',
        Variants: [],
      },
      {
        Name: 'MIRAGE',
        Variants: [],
      },
      {
        Name: 'OUTLANDER',
        Variants: [],
      },
      {
        Name: 'PAJERO',
        Variants: [],
      },
      {
        Name: 'PAJERO SPORT',
        Variants: [],
      },
      {
        Name: 'TRITON',
        Variants: [],
      },
      {
        Name: 'i-MiEV',
        Variants: [],
      },
    ],
    NISSAN: [
      {
        Name: '370Z',
        Variants: [],
      },
      {
        Name: 'ALMERA',
        Variants: [],
      },
      {
        Name: 'ALTIMA',
        Variants: [],
      },
      {
        Name: 'DUALIS',
        Variants: [],
      },
      {
        Name: 'GT-R',
        Variants: [],
      },
      {
        Name: 'JUKE',
        Variants: [],
      },
      {
        Name: 'LEAF',
        Variants: [],
      },
      {
        Name: 'MAXIMA',
        Variants: [],
      },
      {
        Name: 'MICRA',
        Variants: [],
      },
      {
        Name: 'MURANO',
        Variants: [],
      },
      {
        Name: 'NAVARA',
        Variants: [],
      },
      {
        Name: 'PATHFINDER',
        Variants: [],
      },
      {
        Name: 'PATROL',
        Variants: [],
      },
      {
        Name: 'PULSAR',
        Variants: [],
      },
      {
        Name: 'QASHQAI',
        Variants: [],
      },
      {
        Name: 'X-TRAIL',
        Variants: [],
      },
    ],
    TOYOTA: [
      {
        Name: '86',
        Variants: [],
      },
      {
        Name: 'AURION',
        Variants: [],
      },
      {
        Name: 'C-HR',
        Variants: [],
      },
      {
        Name: 'CAMRY',
        Variants: [],
      },
      {
        Name: 'COROLLA',
        Variants: [],
      },
      {
        Name: 'FJ CRUISER',
        Variants: [],
      },
      {
        Name: 'FORTUNER',
        Variants: [],
      },
      {
        Name: 'HIACE',
        Variants: [],
      },
      {
        Name: 'HILUX',
        Variants: [],
      },
      {
        Name: 'KLUGER',
        Variants: [],
      },
      {
        Name: 'LANDCRUISER',
        Variants: [],
      },
      {
        Name: 'PRIUS',
        Variants: [],
      },
      {
        Name: 'PRIUS V',
        Variants: [],
      },
      {
        Name: 'PRIUS-C',
        Variants: [],
      },
      {
        Name: 'RAV4',
        Variants: [],
      },
      {
        Name: 'RUKUS',
        Variants: [],
      },
      {
        Name: 'TARAGO',
        Variants: [],
      },
      {
        Name: 'YARIS',
        Variants: [],
      },
    ],
    VOLKSWAGEN: [
      {
        Name: 'AMAROK',
        Variants: [],
      },
      {
        Name: 'ARTEON',
        Variants: [],
      },
      {
        Name: 'BEETLE',
        Variants: [],
      },
      {
        Name: 'CADDY',
        Variants: [],
      },
      {
        Name: 'CARAVELLE',
        Variants: [],
      },
      {
        Name: 'CC',
        Variants: [],
      },
      {
        Name: 'EOS',
        Variants: [],
      },
      {
        Name: 'GOLF',
        Variants: [],
      },
      {
        Name: 'JETTA',
        Variants: [],
      },
      {
        Name: 'MULTIVAN',
        Variants: [],
      },
      {
        Name: 'PASSAT',
        Variants: [],
      },
      {
        Name: 'POLO',
        Variants: [],
      },
      {
        Name: 'SCIROCCO',
        Variants: [],
      },
      {
        Name: 'TIGUAN',
        Variants: [],
      },
      {
        Name: 'TOUAREG',
        Variants: [],
      },
      {
        Name: 'TRANSPORTER',
        Variants: [],
      },
      {
        Name: 'UP!',
        Variants: [],
      },
    ],
  },
  ModelTab: 0,
  VariantTab: 0,
};
