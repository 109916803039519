import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterPaintColour(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('PaintColour.')) {
    const key = filterKey.substring(12);

    if (key) {
      if (filter.Search.PaintColour) {
        filter.Search.PaintColour.push(key);
      } else {
        filter.Search.PaintColour = [key];
      }
    }
    return true;
  }
  return false;
}
