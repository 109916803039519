export const RouteNames = {
  SearchRouteName: 'search',
  SearchWithLocationName: 'search-location',
  NoResultsRouteName: 'no-results',
  SellRouteName: 'sell',
  ServiceRouteName: 'service',
  FinanceRouteName: 'finance',
  LocationsRouteName: 'locations',
  FavouritesRouteName: 'favourites',
  CareersRouteName: 'careers',
  TermsRouteName: 'terms',
  StopNotSellingLandingRouteName: 'stop-not-selling',
  BlogRouteName: 'articles',
};
