var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLandingPage)?_c('div',{staticClass:"primary-section py-5"},[_c('v-container',[_c('div',{staticClass:"heading mb-5 d-flex justify-center d-md-block"},[_c('div',{staticClass:"logo"},[_c('Logo')],1)]),_c('v-row',_vm._l((_vm.footerColumns),function(ref){
var columnNumber = ref.columnNumber;
var itemGroups = ref.itemGroups;
return _c('v-col',{key:columnNumber,staticClass:"flex-grow-1",attrs:{"cols":"6","sm":"4","md":"auto"}},_vm._l((itemGroups),function(ref){
var groupTitle = ref.groupTitle;
var groupItems = ref.groupItems;
return _c('div',[_c('h5',{staticClass:"mb-6 white--text"},[_vm._v(_vm._s(groupTitle))]),_vm._l((groupItems),function(ref){
var title = ref.title;
var target = ref.target;
return _c('p',{key:title},[_c('router-link',{staticClass:"white--text",attrs:{"to":target},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}}},[_vm._v(_vm._s(title))])],1)})],2)}),0)}),1)],1)],1):_vm._e(),_c('div',{class:_vm.isLandingPage ? "primary-section" : "grey"},[_c('v-container',{staticClass:"py-3 d-md-flex align-center"},[(_vm.isLandingPage)?_c('div',{staticClass:"heading mr-10"},[_c('Logo')],1):_vm._e(),_c('div',{staticClass:"d-flex align-center flex-wrap",class:{ "justify-center": _vm.isLandingPage }},[_c('p',{staticClass:"mb-0 pr-3 white--text"},[_vm._v("easyauto123")]),_c('p',{staticClass:"pr-3 my-3 white--text"},[_vm._v("2021 ACN 148 136 314")]),_c('a',{staticClass:"border-left px-3 white--text my-3",attrs:{"href":"/terms-of-use","target":"_blank"}},[_vm._v("Terms of use")]),_c('a',{staticClass:"border-left px-3 my-3 white--text",attrs:{"href":"https://www.eagersautomotive.com.au/privacy-policy/","target":"_blank","rel":"noreferrer"}},[_vm._v("Privacy policy")]),_c('a',{staticClass:"border-left px-3 my-3 white--text",on:{"click":_vm.showAppVersionModal}},[_vm._v("App version "+_vm._s(_vm.MainVersion))])])])],1),_c('app-version',{model:{value:(_vm.isAppVersionModalVisible),callback:function ($$v) {_vm.isAppVersionModalVisible=$$v},expression:"isAppVersionModalVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }