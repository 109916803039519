import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterFuelType(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('FuelType.')) {
    const fuelType = filterKey.substr(9);
    if (fuelType) {
      if (!filter.Search.FuelType) {
        filter.Search.FuelType = [fuelType];
      } else {
        filter.Search.FuelType.push(fuelType);
      }
    }
    return true;
  }
  return false;
}
