import { render, staticRenderFns } from "./AllianzDisclaimer.vue?vue&type=template&id=7f286816&scoped=true&lang=pug&"
import script from "./AllianzDisclaimer.ts?vue&type=script&lang=js&"
export * from "./AllianzDisclaimer.ts?vue&type=script&lang=js&"
import style0 from "./AllianzDisclaimer.vue?vue&type=style&index=0&id=7f286816&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f286816",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionWithContainer: require('/opt/atlassian/pipelines/agent/build/website/src/components/section-with-container/SectionWithContainer.vue').default})
