import { DateTime } from 'luxon';
import { namespace } from 'vuex-class';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import {
  PreApproval,
  FinanceChoices,
  VehicleDetails,
  Outgoings,
  Incomings,
} from '../../../shared/personalised-finance/types/pre-approval';

import {
  financeChoicesGuard,
  incomingsGuard,
  outgoingsGuard,
  personalDetailsGuard,
  residentialAddressGuard,
  taurusDataGuard,
  vehicleDetailsGuard,
} from '../../../shared/personalised-finance/types/type-guards';
import {
  AnonymousPricing,
  EligibilityCriteria,
} from '../../../shared/personalised-finance/types/pre-approval/config';
import { PersonalDetails } from '../../../shared/personalised-finance/types/pre-approval/person';
import { PrequalifiedApplicationResult } from '../../../shared/personalised-finance/types';
import { LocationDetails } from '../../inventory/search';
import {
  eligibilityCriteriaWrapper,
  EligibilityCriteriaWrapper,
} from '@/features/personalised-finance/types';
import {
  GetAnonymousPricing,
  GetEligibilityCriteria,
} from '@/features/personalised-finance/api';
import { storageWrapper } from '@/tools/storage';

const name = 'personalisedFinance';
let anonymousPricingAt: DateTime = DateTime.local().plus({ hours: -24 });
let eligibilityCriteriaAt: DateTime = DateTime.local().plus({ hours: -24 });

@Module({ stateFactory: true })
export default class PersonalisedFinance extends VuexModule {
  public PreApproval: PreApproval = {
    PersonalDetails: {
      ...personalDetailsGuard,
      ResidentialAddress: { ...residentialAddressGuard },
    },
    VehicleDetails: { ...vehicleDetailsGuard },
    FinanceChoices: { ...financeChoicesGuard },
    Financials: {
      Incomings: { ...incomingsGuard },
      Outgoings: { ...outgoingsGuard },
    },
    LocationKey: '',
    TaurusData: { ...taurusDataGuard },
  };

  public selectedLocation: LocationDetails | undefined;
  public AnonymousPricing: AnonymousPricing = {} as AnonymousPricing;
  public PreApprovalResult: PrequalifiedApplicationResult | undefined =
    undefined;

  public EligibilityCriteriaWrapper: EligibilityCriteriaWrapper =
    eligibilityCriteriaWrapper;

  @Mutation public SetEligibilityCriteria(value: EligibilityCriteria) {
    if (!value) {
      return;
    }
    this.EligibilityCriteriaWrapper.EligibilityCriteria = value;
    storageWrapper.Write(`${name}.EligibilityCriteria`, value);
    eligibilityCriteriaAt = DateTime.local();
  }

  @Action({ rawError: true, commit: 'SetEligibilityCriteria' })
  public async LoadEligibilityCriteria() {
    try {
      if (eligibilityCriteriaAt.diffNow('hours').hours > -2) {
        return;
      }

      return await GetEligibilityCriteria();
    } catch (error) {
      // handle some error here
    }
  }

  @Mutation public Init() {
    const anonymousPricing = storageWrapper.Read<AnonymousPricing>(
      `${name}.AnonymousPricing`
    );
    if (anonymousPricing) {
      this.AnonymousPricing = anonymousPricing;
    }

    const eligibilityCriteria = storageWrapper.Read<EligibilityCriteria>(
      `${name}.EligibilityCriteria`
    );
    if (eligibilityCriteria) {
      this.EligibilityCriteriaWrapper.EligibilityCriteria = eligibilityCriteria;
    }
  }

  @Mutation public UpdatePreApprovalResult(
    value: PrequalifiedApplicationResult
  ) {
    this.PreApprovalResult = value;
  }

  @Mutation
  public SetAnonymousPricing(anonymousPricing: AnonymousPricing): void {
    if (!anonymousPricing) {
      return;
    }
    this.AnonymousPricing = anonymousPricing;
    storageWrapper.Write(`${name}.AnonymousPricing`, this.AnonymousPricing);
    anonymousPricingAt = DateTime.local();
  }

  @Action({ rawError: true, commit: 'SetAnonymousPricing' })
  public async LoadAnonymousPricing() {
    try {
      if (anonymousPricingAt.diffNow('hours').hours > -2) {
        return;
      }

      return await GetAnonymousPricing();
    } catch (error) {
      // handle some error here
    }
  }

  @Mutation
  public UpdatePreApproval(value: PreApproval) {
    this.PreApproval = {
      ...this.PreApproval,
      ...value,
    };
  }

  @Mutation
  public ToggleConsent(consentName: string): void {
    if (!this.PreApproval.Consents) {
      this.PreApproval.Consents = {};
    }
    this.PreApproval.Consents = {
      ...this.PreApproval.Consents,
      [consentName]: {
        ...this.PreApproval.Consents[consentName],
      },
    };
  }

  @Mutation
  public UpdateFinanceChoices(value: FinanceChoices): void {
    this.PreApproval.FinanceChoices = {
      ...this.PreApproval.FinanceChoices,
      ...value,
    };
  }

  @Mutation
  public UpdatePersonalDetails(value: PersonalDetails): void {
    value.Email = value.Email?.trim();
    this.PreApproval.PersonalDetails = {
      ...this.PreApproval.PersonalDetails,
      ...value,
    };
  }

  @Mutation
  public UpdateOutgoings(value: Outgoings) {
    if (!this.PreApproval.Financials) {
      this.PreApproval.Financials = {};
    }
    this.PreApproval.Financials.Outgoings = {
      ...this.PreApproval.Financials.Outgoings,
      ...value,
    };
  }

  @Mutation
  public UpdateIncomings(value: Incomings) {
    if (!this.PreApproval.Financials) {
      this.PreApproval.Financials = {
        Incomings: { ...incomingsGuard },
        Outgoings: { ...outgoingsGuard },
      };
    }
    this.PreApproval.Financials.Incomings = {
      ...this.PreApproval.Financials.Incomings,
      ...value,
    };
  }

  @Mutation
  public UpdateVehicleDetails(value: VehicleDetails) {
    this.PreApproval.VehicleDetails = {
      ...this.PreApproval.VehicleDetails,
      ...value,
    };
  }

  @Mutation
  public ClearVehicleDetails() {
    this.PreApproval.VehicleDetails = vehicleDetailsGuard;
  }

  @Mutation
  public SetLocationKey(locationKey: string): void {
    this.PreApproval.LocationKey = locationKey;
  }
}

export const PersonalisedFinanceModule = namespace(name);
