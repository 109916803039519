import { Component, Vue } from 'vue-property-decorator';
import Faqs from '@/components/Faqs/Faqs';

@Component({
  name: 'subscription-faqs',
  components: {
    Faqs,
  },
  data: () => ({
    faqs: [
      {
        title: 'What is car subscription?',
        description: [
          `Car subscription allows you to pay a recurring fee to access a specific vehicle for a certain
          period of time (eg. month-to-month), instead of buying a car or leasing it.`,
        ],
      },
      {
        title: 'How is subscription different from a lease?',
        description: [
          `Car leases and car subscription plans are both attractive alternatives to buying. A car
          subscription gives you access to a fleet of vehicles, maintenance costs, and insurance for a flat
          fee. The main highlights of car subscription services are ease, flexibility, and convenience.`,
        ],
      },
      {
        title: 'What is included in my car subscription plan?',
        description: [
          `All subscription plans include the costs of registration, warranty, comprehensive insurance,
          servicing and roadside assistance. They also include Loyalty Rewards credited to your account
          at different intervals of your subscription.`,
        ],
      },
      {
        title: 'How often are car subscription payments?',
        description: [
          `When you subscribe, you have the choice between weekly, fortnightly or monthly billing cycles.
          All payments are made in advance through your chosen payment method.`,
        ],
      },
      {
        title: 'How often can I swap my car?',
        description: [
          `All subscription plans allow you to swap a vehicle for free after its 120-day minimum
          subscription term with at least 21 days written notice. For any vehicle swaps before its minimum
          term, there is a $350 early swap fee.`,
        ],
      },
      {
        title: 'Who is Simplr?',
        description: [
          `Simplr and easyauto123 have partnered to offer car subscription as a new alternative to motor
          vehicle ownership. Simplr offers Australia's largest range of subscription cars and is backed by
          the country's largest automotive retail group, Eagers Automotive.`,
        ],
      },
    ],
  }),
})
export default class extends Vue {}
