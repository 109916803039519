import Vue from 'vue';
import { VNode } from 'vue/types/umd';
import { VBtn } from 'vuetify/lib';

const name = 'icon-button';

export default Vue.extend({
  name,

  render(h): VNode {
    return h(
      VBtn,
      {
        attrs: { ...this.$attrs },
        scopedSlots: this.$scopedSlots,
        on: this.$listeners,
        props: {
          icon: true,
          ...this.$attrs,
        },
      },
      this.$slots?.default
    );
  },
});
