export interface Reviews {
  _ID: string;
  Name: string;
  Location: string;
  Intro: string;
  Review: string;
  Rating: number;
}

export interface ReviewOptions {
  Reviews: Reviews[];
}

export const reviewOptions: ReviewOptions = {
  Reviews: [
    {
      _ID: 'review_1',
      Name: 'Greg & Jacob (Son) Hudson',
      Location: 'Brisbane, QLD',
      Rating: 5,
      Intro:
        'From the moment we entered the easyauto123 warehouse, it was a no pressure environment...',
      Review:
        'From the moment we entered the easyauto123 warehouse, it was a no pressure environment. Our salesperson Kate showed us the Mitsi Lancer we were interested in and left us to take our time to look at the vehicle. When we made the decision to buy the whole process was quick and painless. When we picked the car up two days later it was well groomed and had been serviced. Fantastic experience and would definitely recommend to anybody wanting a great car for a great price in friendly and no pressure showroom.',
    },
    {
      _ID: 'review_2',
      Name: 'Emily Minturn',
      Location: 'Brisbane, QLD',
      Rating: 5,
      Intro:
        'Staff are very friendly, knowledgeable & welcoming. Staff are not pushy at all, they step back...',
      Review:
        'Staff are very friendly, knowledgeable & welcoming. Staff are not pushy at all, they step back & allow time to freely browse cars within the warehouse, yet are available as soon as you need them. This along with a clean & professional environment definitely made my experience a very pleasant one, 100% will be recommending easyauto123 to friends & family.',
    },
    {
      _ID: 'review_3',
      Name: 'Alyce McKeown',
      Location: 'Melbourne, VIC',
      Rating: 5,
      Intro:
        "Very happy with my new car from easyauto123. I'm an interstate buyer and Andy made the process...",
      Review:
        "Very happy with my new car from easyauto123. I'm an interstate buyer and Andy made the process of purchasing my car from interstate a simple experience!",
    },
    {
      _ID: 'review_4',
      Name: 'David Christopher Sie',
      Location: 'Perth, WA',
      Rating: 5,
      Intro:
        'Giuseppe made the entire process easy and without the high pressure tactics employed by other dealerships...',
      Review:
        'Giuseppe made the entire process easy and without the high pressure tactics employed by other dealerships. We found the right car at the right price. Cheers',
    },
    {
      _ID: 'review_5',
      Name: 'Luke Neal',
      Location: 'Perth, WA',
      Rating: 5,
      Intro:
        'From the moment we entered the easyauto123 warehouse, it was a no pressure environment...',
      Review:
        'From the moment I arrived the team executed one of the best meet and greets I have ever experienced. Previn Chandran was excellent to deal with, he commenced with great rapport building questions and was very professional, this guy made buying my car a delight. The finance was done by Renne Lang and she got me the best interest rate around and provided the best handover to her team for what was a brilliant delivery of my new car. Thanks team easyauto123, you are stars!',
    },
    {
      _ID: 'review_6',
      Name: 'Chanda Khoun',
      Location: 'Perth, WA',
      Rating: 5,
      Intro:
        "Everyone was so friendly and I didn't feel pressured or hassled like other car dealers I've been to...",
      Review:
        "Everyone was so friendly and I didn't feel pressured or hassled like other car dealers I've been to. Would not hesitate to recommend to friends and family. Thank you.",
    },
    {
      _ID: 'review_7',
      Name: 'Mark F',
      Location: 'Perth, WA',
      Rating: 5,
      Intro:
        'Vehicles are competitively priced and staff were easy to deal with during the purchase process...',
      Review:
        'Vehicles are competitively priced and staff were easy to deal with during the purchase process.',
    },
    {
      _ID: 'review_8',
      Name: 'Rosita Wilson',
      Location: 'Perth, WA',
      Rating: 5,
      Intro:
        'The best car buying experience I have ever had! My next car will becoming from easyauto123 Joondalup...',
      Review:
        'The best car buying experience I have ever had! My next car will becoming from easyauto123 Joondalup, and I will not hesitate to recommend the team there to everyone I know who is looking for a car. They are the best!',
    },
    {
      _ID: 'review_9',
      Name: 'Shane McCarthy',
      Location: 'Seven Hills, NSW',
      Rating: 5,
      Intro: 'The whole process is easy.',
      Review: 'The whole process is easy.',
    },
    {
      _ID: 'review_10',
      Name: 'Lance Hawker',
      Location: 'Melbourne, VIC',
      Rating: 5,
      Intro:
        'I found Jai and Sean to be very helpful and they gave me very good advice and would highly recommend them...',
      Review:
        'I found Jai and Sean to be very helpful and they gave me very good advice and would highly recommend them when you purchase a car and will definitely purchase my next car through them.',
    },
    {
      _ID: 'review_11',
      Name: 'Emily Pemble',
      Location: 'Seven Hills, NSW',
      Rating: 5,
      Intro:
        'Our sales person Anthony was fantastic, he was welcoming, kind and patient. He ensured I was satisfied with...',
      Review:
        'Our sales person Anthony was fantastic, he was welcoming, kind and patient. He ensured I was satisfied with the vehicle before committing to purchasing it. He ensured I was well looked after. Overall I am very happy with his customer service and would definitely come back to purchase vehicles in the future and recommend the company to friends and family.',
    },
  ],
};

export interface SlickOptions {
  slidesToShow: number;
  centerMode: boolean;
  dots: boolean;
  arrows: boolean;
  variableWidth: boolean;
  infinite: boolean;
}

export const slickOptions: SlickOptions = {
  slidesToShow: 1,
  centerMode: false,
  dots: true,
  arrows: false,
  variableWidth: true,
  infinite: false,
};
