import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterLifestyle(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Lifestyle.')) {
    const key = filterKey.substring(10);

    if (key) {
      if (filter.Search.Lifestyle) {
        filter.Search.Lifestyle.push(key);
      } else {
        filter.Search.Lifestyle = [key];
      }
    }
    return true;
  }
  return false;
}
