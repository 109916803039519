import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCBWJpRDT96x1jVnbuDHUveru1Bsew_EjQ',
    libraries: 'places',
  },

  installComponents: true,
});
