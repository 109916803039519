import { RouteConfig } from 'vue-router/types';
import { RouteNames } from './route-consts';

export const sellRoutes: RouteConfig[] = [
  {
    path: '/sell',
    name: RouteNames.SellRouteName,
    component: () =>
      import('@/features/sell/Sell.vue').then((m) => m.default || m),
  },
];
