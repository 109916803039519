import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterTransmission(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Trans.')) {
    const trans = filterKey.substr(6);
    if (trans) {
      if (!filter.Search.Transmission) {
        filter.Search.Transmission = [trans];
      } else {
        filter.Search.Transmission.push(trans);
      }
    }
    return true;
  }
  return false;
}
