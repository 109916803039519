import Vue from 'vue';
import { VNode } from 'vue/types/umd';
import ButtonWithIcons from './button-with-icon';

const name = 'text-button';

export default Vue.extend({
  name,

  render(h): VNode {
    return h(
      ButtonWithIcons,
      {
        attrs: {
          text: true,
          large: true,
          ...this.$attrs,
          ...this.$props,
        },
        scopedSlots: this.$scopedSlots,
        on: this.$listeners,
        props: {
          ...this.$attrs,
          ...this.$props,
        },
      },
      this.$slots?.default
    );
  },
});
