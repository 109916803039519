import { FormatAndValidatePhone } from '../utils/formatters/phone.numbers';
import { IsValidNamePart } from '../utils/validation/contact.names';
import { ValidateEmail } from '../utils/validation/email';
import { ValidationStatusPhone } from '../utils/validation/types';
import * as contracts from './index';

export const NameValidationError = 'Must provide First name';
export const SurnameValidationError = 'Must provide Last name (Family name)';

export function ValidatePhone(value: string | undefined): ValidationStatusPhone {
  return FormatAndValidatePhone(value);
}

export function ValidateCustomerDetails(value: contracts.CustomerDetails): contracts.ValidatedCustomerDetails {
  const errors: contracts.ValidatedCustomerDetails = {
    Email: '',
    HasErrors: false,
    Name: '',
    Phone: '',
    PhoneFormatted: '',
    PhoneIsMobile: false,
    Surname: '',
    PostCode: '',
    IsExistingCustomer: false,
  };

  if (!value) {
    errors.HasErrors = true;
    errors.Name = 'Missing customer details';
    return errors;
  }

  if (!IsValidNamePart(value.Surname) || !value.Surname) {
    errors.Surname = NameValidationError;
    errors.HasErrors = true;
  }

  if (!IsValidNamePart(value.Name) || !value.Name) {
    errors.Surname = SurnameValidationError;
    errors.HasErrors = true;
  }

  if (!value.Email && !value.Phone) {
    errors.Phone = `\nMust provide one of Email or Phone number`;
    errors.HasErrors = true;
  }

  if (value.Email) {
    errors.Email = ValidateEmail(value.Email).error;
    if (errors.Email) {
      errors.HasErrors = true;
    }
  }

  if (value.Phone) {
    const validationPhone = ValidatePhone(value.Phone);
    errors.Phone = validationPhone.error;
    if (errors.Phone) {
      errors.HasErrors = true;
    }
    errors.PhoneFormatted = validationPhone.formatted;
    errors.PhoneIsMobile = validationPhone.isMobile;
  }

  return errors;
}
