import { RepaymentOptions } from '../../filter/types';
import {
  InventorySearchConfig,
  NumberRange,
} from '../../../../../shared/inventory/search';

export function FilterRepayment(
  filter: InventorySearchConfig,
  filterKey: string,
  finance: RepaymentOptions
): boolean {
  if (filterKey.startsWith('Repayment.')) {
    let search: NumberRange;
    if (finance.IntervalType === 'week') {
      if (!filter.Search.FinancePerWeek) {
        filter.Search.FinancePerWeek = {};
      }
      search = filter.Search.FinancePerWeek;
    } else if (finance.IntervalType === 'fortnight') {
      if (!filter.Search.FinancePerFortnight) {
        filter.Search.FinancePerFortnight = {};
      }
      search = filter.Search.FinancePerFortnight;
    } else {
      if (!filter.Search.FinancePerMonth) {
        filter.Search.FinancePerMonth = {};
      }
      search = filter.Search.FinancePerMonth;
    }

    if (
      finance.MinValue &&
      finance.MaxValue &&
      Number.isInteger(finance.MinValue) &&
      Number.isInteger(finance.MaxValue)
    ) {
      if (finance.MinValue <= finance.MaxValue) {
        search.MinValue = finance.MinValue;
        search.MaxValue = finance.MaxValue;
      } else {
        search.MinValue = finance.MaxValue;
        search.MaxValue = finance.MinValue;
      }
    } else if (finance.MinValue && Number.isInteger(finance.MinValue)) {
      search.MinValue = finance.MinValue;
    } else if (finance.MaxValue && Number.isInteger(finance.MaxValue)) {
      search.MaxValue = finance.MaxValue;
    }
    return true;
  }
  return false;
}
