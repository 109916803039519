import { Vue, Prop, Component, Watch } from 'vue-property-decorator';

@Component
export default class ToggleButton extends Vue {
  public IsChecked: boolean = false;
  @Prop() public Label!: string;
  @Prop() public value!: boolean;
  @Prop() public Toggle!: () => void;

  @Watch('value') public OnValueChanged(newValue: boolean) {
    if (newValue !== this.IsChecked) {
      this.IsChecked = newValue;
    }
  }

  public mounted() {
    this.IsChecked = this.value || false;
  }

  public ToggleValue() {
    this.IsChecked = !this.IsChecked;
    this.Toggle();
  }

  public get HasSlot(): boolean {
    return !!this.$scopedSlots.default;
  }

  public get CalculateClass() {
    return {
      'is-selected': this.IsChecked,
    };
  }

  public get BorderColour() {
    if (this.IsChecked) {
      return 'secondary';
    }
    return 'ink-low';
  }

  public get LabelColour() {
    if (this.IsChecked) {
      return 'ink--text';
    }
    return 'ink-medium--text';
  }
}
