import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { setContext } from '@sentry/browser';
import * as validation from '../../../shared/enquiries/validation.helpers';
import {
  CustomerDetails,
  EnquiryData,
  ValidatedCustomerDetails,
  AppraisalDetails,
  VehicleDetails,
  EnquiryTypeDefinition,
} from '../../../shared/enquiries';
import { LocationDetails } from '../../inventory/search';
import { storageWrapper } from '@/tools/storage';
import router from '@/router';
import { enquiryApi } from '@/features/enquiries/api';
import {
  GetStockNumber,
  GetLocationCode,
} from '@/features/inventory/helpers/getters';
import {
  GetEnquiryType,
  GetVehicleCondition,
} from '@/features/enquiries/helpers';

import * as typ from '@/features/enquiries/types';

const name = 'enquiries';
@Module({ stateFactory: true })
export default class Enquiries extends VuexModule {
  public Validate: typ.Validate = typ.validate;
  public EnquiryData: EnquiryData = typ.newEnquiryData();
  public OdometerDetails: typ.OdometerDetails = typ.odometerDetails;
  public SellDetails: typ.SellDetails = typ.sellDetails;
  public ValidatedCustomerDetails: ValidatedCustomerDetails =
    {} as ValidatedCustomerDetails;

  public SelectedLocation: LocationDetails | undefined;

  @Mutation public Init() {
    const enquiryData = storageWrapper.Read<EnquiryData>(`${name}.EnquiryData`);
    this.EnquiryData = { ...typ.newEnquiryData() };
    if (enquiryData) {
      this.EnquiryData = { ...this.EnquiryData, ...enquiryData };
    }
  }

  @Mutation public SetLocation(Location?: LocationDetails) {
    this.SelectedLocation = Location;
  }

  @Mutation public UpdateEnquiryData(value: EnquiryData) {
    this.EnquiryData = { ...this.EnquiryData, ...value };
  }

  @Mutation public UpdateAppraisalDetails(value: AppraisalDetails) {
    this.EnquiryData.AppraisalDetails = {
      ...this.EnquiryData.AppraisalDetails,
      ...value,
    };
  }

  @Mutation public UpdateCustomerDetails(value: CustomerDetails) {
    this.EnquiryData.CustomerDetails = {
      ...this.EnquiryData.CustomerDetails,
      ...value,
    };
    this.ValidatedCustomerDetails = validation.ValidateCustomerDetails(
      this.EnquiryData.CustomerDetails
    );
  }

  @Mutation public UpdateVehicleDetails(value: VehicleDetails) {
    this.EnquiryData.VehicleDetails = {
      ...this.EnquiryData.VehicleDetails,
      ...value,
    };
  }

  @Action({ rawError: true }) public async SubmitEnquiry(
    x: typ.SubmitParams
  ): Promise<any> {
    const params = x.params;
    const enquiryData = x.enquiryData;
    const customerValidation = validation.ValidateCustomerDetails(
      enquiryData.CustomerDetails
    );

    enquiryData.CustomerDetails.PhoneIsMobile =
      customerValidation.PhoneIsMobile;

    if (!customerValidation.HasErrors) {
      enquiryData.EnquiryType = GetEnquiryType(
        params.routeName
      ) as EnquiryTypeDefinition;
      enquiryData.EnquiryDetails.SubmittedFromUrl = params.SubmittedFromUrl;
      enquiryData.AppraisalDetails.VehicleCondition =
        GetVehicleCondition(params);
      if (params.inv?._id) {
        enquiryData.VehicleDetails.StockNumber = GetStockNumber(params.inv);
        enquiryData.VehicleDetails.LocationKey = GetLocationCode(params.inv);
      } else {
        enquiryData.VehicleDetails.StockNumber = '';
        enquiryData.VehicleDetails.LocationKey = '';
      }
      try {
        await enquiryApi.SubmitEnquiry(enquiryData);
        router.push(params.ThankYou);
      } catch (error) {
        setContext('apiError', { error });
        throw error;
      }
    }
  }
}

export const EnquiriesModule = namespace(name);
