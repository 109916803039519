import { Component, Vue } from 'vue-property-decorator';
import SpecialHoursAlert from '@/features/location/special-hours-alert/HoursAlert.vue';
// import Covid19 from './covid-19/covid-19-toast.vue';

@Component({
  components: {
    SpecialHoursAlert,
    // Covid19,
  },
})
export default class PSAGroup extends Vue {}
