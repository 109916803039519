import Vue from 'vue';
import { VNode, VNodeData } from 'vue/types/umd';

export default Vue.extend({
  name: 'DualBlock',

  props: {
    height: Number,
  },

  data() {
    return {
      width: 0,
      leftWidth: 0,
      timer: 0,
    };
  },

  computed: {
    screenWidth(): number {
      return this.$vuetify.breakpoint.width;
    },
  },

  watch: {
    screenWidth() {
      clearTimeout(this.timer);
      this.timer = <any>setTimeout(this.CalculateSizes, 50);
    },
  },

  methods: {
    genLeftBlock(): VNode {
      const props: VNodeData = {
        staticStyle: {
          display: 'inline-block',
          height: '100%',
          position: 'absolute',
        },
        ref: 'left-block',
      };
      return this.$createElement('div', props, this.$scopedSlots.left?.({}));
    },

    mounted() {
      this.CalculateSizes();
    },

    genRightBlock(): VNode {
      return this.$createElement(
        'div',
        {
          staticStyle: {
            display: 'inline-block',
            height: '100%',
          },
          style: {
            width: `${this.width - this.leftWidth}px`,
            top: 0,
            left: `${this.leftWidth}px`,
            position: 'absolute',
          },
        },
        this.$scopedSlots.right?.({})
      );
    },
    ClearTimer() {
      clearTimeout(this.timer);
      this.timer = 0;
    },
    CalculateSizes() {
      this.ClearTimer();
      const dualBlock = this.$refs['dual-block'] as HTMLElement;
      let dataChanged = false;
      if (dualBlock) {
        const width = Math.trunc(dualBlock.clientWidth - 0.5);
        if (width !== this.width) {
          this.width = width;
          this.$emit('update:width', this.width);
          dataChanged = true;
        }

        const leftBlock = this.$refs['left-block'] as HTMLElement;
        if (leftBlock) {
          const leftWidth = Math.ceil(leftBlock.clientWidth);
          if (leftWidth !== this.leftWidth) {
            this.leftWidth = leftWidth;
            dataChanged = true;
          }
        }
      }

      if (dataChanged) {
        // loop until sizes no longer change
        this.timer = <any>setTimeout(this.CalculateSizes, 50);
      }
    },
  },

  render(h): VNode {
    const dualBlockData: VNodeData = {
      ref: 'dual-block',
      staticStyle: {
        width: '100%',
        position: 'relative',
      },
    };

    if (this.height) {
      dualBlockData.style = {
        height: `${this.height}px`,
      };
    }
    return h('div', dualBlockData, [this.genLeftBlock(), this.genRightBlock()]);
  },
});
