import Vue from 'vue';
import { DirectiveOptions } from 'vue/types/umd';

export const Focus: DirectiveOptions = {
  inserted: (el) => {
    el.focus();
  },
};

Vue.directive('focus', Focus);
