import { DateTime } from 'luxon';
import { LocationDetails } from '../../../../shared/inventory/search';
import { OpeningDays } from '../../../../shared/global/types';
import {
  getExceptionDate,
  noTimePart,
  maxMonthsAllowedInCalendars,
} from '@/helpers/timezone';

export function getDirections(location: LocationDetails | undefined) {
  if (location) {
    const coordinates = location.Address?.Coordinates;

    if (location.Description === 'Liverpool') {
      return `https://www.google.com/maps?q=easyauto123+${location.Description}+Hume+Highway`;
    }

    if (coordinates) {
      return `https://www.google.com/maps?q=easyauto123+${location.Description}`;
    }
  }
}

export function GetFirstBusinessDays(
  openingDays?: OpeningDays,
  skipBusinessDays: number = 2,
  daysCount: number = 5,
  ignoreDays: number = 1
): DateTime[] {
  const openDays: DateTime[] = [];
  const maxDays = skipBusinessDays + daysCount;
  let date = DateTime.local().set(noTimePart).plus({ days: ignoreDays });
  const maxdate = date.plus({ days: maxDays * 2 });
  let counter = 0;
  while (counter < maxDays && date < maxdate) {
    if (IsOpenOnDate(openingDays, date, date)) {
      openDays.push(date);
      counter++;
    }
    date = date.plus({ days: 1 });
  }

  return openDays.slice(skipBusinessDays, maxDays);
}

export function IsOpenOnDate(
  openingDays?: OpeningDays,
  firstDayAllowed?: DateTime,
  date?: DateTime
): boolean {
  if (!openingDays || !firstDayAllowed || !date) {
    return false;
  }
  const justDate = date.set(noTimePart);
  const lastDayAllowed = DateTime.local()
    .set(noTimePart)
    .plus({ months: maxMonthsAllowedInCalendars });
  if (justDate < firstDayAllowed || justDate > lastDayAllowed) {
    return false;
  }
  const dayOfWeek = justDate.get('weekday');
  const isNormallyOpen =
    dayOfWeek === 1
      ? !!openingDays.Monday?.Open
      : dayOfWeek === 2
      ? !!openingDays.Tuesday?.Open
      : dayOfWeek === 3
      ? !!openingDays.Wednesday?.Open
      : dayOfWeek === 4
      ? !!openingDays.Thursday?.Open
      : dayOfWeek === 5
      ? !!openingDays.Friday?.Open
      : dayOfWeek === 6
      ? !!openingDays.Saturday?.Open
      : !!openingDays.Sunday?.Open;

  if (isNormallyOpen) {
    const exceptionDay = openingDays.Exception?.find(
      (x) => getExceptionDate(x)?.valueOf() === justDate.valueOf()
    );
    if (exceptionDay) {
      return !!exceptionDay.Open;
    }
  }
  return isNormallyOpen;
}
