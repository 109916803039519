import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'monthly-subscription',
  data: () => ({
    content: [
      'Flexible contract term',
      'Switch cars when you like',
      'Comprehensive insurance',
      'Free maintenance and servicing',
      'Free roadside assistance',
      'Free loyalty rewards',
    ],
  }),
})
export default class MonthlySubscription extends Vue {
  public isFirstElement(index: number): boolean {
    return index === 0;
  }

  public isLastElement(index: number, content: string[]) {
    return index === content.length - 1;
  }
}
