import Vue from 'vue';
import { VNode, PropType } from 'vue/types/umd';
import { FAQAnswer } from '../../../../../shared/global/faq';
import { faqCreateParagraphItem } from './item-types/paragraph';
import { faqCreateBulletItem } from './item-types/bullet';
const name = 'faq-answer-item';

export default Vue.extend({
  name,

  props: {
    item: {} as PropType<FAQAnswer | undefined>,
  },

  render(h): VNode {
    switch (this.item?.type) {
      case 'p':
        return faqCreateParagraphItem(h, this.item);
      case 'bullet':
        return faqCreateBulletItem(h, this.item);
      default:
        return h();
    }
  },
});
