import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterMakes(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Make.')) {
    const key = filterKey.substring(5);
    if (!filter.Search.Make) {
      filter.Search.Make = [key];
    } else {
      filter.Search.Make.push(key);
    }
    return true;
  }
  return false;
}
