import { InventorySearchConfig } from '../../../../../shared/inventory/search';
import { RangeOptions } from '../../filter/types';

export function FilterFuelEcon(
  filter: InventorySearchConfig,
  filterKey: string,
  fuelEcon: RangeOptions
): boolean {
  if (filterKey.startsWith('FuelEcon.')) {
    if (!filter.Search.FuelConsumption) {
      filter.Search.FuelConsumption = {};
    }

    if (
      fuelEcon.Criteria.Values &&
      fuelEcon.MaxValue < fuelEcon.Criteria.Values.length - 1
    ) {
      const maxValue = fuelEcon.Criteria.Values?.[fuelEcon.MaxValue];
      if (maxValue) {
        const filterValue = maxValue.Value as number;
        if (Number.isInteger(filterValue)) {
          filter.Search.FuelConsumption.MaxValue = filterValue;
        }
      }
    }

    return true;
  }
  return false;
}
