import Vue from 'vue';
import { VNode } from 'vue/types/umd';
const name = 'svg-close';

export default Vue.extend({
  name,

  props: {
    strokeColour: {
      type: String,
      default: 'icon',
    },
  },

  render(h): VNode {
    const size = 24;
    const margin = 5;
    return h(
      'svg',
      {
        attrs: {
          width: `${size}px`,
          height: `${size}px`,
          viewBox: `0 0 ${size} ${size}`,
        },
      },
      [
        this.$createElement(
          'g',
          {
            attrs: {
              'stroke-width': '2px',
              stroke: `var(--v-surface-base)`,
            },
          },
          [
            this.$createElement('line', {
              attrs: {
                x1: `${margin}`,
                y1: `${margin}`,
                x2: `${size - margin}`,
                y2: `${size - margin}`,
              },
            }),
            this.$createElement('line', {
              attrs: {
                x1: `${size - margin}`,
                y1: `${margin}`,
                x2: `${margin}`,
                y2: `${size - margin}`,
              },
            }),
          ]
        ),
      ]
    );
  },
});
