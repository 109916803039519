import { SearchHints, Selections } from '../types';
import { rangeFormatters, SetRange, SetRangeFromValues } from './rangeHelpers';
import { storageWrapper } from '@/tools/storage';

const name = 'filter';

export function CleanupFilters(
  key: string,
  hints: SearchHints[],
  selections: Selections
) {
  const cleanupComplete =
    CleanupModels(key, selections) ||
    CleanupVariants(key, selections) ||
    CleanupSeats(key, hints, selections) ||
    CleanupYears(key, hints, selections) ||
    CleanupRepaymentFilter(key, hints, selections) ||
    CleanupPrice(key, hints, selections) ||
    CleanupOdometer(key, hints, selections) ||
    CleanupFuelEcon(key, hints, selections);

  if (!cleanupComplete) {
    // Nothing really to do
  }
}

function CleanupModels(key: string, selections: Selections): boolean {
  if (key.startsWith('Make.')) {
    const make = key.substr(5);
    while (true) {
      const i = selections.filters.findIndex(
        (x) =>
          x.startsWith(`Model.${make}.`) || x.startsWith(`Variant.${make}.`)
      );
      if (i >= 0) {
        selections.filters.splice(i, 1);
      } else {
        break;
      }
    }

    return true;
  }
  return false;
}

function CleanupVariants(key: string, selections: Selections): boolean {
  if (key.startsWith('Model.')) {
    const makeAndModel = key.substr(6);
    while (true) {
      const i = selections.filters.findIndex((x) =>
        x.startsWith(`Variant.${makeAndModel}.`)
      );
      if (i >= 0) {
        selections.filters.splice(i, 1);
      } else {
        break;
      }
    }

    return true;
  }
  return false;
}

function CleanupSeats(
  key: string,
  hints: SearchHints[],
  selections: Selections
): boolean {
  if (selections.seats.Criteria.Values && key.startsWith('Seats.')) {
    const maxValue = selections.seats.Criteria.Values.length - 1;
    SetRange(
      rangeFormatters.Seats,
      selections.seats,
      selections.filters,
      hints,
      [0, maxValue]
    );
    storageWrapper.Write(`${name}.SeatsOptions`, selections.seats);

    return true;
  }
  return false;
}

function CleanupYears(
  key: string,
  hints: SearchHints[],
  selections: Selections
): boolean {
  if (key.startsWith('Years.')) {
    SetRangeFromValues(
      rangeFormatters.Years,
      selections.years,
      selections.filters,
      hints,
      [0, 0]
    );
    storageWrapper.Write(`${name}.YearOptions`, selections.years);

    return true;
  }
  return false;
}

function CleanupRepaymentFilter(
  key: string,
  hints: SearchHints[],
  selections: Selections
): boolean {
  if (key.startsWith('Repayment.')) {
    selections.finance.IntervalType = 'week';
    SetRangeFromValues(
      rangeFormatters.RepaymentPerWeek,
      selections.finance,
      selections.filters,
      hints,
      [0, 0]
    );
    storageWrapper.Write(`${name}.Repayment`, selections.finance);

    return true;
  }
  return false;
}

function CleanupPrice(
  key: string,
  hints: SearchHints[],
  selections: Selections
): boolean {
  if (
    selections.price.OverallPriceValue.Criteria.Values &&
    key.startsWith('Price.')
  ) {
    const maxValue =
      selections.price.OverallPriceValue.Criteria.Values.length - 1;
    selections.price.PriceType = 0;
    SetRange(
      rangeFormatters.Years,
      selections.price.OverallPriceValue,
      selections.filters,
      hints,
      [0, maxValue]
    );
    storageWrapper.Write(`${name}.PriceOptions`, selections.price);
    return true;
  }
  return false;
}

function CleanupOdometer(
  key: string,
  hints: SearchHints[],
  selections: Selections
): boolean {
  if (selections.odometer.Criteria.Values && key.startsWith('Odometer.')) {
    const maxValue = selections.odometer.Criteria.Values.length - 1;
    SetRange(
      rangeFormatters.Years,
      selections.odometer,
      selections.filters,
      hints,
      [0, maxValue]
    );
    storageWrapper.Write(`${name}.KilometresOptions`, selections.odometer);
    return true;
  }
  return false;
}

function CleanupFuelEcon(
  key: string,
  hints: SearchHints[],
  selections: Selections
): boolean {
  if (selections.fuelEcon.Criteria.Values && key.startsWith('FuelEcon.')) {
    const maxValue = selections.fuelEcon.Criteria.Values.length - 1;
    SetRange(
      rangeFormatters.Years,
      selections.fuelEcon,
      selections.filters,
      hints,
      [0, maxValue]
    );
    storageWrapper.Write(`${name}.FuelEconomyOptions`, selections.fuelEcon);
    return true;
  }
  return false;
}
