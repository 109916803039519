import { Component, Vue, Prop } from 'vue-property-decorator';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { VehicleType } from '../../../../shared/inventory/search';
import { FilterModule } from '@/store/filter';
import { routerHelper } from '@/helpers/router-helper';
import { RouteNames } from '@/router/route-consts';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';

@Component({
  name: 'shopping-by-type',
  components: {
    SectionWithContainer,
    Swiper,
    SwiperSlide,
  },
})
export default class extends Vue {
  @Prop() public stores!: string[];
  @Prop({ default: false }) public showSubscriptionCars!: boolean;

  @FilterModule.State public vehicleTypes!: VehicleType[];
  @FilterModule.Mutation private ToggleFilter!: (key: string) => void;
  @FilterModule.Mutation private ClearFilters!: () => void;

  public get mainVehicleTypes() {
    return this.vehicleTypes.filter((item) => item.isMain);
  }

  public get numberOfItems(): number {
    return this.mainVehicleTypes.length;
  }

  public mounted() {
    const shopByTypeSwiper = (this.$refs.shopByTypeSwiper as any)?.$swiper;
    if (shopByTypeSwiper) shopByTypeSwiper.update();
  }

  public get swiperOptions() {
    return {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1440: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 3.5,
        },
        640: {
          slidesPerView: 2.5,
          slidesOffsetAfter: 24,
          slidesOffsetBefore: 24,
        },
        320: {
          slidesPerView: 1.5,
          centeredSlides: true,
        },
      },
    };
  }

  public setVehicleType(value: VehicleType) {
    this.ClearFilters();
    this.ToggleFilter(`VehType.${value.Key}`);
    if (this.showSubscriptionCars) {
      this.ToggleFilter('Subscription.Subcription eligible only');
    }
    if (this.stores) {
      this.stores.forEach((store) => {
        this.ToggleFilter(`Location.${store}`);
      });
    }

    routerHelper.$router!.push({ name: RouteNames.SearchRouteName });
  }

  public searchAll() {
    this.ClearFilters();
    if (this.showSubscriptionCars) {
      this.ToggleFilter('Subscription.Subcription eligible only');
    }
    if (this.stores) {
      this.stores.forEach((store) => {
        this.ToggleFilter(`Location.${store}`);
      });
    }

    routerHelper.$router!.push({ name: RouteNames.SearchRouteName });
  }
}
