// Styles
import './_anchor.scss';
import Vue from 'vue';
import { VNode } from 'vue/types/umd';

const name = 'anchor';

export default Vue.extend({
  _scopeId: 'cd204e47-dd8c-419a-a845-68a54eff259f',
} as any).extend({
  name,

  render(h): VNode {
    return h('a', {
      staticClass: name,
    });
  },
});
