import { ConsentItem } from './pre-approval/config';
import { PersonalDetails } from './pre-approval/person';
import {
  TaurusData,
  FinanceChoices,
  FindCustomerInput,
  Incomings,
  MobileVerification,
  Outgoings,
  VehicleDetails,
} from './pre-approval';
import { ActiveQuote, EbsExtractionRequest } from '.';

export const residentialAddressGuard = {
  UnitNumber: '',
  StreetNumber: '',
  StreetName: '',
  StreetType: '',
  Locality: '',
  State: '',
  PostCode: '',
};

export const personalDetailsGuard: PersonalDetails = {
  Forename: undefined,
  Middlename: undefined,
  Surname: undefined,
  Phone: undefined,
  Email: undefined,
  LicenceNo: undefined,
  DateOfBirth: undefined,
  ResidentialAddress: residentialAddressGuard,
};

export const vehicleDetailsGuard: VehicleDetails = {
  VehicleIdentificationNumber: undefined,
  StockNumber: undefined,
  Url: undefined,
  TotalVehiclePrice: undefined,
  VehiclePriceBudget: undefined,
};

export const financeChoicesGuard: FinanceChoices = {
  LoanTermInMonths: 0,
  CashDeposit: undefined,
  TradeInEquity: undefined,
  RepaymentFrequency: undefined,
};

export const incomingsGuard: Incomings = {
  Frequency: 'Weekly',
  Amount: undefined,
};

export const outgoingsGuard: Outgoings = {
  RentMortgage: undefined,
  TotalCreditCardLimits: undefined,
  MonthlyCarLoanRepayments: undefined,
  MonthlyOtherLiabilityRepayments: undefined,
  MonthlyHouseholdExpenses: undefined,
};

export const mobileVerificationGuard: MobileVerification = {
  Mobile: '',
  VerificationCode: '',
};

export const findCustomerInputGuard: FindCustomerInput = {
  Mobile: '',
  DriverLicenceNumber: '',
};

export const consentItemGuard: ConsentItem = {
  message: '',
  order: 0,
  title: '',
  version: 0,
  helpText: undefined,
};

export const taurusDataGuard: TaurusData = {
  MobileVerificationStatus: undefined,
  DataErrors: undefined
};

export const activeQuoteGuard: ActiveQuote = {
  SalePrice: 0,
  EstablishmentFees: 0,
  MonthlyFees: 0,
  Deposit: 0,
  Disclaimer: '',
  InterestRate: 0,
  MinTerm: 0,
  MaxTerm: 0,
  SelectedFrequency: 'Weekly',
  Term: 0,
  RegularRepaymentStructure: {
    WeeklyAmount: 0,
    FortnightlyAmount: 0,
    MonthlyAmount: 0,
  },
};

export const ebsExtractionRequestGuard: EbsExtractionRequest = {
  ThankyouUrl: '',
  ReserveUrl: undefined,
};
