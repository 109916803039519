import { noVariantLabel } from '../const';
import { SearchHints } from '../types';
import { Model } from '~/../shared/inventory/search';

export const makeBmw = 'BMW';

export function CreateOverrideForBmw(
  model: Model,
  searchHintsContainer: SearchHints[]
) {
  const removeSpaceForModels = ['1', '2', '3', '4', '5', '6', '7', '8'];

  if (removeSpaceForModels.includes(model.Name ?? '')) {
    searchHintsContainer.push({
      Description: `${makeBmw.toProperCase()} ${model.Name.toProperCase()}`,
      Source: 'Model',
      Key: `Model.${makeBmw}.${model.Name}`,
    });

    if (model.Variants) {
      searchHintsContainer.push(
        ...model.Variants.map((variant: string) => ({
          Description: `${makeBmw.toProperCase()} ${model.Name.toProperCase()}${
            variant.toProperCase() || noVariantLabel
          }`,
          Source: 'Variant',
          Key: `Variant.${makeBmw}.${model.Name}.${variant}`,
        }))
      );
    }
  }

  searchHintsContainer.push({
    Description: `${makeBmw.toProperCase()} ${model.Name.toProperCase()}`,
    Source: 'Model',
    Key: `Model.${makeBmw}.${model.Name}`,
  });

  if (model.Variants) {
    searchHintsContainer.push(
      ...model.Variants.map((variant: string) => ({
        Description: `${makeBmw.toProperCase()} ${model.Name.toProperCase()} ${
          variant.toProperCase() || noVariantLabel
        }`,
        Source: 'Variant',
        Key: `Variant.${makeBmw}.${model.Name}.${variant}`,
      }))
    );
  }
}
