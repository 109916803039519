import { RouteConfig } from 'vue-router/types';
import { RouteNames } from './route-consts';

export const serviceRoutes: RouteConfig[] = [
  {
    path: '/service/:serviceType?',
    name: RouteNames.ServiceRouteName,
    component: () =>
      import('@/features/service/ServiceWithDealerLocator.vue').then(
        (m) => m.default || m
      ),
  },
];
