import { Component, Vue, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { LocationDetails } from '../../../../../shared/inventory/search';
import { getDirections } from '../helpers';
import { LocationModule } from '@/store/location';
import {
  isOpenToday as isOpenNow,
  getTodayOpeningHoursInTwelveLabel,
} from '@/helpers/timezone';
import {
  ClickToCall,
  leadTypeSubCategoryType,
  formNameType,
} from '@/helpers/analytics-helper';
import { HeaderModule } from '@/store/header';

@Component
export default class Locationitem extends Vue {
  @Prop() public location!: LocationDetails | undefined;
  @Prop() public closeDrawer!: boolean;
  @Prop({ default: undefined }) public elevation: string | undefined;
  @Prop({ default: true }) public isMobile!: boolean;

  @LocationModule.State private LocalTime!: DateTime;
  @LocationModule.Mutation private UpdateLocation!: (
    locationKey: string
  ) => void;

  @HeaderModule.Mutation private hideDrawers!: () => void;

  public get locationKey(): string {
    return this.location?.Key || '';
  }

  public get name(): string {
    if (this.location?.Address) {
      if (this.location.Address.City === this.location.Description) {
        return this.location.Description;
      }
      return [this.location.Address.City, this.location.Description]
        .filter((x) => x)
        .join(', ');
    }
    return '';
  }

  public get addressLine1() {
    const adr = this.location?.Address;
    return `${adr?.StreetNumber || ''} ${adr?.Street || ''}`;
  }

  public get addressLine2() {
    const adr = this.location?.Address;
    return `${adr?.Suburb || ''} ${adr?.StateShort || ''} ${
      adr?.PostCode || ''
    }`;
  }

  public get PreferredStore() {
    return this.location?.Selected ? this.location.Key : undefined;
  }

  public set PreferredStore(value: string | undefined) {
    if (!value) {
      return;
    }

    this.UpdateLocation(value);

    if (this.closeDrawer) {
      this.hideDrawers();
    }
  }

  public showDirections() {
    const url = getDirections(this.location);

    window.open(url, '_blank');
  }

  public showInfo() {
    if (this.location?.LocationUrl) {
      this.$router.push({
        name: 'locations-details',
        params: { locationUrl: this.location.LocationUrl },
      });
    }

    if (this.closeDrawer) {
      this.hideDrawers();
    }
  }

  public openingHours() {
    const openingHours = this.location?.OpeningHours;
    if (openingHours) {
      return getTodayOpeningHoursInTwelveLabel(openingHours, this.LocalTime);
    }
    return '';
  }

  public get isOpen() {
    const openingHours = this.location?.OpeningHours;

    return openingHours && isOpenNow(openingHours, this.LocalTime);
  }

  public get storePhoneNumber() {
    if (this.location?.PhoneNumbers) {
      return this.location.PhoneNumbers.Store || '';
    }
    return '';
  }

  public phoneUs(lead: leadTypeSubCategoryType[]) {
    ClickToCall(`stores${this.location?.Key}${lead[0]}` as formNameType, lead);
  }
}
