export interface BannerCTA {
  Link: string;
  LinkText: string;
}

export interface BannerOptions {
  Image: string;
  Text: string;
  PrimaryCTA: BannerCTA;
  SecondaryCTA: BannerCTA;
  Align: string;
}

export const bannerOptions: BannerOptions = {
  Image: '/img/finance/finance-banner.webp',
  Text: 'Car finance made easy',
  PrimaryCTA: {
    Link: '/buy/finance/buy-enquiry-finance',
    LinkText: 'Book a finance appointment',
  },
  SecondaryCTA: {
    Link: '/buy/personalised-finance',
    LinkText: 'Get Pre-Approved',
  },
  Align: 'center',
};
