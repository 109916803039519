import {
  Payment,
  PaymentFormDetails,
  PaymentsConfig,
} from '../../../../../shared/payments';
import { apiFunctions } from '@/helpers/functions';

class ReserveApi {
  public async GetPaymentsConfig(): Promise<PaymentsConfig> {
    const callUrl: string = 'config/payments';
    const res = await apiFunctions.axios.get(callUrl);

    return res?.data as PaymentsConfig;
  }

  public async ConfirmPayerDetails(payment: Payment): Promise<Payment> {
    const callUrl: string = 'confirmPayerDetails';
    const res = await apiFunctions.axios.post(callUrl, payment, {
      withCredentials: true,
    });

    return res?.data;
  }

  public async SubmitPayment(
    payment: Payment,
    retries: number = 1
  ): Promise<PaymentFormDetails> {
    while (retries-- >= 0) {
      try {
        const callUrl: string = 'submitPayment';
        const res = await apiFunctions.axios.post(callUrl, payment, {
          withCredentials: true,
        });

        return res?.data;
      } catch (error) {
        const errorDetails = error.response;

        if (errorDetails?.status >= 400 && errorDetails?.status < 500) {
          throw error;
        }

        if (retries >= 0) {
          continue;
        }

        throw error;
      }
    }

    throw new Error('Failed to SubmitPayment after all retries');
  }

  public async CheckPayment(): Promise<Payment> {
    const callUrl: string = 'checkPayment';
    const res = await apiFunctions.axios.post(callUrl, undefined, {
      withCredentials: true,
    });

    return res?.data;
  }
}

export const reserveApi = new ReserveApi();
