/* eslint-disable camelcase */
import { Identifier, Dictionary } from '../global/types';

export interface BillingAddress {
  Unit: string | undefined;
  Street: string | undefined;
  Suburb: string | undefined;
  State: string | undefined;
  PostCode: string | undefined;
}

export const billingAddress: BillingAddress = {
  Unit: undefined,
  Street: undefined,
  Suburb: undefined,
  State: undefined,
  PostCode: undefined,
};

export interface AppointmentPreferences {
  PreferredMethod: string | undefined;
  PreferredDate: string | undefined;
  PreferredTime: string | undefined;
  InterestedInFinance: boolean | undefined;
  InterestedToSell: boolean | undefined;
  InterestedInTestDrive: boolean | undefined;
  InterestedToBuy: boolean | undefined;
}

export const appointmentPreferences: AppointmentPreferences = {
  PreferredMethod: undefined,
  PreferredDate: undefined,
  PreferredTime: undefined,
  InterestedInFinance: undefined,
  InterestedToSell: undefined,
  InterestedInTestDrive: undefined,
  InterestedToBuy: undefined,
};

export interface PayerDetails {
  Forename: string | undefined;
  Surname: string | undefined;
  Phone: string | undefined;
  Email: string | undefined;
  PhoneIsMobile: boolean | undefined;
  LicenceNo: string | undefined;
  DateOfBirth: string | undefined;
  BillingAddress: BillingAddress;
  AppointmentPreferences: AppointmentPreferences;
  CustomerComments: string | undefined;
}

export const payerDetailsGuard: PayerDetails = {
  Forename: undefined,
  Surname: undefined,
  Phone: undefined,
  Email: undefined,
  PhoneIsMobile: false,
  LicenceNo: undefined,
  DateOfBirth: undefined,
  BillingAddress: billingAddress,
  AppointmentPreferences: appointmentPreferences,
  CustomerComments: undefined,
};

export function CreateEmptyPayerDetails(): PayerDetails {
  return {
    ...payerDetailsGuard,
    BillingAddress: { ...billingAddress },
    AppointmentPreferences: { ...appointmentPreferences },
  };
}

export function UpdatePayerDetailsData(
  oldValue: PayerDetails,
  newValue: PayerDetails
): PayerDetails {
  return {
    ...oldValue,
    ...newValue,
    BillingAddress: {
      ...oldValue.BillingAddress,
      ...newValue?.BillingAddress,
    },
    AppointmentPreferences: {
      ...oldValue.AppointmentPreferences,
      ...newValue?.AppointmentPreferences,
    },
  };
}

export interface PaymentDetails {
  SessionId: string | undefined;
  IpAddress: string | undefined;
  PaymentAmount: number | undefined;
  StockNumber: string | undefined;
  VehicleIdentificationNumber: string | undefined;
  ReferenceNumber: string | undefined;
  LocationKey: string | undefined;
  ScreenHeight: string | undefined;
  ScreenWidth: string | undefined;
}

export const paymentDetailsGuard: PaymentDetails = {
  SessionId: undefined,
  IpAddress: undefined,
  LocationKey: undefined,
  PaymentAmount: undefined,
  StockNumber: undefined,
  VehicleIdentificationNumber: undefined,
  ReferenceNumber: undefined,
  ScreenHeight: undefined,
  ScreenWidth: undefined,
};

export interface PaymentState {
  InProgress: string; // submitted to payment processor
  Successful: string; // payment has been successfully taken
  Cancelled: string; // cancelled in payment processor
  Failed: string; // payment failed (e.g. declined in processor)
  Abandoned: string; // did not proceed to payment processing
  CarNotAvailable: string; // can't proceed due to already reserved, sold, or otherwise not available
}

export interface PaymentType {
  Reservation: string;
  Purchase: string;
}

export interface PaymentTransactionFields {
  bill_to_forename?: string;
  bill_to_surname?: string;
  bill_to_phone?: string;
  bill_to_email?: string;
  bill_to_address_country: string;
  transaction_uuid: string;
  reference_number: string;
  transaction_type: string;
  amount: string;
  currency: string;
  merchant_defined_data5: string;
  merchant_defined_data6: string;
  customer_ip_address: string;
  customer_browser_screen_height: string;
  customer_browser_screen_width: string;
}

export interface PaymentSignedFields extends PaymentTransactionFields {
  access_key: string;
  profile_id: string;
  signed_field_names: string;
  signed_date_time: string;
  locale: string;
  payment_method: string;
  unsigned_field_names: string;
  signature?: string;
}

export interface PaymentFormDetails {
  PostUri: string;
  FormData: PaymentSignedFields;
}

export interface TransactionDetails {
  RequestTime: Date;
  ResponseTime: Date;
  RequestPostData?: PaymentFormDetails;
  ProcessorResponse?: Dictionary<string>;
}

export interface Payment extends Identifier {
  PaymentState: keyof PaymentState | undefined;
  PaymentType: keyof PaymentType;
  PaymentDetails: PaymentDetails;
  PayerDetails: PayerDetails;
  TransactionDetails: TransactionDetails;
}

const paymentSignedFieldsValidator: PaymentSignedFields = {
  bill_to_forename: '',
  bill_to_surname: '',
  bill_to_phone: '',
  bill_to_email: '',
  bill_to_address_country: '',
  transaction_uuid: '',
  reference_number: '',
  transaction_type: '',
  amount: '',
  currency: '',
  merchant_defined_data5: '',
  merchant_defined_data6: '',
  customer_ip_address: '',
  customer_browser_screen_height: '',
  customer_browser_screen_width: '',
  access_key: '',
  profile_id: '',
  signed_field_names: '',
  signed_date_time: '',
  locale: '',
  payment_method: '',
  unsigned_field_names: '',
};

const paymentSignedFieldsLocal: (keyof PaymentSignedFields)[] = [];

for (const key in paymentSignedFieldsValidator) {
  // eslint-disable-next-line no-prototype-builtins
  if (Object.prototype.hasOwnProperty.call(paymentSignedFieldsValidator, key)) {
    paymentSignedFieldsLocal.push(key as keyof PaymentSignedFields);
  }
}

export const paymentSignedFields = paymentSignedFieldsLocal;

export interface EncryptionKeys {
  access_key: string;
  profile_id: string;
  secret: string;
}

export type LocationKey = 'EB' | 'EC' | 'EN' | 'EH' | 'EJ' | 'EK' | 'EM' | 'ER' | 'ES' | 'EO' | 'EV' ;
export type EnvironmentKey = 'local' | 'Test1' | 'Test2' | 'Test3' | 'Test4' | 'Qual' | 'Prod';

export interface EnvironmentEncryptionKeys extends Identifier {
  _id: EnvironmentKey;
  Default?: EncryptionKeys;
  Location: { [key in LocationKey]?: EncryptionKeys };
  PostUri: string;
}

export const environments: { [key in EnvironmentKey]: string } = {
  Prod: 'easyauto123.com.au',
  Qual: 'easyauto123-website.web.app',
  Test1: 'easyauto123-test1.web.app',
  Test2: 'easyauto123-test2.web.app',
  Test3: 'easyauto123-test3.web.app',
  Test4: 'easyauto123-test4.web.app',
  local: 'localhost',
};

export interface PaymentsConfig extends Identifier {
  ReservationAmount: number;
}
