import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterStockNumber(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('StockNumber.')) {
    const key = filterKey.substr(12);
    if (filter.Search.StockNumber) {
      filter.Search.StockNumber.push(key);
    } else {
      filter.Search.StockNumber = [key];
    }
    return true;
  }
  return false;
}
