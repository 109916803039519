import { Component, Vue } from 'vue-property-decorator';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
@Component({
  name: 'insurance-claim',
  components: {
    SectionWithContainer,
  },
  data: () => ({
    online: {
      title: 'Online, any time',
      content: [
        `A convenient way to lodge a car insurance claim is via the <a href='https://www.allianzclaims.com.au' class='error--text' target='_blank'> online claim form</a>. You can commence submitting your car insurance claim notification 24 hours a day, 7 days a week.`,
        `All you need to do is complete the <a href='https://www.allianzclaims.com.au' class='error--text' target='_blank'> online claim form</a>. Once it is lodged, a friendly Allianz claims consultant will contact you as soon as possible.`,
      ],
      benefits: [
        'Secure form',
        'No phone queues',
        'Immediate confirmation number for your reference',
      ],
    },
    overThePhone: {
      title: 'Over the phone - 24 hours a day, 7 days a week claims service',
      content:
        'You can call the claims hotline on 1300 138 926 and an Allianz consultant will help you commence the claims process.',
    },
  }),
})
export default class InsuranceClaim extends Vue {}
