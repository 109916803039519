import Vue from 'vue';
import { VNode } from 'vue/types/umd';
import { Dictionary } from '../../../../shared/global/types';
const name = 'svg-icon';

const svgImageCache: Dictionary<HTMLElement> = {};

export default Vue.extend({
  name,

  props: {
    iconColour: String,
    width: String,
    height: String,
    overflow: Boolean,
    data: String,
  },

  data() {
    return {
      isLoaderHere: false,
      svgElement: undefined as any as HTMLElement,
    };
  },

  watch: {
    iconColour(newValue: string) {
      if (this.svgElement) {
        this.svgElement.style.fill = newValue;
      }
    },
    width(newValue: string) {
      if (this.svgElement) {
        this.svgElement.setAttribute('width', newValue);
        (this.$el as HTMLElement).style.width = newValue;
      }
    },
    height(newValue: string) {
      if (this.svgElement) {
        this.svgElement.setAttribute('height', newValue);
        (this.$el as HTMLElement).style.height = newValue;
      }
    },
    overflow(newValue: boolean) {
      if (this.svgElement) {
        if (newValue !== undefined) {
          this.svgElement.style.setProperty('overflow', 'visible');
        } else {
          this.svgElement.style.removeProperty('overflow');
        }
      }
    },
  },

  methods: {
    SvgLoaded(event: Event) {
      const object = event.target as HTMLObjectElement | null;
      if (!object) {
        return;
      }
      const document = object?.getSVGDocument();
      const svgElement = document?.documentElement;
      if (!svgElement) {
        return;
      }
      svgImageCache[this.data] = svgElement;
      while (this.$el.childElementCount > 0) {
        this.$el.removeChild(this.$el.children[0]);
      }
      this.LoadSvgFromCache();
    },
    LoadSvgFromCache() {
      this.isLoaderHere = false;
      if (this.$el.childElementCount === 0) {
        const svgElement = svgImageCache[this.data].cloneNode(
          true
        ) as HTMLElement;
        if (this.iconColour) {
          svgElement.style.fill = this.iconColour;
        }
        if (this.width) {
          svgElement.setAttribute('width', this.width);
          (this.$el as HTMLElement).style.width = this.width;
        }
        if (this.height) {
          svgElement.setAttribute('height', this.height);
          (this.$el as HTMLElement).style.height = this.height;
        }

        if (this.overflow) {
          svgElement.style.overflow = 'visible';
        } else if (svgElement.style.overflow) {
          svgElement.style.removeProperty('overflow');
        }

        this.$el.appendChild(svgElement);
        this.svgElement = svgElement;
      }
    },
  },

  render(h): VNode {
    if (!this.data) {
      return h();
    }
    if (!this.isLoaderHere && svgImageCache[this.data]) {
      this.$nextTick(this.LoadSvgFromCache);
      return h('div', {
        staticClass: name,
        staticStyle: {
          'pointer-events': 'none',
        },
        attrs: {
          fill: this.iconColour,
        },
      });
    } else {
      this.isLoaderHere = true;
      return h(
        'div',
        {
          staticClass: name,
          staticStyle: {
            'pointer-events': 'none',
          },
          attrs: {
            fill: this.iconColour,
          },
        },
        [
          this.$createElement('object', {
            attrs: { ...this.$attrs, data: this.data },
            staticStyle: {
              visibility: 'hidden',
            },
            on: {
              load: this.SvgLoaded,
            },
          }),
        ]
      );
    }
  },
});
