








import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class RadioButton extends Vue {
  @Prop() public Label!: string;
  @Prop() public value!: string;

  public get DisplayLabel(): string {
    return this.Label || this.value;
  }
}
