var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-with-container',{staticClass:"popular-cars"},[_c('h3',{staticClass:"secondary--text text-center mb-8"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"popular-cars--desktop"},[_c('v-row',_vm._l((_vm.popularCars),function(ref){
var make = ref.make;
var model = ref.model;
var SearchKey = ref.SearchKey;
var image = ref.image;
return _c('v-col',{key:model,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"car",on:{"click":function($event){return _vm.SearchFor(SearchKey.make, SearchKey.model)}}},[_c('div',{staticClass:"image rounded-lg"},[_c('img',{attrs:{"src":image,"alt":(make + " " + model),"loading":"lazy"}})]),_c('h4',{staticClass:"white--text"},[_vm._v(_vm._s(make)+" "+_vm._s(model))])])])}),1)],1),_c('div',{staticClass:"models my-md-8"},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h5',{staticClass:"secondary--text text-center"},[_vm._v("See more used cars")])]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"justify-space-between list mx-n8"},_vm._l((_vm.carList),function(ref,index){
var cars = ref.cars;
return _c('v-col',{key:index,staticClass:"md-auto",attrs:{"cols":"6","sm":"3"}},_vm._l((cars),function(ref){
var model = ref.model;
var searchKey = ref.searchKey;
return _c('p',{key:model,on:{"click":function($event){return _vm.SearchFor(searchKey.make, searchKey.model)}}},[_vm._v(_vm._s(model))])}),0)}),1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }