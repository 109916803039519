import Vue from 'vue';
import { VNode, VNodeData } from 'vue/types/umd';
import { VBtn } from 'vuetify/lib';
import { Dictionary } from 'vue-router/types/router';

const name = 'button-with-icon';

export default Vue.extend({
  name,
  props: {
    label: String,
    border: String,
    background: String,
  },

  computed: {
    backgroundColor(): string | undefined {
      return this.background ? `var(--v-${this.background}-base)` : undefined;
    },
  },

  render(h): VNode {
    const style: Dictionary<string> = {};
    const data: VNodeData = {
      attrs: this.$attrs,
      props: {
        ...this.$attrs,
        ...this.$props,
      },
      staticClass: `${name}`,
      style,
      on: this.$listeners,
    };
    if (this.border) {
      style['border-color'] = `var(--v-${this.border}-base)`;
      style.border = '1px solid';
    }
    if (this.backgroundColor) {
      style['background-color'] = this.backgroundColor;
      style['::before'] = '{color: "transparent"}';
    }
    return h(VBtn, data, this.$slots.default || this.label);
  },
});
