import { Inventory } from '../../../../../shared/inventory';
export interface VdpOptions {
  ShowCalculator: boolean;
  ShowPremium: boolean;
  ShowPremiumPlus: boolean;
  ProtectionPlanDialog: boolean;
  ShowRoadsideAssistance: boolean;
  ShowAdditionalPartsSavings: boolean;
  carouselStart: number;
}

export const vdpOptions: VdpOptions = {
  ShowCalculator: false,
  ShowPremium: false,
  ShowPremiumPlus: false,
  ProtectionPlanDialog: false,
  ShowRoadsideAssistance: false,
  ShowAdditionalPartsSavings: false,
  carouselStart: 0,
};

export interface AdvertisedRateOptions {
  ShowAdvertisedRateDisclaimer: boolean;
  Inventory: Inventory | undefined;
}

export const advertisedRateOptions: AdvertisedRateOptions = {
  ShowAdvertisedRateDisclaimer: false,
  Inventory: undefined,
};
