import Vue from 'vue';
import { VNode } from 'vue/types/umd';

const name = 'aspect-container';

export default Vue.extend({
  name,
  props: {
    aspectRatio: {
      type: Number,
      default: 1,
    },
  },

  render(h): VNode {
    const ratio = this.aspectRatio || 1;

    return h(
      'div',
      {
        staticClass: name,
        staticStyle: {
          position: 'relative',
        },
      },
      [
        h('div', {
          staticStyle: { width: '100%' },
          style: { paddingBottom: `${100 / ratio}%` },
        }),
        h(
          'div',
          {
            staticStyle: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            },
          },
          this.$slots.default
        ),
      ]
    );
  },
});
