import { DateTime } from 'luxon';
import { OpeningDays } from '../../../../shared/global/types';
import { SubmitEnquiryParams } from './types';
import {
  noTimePart,
  getTodayOpeningHours,
  getHoursInTargetTimezone,
} from '@/helpers/timezone';
import { RouteNames } from '@/router/route-consts';

export function GetEnquiryType(value?: string): string {
  if (value === 'seethiscar') {
    return 'Buy_Specific_Car';
  } else if (value === 'generalsoldenquire') {
    return 'Buy_Specific_Car';
  } else if (value === 'generalenquire') {
    return 'Buy_Specific_Car';
  } else if (value === 'financeenquire') {
    return 'Buy_Finance';
  } else if (value === 'financeenquiry') {
    return 'Buy_Finance';
  } else if (value === RouteNames.NoResultsRouteName) {
    return 'Buy_Notify';
  } else if (value === 'pricematchenquire') {
    return 'Buy_Price_Match';
  } else if (value === 'sellenquire') {
    return 'Sell_My_Car';
  } else if (value === 'black-friday') {
    return 'Buy_Finance';
  }
  return '';
}

export function GetVehicleCondition(params: SubmitEnquiryParams): string {
  return (
    'Exterior paint and body: ' +
    params.Exterior +
    '\t\n' +
    'Tyres: ' +
    params.Tyres +
    '\t\n' +
    'Interior and upholstery: ' +
    params.Interior +
    '\t\n' +
    'Service books: ' +
    params.Service
  );
}

function MoveTimeToDate(
  date: DateTime,
  time: DateTime | undefined
): DateTime | undefined {
  if (!time) {
    return;
  }
  return time.set({ day: date.day, month: date.month, year: date.year });
}

export function GetSellEnquiryBookingHours(
  openingDays?: OpeningDays,
  date?: DateTime
): DateTime[] {
  const justDate = (date || DateTime.local()).set(noTimePart);

  const hours = [];
  if (openingDays) {
    const todayOpeningHours = getTodayOpeningHours(openingDays, justDate);
    const openingTime = MoveTimeToDate(
      justDate,
      getHoursInTargetTimezone(todayOpeningHours?.Open, openingDays.Timezone)
    );
    const closingTime = MoveTimeToDate(
      justDate,
      getHoursInTargetTimezone(todayOpeningHours?.Close, openingDays.Timezone)
    );
    if (openingTime && closingTime) {
      let slotTime = openingTime;
      while (slotTime < closingTime) {
        if (slotTime > DateTime.local()) {
          hours.push(slotTime);
        }
        slotTime = slotTime.plus({ minutes: 30 });
      }
    }
  }
  return hours;
}
