import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'section-with-container' })
export default class extends Vue {
  @Prop() public noPadding: boolean | undefined;
  @Prop() public halfBottomPadding: boolean | undefined;
  @Prop() public halfTopPadding: boolean | undefined;
  @Prop() public noHorizontalPadding: boolean | undefined;
  @Prop() public noRightMargin: boolean | undefined;
}
