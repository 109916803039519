import { NuxtAxiosInstance } from '@nuxtjs/axios';

let $axios: NuxtAxiosInstance;

export function initializeAxios(axiosInstance: NuxtAxiosInstance) {
  $axios = axiosInstance;
}
export interface DebounceTimer {
  reject?: (reason?: any) => void;
  timer?: number;
}

class FirebaseFunctions {
  public get axios() {
    return $axios;
  }

  public async Debounce<T>(
    timer: DebounceTimer,
    method: () => Promise<T>
  ): Promise<T> {
    clearTimeout(timer.timer);
    if (timer.reject) {
      timer.reject('debounce reject');
    }

    await new Promise((resolve, reject) => {
      (timer.timer as any) = setTimeout(resolve, 200);
      timer.reject = reject;
    });

    return await method();
  }
}

export const apiFunctions = new FirebaseFunctions();
