export interface IFaqsDetails {
  title: string;
  description: string[];
}

interface IPopularQuestionsFaqs {
  [key: string]: IFaqsDetails[];
}

const PopularQuestionsFaqs: IPopularQuestionsFaqs = {
  'Buying a car': [
    {
      title: 'Can I really buy a used car online?',
      description: [
        'Yes, you can select one of our used cars, pay a deposit and sign your paperwork, 100 % online. You can also come into any of our <a href="/locations", target="_blank", class="error--text">superstores</a> to view and test-drive a car before you buy it.',
      ],
    },
    {
      title: 'Can you deliver my used car?',
      description: [
        'In most cases yes! We have a network of transport companies that will deliver to most locations. Contact one of our friendly team members and we’ll arrange a quote for you, as a delivery fee will apply.',
      ],
    },
    {
      title: 'How do I reserve a used car?',
      description: [
        'Simply find a car you like, select the “RESERVE NOW” button, fill in some basic information, pay a fully refundable $500 deposit and we’ll hold the car for 48 hours subject to confirmation from one of our friendly team members.',
      ],
    },
    {
      title: 'What is the Registration Service Fee?',
      description: [
        'This is a fee we charge to prepare all of your paperwork for transfer and registration of your car and, in some cases, transfer the ownership of the car into your name. This often requires significant administration and time spent with local transport authorities. Alternatively, you may opt to organise this yourself, where applicable.',
      ],
    },
    {
      title: 'How does the 7-day Money Back Guarantee Work?',
      description: [
        'We are here to make used car buying easy and we know that one of the things customers buying used cars are often worried about is buying the wrong car or changing their mind. Well, worry no more.',
        'Did you know that we are so proud of the quality of the thousands of easyauto123 Quality Verified vehicles that we sell each year, that they are all backed by our unique 7-day Money Back Guarantee? See the full terms and conditions <a href="/terms-of-use/ea123--7day%20terms-v5.pdf", target="_blank", class="error--text">here</a>.',
      ],
    },
    {
      title: 'Do all cars come with a 7-day Money Back Guarantee?',
      description: [
        '<img width="40px" src = "/img/vdp/qv.svg" /> All our Quality Verified cars with this seal come with our famous 7-day Money Back Guarantee.',
        '<img width="40px" src="/img/vdp/budget.svg" /> &nbsp;Budget cars with this icon <u>do not</u> qualify for the 7-day Money Back Guarantee.',
      ],
    },
    {
      title: 'What is a Quality Verified used car?',
      description: [
        'Each Quality Verified used car must pass our rigorous vehicle inspection and comes with our famous 7-day Money Back Guarantee and roadside assistance.',
      ],
    },
    {
      title: 'What is a Budget used car?',
      description: [
        'A budget car is usually a little older, a little cheaper and may have more signs of wear and tear and does not meet our meticulous Quality Verified standards. We save on repairing dents and scratches and pass those savings on to you. All Budget cars still must meet our minimum safety requirements before sale.',
      ],
    },
    {
      title: 'Does my car come with free roadside assistance?',
      description: [
        'Most of our Quality Verified used cars come with free roadside assistance. Please confirm with an easyauto123 Team Member at the time of purchase.',
        'We are here to make used car buying easy and we know that one of the things customers buying used cars often worry about is what if something unexpectedly goes wrong.',
        'Well, one of the many benefits of buying a car with easyauto123 is that we care for our customers, and that’s why we offer Free Roadside Assistance on most Quality Verified vehicles we sell. So, you can drive with confidence knowing if you need help it is there 24 hours a day, 7 days a week, 365 days a year.',
        'We do not offer free roadside assistance on our Budget range of used cars, but we may be able to offer you a discounted rate on premium roadside assistance.',
      ],
    },
    {
      title: 'Does easyauto123 really have Haggle Free Pricing?',
      description: [
        `Yes, we are here to make used car buying easy and we know that one of the things customers dislike most about buying a used car is that often the advertised price is not the best price. To get a better price you have to go back and forth numerous times and negotiate with a mysterious ‘Sales Manager’.`,
        'easyauto123 has taken all the stress and anxiety away, as well as saving you heaps of time, by having Haggle Free Pricing. It’s pretty simple: we put our Best Price on every car upfront. That’s one less thing to worry about when buying your used car from us.',
      ],
    },
    {
      title: 'Can I subscribe a car?',
      description: [
        'Many of our quality used cars are eligible for our subscription service. Simply find a car and select “SUBSCRIBE CAR NOW” to see if the car you love is eligible for subscription.',
      ],
    },
  ],
  'Selling a used car': [
    {
      title: 'Will you buy any used car?',
      description: [
        'In most cases yes, we’ll happily provide you with an obligation free valuation on any used car you have to sell. It doesn’t mean that it will pass our strict standards and make it into one of our superstores. We always conduct a PPSR check and will not buy any written-off or reported stolen cars.',
      ],
    },
    {
      title: 'Can I just sell my used car to you?',
      description: [
        'Yes, we’re happy to give you an obligation free valuation and buy your car from you outright or as a trade-in as part of a sale.',
      ],
    },
    {
      title: 'I have finance owing on my used car, will you buy it?',
      description: [
        'Yes, however we will require you to provide a current payout letter from your existing financier and settle any outstanding finance.',
      ],
    },
    {
      title:
        'I can’t make it to one of your locations, can you still buy my car?',
      description: [
        'We may be able to give you a valuation based on images or a video call and arrange to transport your car to us if you are happy with the agreement.',
      ],
    },
    {
      title: 'What are the advantages of selling my used car to easyauto123?',
      description: [
        'We don’t just make used car buying easy but selling your car too. Let us demonstrate to you just how easy it is to sell your used car. Regardless of age, kilometres or condition we will buy any car. Yes, any car!',
        'But why are we different to all the others, well here’s why.',
        '<p class="mb-n5"><strong>No obligation to sell</strong></p>',
        'We don’t use pushy sales tactics but provide a no hassle assessment of your vehicle.',
        '<p class="mb-n5"><strong>We can settle any finance</strong></p>',
        'Looking to sell a car with remaining finance? No problem, we can settle it!',
        '<p class="mb-n5"><strong>Sell your car in under an hour</strong></p>',
        `We'll buy your car in under an hour - get a free valuation onsite or by entering your details online <a href="/locations", target="_blank", class="error--text">Sell or trade-in your used car online</a> and we will contact you `,
        '<p class="mb-n5"><strong>Avoid the hassle of a lengthy private sale</strong></p>',
        'Dodge the hassle of a private car sale with our quick, convenient appointments.',
        '<p class="mb-n5"><strong>Have cash in your bank in no time</strong></p>',
        'We’ll have the cash in your bank, quick & smart.',
      ],
    },
  ],
  'Car care and accessories': [
    {
      title: 'What accessories can you fit to my car?',
      description: [
        'We have access to a large range of quality accessories such as bull bars, roof racks, tow bars, bike racks, and many more. If they make it, we can most likely get it and fit it before you take delivery of your car, just ask one of our friendly team members for more information.',
      ],
    },
    {
      title: 'Can you tint my windows before delivery?',
      description: [
        'Yes, we can provide you with a selection of window tints for you to choose from and have it fitted before you pick up your car. Ask one of our friendly team members for more information.',
      ],
    },
    {
      title: 'How can I ensure my car stays in great condition?',
      description: [
        'We can provide you with paint protection, seat covers and other protection products to ensure your car stays in excellent condition for longer. Ask one of our friendly team for more information.',
      ],
    },
  ],
  'Finance a used car': [
    {
      title: 'Can I do my finance application 100% online?',
      description: [
        "Yes, say goodbye to sitting in finance offices for hours on end. Jump online and experience used car buying and financing with ease and convenience. You can do your finance application 100% online in only minutes. You can even do it on your mobile device. All it takes is some basic information, a driver's licence and you could be fully approved, all without ever visiting a used car dealership. Now, that’s easy. ",
      ],
    },
    {
      title: 'Is applying for finance online safe?',
      description: [
        'Our partners use bank grade security. You can find more detailed information on our dedicated finance page <a href="/buy/finance", target="_blank", class="error--text">here</a>.',
      ],
    },
    {
      title: 'I’ve had problems getting finance previously, can you help?',
      description: [
        'Every case is different but easyauto123 has access to multiple lenders and may be able to help. Contact your nearest <a href="/locations", target="_blank", class="error--text">superstore</a> and we’ll do our best to assist.',
      ],
    },
    {
      title:
        'I haven’t found a used car yet, can I still do an online finance application?',
      description: [
        'Yes, you can apply for conditional pre-approval against a specific car, or if you haven’t quite found that perfect car, no stress, apply for pre-approval against your dream car budget, all in under 5 minutes. It’s that simple.',
      ],
    },
  ],
  'Servicing and Owning': [
    {
      title: 'Where can I service my car?',
      description: [
        'Please enter your Suburb or Postcode <a href="/service/book-service#service-locations", target="_blank", class="error--text">here</a> to locate your closest Partner Service Centre.',
      ],
    },
    {
      title:
        'How do I make a service booking at one of the Preferred Partner Service Centres?',
      description: [
        'Our list of Preferred Partner Service Centres has links and phone numbers for each of the centres, making direct booking easy. Please enter your Suburb or Postcode <a href="/service/book-service#service-locations", target="_blank", class="error--text">here</a> to locate your closest Partner Service Centre.',
      ],
    },
    {
      title:
        'What is the service price at the Preferred Partner Service Centres?',
      description: [
        'Please enter your Suburb or Postcode <a href="/service/book-service#service-locations", target="_blank", class="error--text">here</a> to locate your closest Partner Service Centre and then click on the "<a href="/service/book-service#service-locations", target="_blank", class="error--text">Book a Service</a>" to make an Online Booking and to access Premium or Premium PLUS pricing. If you select Logbook Service, the Partner Service Centre will respond with the cost for your relevant vehicle and service requirements.',
      ],
    },
    {
      title:
        'I have an easy Car Care™ Protection Plan, where can I service to maintain my plan?',
      description: [
        'Scheduled car maintenance servicing to maintain your Protection Plan can be done at any Preferred Partner Service Centre. Please enter your Suburb or Postcode <a href="/service/book-service#service-locations", target="_blank", class="error--text">here</a> to locate your closest Partner Service Centre.',
      ],
    },
    {
      title: 'I have a Service Saver Plan, what do I do?',
      description: [
        'easyauto123 will service your car for the life of your Service Saver Plan. Please follow the link to make a booking at your nearest <a href="/locations", target="_blank", class="error--text">easyauto123 superstore</a>.',
      ],
    },
    {
      title:
        'I have an easy Car Care™ Protection Plan with a Service and Tyre Credit and need to repair or replace my tyres. Where can I get this done?',
      description: [
        'Your Service and Tyre Credit can be validated and used at any Preferred Partner Service Centre. Please enter your Suburb or Postcode <a href="/service/book-service#service-locations", target="_blank", class="error--text">here</a> to locate your closest Partner Service Centre.',
      ],
    },
  ],

  'Protection Plans and other': [
    {
      title:
        'My car is still under the statutory warranty and there is an issue or concern, what do I do?',
      description: [
        'At easyauto123 we pride ourselves on delivering quality used cars, so if there is an unfortunate issue or concern, please contact us directly so we can quickly address it for you. Please contact your nearest <a href="/locations", target="_blank", class="error--text">easyauto123 superstore</a>.',
      ],
    },
    {
      title:
        'I have an easy Car Care™ Protection Plan and there is a mechanical issue or concern with my car, what do I do?',
      description: [
        'As a customer with an easy Car Care™ Protection Plan and who has a concern or mechanical issue with their car, please contact your <a href="/locations", target="_blank", class="error--text">easyauto123 superstore</a> for assistance.',
      ],
    },
    {
      title: 'I’ve broken down, how do I contact roadside assistance?',
      description: [
        'Call our national roadside assist hotline 24/7 on 1800 817 877. Have your vehicle registration and your current location ready.',
      ],
    },
  ],
};

export default PopularQuestionsFaqs;
