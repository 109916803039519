import { render, staticRenderFns } from "./faq-item.vue?vue&type=template&id=fba56cbe&scoped=true&lang=pug&"
import script from "./faq-item.ts?vue&type=script&lang=js&"
export * from "./faq-item.ts?vue&type=script&lang=js&"
import style0 from "./faq-item.vue?vue&type=style&index=0&id=fba56cbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fba56cbe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon})
