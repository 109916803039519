import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Toast extends Vue {
  @Prop() public IsVisible!: boolean;
  @Prop() public height!: number | undefined;

  public get MinHeight(): number {
    return Math.max(this.height || 0, 36) + 33;
  }

  public get Style(): object {
    return { height: `${this.MinHeight}px` };
  }

  public get ToastClass() {
    return { collapsed: !this.IsVisible };
  }

  public CloseToast() {
    this.$emit('on-close');
  }
}
