import Vue from 'vue';

import PrivacyPolicyLink from '../features/privacy-policy/link/PrivacyPolicyLink.vue';
import DualBlock from '@/components/building-blocks/dual-block';
import HeaderSimple from '@/components/building-blocks/header-simple/header-simple.vue';
import Anchor from '@/components/building-blocks/anchor';
import AspectContainer from '@/components/building-blocks/aspect-container';
import CentredContainer from '@/components/building-blocks/centred-container';

import NumberDropDown from '@/components/input/NumberDropDown/NumberDropDown.vue';
import InputElement from '@/components/input/text/input-element';
import SimpleCheckbox from '@/components/input/text/simple-checkbox';
import Toast from '@/components/toast/toast.vue';
import ToastGroup from '@/components/toast/toast-group.vue';
import ScrollContainer from '@/components/containers/scroll-container/scroll-container';
import LineSeparator from '@/components/decorators/line-separator';
import FormDatePicker from '@/components/input/FormDatePicker/form-date-picker.vue';
import FormTimePicker from '@/components/input/FormTimePicker/form-time-picker.vue';

import CheckBox from '@/components/buttons/check-box/check-box.vue';
import BrickButton from '@/components/buttons/brick-button/brick-button';
import ToggleButton from '@/components/buttons/toggle-button/toggle-button.vue';
import ColourButton from '@/components/buttons/colour-button/colour-button.vue';
import ButtonIcon from '@/components/icons/button-icon.vue';
import ButtonWithIcon from '@/components/buttons/button-with-icon';

import PrimaryButton from '@/components/buttons/primary-button';
import SecondaryButton from '@/components/buttons/secondary-button';
import TextButton from '@/components/buttons/text-button';
import IconButton from '@/components/buttons/icon-button';
import FabButton from '@/components/buttons/fab-button';
import RadioButton from '@/components/buttons/radio-button/RadioButton.vue';

import SvgIcon from '@/components/icons/svg-icon';
import SvgClose from '@/components/icons/svg-close';
import SvgIconDone from '@/components/icons/svg-icon-done.vue';
import SvgIconDescription from '@/components/icons/svg-icon-description.vue';
import SvgIconPlus from '@/components/icons/svg-icon-plus.vue';
import SvgIconPrint from '@/components/icons/svg-icon-print.vue';
import SvgIconInfo from '@/components/icons/svg-icon-info.vue';
import SvgIconStarFull from '@/components/icons/svg-icon-star-full.vue';
import SvgIconStarHalf from '@/components/icons/svg-icon-star-half.vue';
import SvgIconStarEmpty from '@/components/icons/svg-icon-star-empty.vue';
import SvgIconPerson from '@/components/icons/svg-icon-person.vue';
import SvgIconPayment from '@/components/icons/svg-icon-payment.vue';
import SvgIconDirectionsCar from '@/components/icons/svg-icon-directions-car.vue';
import SvgIconDirections from '@/components/icons/svg-icon-directions.vue';
import SvgIconEdit from '@/components/icons/svg-icon-edit.vue';
import SvgIconArrowBack from '@/components/icons/svg-icon-arrow-back.vue';

import SvgIconMapNationwide from '@/components/icons/svg-icon-map-nationwide.vue';
import SvgIconEye from '@/components/icons/svg-icon-eye.vue';
import SvgIconHotCar from '@/components/icons/svg-icon-hot-car.vue';
import SvgIconExport from '@/components/icons/svg-icon-export.vue';
import SvgIconCar from '@/components/icons/svg-icon-car.vue';
import SvgIcon360View from '@/components/icons/svg-icon-360-view.vue';
import SvgIcon360Car from '@/components/icons/svg-icon-360-car.vue';
import SvgIcon360Ellipse from '@/components/icons/svg-icon-360-ellipse.vue';
import SvgIconFullscreen from '@/components/icons/svg-icon-fullscreen.vue';
import SvgIconExitFullscreen from '@/components/icons/svg-icon-exit-fullscreen.vue';
import SvgIconMap from '@/components/icons/svg-icon-map.vue';
import SvgIconGasStation from '@/components/icons/svg-icon-gas-station.vue';
import SvgIconPound from '@/components/icons/svg-icon-pound.vue';
import SvgIconChevronLeft from '@/components/icons/svg-icon-chevron-left.vue';
import SvgIconChevronRight from '@/components/icons/svg-icon-chevron-right.vue';
import SvgIconChevronDown from '@/components/icons/svg-icon-chevron-down.vue';
import SvgIconClose from '@/components/icons/svg-icon-close.vue';
import SvgIconTouch from '@/components/icons/svg-icon-touch.vue';

import FrequentlyAskedQuestions from '@/components/building-blocks/faq/faq.vue';
import FaqHeader from '@/components/building-blocks/faq/faq-header.vue';

Vue.component('DualBlock', DualBlock);
Vue.component('HeaderSimple', HeaderSimple);
Vue.component('Anchor', Anchor);
Vue.component('AspectContainer', AspectContainer);
Vue.component('CentredContainer', CentredContainer);

Vue.component('NumberDropDown', NumberDropDown);
Vue.component('InputElement', InputElement);
Vue.component('Toast', Toast);
Vue.component('ToastGroup', ToastGroup);
Vue.component('ScrollContainer', ScrollContainer);
Vue.component('LineSeparator', LineSeparator);
Vue.component('SimpleCheckbox', SimpleCheckbox);
Vue.component('FormDatePicker', FormDatePicker);
Vue.component('FormTimePicker', FormTimePicker);

Vue.component('RadioButton', RadioButton);
Vue.component('CheckBox', CheckBox);
Vue.component('BrickButton', BrickButton);
Vue.component('ToggleButton', ToggleButton);
Vue.component('ColourButton', ColourButton);
Vue.component('ButtonIcon', ButtonIcon);
Vue.component('ButtonWithIcon', ButtonWithIcon);

Vue.component('PrimaryButton', PrimaryButton);
Vue.component('SecondaryButton', SecondaryButton);
Vue.component('TextButton', TextButton);
Vue.component('IconButton', IconButton);
Vue.component('FabButton', FabButton);

Vue.component('SvgIcon', SvgIcon);
Vue.component('SvgClose', SvgClose);
Vue.component('SvgIconDone', SvgIconDone);
Vue.component('SvgIconDescription', SvgIconDescription);
Vue.component('SvgIconPlus', SvgIconPlus);
Vue.component('SvgIconPrint', SvgIconPrint);
Vue.component('SvgIconInfo', SvgIconInfo);
Vue.component('SvgIconStarFull', SvgIconStarFull);
Vue.component('SvgIconStarHalf', SvgIconStarHalf);
Vue.component('SvgIconStarEmpty', SvgIconStarEmpty);
Vue.component('SvgIconPerson', SvgIconPerson);
Vue.component('SvgIconPayment', SvgIconPayment);
Vue.component('SvgIconDirectionsCar', SvgIconDirectionsCar);
Vue.component('SvgIconDirectionsCar', SvgIconDirectionsCar);
Vue.component('SvgIconDirections', SvgIconDirections);
Vue.component('SvgIconEdit', SvgIconEdit);
Vue.component('SvgIconArrowBack', SvgIconArrowBack);
Vue.component('SvgIconMapNationwide', SvgIconMapNationwide);
Vue.component('SvgIconCar', SvgIconCar);
Vue.component('SvgIcon360View', SvgIcon360View);
Vue.component('SvgIcon360Car', SvgIcon360Car);
Vue.component('SvgIcon360Ellipse', SvgIcon360Ellipse);
Vue.component('SvgIconFullscreen', SvgIconFullscreen);
Vue.component('SvgIconExitFullscreen', SvgIconExitFullscreen);
Vue.component('SvgIconMap', SvgIconMap);
Vue.component('SvgIconGasStation', SvgIconGasStation);
Vue.component('SvgIconPound', SvgIconPound);
Vue.component('SvgIconChevronLeft', SvgIconChevronLeft);
Vue.component('SvgIconChevronRight', SvgIconChevronRight);
Vue.component('SvgIconChevronDown', SvgIconChevronDown);
Vue.component('SvgIconClose', SvgIconClose);
Vue.component('SvgIconTouch', SvgIconTouch);
Vue.component('SvgIconEye', SvgIconEye);
Vue.component('SvgIconHotCar', SvgIconHotCar);
Vue.component('SvgIconExport', SvgIconExport);

Vue.component('PrivacyPolicyLink', PrivacyPolicyLink);

Vue.component('FrequentlyAskedQuestions', FrequentlyAskedQuestions);
Vue.component('FaqHeader', FaqHeader);
