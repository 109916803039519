import '@/components/vuetify/overlayable/overlayable';

// vuetify.options.ts
export default function () {
  return {
    breakpoint: {},
    icons: {
      iconfont: 'mdi',
    },
    lang: {},
    theme: {
      options: {
        customProperties: true,
      },
      light: true,
      themes: {
        light: {
          anchor: {
            base: '#28477c',
          },
          'light-blue': {
            base: '#e0f0f9',
          },
          'dark-primary': {
            base: '#c2a700',
          },
          'super-light-grey': {
            base: '#f4f6f8',
          },
          review: {
            base: '#008EE3',
          },
          'light-grey': {
            base: '#C7C7C7',
          },
          grey: {
            base: '#4D4D4F',
            lighten5: '#46662b',
            lighten4: '#66883a',
            lighten3: '#789c43',
            lighten2: '#8db04d',
            lighten1: '#9cc055',
          },
          error: {
            base: '#ed1c24',
            lighten5: '#ffe4d5',
            lighten4: '#ffc6b9',
            lighten3: '#ffa99e',
            lighten2: '#ff8c84',
            lighten1: '#ff6f6a',
            darken1: '#df323b',
            darken2: '#bf0025',
            darken3: '#9f0010',
            darken4: '#800000',
          },
          primary: {
            base: '#ffde3f',
            lighten1: '#ffeb85',
            lighten2: '#fff1ad',
            lighten3: '#fff8d6',
            lighten4: '#ffffff',
            darken1: '#e0c21b',
            darken2: '#c2a700',
            darken3: '#a48d00',
            darken4: '#877400',
          },
          secondary: {
            base: '#28477c',
            lighten1: '#ffeb85',
            lighten2: '#4674c3',
            lighten3: '#658bcd',
            lighten4: '#93aedc',
            darken1: '#28477c',
            darken2: '#14233e',
            darken3: '#658bcd',
            darken4: '#05080f',
          },
          text: '#373739',
          ink: '#000000',
          surface: {
            base: '#fff',
            darken1: '#eeeeee',
            darken2: '#d6d6d6',
            darken3: '#c2c2c2',
            darken4: '#a3a3a3',
          },
        },
      },
    },
  };
}
