import { InventorySearchConfig } from '../../../../../shared/inventory/search';
import { RangeOptions } from '../../filter/types';

export function FilterYears(
  filter: InventorySearchConfig,
  filterKey: string,
  years: RangeOptions
): boolean {
  if (filterKey.startsWith('Years.')) {
    if (!filter.Search.ReleaseYear) {
      filter.Search.ReleaseYear = {};
    }
    if (
      years.MinValue &&
      years.MaxValue &&
      Number.isInteger(years.MinValue) &&
      Number.isInteger(years.MaxValue)
    ) {
      if (years.MinValue <= years.MaxValue) {
        filter.Search.ReleaseYear.MinValue = years.MinValue;
        filter.Search.ReleaseYear.MaxValue = years.MaxValue;
      } else {
        filter.Search.ReleaseYear.MinValue = years.MaxValue;
        filter.Search.ReleaseYear.MaxValue = years.MinValue;
      }
    } else if (years.MinValue && Number.isInteger(years.MinValue)) {
      filter.Search.ReleaseYear.MinValue = years.MinValue;
    } else if (years.MaxValue && Number.isInteger(years.MaxValue)) {
      filter.Search.ReleaseYear.MaxValue = years.MaxValue;
    }
    return true;
  }
  return false;
}
