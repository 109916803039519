export function IsValidString(
  value: string | undefined,
  maxLength: number
): boolean {
  if (!value) {
    return true;
  }
  if (typeof value === 'string') {
    return value.length <= maxLength;
  }
  return false;
}

export function IsValidAlphaNumeric(
  value: string | undefined,
  maxLength: number
): boolean {
  if (!value) {
    return true;
  }
  if (typeof value === 'string') {
    if (value.length > maxLength) {
      return false;
    }

    const re = new RegExp(`^([a-zA-Z0-9]{1,${maxLength}})$`);

    return re.test(value);
  }
  return false;
}
