import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component
export default class FormTimePicker extends Vue {
  @Prop() public value!: string;
  @Prop() public allowedTimes!: string[];
  @Prop({
    default: 2,
  })
  public lgCols!: number;

  public get Value() {
    return this.value;
  }

  public set Value(value: string) {
    this.$emit('input', value);
  }
}
