class SessionWrapper {
  public Read<T>(key: string): T | null {
    if (!process.client || !window.sessionStorage) {
      return null;
    }

    const value = window.sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    }

    return null;
  }

  public Write(key: string, value: any) {
    if (!process.client || !window.sessionStorage) {
      return;
    }

    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // If we exceeded quota there is not much we can do about it.
    }
  }

  public Remove(key: string) {
    if (!process.client || !window.sessionStorage) {
      return;
    }

    window.sessionStorage.removeItem(key);
  }
}

export const sessionWrapper = new SessionWrapper();
