import Vue from 'vue';
import { VNode, VNodeChildren } from 'vue/types/umd';

const name = 'scroll-container';

export interface ScrollContainer extends Vue {
  performScroll(goForward: boolean): void;
}

export default Vue.extend({
  name,

  props: {
    height: Number,
    pageCount: Number,
    lastPage: Boolean,
    firstPage: Boolean,
  },

  data() {
    return {
      targetPosition: 0,
    };
  },

  methods: {
    mounted() {
      this.$nextTick(this.calculatePages);
    },
    genScroller(): VNode {
      const children: VNodeChildren = [];
      if (this.$scopedSlots.default) {
        const content = this.$scopedSlots.default({});
        if (content) {
          for (const child of content) {
            if (!child.data) {
              child.data = {};
            }
            if (!child.data.staticStyle) {
              child.data.staticStyle = {};
            }
            child.data.staticStyle.display = 'inline-block';
            child.data.staticStyle['white-space'] = 'normal';
            children.push(child);
          }
        }
      }
      return this.$createElement(
        'div',
        {
          ref: 'scroll',
          staticClass: 'px-6',
          staticStyle: {
            'white-space': 'nowrap',
            'overflow-x': 'scroll',
            'overflow-y': 'hidden',
            '-webkit-overflow-scrolling': 'touch',
            'scroll-behavior': 'smooth',
            height: `${this.height}px`,
          },
          on: {
            scroll: this.onScroll,
          },
        },
        children
      );
    },
    onScroll() {
      this.calculatePages();
    },
    performScroll(goForward: boolean) {
      const scroll = this.$refs.scroll as Element;
      if (!scroll) {
        return;
      }

      const scrollDistance = scroll.clientWidth * 0.9 * (goForward ? 1 : -1);
      const newTarget = Math.max(
        0,
        Math.min(
          this.targetPosition + scrollDistance,
          scroll.scrollWidth - scroll.clientWidth
        )
      );
      this.targetPosition = newTarget;

      this.$nextTick(() => {
        scroll.scrollTo(newTarget, 0);
      });
    },
    calculatePages() {
      const scroll = this.$refs.scroll as Element;
      if (!scroll) {
        return;
      }

      if (scroll.scrollLeft <= 0 && !this.firstPage) {
        this.$emit('update:firstPage', true);
      } else if (this.firstPage) {
        this.$emit('update:firstPage', false);
      }

      if (
        scroll.scrollLeft + scroll.clientWidth >= scroll.scrollWidth &&
        !this.lastPage
      ) {
        this.$emit('update:lastPage', true);
      } else if (this.lastPage) {
        this.$emit('update:lastPage', false);
      }
    },
  },

  render(h): VNode {
    if (!this.$scopedSlots.default) {
      return h();
    }
    return h(
      'div',
      {
        staticClass: `${name}`,
        attrs: this.$attrs,
        style: {
          height: `${this.height}px`,
        },
        staticStyle: {
          'overflow-x': 'hidden',
        },
      },
      [this.genScroller()]
    );
  },
});
