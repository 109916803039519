import { Module, VuexModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import * as typ from '../features/reviews-carousel/types';

const name = 'reviews';

@Module({ stateFactory: true })
export default class Reviews extends VuexModule {
  public ReviewOptions: typ.ReviewOptions = typ.reviewOptions;
  public SlickOptions: typ.SlickOptions = typ.slickOptions;
}

export const ReviewsModule = namespace(name);
