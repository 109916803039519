// If we ever need a full solution for phone number validation we can use
// https://www.npmjs.com/package/libphonenumber-js
//
// The library above support full international numbers plus a lot of extra features.
// I wish there was something broken in countries so we can import only the country we are intersted in.

const phoneNotValid =
  'Please enter a valid phone number containing 10 digits. If using a landline please add area code.';

export function FormatAndValidatePhone(value: string | undefined): {
  error: string;
  formatted: string;
  isMobile: boolean;
} {
  const phoneNumber = value?.trim() || '';
  const result = {
    error: '',
    formatted: '',
    isMobile: false,
  };
  let numbers = '';
  let extension = '';
  let hasExtension = false;
  let hasPlus = false;
  for (let index = 0; index < phoneNumber.length; index++) {
    const phoneChar = phoneNumber[index];
    if (phoneChar >= '0' && phoneChar <= '9') {
      if (hasExtension) {
        extension += phoneChar;
      } else {
        numbers += phoneChar;
      }
    } else if (phoneChar === 'x') {
      hasExtension = true;
    } else if (phoneChar === '+') {
      if (index === 0) {
        hasPlus = true;
      } else {
        result.error = phoneNotValid;
        return result;
      }
    } else if (phoneChar === '(' || phoneChar === ')') {
      if (hasExtension) {
        result.error = phoneNotValid;
        return result;
      }
    } else if (phoneChar !== ' ') {
      result.error = phoneNotValid;
      return result;
    }
  }

  if (numbers.length < 10) {
    result.error = phoneNotValid;
    return result;
  }

  const ValidateAustralianNumber = (aussie: string) => {
    let area = aussie.substr(0, 2);
    if (aussie.length === 10) {
      if (area === '02' || area === '03' || area === '07' || area === '08') {
        result.formatted += `(${area}) ${aussie.substr(2, 4)} ${aussie.substr(
          6,
          4
        )}`;
      } else if (area === '04' || area === '05') {
        result.isMobile = true;
        if (aussie.length < numbers.length) {
          result.formatted += `${aussie.substr(1, 3)} ${aussie.substr(
            4,
            3
          )} ${aussie.substr(7, 3)}`;
        } else {
          result.formatted += `${aussie.substr(0, 4)} ${aussie.substr(
            4,
            3
          )} ${aussie.substr(7, 3)}`;
        }
      } else {
        result.error = phoneNotValid;
      }
    } else if (aussie.length === 9) {
      area = aussie.substr(0, 1);
      if (area === '2' || area === '3' || area === '7' || area === '8') {
        result.formatted += `${area} ${aussie.substr(1, 4)} ${aussie.substr(
          5,
          4
        )}`;
      } else if (area === '4' || area === '5') {
        result.isMobile = true;
        result.formatted += `${aussie.substr(0, 3)} ${aussie.substr(
          3,
          3
        )} ${aussie.substr(6, 3)}`;
      } else {
        result.error = phoneNotValid;
      }
    } else {
      result.error = phoneNotValid;
    }
  };

  if (numbers.length === 10) {
    ValidateAustralianNumber(numbers);
  } else {
    if (hasPlus) {
      result.formatted = '+';
    }
    if (numbers.substr(0, 2) === '61') {
      result.formatted += '61 ';
      ValidateAustralianNumber(numbers.substr(2));
    } else {
      // Other countries need to come in here.
      result.error = phoneNotValid;
      return result;
    }
  }

  if (hasExtension) {
    result.formatted += ` x${extension}`;
  }

  return result;
}
