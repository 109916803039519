import Vue from 'vue';
import { Dictionary } from 'vue-router/types/router';
import { Inventory } from '../../../../../shared/inventory';
import { SoldStatuses } from './common';
import { storageWrapper } from '@/tools/storage';
import { CacheBuckets } from '@/components/cache/types';

export function ReadInventoryFromStorage(): Inventory[] {
  const invBuckets = storageWrapper.Read<CacheBuckets>('inv-buckets') || {
    count: 0,
    parallel: 1,
  };

  const invList = [] as Inventory[];
  try {
    for (let index = 0; index < invBuckets.count; index++) {
      try {
        const bucket = storageWrapper.Unzip<Inventory[]>(`inv-bucket-${index}`);
        if (bucket) {
          bucket.forEach(UpdateTextData);
          invList.push(...bucket);
        }
      } catch (error) {
        // Oh well! We could not read the bucket... life goes on.
      }
    }
  } catch (error) {
    // Could not load from local storage. Oh well.
  }
  return invList;
}

export function UpdateInventoryDetails(inv: Inventory, invDetails: Inventory) {
  if (!inv || !invDetails) {
    return;
  }

  if (inv._id !== invDetails._id) {
    return;
  }

  UpdateInventorySummary(inv, invDetails);
}

export function UpdateTextData(inv: Inventory) {
  if (inv.GlassVehicle?.VehicleDetails?.Make) {
    inv.GlassVehicle.VehicleDetails.Make =
      inv.GlassVehicle.VehicleDetails.Make.toProperCase();
  }

  if (inv.GlassVehicle?.VehicleDetails?.Model) {
    inv.GlassVehicle.VehicleDetails.Model =
      inv.GlassVehicle.VehicleDetails.Model.toProperCase();
  }

  if (inv.Particulars?.Colour) {
    inv.Particulars.Colour = inv.Particulars.Colour.toProperCase('/');
  }
}

export function EnsureInventoryStructure(inv: Inventory): Inventory {
  Vue.set(inv, 'Price', inv.Price || {});
  Vue.set(inv, 'Search', inv.Search || {});
  Vue.set(inv, 'Particulars', inv.Particulars || {});
  Vue.set(inv, 'GlassVehicle', inv.GlassVehicle || {});
  Vue.set(inv, 'Calculated', inv.Calculated || {});
  Vue.set(inv, 'Location', inv.Location || {});

  EnsureSearchStructure(inv);
  inv.Particulars = {
    ...inv.Particulars,
    ...{ ImageUrls: inv.Particulars.ImageUrls || [] },
  };

  inv.GlassVehicle = {
    ...inv.GlassVehicle,
    ...{
      VehicleDetails: inv.GlassVehicle.VehicleDetails || {},
      GreenDetails: inv.GlassVehicle.GreenDetails || {},
      VehicleSpecifications: inv.GlassVehicle.VehicleSpecifications || {},
    },
  };
  inv.GlassVehicle.VehicleSpecifications = {
    ...inv.GlassVehicle.VehicleSpecifications,
    ...{
      DriveSpecifications:
        inv.GlassVehicle.VehicleSpecifications.DriveSpecifications || {},
      EngineSpecifications:
        inv.GlassVehicle.VehicleSpecifications.EngineSpecifications || {},
      WarrantySpecifications:
        inv.GlassVehicle.VehicleSpecifications.WarrantySpecifications || {},
      OptionSpecifications:
        inv.GlassVehicle.VehicleSpecifications.OptionSpecifications || {},
      Suspension: inv.GlassVehicle.VehicleSpecifications.Suspension || {},
    },
  };

  return inv;
}

function GetDetailsExternal360Updates(inv: Inventory, updates: Inventory) {
  let external360Url = inv.Particulars.ExternalImages360;

  // if there are more than 1 ImageUrls coming from updates then we're dealing with detailed projection.
  if (updates.Particulars.ImageUrls.length > 1) {
    // if details have no 360 image it will remove the existing one.
    external360Url = updates.Particulars.ExternalImages360;
  }

  return external360Url;
}

function GetDetailsInternal360Updates(inv: Inventory, updates: Inventory) {
  let internal360Url = inv.Particulars.Image360Url;

  // if there are more than 1 ImageUrls coming from updates then we're dealing with detailed projection.
  if (updates.Particulars.ImageUrls.length > 1) {
    // if details have no 360 image it will remove the existing one.
    internal360Url = updates.Particulars.Image360Url;
  }

  return internal360Url;
}

function GetDetailsImagesUpdates(inv: Inventory, updates: Inventory) {
  let imageUrls = inv.Particulars.ImageUrls;

  // ImageUrls coming from summary Inventory will have 1 image max.
  // ImageUrls coming from detailed Inventory will have more than 1.
  // Make sure details always override cache.
  // Make sure summary only overrides cache if there are no details present in the cache.
  if (
    updates.Particulars.ImageUrls.length > 1 ||
    imageUrls.length <= updates.Particulars.ImageUrls.length
  ) {
    imageUrls = updates.Particulars.ImageUrls;
  }

  return imageUrls;
}

function UpdateInventoryParticulars(inv: Inventory, updates: Inventory) {
  const imageUrls = GetDetailsImagesUpdates(inv, updates);
  const internal360Url = GetDetailsInternal360Updates(inv, updates);
  const external360Url = GetDetailsExternal360Updates(inv, updates);

  inv.Particulars = {
    ...inv.Particulars,
    ...updates.Particulars,
    ImageUrls: imageUrls,
    Image360Url: internal360Url,
    ExternalImages360: external360Url,
  };
}

function UpdateInventorySearch(inv: Inventory, updates: Inventory) {
  inv.Search = {
    ...inv.Search,
    ...updates.Search,
  };

  EnsureSearchStructure(inv);
}

function EnsureSearchStructure(inv: Inventory) {
  if (!inv.Search.LocationCode) {
    Vue.set(inv.Search, 'LocationCode', '');
  }
  if (!inv.Search.StatusCode) {
    Vue.set(inv.Search, 'StatusCode', '');
  }
  if (!inv.Search.FuelType) {
    Vue.set(inv.Search, 'FuelType', []);
  }
  if (!inv.Search.Transmission) {
    Vue.set(inv.Search, 'Transmission', []);
  }
  if (!inv.Search.Lifestyle) {
    Vue.set(inv.Search, 'Lifestyle', []);
  }
  if (!inv.Search.VehicleType) {
    Vue.set(inv.Search, 'VehicleType', []);
  }
  if (!inv.Search.VehicleSize) {
    Vue.set(inv.Search, 'VehicleSize', []);
  }
  if (!inv.Search.PaintColour) {
    Vue.set(inv.Search, 'PaintColour', '');
  }
  if (!inv.Search.ProductCategory) {
    Vue.set(inv.Search, 'ProductCategory', []);
  }
  if (!inv.Search.DriveType) {
    Vue.set(inv.Search, 'DriveType', []);
  }
  if (inv.Search.IsReservedDate === undefined) {
    Vue.set(inv.Search, 'IsReservedDate', null);
  }
}

export function UpdateCalculatedProps(inv: Inventory) {
  inv.Calculated = {
    ...inv.Calculated,
    IsSold: false,
  };

  const isSold = SoldStatuses.includes(inv.Search.StatusCode);

  inv.Calculated.IsSold = isSold;
}

export function UpdateInventorySummary(
  oldInv: Inventory,
  updates: Inventory
): Inventory {
  const newInv = oldInv || EnsureInventoryStructure({} as Inventory);

  if (!updates) {
    return newInv;
  }

  newInv._id = updates._id;
  newInv.CreatedTime = updates.CreatedTime;
  newInv.ModifiedTime = updates.ModifiedTime;
  if (newInv.Location) {
    newInv.Location.BuyOnlineEnable = updates.Location?.BuyOnlineEnable;
    newInv.Location.Address = updates.Location?.Address;
  }
  UpdateInventoryParticulars(newInv, updates);
  newInv.Price = { ...newInv.Price, ...updates.Price };
  UpdateInventorySearch(newInv, updates);
  newInv.Vehicle = { ...newInv.Vehicle, ...updates.Vehicle };

  newInv.GlassVehicle.GreenDetails = {
    ...newInv.GlassVehicle.GreenDetails,
    ...updates.GlassVehicle?.GreenDetails,
  };
  newInv.GlassVehicle.VehicleDetails = {
    ...newInv.GlassVehicle.VehicleDetails,
    ...updates.GlassVehicle?.VehicleDetails,
  };
  newInv.GlassVehicle.VehicleSpecifications.DriveSpecifications = {
    ...newInv.GlassVehicle.VehicleSpecifications.DriveSpecifications,
    ...updates.GlassVehicle?.VehicleSpecifications?.DriveSpecifications,
  };
  newInv.GlassVehicle.VehicleSpecifications.EngineSpecifications = {
    ...newInv.GlassVehicle.VehicleSpecifications.EngineSpecifications,
    ...updates.GlassVehicle?.VehicleSpecifications?.EngineSpecifications,
  };
  newInv.GlassVehicle.VehicleSpecifications.OptionSpecifications = {
    ...newInv.GlassVehicle.VehicleSpecifications.OptionSpecifications,
    ...updates.GlassVehicle?.VehicleSpecifications?.OptionSpecifications,
  };
  newInv.GlassVehicle.VehicleSpecifications.WarrantySpecifications = {
    ...newInv.GlassVehicle.VehicleSpecifications.WarrantySpecifications,
    ...updates.GlassVehicle?.VehicleSpecifications?.WarrantySpecifications,
  };
  newInv.GlassVehicle.VehicleSpecifications.Suspension = {
    ...newInv.GlassVehicle.VehicleSpecifications.Suspension,
    ...updates.GlassVehicle?.VehicleSpecifications?.Suspension,
  };

  UpdateCalculatedProps(newInv);
  UpdateTextData(newInv);

  return newInv;
}

export function LoadNewInventory(
  invs: Inventory[],
  inventoryList: Dictionary<Inventory>
) {
  if (!Array.isArray(invs)) {
    return;
  }

  invs.forEach(function LoadEachInventory(inv: Inventory) {
    Vue.set(
      inventoryList,
      inv._id,
      UpdateInventorySummary(inventoryList[inv._id], inv)
    );
  });
}
