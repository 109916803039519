import { RouteConfig } from 'vue-router/types';
import { RouteNames } from './route-consts';

export const otherRoutes: RouteConfig[] = [
  {
    path: '/about-us/our-people-and-values/careers',
    name: RouteNames.CareersRouteName,
    component: () =>
      import('@/features/careers/Careers').then((m) => m.default || m),
  },
  {
    path: '/locations',
    name: RouteNames.LocationsRouteName,
    component: () =>
      import('@/features/location/Locations/Locations.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/locations/:locationUrl',
    name: 'locations-details',
    props: (route) => route.params,
    component: () =>
      import(
        '@/features/location/Locations/LocationDetails/LocationDetails.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/locations/state/:state',
    name: 'state-location',
    props: (route) => route.params,
    component: () =>
      import('@/features/location/state-location/StateLocation.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/terms-of-use',
    name: RouteNames.TermsRouteName,
    component: () =>
      import('@/features/terms-of-use/TermsOfUse').then((m) => m.default || m),
  },
  {
    path: '/redirect-to-simplr/:VIN',
    name: 'redirect-to-simplr',
    component: () =>
      import(
        '@/features/buy/vehicle-details/redirect-to-simplr/RedirectToSimplr'
      ).then((m) => m.default || m),
  },
  {
    path: '/articles',
    name: RouteNames.BlogRouteName,
    component: () => import('@/features/blog/Blog').then((m) => m.default || m),
  },
];
