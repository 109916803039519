import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterRegistration(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Registration.')) {
    const key = filterKey.substr(13);
    if (filter.Search.Registration) {
      filter.Search.Registration.push(key);
    } else {
      filter.Search.Registration = [key];
    }
    return true;
  }
  return false;
}
