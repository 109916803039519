import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { repaymentPeriods } from '../../../shared/finance';
import { Inventory } from '../../inventory';
import * as typ from '@/features/buy/vehicle-details/types';

const name = 'vdp';

@Module({ stateFactory: true })
export default class VDP extends VuexModule {
  public VdpOptions: typ.VdpOptions = typ.vdpOptions;
  public AdvertisedRateOptions: typ.AdvertisedRateOptions =
    typ.advertisedRateOptions;

  public RepaymentPeriods: number[] = repaymentPeriods;

  @Mutation public showDisclaimer(inventory: Inventory) {
    this.AdvertisedRateOptions.Inventory = inventory;
    this.AdvertisedRateOptions.ShowAdvertisedRateDisclaimer = true;
  }
}

export const VDPModule = namespace(name);
