import { Vue, Component } from 'vue-property-decorator';
import Description from '@/components/section-with-description/SectionWithDescription';

@Component({
  name: 'subscription-steps',
  components: {
    Description,
  },
  data: () => ({
    steps: [
      {
        Step: 'step 1',
        ImageUrl: '/img/home-page/buy_dream_car.webp',
        Title: 'Find your car',
        Description:
          "Our partnership with Simplr, means we have Australia's biggest selection of subscription cars.",
      },
      {
        Step: 'step 2',
        ImageUrl: '/img/home-page/finance_apply_online.webp',
        Title: 'Choose your plan',
        Description: `We offer three ownership levels, you just need to choose the one that's right for you.`,
      },
      {
        Step: 'step 3',
        ImageUrl: '/img/home-page/buy_happy.webp',
        Title: 'Arrange a pick-up time',
        Description: `Once you're approved, all that's left is to set a day to come and collect your new wheels.`,
      },
    ],
  }),
})
export default class SubscriptionSteps extends Vue {}
