import { render, staticRenderFns } from "./toggle-button.vue?vue&type=template&id=026852b1&scoped=true&lang=pug&"
import script from "./toggle-button.ts?vue&type=script&lang=js&"
export * from "./toggle-button.ts?vue&type=script&lang=js&"
import style0 from "./toggle-button.vue?vue&type=style&index=0&id=026852b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026852b1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
