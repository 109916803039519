import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterAncap(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Ancap.')) {
    const key = filterKey.substring(6);
    const value = Number.parseFloat(key);

    if (value) {
      filter.Search.Ancap = value;
    }
    return true;
  }
  return false;
}
