import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { DateTime } from 'luxon';
import orderBy from 'lodash/orderBy';
import { Coordinates } from '../../../shared/global/types';
import { LocationDetails } from '../../../shared/inventory/search';
import { getDistance } from '../../../shared/location';
import { dataLayerAdd, TagToolNavigateEvent } from '@/helpers/analytics-helper';
import * as typ from '@/features/location/types';
import { storageWrapper } from '@/tools/storage';

export const name = 'location';

@Module({ name, stateFactory: true, namespaced: true })
export default class Location extends VuexModule {
  public locations: LocationDetails[] = [];
  public LocalTime: DateTime = DateTime.local();
  public LocationsMap: typ.LocationsMap = typ.locationsMap;
  public preferredLocation: LocationDetails | undefined;

  @Mutation public UpdateLocation(locationKey?: string) {
    this.locations = this.locations.map((item) => ({
      ...item,
      Selected: item.Key === locationKey,
    }));

    storageWrapper.Write('Location.PreferredLocation', locationKey);
    const toolData: TagToolNavigateEvent = {
      event: 'toolNavigate',
      toolEvent: 'complete',
      toolName: 'setLocation',
    };

    this.preferredLocation = this.locations.find((item) => item.Selected);

    dataLayerAdd(toolData);
  }

  @Mutation public loadLocations(locations: LocationDetails[]) {
    if (process.browser) {
      const preferredLocationKey = storageWrapper.Read(
        'Location.PreferredLocation'
      );
      if (preferredLocationKey) {
        this.locations = locations.map((item) => ({
          ...item,
          Selected: item.Key === preferredLocationKey,
        }));
        return;
      }
    }
    this.locations = locations;
  }

  @Mutation public orderByDistance(from: Coordinates) {
    if (!from) {
      return;
    }

    this.locations = orderBy(
      this.locations.map((item) => ({
        ...item,
        Distance: item.Address?.Coordinates
          ? getDistance(from, item.Address?.Coordinates)
          : Number.MAX_SAFE_INTEGER,
      })),
      (x) => x.Distance
    );
  }

  public get selectedLocation(): LocationDetails | undefined {
    return this.locations.find((item) => item.Selected);
  }
}

export const LocationModule = namespace(name);
