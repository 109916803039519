import { Coordinates } from '../global/types';

function toRadians(deg: number) {
  return deg * (Math.PI / 180);
}

export function getDistance(position1: Coordinates, position2: Coordinates) {
  const lat1 = position1.Latitude!;
  const lat2 = position2.Latitude!;
  const lon1 = position1.Longitude!;
  const lon2 = position2.Longitude!;
  const R = 6371000; // metres
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}
