import { Component } from 'vue-property-decorator';
import AllianzHeader from '@/features/allianz-landing-page/AllianzHeader';
import GetOnlineQuote from '@/features/allianz-landing-page/GetOnlineQuote';
import AllianzInsurance from '@/features/allianz-landing-page/AllianzInsurance';
import InsuranceTable from '@/features/allianz-landing-page/InsuranceTable';
import OptionalBenefits from '@/features/allianz-landing-page/OptionalBenefits';
import InsuranceClaim from '@/features/allianz-landing-page/InsuranceClaim';
import AllianzDisclaimer from '@/features/allianz-landing-page/AllianzDisclaimer';
import { PagesWithMetaTagsFromTheServer } from '@/components/pages-with-metatags-from-the-server/PagesWithMetaTagsFromTheServer';

@Component({
  name: 'allianz-landing-page',
  components: {
    AllianzHeader,
    GetOnlineQuote,
    AllianzInsurance,
    InsuranceTable,
    OptionalBenefits,
    InsuranceClaim,
    AllianzDisclaimer,
  },
})
export default class AllianzLandingPage extends PagesWithMetaTagsFromTheServer {}
