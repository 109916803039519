import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { sessionWrapper } from '@/tools/session';
import { VBtn, VIcon } from 'vuetify/lib';

@Component({
  name: 'menu-item',
  components: {
    VBtn,
    VIcon,
  },
})
export default class MenuItem extends Vue {
  @Prop({ required: false }) public icon: String | undefined;
  @Prop({ required: false }) public iconColor: String | undefined;
  @Prop({ required: false }) public title: String | undefined;
  @Prop({ required: true }) public to: String | undefined;
  @Prop({ required: false }) public color: String | undefined;
  @Prop({ required: false }) public block: String | undefined;
  @Prop({ required: false }) public clearScrollPosition?: boolean;

  public clearScrollPositionSession() {
    if (this.clearScrollPosition) {
      sessionWrapper.Remove('scrollPosition');
    }
  }

  public render() {
    return (
      <v-btn
        to={{ name: this.to }}
        onClick={this.clearScrollPositionSession}
        elevation="0"
        color={this.color}
        block={this.block}
        {...this.$attrs}
        {...this.$props}
      >
        <div class="d-flex align-center w-100 text-capitalize font-normal">
          {this.icon && (
            <v-icon class="mr-2" color={this.iconColor || 'ink'}>
              mdi-{this.icon}
            </v-icon>
          )}
          <span class="black--text">{this.title}</span>
        </div>
      </v-btn>
    );
  }
}
