import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RawLocation, Location } from 'vue-router/types';
import Fuse from 'fuse.js';
import { Dictionary } from '../../../../../shared/global/types';
import { Inventory } from '../../../../../shared/inventory';
import { SearchHints } from '../types';
import { InventoryModule } from '@/store/inventoryStore';
import { GetVDPRoute } from '@/features/inventory/helpers/getters';
import { routesForSearchResults } from '@/router/types';
import { InternalSearch } from '@/helpers/analytics-helper';
import { RouteNames } from '@/router/route-consts';
import { FilterModule } from '@/store/filter';

@Component
export default class SearchBoxFilter extends Vue {
  @Prop() public className: string | undefined;
  @Prop({ required: false, default: 'white' }) public backgroundColor:
    | string
    | undefined;

  @FilterModule.State public SelectedFilter!: string[];
  @FilterModule.State private SearchHints!: SearchHints[];
  @FilterModule.State private StockNumberSearchHints!: SearchHints[];
  @FilterModule.State private RegistrationSearchHints!: SearchHints[];
  @FilterModule.Mutation private ToggleFilter!: (key: string) => void;
  @FilterModule.Mutation private ClearFilters!: () => void;
  @InventoryModule.State private InventoryList!: Dictionary<Inventory>;

  private SearchBoxModel: Fuse.FuseResult<SearchHints> | null = null;
  private searchText: string | null = '';
  private SanitisedSearchText: string | null = '';

  public get ShowFilterLinks(): boolean {
    return routesForSearchResults.includes(this.$route.name as string);
  }

  public FocusOnMe(e: TouchEvent) {
    let target = e.target as HTMLElement | null;
    let counter = 4;
    while (!target?.attributes.getNamedItem('tabindex')) {
      if (target == null || counter < 0) {
        return;
      }
      counter--;
      target = target?.parentElement;
    }
    target?.focus();
  }

  @Watch('searchText') public SearchTextChanged(value: string | null) {
    if (value) {
      const s = value.toLowerCase().replace(/ /g, '').slice(0, 32);
      if (s !== this.SanitisedSearchText) {
        this.SanitisedSearchText = s;
      }
    } else if (this.SanitisedSearchText !== null) {
      this.SanitisedSearchText = null;
    }
  }

  public get SearchItems(): Fuse.FuseResult<SearchHints>[] {
    const searchText = this.SanitisedSearchText;
    if (searchText) {
      if (
        this.SearchBoxModel &&
        this.SearchBoxModel.item.Description === this.searchText
      ) {
        return [];
      }
      const searchItems = this.FilteredSearchHints.search(searchText);
      const stockAndRegoItems = this.StockAndRegoSearchHints.filter((x) =>
        x.Description.toLowerCase().includes(searchText)
      ).map((x) => {
        const index = x.Description.toLowerCase().indexOf(searchText, 0);
        return {
          item: x,
          refIndex: 0,
          matches: [{ indices: [[index, index + searchText.length - 1]] }],
          score: 0,
        } as Fuse.FuseResult<SearchHints>;
      });
      return searchItems
        .filter((x) =>
          x.matches?.every((y) => y.indices.every((z) => z[0] <= z[1]))
        )
        .concat(stockAndRegoItems);
    }
    return [];
  }

  public GetHighlighted(
    index: number,
    item: Fuse.FuseResult<SearchHints>
  ): string {
    if (item?.matches) {
      for (const match of item.matches) {
        if (match.indices) {
          for (const matchIndex of match.indices) {
            if (index >= matchIndex[0] && index <= matchIndex[1]) {
              return 'v-list-item__mask';
            }
          }
        }
      }
    }
    return '';
  }

  public get SearchText() {
    return this.searchText;
  }

  public set SearchText(value: string | null) {
    if (this.SearchBoxModel) {
      if (value !== null && value !== undefined) {
        if (this.SearchBoxModel.item.Description !== value) {
          if (!value.includes(this.SearchBoxModel.item.Description)) {
            this.ClearFilters();
          }
        } else if (this.SelectedFilter.length === 0) {
          this.ToggleFilter(this.SearchBoxModel.item.Key);
        }
      }
    }
    this.searchText = value;
  }

  public get Model() {
    return this.SearchBoxModel;
  }

  public set Model(searchValue: Fuse.FuseResult<SearchHints> | null) {
    this.ClearFilters();

    this.SearchBoxModel = searchValue;

    if (!searchValue) {
      return;
    }

    const value = searchValue.item;

    const desiredRoute: RawLocation = { name: RouteNames.SearchRouteName };

    if (value) {
      this.ToggleFilter(value.Key);

      if (value.Source === 'StockNumber') {
        for (const key in this.InventoryList) {
          if (Object.prototype.hasOwnProperty.call(this.InventoryList, key)) {
            const inv = this.InventoryList[key];
            if (inv.Particulars.StockNumber === value.Key.substr(12)) {
              const vdp = GetVDPRoute(inv, inv.Location);
              if (vdp) {
                desiredRoute.name = vdp.name;
                desiredRoute.params = vdp.params;
              }
            }
          }
        }
      } else if (value.Source === 'Registration') {
        let vdp: Location | '' = '';
        for (const key in this.InventoryList) {
          if (Object.prototype.hasOwnProperty.call(this.InventoryList, key)) {
            const inv = this.InventoryList[key];
            const regoNumber = inv.Vehicle?.Registration?.Number;
            if (regoNumber === value.Key.substr(13)) {
              if (vdp) {
                vdp = '';
                break;
              }
              vdp = GetVDPRoute(inv)!;
            }
          }
        }
        if (vdp) {
          desiredRoute.name = vdp.name;
          desiredRoute.params = vdp.params;
        }
      }
    }

    if (searchValue?.item?.Description) {
      InternalSearch(
        RouteNames.SearchRouteName as 'search',
        searchValue.item.Description
      );
    }

    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name !== desiredRoute.name) {
      this.$router.push(desiredRoute);
    } else if (
      currentRoute.name === 'details' &&
      currentRoute.params &&
      desiredRoute.params &&
      currentRoute.params.VehicleId !== desiredRoute.params.VehicleId
    ) {
      this.$router.push(desiredRoute);
    }

    this.$nextTick(() => {
      this.SearchBoxModel = null;
    });
  }

  public ClearSearchBox() {
    this.Model = null;
  }

  public get ShowMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  private get FilteredSearchHints(): Fuse<SearchHints> {
    const options = {
      shouldSort: true,
      threshold: 0.5,
      includeScore: true,
      includeMatches: true,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['Description'],
    };
    return new Fuse(this.CombinedSearchHints, options);
  }

  private get CombinedSearchHints(): SearchHints[] {
    return this.SearchHints;
  }

  private get StockAndRegoSearchHints(): SearchHints[] {
    return this.StockNumberSearchHints.concat(this.RegistrationSearchHints);
  }
}
