import { RouteConfig } from 'vue-router/types';

export const filterRoutes: RouteConfig[] = [
  {
    path: '/buy/used-cars/filter/kilometres',
    name: 'filterKilometres',
    component: () =>
      import(
        /* webpackChunkName: "buy" */
        '../features/filter/quick-filter/kilometres-quick-filter/KilometresQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/safety-rating',
    name: 'filterSafetyRating',
    component: () =>
      import(
        /* webpackChunkName: "buy" */
        '../features/filter/quick-filter/safety-rating-quick-filter/SafetyRatingQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/fuel-type',
    name: 'filterFuelType',
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '../features/filter/quick-filter/fuel-type-quick-filter/FuelTypeQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/transmission',
    name: 'filterTransmission',
    component: () =>
      import(
        /* webpackChunkName: "buy" */
        '../features/filter/quick-filter/transmission-quick-filter/TransmissionQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/seats',
    name: 'filterSeats',
    component: () =>
      import(
        /* webpackChunkName: "buy" */
        '../features/filter/quick-filter/seats-quick-filter/SeatsQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/make-model',
    name: 'filterMake',
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '../features/filter/quick-filter/make-quick-filter/MakeQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/year',
    name: 'filterYear',
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '../features/filter/quick-filter/year-quick-filter/YearQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/price',
    name: 'filterPrice',
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '../features/filter/quick-filter/price-quick-filter/PriceQuickFilter.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/filter/location',
    name: 'filterLocation',
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '../features/filter/quick-filter/location-quick-filter/LocationQuickFilter.vue'
      ).then((m) => m.default || m),
  },
];
