import { Module, VuexModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import * as typ from '@/features/home-page/types';

const name = 'homepage';

@Module({ stateFactory: true })
export default class HomePage extends VuexModule {
  public BannerOptions: typ.BannerOptions = typ.bannerOptions;
}

export const HomePageModule = namespace(name);
