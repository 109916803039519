import { Component, Vue } from 'vue-property-decorator';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
@Component({
  name: 'allianz-disclaimer',
  components: {
    SectionWithContainer,
  },
  data: () => ({
    disclaimer: [
      `EASY AUTO 123 PTY LTD ABN 29 148 136 314 arranges this insurance as an agent for the insurer Allianz Australia Insurance Limited ABN 15 000 122 850 AFSL No 234708. We do not provide any advice based on any consideration of your objectives, financial situation or needs. Terms, conditions, limits and exclusions apply. Before making a decision, please consider the <a href="https://www.einsure.com.au/wb/redirect/PolicyDoc-PIC-Motor" target="_blank" class="error--text" >Product Disclosure Statement</a>. The PDS and Target Market Determination are available at <a href="https://www.allianzdealerinsurance.com.au/" target="_blank" class="error--text">www.allianzdealerinsurance.com.au</a>. Please also consider our <a href='/car-insurance/FSG 1236617 2024-01-23 Ver 12.1.pdf' target='_blank' class='error--text'>Financial Services Guide (FSG)</a>, which describes the services we offer and how, before deciding whether to use the services offered by us.  If you purchase this insurance, we will receive a commission that is a percentage of the premium. Ask us for more details before we provide you with services.`,
      `1 - Minimum premiums may apply. Any discounts / entitlements may be subject to rounding and only apply to the extent any minimum premium is not reached. If you are eligible for more than one, we also apply each of them in a predetermined order to the premium (excluding taxes and government charges) as reduced by any prior applied discounts / entitlements. Discounts may not be applied to the premium for optional covers.`,
      '2 - Safe Driver Discount applies to Comprehensive Motor Insurance policies only. Any discount entitlement is determined by the insurance history and driving history of all drivers on the policy and the number of claims made by them. If eligible, the maximum Safe Driver Discount that may be applied on new policies is up to 10%. For more information see our <a href="https://www.einsure.com.au/wb/redirect/pedfi" target="_blank" class="error--text">PED guide</a>.',
      '3 - In certain circumstances we may agree with you to use another repairer than the one that you selected. We will act reasonably in doing so.',
      '4 - We will extend your rental car or daily travel allowance period to the extent that we caused or contributed to the delay in repairing your vehicle.',
      '5 - Premiums payable by instalments may be subject to minor adjustments (upwards or downwards) due to rounding and financial institution transaction fees may apply.',
    ],
  }),
})
export default class AllianzDisclaimer extends Vue {}
