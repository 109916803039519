import { Plugin } from '@nuxt/types';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from 'axios-extensions';
import { initializeAxios } from '@/helpers/functions';

const accessor: Plugin = ({ $axios, $config, req }) => {
  const defaults = $axios.defaults;
  const serverCache = (req as any)?.axiosCache;
  $axios.defaults.validateStatus = (status) => {
    return status >= 200 && status < 400;
  };
  if (defaults.adapter && serverCache) {
    // https://github.com/kuitos/axios-extensions
    $axios.defaults.adapter = throttleAdapterEnhancer(
      cacheAdapterEnhancer(defaults.adapter, {
        enabledByDefault: false,
        // axiosCache is a custom prop from the server (nuxtssr function).
        defaultCache: serverCache,
      })
    );
  }

  $axios.setBaseURL($config.baseUrl);
  initializeAxios($axios);
};

export default accessor;
