import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterLocation(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Location.')) {
    const key = filterKey.substring(9);

    if (key) {
      if (filter.Search.Location) {
        filter.Search.Location.push(key);
      } else {
        filter.Search.Location = [key];
      }
    }
    return true;
  }
  return false;
}
