import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { setContext } from '@sentry/browser';
import {
  PayerDetails,
  Payment,
  CreateEmptyPayerDetails,
  UpdatePayerDetailsData,
  PaymentsConfig,
  PaymentFormDetails,
} from '../../../shared/payments';
import { reserveApi } from '@/features/buy/reserve/api';
import { ReserveStatuses, reserveStatuses } from '@/features/buy/reserve/types';
import { ValidatePayerDetails } from '@/features/buy/reserve/helpers';

const name = 'reserve';

@Module({ stateFactory: true })
export default class Reserve extends VuexModule {
  public PayerDetails: PayerDetails = CreateEmptyPayerDetails();
  public TransactionId: string = '';
  public ReserveStatuses: ReserveStatuses = { ...reserveStatuses };
  public PaymentsConfig: PaymentsConfig = {} as PaymentsConfig;

  @Mutation public SetPaymentsConfig(value: PaymentsConfig) {
    if (!value) {
      return;
    }
    this.PaymentsConfig = value;
  }

  @Action({ rawError: true, commit: 'SetPaymentsConfig' })
  public async LoadPaymentsConfig() {
    try {
      return await reserveApi.GetPaymentsConfig();
    } catch (error) {
      // handle some error here
    }
  }

  @Mutation public UpdatePayerDetails(value: PayerDetails) {
    this.PayerDetails = UpdatePayerDetailsData(this.PayerDetails, value);
  }

  @Mutation public UpdateContactDetails(value: PayerDetails) {
    const temp = UpdatePayerDetailsData(this.PayerDetails, value);

    this.ReserveStatuses.PayerDetailsAreValid = ValidatePayerDetails(temp);

    if (this.ReserveStatuses.PayerDetailsAreValid) {
      this.PayerDetails = {
        ...this.PayerDetails,
        Surname: temp.Surname,
        Forename: temp.Forename,
        Email: temp.Email,
        Phone: temp.Phone,
      };
    }
  }

  @Mutation public SetTransactionId(value: string) {
    this.TransactionId = value;
  }

  @Action({ rawError: true }) public async SubmitPayment(
    payment: Payment
  ): Promise<PaymentFormDetails> {
    const formDetails = await reserveApi.SubmitPayment(payment);
    this.context.commit(
      'SetTransactionId',
      formDetails.FormData.transaction_uuid
    );
    return formDetails;
  }

  @Action({ rawError: true }) public async ConfirmPayerDetails(
    payer: PayerDetails
  ) {
    const self = this.context.state as Reserve;
    const payment = {
      _id: self.TransactionId,
      PayerDetails: payer,
    } as Payment;
    try {
      const value = await reserveApi.ConfirmPayerDetails(payment);
      this.context.commit('SetTransactionId', value._id);
    } catch (error) {
      setContext('apiError', { error });
      throw error;
    }
  }
}

export const ReserveModule = namespace(name);
