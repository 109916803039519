export const Faqs = () => import('../../src/components/Faqs/Faqs.vue' /* webpackChunkName: "components/faqs" */).then(c => wrapFunctional(c.default || c))
export const AllianzInsuranceQuote = () => import('../../src/components/allianz-insurance-quote/AllianzInsuranceQuote.vue' /* webpackChunkName: "components/allianz-insurance-quote" */).then(c => wrapFunctional(c.default || c))
export const BtnTwoRows = () => import('../../src/components/btn-two-rows/btn-two-rows.vue' /* webpackChunkName: "components/btn-two-rows" */).then(c => wrapFunctional(c.default || c))
export const BuyCarsOnline = () => import('../../src/components/buy-cars-online/BuyCarsOnline.vue' /* webpackChunkName: "components/buy-cars-online" */).then(c => wrapFunctional(c.default || c))
export const DialogBaseDialogWithTitle = () => import('../../src/components/dialog/BaseDialogWithTitle.vue' /* webpackChunkName: "components/dialog-base-dialog-with-title" */).then(c => wrapFunctional(c.default || c))
export const DrawerOverlay = () => import('../../src/components/drawer-overlay/drawer-overlay.vue' /* webpackChunkName: "components/drawer-overlay" */).then(c => wrapFunctional(c.default || c))
export const Expandable = () => import('../../src/components/expandable-content/Expandable.vue' /* webpackChunkName: "components/expandable" */).then(c => wrapFunctional(c.default || c))
export const ExpansionPanel = () => import('../../src/components/expansion-panel/expansion-panel.vue' /* webpackChunkName: "components/expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const IconsButtonIcon = () => import('../../src/components/icons/button-icon.vue' /* webpackChunkName: "components/icons-button-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIcon360Car = () => import('../../src/components/icons/svg-icon-360-car.vue' /* webpackChunkName: "components/icons-svg-icon360-car" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIcon360Ellipse = () => import('../../src/components/icons/svg-icon-360-ellipse.vue' /* webpackChunkName: "components/icons-svg-icon360-ellipse" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIcon360View = () => import('../../src/components/icons/svg-icon-360-view.vue' /* webpackChunkName: "components/icons-svg-icon360-view" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconArrowBack = () => import('../../src/components/icons/svg-icon-arrow-back.vue' /* webpackChunkName: "components/icons-svg-icon-arrow-back" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconCar = () => import('../../src/components/icons/svg-icon-car.vue' /* webpackChunkName: "components/icons-svg-icon-car" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconChevronDown = () => import('../../src/components/icons/svg-icon-chevron-down.vue' /* webpackChunkName: "components/icons-svg-icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconChevronLeft = () => import('../../src/components/icons/svg-icon-chevron-left.vue' /* webpackChunkName: "components/icons-svg-icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconChevronRight = () => import('../../src/components/icons/svg-icon-chevron-right.vue' /* webpackChunkName: "components/icons-svg-icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconClose = () => import('../../src/components/icons/svg-icon-close.vue' /* webpackChunkName: "components/icons-svg-icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconDescription = () => import('../../src/components/icons/svg-icon-description.vue' /* webpackChunkName: "components/icons-svg-icon-description" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconDirectionsCar = () => import('../../src/components/icons/svg-icon-directions-car.vue' /* webpackChunkName: "components/icons-svg-icon-directions-car" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconDirections = () => import('../../src/components/icons/svg-icon-directions.vue' /* webpackChunkName: "components/icons-svg-icon-directions" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconDone = () => import('../../src/components/icons/svg-icon-done.vue' /* webpackChunkName: "components/icons-svg-icon-done" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconEdit = () => import('../../src/components/icons/svg-icon-edit.vue' /* webpackChunkName: "components/icons-svg-icon-edit" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconExitFullscreen = () => import('../../src/components/icons/svg-icon-exit-fullscreen.vue' /* webpackChunkName: "components/icons-svg-icon-exit-fullscreen" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconExpandMore = () => import('../../src/components/icons/svg-icon-expand-more.vue' /* webpackChunkName: "components/icons-svg-icon-expand-more" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconExport = () => import('../../src/components/icons/svg-icon-export.vue' /* webpackChunkName: "components/icons-svg-icon-export" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconEye = () => import('../../src/components/icons/svg-icon-eye.vue' /* webpackChunkName: "components/icons-svg-icon-eye" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconFullscreen = () => import('../../src/components/icons/svg-icon-fullscreen.vue' /* webpackChunkName: "components/icons-svg-icon-fullscreen" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconGasStation = () => import('../../src/components/icons/svg-icon-gas-station.vue' /* webpackChunkName: "components/icons-svg-icon-gas-station" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconHotCar = () => import('../../src/components/icons/svg-icon-hot-car.vue' /* webpackChunkName: "components/icons-svg-icon-hot-car" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconInfo = () => import('../../src/components/icons/svg-icon-info.vue' /* webpackChunkName: "components/icons-svg-icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconMapNationwide = () => import('../../src/components/icons/svg-icon-map-nationwide.vue' /* webpackChunkName: "components/icons-svg-icon-map-nationwide" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconMap = () => import('../../src/components/icons/svg-icon-map.vue' /* webpackChunkName: "components/icons-svg-icon-map" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconPayment = () => import('../../src/components/icons/svg-icon-payment.vue' /* webpackChunkName: "components/icons-svg-icon-payment" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconPerson = () => import('../../src/components/icons/svg-icon-person.vue' /* webpackChunkName: "components/icons-svg-icon-person" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconPhone = () => import('../../src/components/icons/svg-icon-phone.vue' /* webpackChunkName: "components/icons-svg-icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconPlus = () => import('../../src/components/icons/svg-icon-plus.vue' /* webpackChunkName: "components/icons-svg-icon-plus" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconPound = () => import('../../src/components/icons/svg-icon-pound.vue' /* webpackChunkName: "components/icons-svg-icon-pound" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconPrint = () => import('../../src/components/icons/svg-icon-print.vue' /* webpackChunkName: "components/icons-svg-icon-print" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconStarEmpty = () => import('../../src/components/icons/svg-icon-star-empty.vue' /* webpackChunkName: "components/icons-svg-icon-star-empty" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconStarFull = () => import('../../src/components/icons/svg-icon-star-full.vue' /* webpackChunkName: "components/icons-svg-icon-star-full" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconStarHalf = () => import('../../src/components/icons/svg-icon-star-half.vue' /* webpackChunkName: "components/icons-svg-icon-star-half" */).then(c => wrapFunctional(c.default || c))
export const IconsSvgIconTouch = () => import('../../src/components/icons/svg-icon-touch.vue' /* webpackChunkName: "components/icons-svg-icon-touch" */).then(c => wrapFunctional(c.default || c))
export const InventoryCardSlider = () => import('../../src/components/inventory-card-slider/inventory-card-slider.vue' /* webpackChunkName: "components/inventory-card-slider" */).then(c => wrapFunctional(c.default || c))
export const LocationDetailsSection = () => import('../../src/components/location-details-section/location-details-section.vue' /* webpackChunkName: "components/location-details-section" */).then(c => wrapFunctional(c.default || c))
export const NearestStore = () => import('../../src/components/nearest-store/NearestStore.vue' /* webpackChunkName: "components/nearest-store" */).then(c => wrapFunctional(c.default || c))
export const NumberedSteps = () => import('../../src/components/numbered-steps/NumberedSteps.vue' /* webpackChunkName: "components/numbered-steps" */).then(c => wrapFunctional(c.default || c))
export const OnlineTools = () => import('../../src/components/online-tools/OnlineTools.vue' /* webpackChunkName: "components/online-tools" */).then(c => wrapFunctional(c.default || c))
export const OnlineToolsCard = () => import('../../src/components/online-tools/OnlineToolsCard.vue' /* webpackChunkName: "components/online-tools-card" */).then(c => wrapFunctional(c.default || c))
export const SearchModel = () => import('../../src/components/search-model/SearchModel.vue' /* webpackChunkName: "components/search-model" */).then(c => wrapFunctional(c.default || c))
export const SearchPageLoader = () => import('../../src/components/search-page-loader/search-page-loader.vue' /* webpackChunkName: "components/search-page-loader" */).then(c => wrapFunctional(c.default || c))
export const SectionWithContainer = () => import('../../src/components/section-with-container/SectionWithContainer.vue' /* webpackChunkName: "components/section-with-container" */).then(c => wrapFunctional(c.default || c))
export const SectionWithDescription = () => import('../../src/components/section-with-description/SectionWithDescription.vue' /* webpackChunkName: "components/section-with-description" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../src/components/slider/slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const ToastGroup = () => import('../../src/components/toast/toast-group.vue' /* webpackChunkName: "components/toast-group" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../src/components/toast/toast.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const UppercaseAndNormalCaseWhenMobileHeading = () => import('../../src/components/uppercase-and-normal-case-when-mobile-heading/uppercase-and-normal-case-when-mobile-heading.vue' /* webpackChunkName: "components/uppercase-and-normal-case-when-mobile-heading" */).then(c => wrapFunctional(c.default || c))
export const VirtualScroller = () => import('../../src/components/virtual-scroller/virtual-scroller.vue' /* webpackChunkName: "components/virtual-scroller" */).then(c => wrapFunctional(c.default || c))
export const BuildingBlocksFaqHeader = () => import('../../src/components/building-blocks/faq/faq-header.vue' /* webpackChunkName: "components/building-blocks-faq-header" */).then(c => wrapFunctional(c.default || c))
export const BuildingBlocksFaqItem = () => import('../../src/components/building-blocks/faq/faq-item.vue' /* webpackChunkName: "components/building-blocks-faq-item" */).then(c => wrapFunctional(c.default || c))
export const BuildingBlocksFaq = () => import('../../src/components/building-blocks/faq/faq.vue' /* webpackChunkName: "components/building-blocks-faq" */).then(c => wrapFunctional(c.default || c))
export const BuildingBlocksHeaderSimple = () => import('../../src/components/building-blocks/header-simple/header-simple.vue' /* webpackChunkName: "components/building-blocks-header-simple" */).then(c => wrapFunctional(c.default || c))
export const ButtonsBrickButton = () => import('../../src/components/buttons/brick-button/BrickButton.vue' /* webpackChunkName: "components/buttons-brick-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsCheckBox = () => import('../../src/components/buttons/check-box/check-box.vue' /* webpackChunkName: "components/buttons-check-box" */).then(c => wrapFunctional(c.default || c))
export const ButtonsColourButton = () => import('../../src/components/buttons/colour-button/colour-button.vue' /* webpackChunkName: "components/buttons-colour-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsQuestionButton = () => import('../../src/components/buttons/question-button/question-button.vue' /* webpackChunkName: "components/buttons-question-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsRadioButton = () => import('../../src/components/buttons/radio-button/RadioButton.vue' /* webpackChunkName: "components/buttons-radio-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsToggleButton = () => import('../../src/components/buttons/toggle-button/toggle-button.vue' /* webpackChunkName: "components/buttons-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const InputFormDatePicker = () => import('../../src/components/input/FormDatePicker/form-date-picker.vue' /* webpackChunkName: "components/input-form-date-picker" */).then(c => wrapFunctional(c.default || c))
export const InputFormTimePicker = () => import('../../src/components/input/FormTimePicker/form-time-picker.vue' /* webpackChunkName: "components/input-form-time-picker" */).then(c => wrapFunctional(c.default || c))
export const InputNumberDropDown = () => import('../../src/components/input/NumberDropDown/NumberDropDown.vue' /* webpackChunkName: "components/input-number-drop-down" */).then(c => wrapFunctional(c.default || c))
export const KeenSliderDynamicBullets = () => import('../../src/components/keen-slider/dynamic-bullets/DynamicBullets.vue' /* webpackChunkName: "components/keen-slider-dynamic-bullets" */).then(c => wrapFunctional(c.default || c))
export const KeenSliderStaticBullets = () => import('../../src/components/keen-slider/static-bullets/StaticBullets.vue' /* webpackChunkName: "components/keen-slider-static-bullets" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
