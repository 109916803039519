import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { storageWrapper } from '@/tools/storage';
import { Covid19LastUpdated } from '@/features/public-service-announcements/covid-19/types';

const name = 'psa';

@Module({ name, namespaced: true, stateFactory: true })
export default class PSA extends VuexModule {
  public Covid19: boolean = false;
  public SevenHillsMove: boolean = false;

  @Mutation public LoadCovid19() {
    const date = storageWrapper.Read<string>(`${name}-Covid19`);
    if (date !== Covid19LastUpdated) {
      this.Covid19 = true;
    }
  }

  @Mutation public DismissCovid19() {
    this.Covid19 = false;

    storageWrapper.Write(`${name}-Covid19`, Covid19LastUpdated);
  }
}

export const PSAModule = namespace(name);
