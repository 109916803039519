import { VNode, CreateElement } from 'vue/types/umd';
import { FAQAnswer } from '../../../../../../shared/global/faq';

export function faqCreateParagraphItem(
  h: CreateElement,
  answer: FAQAnswer
): VNode {
  return h(
    'p',
    {
      class: 'body-1-new ink-medium--text',
    },
    [answer.content]
  );
}
