import { RouteConfig } from 'vue-router/types';
import AllianzLandingPage from '@/features/allianz-landing-page/AllianzLandingPage';
import FaqsLandingPage from '@/features/faqs-landing-page/FaqsLandingPage';
import SimplrLandingPage from '@/features/simplr-landing-page/SimplrLandingPage';

export const landingRoutes: RouteConfig[] = [
  {
    path: '/used/:make/:model',
    name: 'vehicle-landing-page',
    component: () =>
      import('@/features/vehicle-landing-page/VehicleLandingPage.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/car-insurance',
    name: 'allianz-landing-page',
    component: AllianzLandingPage,
  },
  {
    path: '/buy-used-cars-online',
    name: 'buy-cars-online',
    component: () =>
      import('@/features/buy-cars-online/BuyCarsOnline.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/faqs',
    name: 'faqs-landing-page',
    component: FaqsLandingPage,
  },
  {
    path: '/car-subscription',
    name: 'simplr-landing-page',
    component: SimplrLandingPage,
  },
];
