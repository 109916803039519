import { Component, Vue } from 'vue-property-decorator';
import { FilterModule } from '@/store/filter';
import { RouteNames } from '@/router/route-consts';

@Component({
  name: 'simplr-header',
})
export default class SimplrHeader extends Vue {
  @FilterModule.Mutation public ClearFilters!: () => void;
  @FilterModule.Mutation public ToggleFilter!: (key: string) => void;

  public findSubscriptionCars() {
    this.ClearFilters();
    this.ToggleFilter('Subscription.Subcription eligible only');
    this.$router.push({ name: RouteNames.SearchRouteName });
  }
}
