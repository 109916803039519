import { SearchCriteria } from '../../../../shared/inventory/search';
import { apiFunctions } from '@/helpers/functions';

class SearchApi {
  public async LoadSearchCriteria(): Promise<SearchCriteria | null> {
    const callUrl: string = 'getSearchCriteria';
    const result = await apiFunctions.axios.get<SearchCriteria>(callUrl, {
      cache: true,
    });
    return result.data;
  }
}

export const searchApi = new SearchApi();
