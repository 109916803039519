import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Inventory } from '../../../shared/inventory';
import { Dictionary } from '../../../shared/global/types';
import {
  Makes,
  SearchCriteria,
  InventorySearchConfig,
  KeyValuePair,
  LocationDetails,
  FinanceOptions,
  TransportOptions,
  VehicleType,
  Lifestyle,
} from '../../../shared/inventory/search';
import * as typ from '@/features/filter/types';
import { storageWrapper } from '@/tools/storage';
import {
  CreateOverrideForBmw,
  makeBmw,
} from '@/features/filter/helpers/model.overrides';
import {
  SetRange,
  rangeFormatters,
  SetPrice,
  SetRangeFromValues,
  SetRepaymentFilterValues,
} from '@/features/filter/helpers/rangeHelpers';
import { CleanupFilters } from '@/features/filter/helpers/cleanupFilters';
import { searchApi } from '@/features/filter/api';
import {
  makeOptions,
  modelOptions,
  noVariantLabel,
} from '@/features/filter/const';
import '@/helpers/extensions';

export const name = 'filter';

@Module({ stateFactory: true })
export default class Filter extends VuexModule {
  public vehicleTypes: VehicleType[] = [];
  public lifestyles: Lifestyle[] = [];
  public VehicleSizeList: string[] = ['Small', 'Medium', 'Large'];
  public ProductCategories: KeyValuePair[] = [];
  public SubscriptionOptions: KeyValuePair[] = [];
  public PaintColours: KeyValuePair[] = [];
  public CylindersOptions: string[] = typ.cylinders;
  public DoorsOptions: number[] = typ.doors;
  public FeaturesOptions: Dictionary<string[]> = {};
  public FuelEconomyOptions: typ.RangeOptions = typ.fuelEconomyOptions;
  public FuelTypeOptions: string[] = [];
  public GreenStarRatingOptions: string[] = [
    '0',
    '0.5',
    '1',
    '1.5',
    '2',
    '2.5',
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
  ];

  public AncapStarRatingOptions: string[] = ['0', '1', '2', '3', '4', '5'];
  public KilometresOptions: typ.RangeOptions = typ.kilometresOptions;
  public LocationOptions: LocationDetails[] = [];
  public MakeOptions: Makes = makeOptions;
  public ModelOptions: typ.ModelOptions = modelOptions;
  public PPlateApprovedOptions: string[] = typ.pPlateApproved;
  public PriceOptions: typ.PriceOptions = { ...typ.priceOptions };
  public FinanceOptions: FinanceOptions = typ.financeOptions;
  public QuickOptions: typ.QuickOptions = typ.quickOptions;
  public SeatsOptions: typ.RangeOptions = typ.seatsOptions;
  public TransmissionOptions: string[] = typ.transmissionOptions;
  public YearOptions: typ.RangeOptions = typ.yearOptions;
  public RepaymentOptions: typ.RepaymentOptions = typ.repaymentOptions;

  public SortOptions: typ.SortOptions = {
    Selected: 0,
    Options: [],
    PreviousSelected: 0,
  };

  public SelectedFilter: string[] = [];
  public InventorySearchConfig: InventorySearchConfig = {
    Search: {},
    Sort: {},
  } as InventorySearchConfig;

  public SearchHints: typ.SearchHints[] = [];
  public StockNumberSearchHints: typ.SearchHints[] = [];
  public RegistrationSearchHints: typ.SearchHints[] = [];
  public StandardOptionsLookup: KeyValuePair[] = [];
  public CountryLookup: Dictionary<string> = {};
  public CylinderLookup: Dictionary<string> = {};
  public TransmissionLookup: Dictionary<string> = {};
  public TransportOptions: TransportOptions = { Locations: [] };

  @Mutation public ToggleFilter(key: string) {
    const index = this.SelectedFilter.findIndex((x) => x === key);
    if (index >= 0) {
      this.SelectedFilter.splice(index, 1);
    } else {
      const searchHint =
        this.SearchHints.find((x) => x.Key === key) ||
        this.StockNumberSearchHints.find((x) => x.Key === key) ||
        this.RegistrationSearchHints.find((x) => x.Key === key);

      if (searchHint) {
        this.SelectedFilter.push(searchHint.Key);

        if (key.startsWith('Model.')) {
          const modelIndex = key.lastIndexOf('.');
          const make = key.substr(6, modelIndex - 6);
          if (make) {
            const makeKey = `Make.${make}`;
            if (this.SelectedFilter.findIndex((x) => x === makeKey) < 0) {
              this.SelectedFilter.push(makeKey);
            }
          }
        } else if (key.startsWith('Variant.')) {
          const makeIndex = key.indexOf('.');
          let rest = key.substr(makeIndex + 1);
          const modelIndex = rest.indexOf('.');
          const make = rest.substr(0, modelIndex);
          rest = rest.substr(modelIndex + 1);
          const variantIndex = rest.indexOf('.');
          const model = rest.substr(0, variantIndex);

          if (model) {
            const modelKey = `Model.${make}.${model}`;
            if (this.SelectedFilter.findIndex((x) => x === modelKey) < 0) {
              this.SelectedFilter.push(modelKey);
            }

            if (make) {
              const makeKey = `Make.${make}`;
              if (this.SelectedFilter.findIndex((x) => x === makeKey) < 0) {
                this.SelectedFilter.push(makeKey);
              }
            }
          }
        }
      }
    }

    if (index < 0) {
      // this is here just in case in another phase we want to track filters and chips etc
      // InternalSearch('refinement', key)
    }

    if (index >= 0) {
      const selections: typ.Selections = {
        seats: this.SeatsOptions,
        years: this.YearOptions,
        filters: this.SelectedFilter,
        fuelEcon: this.FuelEconomyOptions,
        odometer: this.KilometresOptions,
        price: this.PriceOptions,
        finance: this.RepaymentOptions,
      };
      CleanupFilters(key, this.SearchHints, selections);
    }

    storageWrapper.Write(`${name}.SelectedFilter`, this.SelectedFilter);
    storageWrapper.Write(`${name}.SearchHints`, this.SearchHints);
  }

  @Mutation public ClearFilters() {
    const filters: string[] = [];
    filters.push(...this.SelectedFilter);

    const selections: typ.Selections = {
      seats: this.SeatsOptions,
      years: this.YearOptions,
      filters: this.SelectedFilter,
      fuelEcon: this.FuelEconomyOptions,
      odometer: this.KilometresOptions,
      price: this.PriceOptions,
      finance: this.RepaymentOptions,
    };

    for (const key of filters) {
      const index = this.SelectedFilter.findIndex((x) => x === key);
      if (index >= 0) {
        this.SelectedFilter.splice(index, 1);
      }
      CleanupFilters(key, this.SearchHints, selections);
    }
  }

  @Mutation public UpdateSeats(value: number[]) {
    SetRange(
      rangeFormatters.Seats,
      this.SeatsOptions,
      this.SelectedFilter,
      this.SearchHints,
      value
    );
    storageWrapper.Write(`${name}.SeatsOptions`, this.SeatsOptions);
  }

  @Mutation public UpdateYears(value: number[]) {
    SetRangeFromValues(
      rangeFormatters.Years,
      this.YearOptions,
      this.SelectedFilter,
      this.SearchHints,
      value
    );
    storageWrapper.Write(`${name}.YearOptions`, this.YearOptions);
  }

  @Mutation public UpdateRepaymentFilter(value: typ.RepaymentOptions) {
    const combinedValues = { ...this.RepaymentOptions, ...value };
    if (value.IntervalType) {
      this.RepaymentOptions.IntervalType = value.IntervalType;
    }
    SetRepaymentFilterValues(
      this.RepaymentOptions,
      this.SelectedFilter,
      this.SearchHints,
      [combinedValues.MinValue, combinedValues.MaxValue]
    );

    storageWrapper.Write(`${name}.Repayment`, this.RepaymentOptions);
  }

  @Mutation public UpdatePrice(value: number[]) {
    if (value[1] === 0) {
      value[1] = 1;
    }
    SetPrice(
      this.FinanceOptions,
      this.PriceOptions,
      this.SelectedFilter,
      this.SearchHints,
      value
    );
    storageWrapper.Write(`${name}.PriceOptions`, this.PriceOptions);
  }

  @Mutation public UpdateKm(value: number) {
    if (value === 0) {
      value = 1;
    }
    SetRange(
      rangeFormatters.Odometer,
      this.KilometresOptions,
      this.SelectedFilter,
      this.SearchHints,
      [0, value]
    );
    storageWrapper.Write(`${name}.KilometresOptions`, this.KilometresOptions);
  }

  @Mutation public UpdateFuelEcon(value: number) {
    SetRange(
      rangeFormatters.FuelEcon,
      this.FuelEconomyOptions,
      this.SelectedFilter,
      this.SearchHints,
      [0, value]
    );
    storageWrapper.Write(`${name}.FuelEconomyOptions`, this.FuelEconomyOptions);
  }

  @Mutation public UpdatePriceOptions(value: typ.PriceOptions) {
    this.PriceOptions = { ...this.PriceOptions, ...value };
    SetPrice(
      this.FinanceOptions,
      this.PriceOptions,
      this.SelectedFilter,
      this.SearchHints
    );
    storageWrapper.Write(`${name}.PriceOptions`, this.PriceOptions);
  }

  @Mutation public RevertSortOptions() {
    this.SortOptions.Selected = this.SortOptions.PreviousSelected;
    storageWrapper.Write(`${name}.SortOptions`, this.SortOptions);
  }

  @Mutation public UpdateSortOptions(value: string) {
    let index = this.SortOptions.Options.indexOf(value);
    if (index < 0) {
      index = 0;
    }
    this.SortOptions.PreviousSelected = this.SortOptions.Selected;
    this.SortOptions.Selected = index;
    storageWrapper.Write(`${name}.SortOptions`, this.SortOptions);
  }

  @Mutation public SetSearchCriteria(criteria: SearchCriteria) {
    if (!criteria) {
      return;
    }

    this.MakeOptions = { ...this.MakeOptions, ...criteria.Makes };
    storageWrapper.Write(`${name}.MakeOptions`, this.MakeOptions);

    this.ModelOptions = { ...this.ModelOptions, ...criteria.Models };
    storageWrapper.Write(`${name}.ModelOptions`, this.ModelOptions);

    this.SeatsOptions = { ...this.SeatsOptions, Criteria: criteria.Seats };
    storageWrapper.Write(`${name}.SeatsOptions`, this.SeatsOptions);

    if (criteria.vehicleTypes) {
      this.vehicleTypes = criteria.vehicleTypes;
      storageWrapper.Write(`${name}.vehicleTypes`, this.vehicleTypes);
    }

    if (criteria.lifestyles) {
      this.lifestyles = criteria.lifestyles;
      storageWrapper.Write(`${name}.lifestyles`, this.lifestyles);
    }

    if (
      criteria.Green &&
      criteria.Green.Values &&
      criteria.Green.Values.length
    ) {
      this.GreenStarRatingOptions = criteria.Green.Values.map(
        (x) => `${x.Value}`
      );
      storageWrapper.Write(
        `${name}.GreenStarRatingOptions`,
        this.GreenStarRatingOptions
      );
    }

    if (
      criteria.Ancap &&
      criteria.Ancap.Values &&
      criteria.Ancap.Values.length
    ) {
      this.AncapStarRatingOptions = criteria.Ancap.Values.map(
        (x) => `${x.Value}`
      );
      storageWrapper.Write(
        `${name}.AncapStarRatingOptions`,
        this.AncapStarRatingOptions
      );
    }

    if (
      criteria.ReleaseYears &&
      criteria.ReleaseYears.Values &&
      criteria.ReleaseYears.Values.length
    ) {
      this.YearOptions = {
        ...this.YearOptions,
        Criteria: criteria.ReleaseYears,
      };
      storageWrapper.Write(`${name}.YearOptions`, this.YearOptions);
    }

    if (
      criteria.Odometer &&
      criteria.Odometer.Values &&
      criteria.Odometer.Values.length
    ) {
      this.KilometresOptions = {
        ...this.KilometresOptions,
        Criteria: criteria.Odometer,
      };
      if (this.KilometresOptions.MaxValue >= criteria.Odometer.Values.length) {
        this.KilometresOptions.MaxValue = criteria.Odometer.Values.length - 1;
      }
      storageWrapper.Write(`${name}.KilometresOptions`, this.KilometresOptions);
    }

    if (
      criteria.Prices &&
      criteria.Prices.Values &&
      criteria.Prices.Values.length
    ) {
      Vue.set(this.PriceOptions.OverallPriceValue, 'Criteria', criteria.Prices);
      if (
        this.PriceOptions.OverallPriceValue.MaxValue >=
        criteria.Prices.Values[criteria.Prices.Values.length - 1].Value
      ) {
        this.PriceOptions.OverallPriceValue.MaxValue =
          criteria.Prices.Values[criteria.Prices.Values.length - 1].Value;
      }
      storageWrapper.Write(`${name}.PriceOptions`, this.PriceOptions);
    }

    this.FinanceOptions = {
      ...this.FinanceOptions,
      ...criteria.FinanceOptions,
    };
    storageWrapper.Write(`${name}.FuelTypeOptions`, this.FuelTypeOptions);

    if (criteria.Locations) {
      this.LocationOptions = criteria.Locations;
      storageWrapper.Write(`${name}.LocationOptions`, this.LocationOptions);
    }

    if (criteria.VehicleSizes) {
      this.VehicleSizeList = criteria.VehicleSizes;
      storageWrapper.Write(`${name}.VehicleSizeList`, this.VehicleSizeList);
    }

    if (criteria.ProdCateg) {
      this.ProductCategories = criteria.ProdCateg;
      storageWrapper.Write(`${name}.ProductCategories`, this.ProductCategories);
    }

    if (criteria.Subscription) {
      this.SubscriptionOptions = criteria.Subscription;
      storageWrapper.Write(
        `${name}.SubscriptionOptions`,
        this.SubscriptionOptions
      );
    }

    if (criteria.Transmission) {
      this.TransmissionOptions = criteria.Transmission;
      storageWrapper.Write(
        `${name}.TransmissionOptions`,
        this.TransmissionOptions
      );
    }

    if (criteria.Cylinder) {
      this.CylindersOptions = criteria.Cylinder;
      storageWrapper.Write(`${name}.CylindersOptions`, this.CylindersOptions);
    }

    if (criteria.Features) {
      this.FeaturesOptions = criteria.Features;
      storageWrapper.Write(`${name}.FeaturesOptions`, this.FeaturesOptions);
    }

    if (criteria.FuelTypes) {
      this.FuelTypeOptions = criteria.FuelTypes;
      storageWrapper.Write(`${name}.FuelTypeOptions`, this.FuelTypeOptions);
    }

    if (criteria.PaintColours) {
      this.PaintColours = criteria.PaintColours;
      storageWrapper.Write(`${name}.PaintColours`, this.PaintColours);
    }

    if (Array.isArray(criteria.OptionsLookup)) {
      this.StandardOptionsLookup = criteria.OptionsLookup;
    } else {
      const newStandardOptions: KeyValuePair[] = [];
      for (const key in criteria.OptionsLookup) {
        if (Object.prototype.hasOwnProperty.call(criteria.OptionsLookup, key)) {
          const value = criteria.OptionsLookup[key];
          newStandardOptions.push({ Key: key, Value: value });
        }
      }
      this.StandardOptionsLookup = newStandardOptions;
    }
    storageWrapper.Zip(
      `${name}.StandardOptionsLookup`,
      this.StandardOptionsLookup
    );

    this.CountryLookup = { ...this.CountryLookup, ...criteria.CountryLookup };
    storageWrapper.Zip(`${name}.CountryLookup`, this.CountryLookup);

    this.CylinderLookup = {
      ...this.CylinderLookup,
      ...criteria.CylinderLookup,
    };
    storageWrapper.Zip(`${name}.CylinderLookup`, this.CylinderLookup);

    this.TransmissionLookup = {
      ...this.TransmissionLookup,
      ...criteria.TransmissionLookup,
    };
    storageWrapper.Zip(`${name}.TransmissionLookup`, this.TransmissionLookup);

    this.TransportOptions = {
      ...this.TransportOptions,
      ...criteria.TransportOptions,
    };
    storageWrapper.Zip(`${name}.TransportOptions`, this.TransportOptions);

    const makeOptions = this.MakeOptions.List.filter((x) => x).map((x) => ({
      Description: x.toProperCase(),
      Source: 'Make',
      Key: `Make.${x}`,
    }));

    const transmissionOptions = this.TransmissionOptions.map((x) => ({
      Description: x,
      Source: 'Trans',
      Key: `Trans.${x}`,
    }));

    const cylindersOptions = this.CylindersOptions.map((x) => ({
      Description: `${x} cylinders`,
      Source: 'Cyl',
      Key: `Cyl.${x}`,
    }));

    const featOptions = Object.keys(this.FeaturesOptions).reduce(
      (acc: any[], key) => {
        const element = this.FeaturesOptions[key];
        return [
          ...acc,
          ...element.map((key) => ({
            Description: `${key}`,
            Source: 'Feat',
            Key: `Feat.${key}`,
          })),
        ];
      },
      []
    );

    const vehicleOptions = this.vehicleTypes.map((x) => ({
      Description: x.Value,
      Source: 'VehType',
      Key: `VehType.${x.Key}`,
    }));

    const lifeStyles = this.lifestyles.map((x) => ({
      Description: x.Value,
      Source: 'Lifestyle',
      Key: `Lifestyle.${x.Key}`,
    }));

    const vehicleSizes = this.VehicleSizeList.map((x) => ({
      Description: x,
      Source: 'VehSize',
      Key: `VehSize.${x}`,
    }));

    const productCategories = this.ProductCategories.map((x) => ({
      Description: x.Value,
      Source: 'ProdCateg',
      Key: `ProdCateg.${x.Key}`,
    }));

    const subscriptionOptions = this.SubscriptionOptions.map((x) => ({
      Description: x.Value,
      Source: 'Subscription',
      Key: `Subscription.${x.Key}`,
    }));

    const greenStarRatingOptions = this.GreenStarRatingOptions.map((x, i) => ({
      Description: `Green ${x}${x === '5' ? '' : '+'} star`,
      Source: 'Green',
      Key: `Green.${i}`,
    }));

    const ancapStarRatingOptions = this.AncapStarRatingOptions.map((x, i) => ({
      Description: `ANCAP ${x}${x === '5' ? '' : '+'} star`,
      Source: 'Ancap',
      Key: `Ancap.${i}`,
    }));

    const locationOptions = this.LocationOptions.map((x) => ({
      Description: `${[
        x.Description,
        (x.Address?.StateShort || '').toUpperCase(),
      ]
        .filter((b) => b)
        .join(', ')}`,
      Source: 'Location',
      Key: `Location.${x.Key}`,
    }));

    const paintColours = this.PaintColours.map((x) => ({
      Description: `${x.Key} paint colour`,
      Source: 'PaintColours',
      Key: `PaintColour.${x.Key}`,
    }));

    const kilometresOptions =
      this.KilometresOptions.Criteria.Values?.map((x) => ({
        Description: x.Description || `${x.Value},000 km or less`,
        Source: 'Odometer',
        Key: `Odometer.${x.Value * 1000}`,
      })) || [];

    const fuelTypeOptions = this.FuelTypeOptions.map((x) => ({
      Description: x,
      Source: 'FuelType',
      Key: `FuelType.${x}`,
    }));

    let searchHints = [
      ...makeOptions,
      ...transmissionOptions,
      ...cylindersOptions,
      ...featOptions,
      ...vehicleOptions,
      ...lifeStyles,
      ...vehicleSizes,
      ...productCategories,
      ...subscriptionOptions,
      ...greenStarRatingOptions,
      ...ancapStarRatingOptions,
      ...locationOptions,
      ...paintColours,
      ...kilometresOptions,
      ...fuelTypeOptions,
    ];

    const makes = this.ModelOptions.Makes;
    for (const make in makes) {
      if (Object.prototype.hasOwnProperty.call(makes, make)) {
        for (const model of makes[make]) {
          if (make === makeBmw) {
            CreateOverrideForBmw(model, searchHints);
          } else {
            searchHints.push({
              Description: `${make.toProperCase()} ${model.Name.toProperCase()}`,
              Source: 'Model',
              Key: `Model.${make}.${model.Name}`,
            });

            if (model.Variants) {
              searchHints = searchHints.concat(
                model.Variants.map((variant) => ({
                  Description: `${make.toProperCase()} ${model.Name.toProperCase()} ${
                    variant.toProperCase() || noVariantLabel
                  }`,
                  Source: 'Variant',
                  Key: `Variant.${make}.${model.Name}.${variant}`,
                }))
              );
            }
          }
        }
      }
    }

    this.SearchHints = searchHints;
    this.SortOptions = { ...this.SortOptions, Options: criteria.Sorts };
    storageWrapper.Write(`${name}.SortOptions`, this.SortOptions);

    SetRange(
      rangeFormatters.Seats,
      this.SeatsOptions,
      this.SelectedFilter,
      this.SearchHints
    );
    SetRangeFromValues(
      rangeFormatters.Years,
      this.YearOptions,
      this.SelectedFilter,
      this.SearchHints
    );
    SetRepaymentFilterValues(
      this.RepaymentOptions,
      this.SelectedFilter,
      this.SearchHints
    );
    SetPrice(
      this.FinanceOptions,
      this.PriceOptions,
      this.SelectedFilter,
      this.SearchHints
    );
    SetRange(
      rangeFormatters.Odometer,
      this.KilometresOptions,
      this.SelectedFilter,
      this.SearchHints
    );
    SetRange(
      rangeFormatters.FuelEcon,
      this.FuelEconomyOptions,
      this.SelectedFilter,
      this.SearchHints
    );
    storageWrapper.Write(`${name}.SearchHints`, this.SearchHints);
  }

  @Mutation public UpdateStockNumberCriteria(inv: Dictionary<Inventory>) {
    const searchHints: typ.SearchHints[] = Object.keys(inv).reduce(
      (acc: typ.SearchHints[], key: string) => {
        const inventory = inv[key];
        const stockNumber = inventory.Particulars.StockNumber;
        if (stockNumber) {
          return [
            ...acc,
            {
              Description: `Stock ${stockNumber}`,
              Source: 'StockNumber',
              Key: `StockNumber.${stockNumber}`,
            },
          ];
        }
        return acc;
      },
      []
    );

    if (this.StockNumberSearchHints.length) {
      this.StockNumberSearchHints.splice(0, this.StockNumberSearchHints.length);
    }

    this.StockNumberSearchHints.push(...searchHints);
  }

  @Mutation public UpdateRegistrationCriteria(inv: Dictionary<Inventory>) {
    const searchHints: typ.SearchHints[] = Object.keys(inv).reduce(
      (acc: typ.SearchHints[], key) => {
        const inventory = inv[key];
        const registration = inventory?.Vehicle?.Registration?.Number;
        if (registration) {
          return [
            ...acc,
            {
              Description: `Registration ${registration}`,
              Source: 'Registration',
              Key: `Registration.${registration}`,
            },
          ];
        }
        return acc;
      },
      []
    );

    if (this.RegistrationSearchHints.length) {
      this.RegistrationSearchHints.splice(
        0,
        this.RegistrationSearchHints.length
      );
    }

    this.RegistrationSearchHints.push(...searchHints);
  }

  @Mutation public Init() {
    const makeOptions = storageWrapper.Read<Makes>(`${name}.MakeOptions`);
    if (
      makeOptions &&
      makeOptions.List &&
      makeOptions.List.asStringArray &&
      makeOptions.List.asStringArray()
    ) {
      this.MakeOptions = { ...this.MakeOptions, ...makeOptions };
    }

    const modelOptions = storageWrapper.Read<typ.ModelOptions>(
      `${name}.ModelOptions`
    );
    if (modelOptions && modelOptions.Makes) {
      this.MakeOptions = { ...this.MakeOptions, ...modelOptions };
    }

    const selectedFilters = storageWrapper.Read<string[]>(
      `${name}.SelectedFilter`
    );
    if (selectedFilters) {
      this.SelectedFilter.splice(0, this.SelectedFilter.length);
      this.SelectedFilter.push(...selectedFilters);
    }

    const seatOptions = storageWrapper.Read<typ.RangeOptions>(
      `${name}.SeatsOptions`
    );
    if (seatOptions) {
      this.SeatsOptions = { ...this.SeatsOptions, ...seatOptions };
      SetRange(
        rangeFormatters.Seats,
        this.SeatsOptions,
        this.SelectedFilter,
        this.SearchHints
      );
    }

    const yearOptions = storageWrapper.Read<typ.RangeOptions>(
      `${name}.YearOptions`
    );
    if (yearOptions) {
      this.YearOptions = { ...this.YearOptions, ...yearOptions };
      if (
        this.YearOptions.MinValue &&
        this.YearOptions.MinValue < 30 &&
        this.YearOptions.Criteria?.Values
      ) {
        this.YearOptions.MinValue =
          this.YearOptions.Criteria?.Values[this.YearOptions.MinValue].Value ||
          0;
      }
      if (
        this.YearOptions.MaxValue &&
        this.YearOptions.MaxValue < 30 &&
        this.YearOptions.Criteria?.Values
      ) {
        this.YearOptions.MaxValue =
          this.YearOptions.Criteria.Values[this.YearOptions.MaxValue].Value ||
          0;
      }
      SetRangeFromValues(
        rangeFormatters.Years,
        this.YearOptions,
        this.SelectedFilter,
        this.SearchHints
      );
    }

    const repaymentOptions = storageWrapper.Read<typ.RepaymentOptions>(
      `${name}.Repayment`
    );
    if (repaymentOptions) {
      this.RepaymentOptions = {
        ...this.RepaymentOptions,
        ...repaymentOptions,
      };
      SetRepaymentFilterValues(
        this.RepaymentOptions,
        this.SelectedFilter,
        this.SearchHints
      );
    }

    const lifeStyles = storageWrapper.Read<KeyValuePair[]>(
      `${name}.lifestyles`
    );
    if (lifeStyles) {
      this.lifestyles.splice(0, this.lifestyles.length);
      this.lifestyles.push(...lifeStyles);
    }

    const vehicleTypes = storageWrapper.Read<KeyValuePair[]>(
      `${name}.vehicleTypes`
    );
    if (vehicleTypes) {
      this.vehicleTypes.splice(0, this.vehicleTypes.length);
      this.vehicleTypes.push(...vehicleTypes);
    }

    const priceValues = storageWrapper.Read<typ.PriceOptions>(
      `${name}.PriceOptions`
    );
    if (priceValues) {
      this.PriceOptions = { ...this.PriceOptions, ...priceValues };
      SetPrice(
        this.FinanceOptions,
        this.PriceOptions,
        this.SelectedFilter,
        this.SearchHints
      );
    }

    const kilometresOptions = storageWrapper.Read<typ.RangeOptions>(
      `${name}.KilometresOptions`
    );
    if (kilometresOptions) {
      this.KilometresOptions = {
        ...this.KilometresOptions,
        ...kilometresOptions,
      };
      SetRange(
        rangeFormatters.Odometer,
        this.KilometresOptions,
        this.SelectedFilter,
        this.SearchHints
      );
    }

    const fuelEconomyOptions = storageWrapper.Read<typ.RangeOptions>(
      `${name}.FuelEconomyOptions`
    );
    if (fuelEconomyOptions) {
      this.FuelEconomyOptions = {
        ...this.KilometresOptions,
        ...fuelEconomyOptions,
      };
      SetRange(
        rangeFormatters.FuelEcon,
        this.FuelEconomyOptions,
        this.SelectedFilter,
        this.SearchHints
      );
    }

    const stockNumberSearchHints = storageWrapper.Read<typ.SearchHints[]>(
      `${name}.StockNumberSearchHints`
    );
    if (stockNumberSearchHints) {
      this.StockNumberSearchHints.splice(0, this.StockNumberSearchHints.length);
      this.StockNumberSearchHints.push(...stockNumberSearchHints);
    }

    const registrationSearchHints = storageWrapper.Read<typ.SearchHints[]>(
      `${name}.RegistrationSearchHints`
    );
    if (registrationSearchHints) {
      this.RegistrationSearchHints.splice(
        0,
        this.RegistrationSearchHints.length
      );
      this.RegistrationSearchHints.push(...registrationSearchHints);
    }

    const sortOptions = storageWrapper.Read<typ.SortOptions>(
      `${name}.SortOptions`
    );
    if (sortOptions) {
      this.SortOptions = { ...this.SortOptions, ...sortOptions };
    }

    const standardOptionsLookup = storageWrapper.Unzip<KeyValuePair[]>(
      `${name}.StandardOptionsLookup`
    );
    if (Array.isArray(standardOptionsLookup)) {
      this.StandardOptionsLookup = standardOptionsLookup;
    }

    const CountryLookup = storageWrapper.Unzip<Dictionary<string>>(
      `${name}.CountryLookup`
    );
    if (CountryLookup) {
      this.CountryLookup = { ...this.CountryLookup, ...CountryLookup };
    }

    const cylinderLookup = storageWrapper.Unzip<Dictionary<string>>(
      `${name}.CylinderLookup`
    );
    if (cylinderLookup) {
      this.CylinderLookup = { ...this.CylinderLookup, ...cylinderLookup };
    }

    const transmissionLookup = storageWrapper.Unzip<Dictionary<string>>(
      `${name}.TransmissionLookup`
    );
    if (transmissionLookup) {
      this.TransmissionLookup = {
        ...this.TransmissionLookup,
        ...transmissionLookup,
      };
    }

    const transportOptions = storageWrapper.Unzip<TransportOptions>(
      `${name}.TransportOptions`
    );
    if (transportOptions) {
      this.TransportOptions = { ...this.TransportOptions, ...transportOptions };
    }

    const paintColours = storageWrapper.Read<KeyValuePair[]>(
      `${name}.PaintColours`
    );
    if (paintColours) {
      this.PaintColours.splice(0, this.PaintColours.length);
      this.PaintColours.push(...paintColours);
    }
  }

  @Action({ commit: 'SetSearchCriteria' }) public async LoadSearchCriteria() {
    try {
      const criteria = await searchApi.LoadSearchCriteria();
      return criteria;
    } catch (error) {
      Sentry.setContext('message', {
        message: 'Error while fetching search criteria',
      });
      Sentry.captureException(error);
      return null;
    }
  }
}

export const FilterModule = namespace(name);
