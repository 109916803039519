const states: string[] = [
  'Western Australia',
  'New South Wales',
  'Queensland',
  'South Australia',
  'Victoria',
  'Tasmania',
];

export interface StateWithStores {
  state: string;
  store: string[];
}

export const statesWithStores: StateWithStores[] = [
  {
    state: 'western-australia',
    store: ['EJ', 'EC'],
  },
  {
    state: 'new-south-wales',
    store: ['EN', 'EK', 'ES', 'EV', 'ER'],
  },
  {
    state: 'queensland',
    store: ['EH'],
  },
  {
    state: 'south-australia',
    store: ['EM'],
  },
  {
    state: 'victoria',
    store: ['EB'],
  },
  {
    state: 'tasmania',
    store: ['EO'],
  },
];

export default states;
