import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'locate-us',
})
export default class extends Vue {
  public videoOpened: boolean = false;

  public toggleVideoOpenedModal() {
    this.videoOpened = !this.videoOpened;
  }
}
