var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-with-container',{staticClass:"faqs",attrs:{"halfTopPadding":_vm.halfTopPadding}},[(_vm.title)?_c(_vm.titleHeadingTag,{tag:"component",staticClass:"secondary--text text-center mb-12"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.subTitle)?_c('h3',{staticClass:"secondary--text text-center"},[_vm._v(_vm._s(_vm.subTitle)),_c('v-divider',{staticClass:"my-5"})],1):_vm._e(),_c('v-expansion-panels',{staticClass:"justify-start",attrs:{"multiple":"","value":_vm.openPanel}},[_c('v-row',[_c('v-col',{staticClass:"py-md-3 py-0",attrs:{"cols":"12","md":_vm.isOneColumn ? 12 : 6}},_vm._l((_vm.faqs),function(ref,index){
var title = ref.title;
var description = ref.description;
return (index % 2 === 0)?_c('v-expansion-panel',{key:title,staticClass:"mb-4"},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"","disable-icon-rotate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('h6',{staticClass:"secondary--text pr-4",domProps:{"innerHTML":_vm._s(title)}}),_c('v-icon',{staticClass:"d-flex justify-end"},[_vm._v(_vm._s(open ? 'mdi-minus-circle' : 'mdi-plus-circle'))])]}}],null,true)}),_c('v-expansion-panel-content',[_c('div',_vm._l((description),function(text){return _c('p',{key:text,domProps:{"innerHTML":_vm._s(text)}})}),0)])],1):_vm._e()}),1),_c('v-col',{staticClass:"py-md-3 py-0",attrs:{"cols":"12","md":_vm.isOneColumn ? 12 : 6}},_vm._l((_vm.faqs),function(ref,index){
var title = ref.title;
var description = ref.description;
return (index % 2 !== 0)?_c('v-expansion-panel',{key:title,staticClass:"mb-4"},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"","disable-icon-rotate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('h6',{staticClass:"secondary--text pr-4",domProps:{"innerHTML":_vm._s(title)}}),_c('v-icon',{staticClass:"d-flex justify-end"},[_vm._v(_vm._s(open ? 'mdi-minus-circle' : 'mdi-plus-circle'))])]}}],null,true)}),_c('v-expansion-panel-content',[_c('div',_vm._l((description),function(text){return _c('p',{key:text,domProps:{"innerHTML":_vm._s(text)}})}),0)])],1):_vm._e()}),1)],1)],1),(_vm.showSeeMoreFaqs)?_c('div',{staticClass:"mt-6"},[_c('h6',{staticClass:"text-center"},[_c('a',{staticClass:"secondary--text text-decoration-underline",attrs:{"href":"/faqs"}},[_vm._v("See more FAQs")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }