import { Dictionary } from '../../../../../shared/global/types';
import { InventorySearchConfig } from '../../../../../shared/inventory/search';
import { Selections } from '../../filter/types';
import { FilterPrice } from './FilterPrice';
import { FilterYears } from './FilterYears';
import { FilterSeats } from './FilterSeats';
import { FilterVehType } from './FilterVehType';
import { FilterLifestyle } from './FilterLifestyle';
import { FilterModels } from './FilterModels';
import { FilterMakes } from './FilterMakes';
import { FilterAncap } from './FilterAncap';
import { FilterGreen } from './FilterGreen';
import { FilterLocation } from './FilterLocation';
import { FilterOdometer } from './FilterOdometer';
import { FilterVehSize } from './FilterVehSize';
import { FilterTransmission } from './FilterTransmission';
import { FilterCylinder } from './FilterCylinder';
import { FilterStockNumber } from './FilterStockNumber';
import { FilterRegistration } from './FilterRegistration';
import { FilterFuelEcon } from './FilterFuelEcon';
import { FilterFuelType } from './FilterFuelType';
import { FilterFeatures } from './FilterFeatures';
import { FilterVariants } from './FilterVariants';
import { FilterPaintColour } from './FilterPaintColour';
import { FilterProdCateg } from './FilterProductCategory';
import { FilterRepayment } from './FilterRepayment';
import { FilterSubscription } from './FilterSubscription';

export function CreateFilters(selections: Selections): InventorySearchConfig {
  const filter: InventorySearchConfig = { Search: {}, Sort: {} };

  const filters = selections.filters;

  for (const filterKey of filters) {
    const found =
      FilterAncap(filter, filterKey) ||
      FilterCylinder(filter, filterKey) ||
      FilterFuelEcon(filter, filterKey, selections.fuelEcon) ||
      FilterFuelType(filter, filterKey) ||
      FilterRegistration(filter, filterKey) ||
      FilterGreen(filter, filterKey) ||
      FilterLifestyle(filter, filterKey) ||
      FilterLocation(filter, filterKey) ||
      FilterMakes(filter, filterKey) ||
      FilterModels(filter, filterKey) ||
      FilterVariants(filter, filterKey) ||
      FilterOdometer(filter, filterKey, selections.odometer) ||
      FilterPrice(filter, filterKey, selections.price) ||
      FilterSeats(filter, filterKey, selections.seats) ||
      FilterStockNumber(filter, filterKey) ||
      FilterTransmission(filter, filterKey) ||
      FilterVehSize(filter, filterKey) ||
      FilterVehType(filter, filterKey) ||
      FilterYears(filter, filterKey, selections.years) ||
      FilterRepayment(filter, filterKey, selections.finance) ||
      FilterFeatures(filter, filterKey) ||
      FilterProdCateg(filter, filterKey) ||
      FilterPaintColour(filter, filterKey) ||
      FilterSubscription(filter, filterKey);

    if (!found) {
      // Maybe we should do something to report that we cannot understand a filter type.
    }
  }

  const coordinates = selections.preferredLocation?.Address?.Coordinates;
  if (coordinates) {
    filter.Search.GeoLocation = [coordinates.Longitude, coordinates.Latitude];
  }

  if (selections.sort) {
    const sortOptions = selections.sort.Options;
    const sortChoice = selections.sort.Selected;

    if (sortChoice >= 0 && sortChoice < sortOptions.length) {
      const sort = sortOptions[sortChoice];
      (filter.Sort as Dictionary<boolean>)[sort] = true;
    }
  }
  return filter;
}
