import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { mainVersion, fullVersion } from '../versionDetails';
import {
  WebpSupport,
  webpSupport,
} from '@/components/api-features/webp-support';
import { intersectSupported } from '@/components/api-features/intersect-support';
import { isStandalone } from '@/components/api-features/is-standalone';

@Module({ stateFactory: true })
export default class App extends VuexModule {
  public FullVersion: string = fullVersion;
  public MainVersion: string = mainVersion;
  public WebpSupport: WebpSupport = webpSupport;
  public IntersectSupported: boolean = !!intersectSupported;
  public Standalone: boolean = isStandalone;

  @Mutation public updateIntersectSupported(supported: boolean) {
    this.IntersectSupported = supported;
  }

  @Mutation public updateWebSupport(webpsupport: WebpSupport) {
    this.WebpSupport = webpsupport;
  }
}

export const AppModule = namespace('app');
