import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export class BaseDialog extends Vue {
  @Prop() public value!: boolean;

  public get LocalValue(): boolean {
    return this.value;
  }

  public set LocalValue(v: boolean) {
    this.$emit('input', v);
  }

  public Close() {
    this.LocalValue = false;
  }
}
