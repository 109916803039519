import { RouteNames } from './route-consts';

export const routesWithoutHeaderAndFooter = [
  'sort',
  'filter',
  'filterMake',
  'filterModel',
  'filterYear',
  'filterPrice',
  'filterLocation',
  'filterSeats',
  'filterTransmission',
  'filterFuelType',
  'filterSafetyRating',
  'filterKilometres',
  'pickLocation',
  'details-calculator',
  'service-plan-overview',
  'gallery',
  'gallery-list',
  'check-payment',
  '360-image-viewer',
  '360-image-viewer-xtern',
  'redirect-to-simplr',
];

export const routesWithoutHeader: string[] = ['redirect-to-simplr'];

export const isLandingPage: string[] = [];

export const routesForSearchResults = [
  RouteNames.NoResultsRouteName,
  RouteNames.SearchRouteName,
];

export const routesWithoutFooter: string[] = [
  ...routesWithoutHeaderAndFooter,
  ...routesForSearchResults,
];

export const routesWithCTAFooterOverlayForTablet = ['details'];
