import { Dictionary } from '../../shared/global/types';

export function UpdateMapWithArray(
  value: any[],
  dictionary: Map<string, any>,
  key: (x: any) => string
) {
  value.forEach((element) => {
    dictionary.set(key(element), element);
  });
}

export function PrepareForUri(value: string): string {
  const cleanUri = value.replace(/[{}()]/g, '').replace(/\W+/g, '-');
  return encodeURI(cleanUri);
}

export function ReplaceMoustachesWithStrings(
  template: string,
  data: Dictionary<string>
): string {
  if (!data) return template;

  template = template?.replace(/\{\{([^}]+)\}\}/g, (match) => {
    match = match.slice(2, -2); // Remove the wrapping curly braces
    const val = data[match.trim()];
    if (!val) return '{{' + match + '}}';
    return val;
  });

  return template;
}
