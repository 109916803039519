import { Vue, Component } from 'vue-property-decorator';
import { Context } from '@nuxt/types';
import { PageMetaData } from '../../../../shared/global/types';
import { getPageMetaData, pageMetaDataApi } from '@/features/api';

@Component({
  async asyncData(ctx: Context) {
    const pageMetaData = await pageMetaDataApi.loadPageMetaDataWithFallback(
      ctx.route.path
    );
    return { pageMetaData };
  },
  head() {
    return (this as any).head;
  },
})
export class PagesWithMetaTagsFromTheServer extends Vue {
  public pageMetaData?: PageMetaData;

  public get head() {
    return getPageMetaData(this.pageMetaData, this.$route.path);
  }
}
