import { Component, Prop, Vue } from 'vue-property-decorator';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { FilterModule } from '@/store/filter';
import { RouteNames } from '@/router/route-consts';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';

@Component({
  name: 'popular-cars',
  data: () => ({
    popularCars: [
      {
        make: 'Toyota',
        model: 'Camry',
        image: '/img/home-page/Toyota-Camry.webp',
        SearchKey: {
          make: 'TOYOTA',
          model: 'CAMRY',
        },
      },
      {
        make: 'Toyota',
        model: 'Hilux',
        image: '/img/home-page/Toyota-Hilux.webp',
        SearchKey: {
          make: 'TOYOTA',
          model: 'HILUX',
        },
      },
      {
        make: 'Ford',
        model: 'Ranger',
        image: '/img/home-page/Ford-Ranger.webp',
        SearchKey: {
          make: 'FORD',
          model: 'RANGER',
        },
      },
      {
        make: 'Hyundai',
        model: 'i30',
        image: '/img/home-page/Hyundai-i30.webp',
        SearchKey: {
          make: 'HYUNDAI',
          model: 'i30',
        },
      },
      {
        make: 'Mazda',
        model: '3',
        image: '/img/landing/mazda-3.webp',
        SearchKey: {
          make: 'MAZDA',
          model: 'MAZDA3',
        },
      },
      {
        make: 'Toyota',
        model: 'Prado',
        image: '/img/home-page/prado.webp',
        SearchKey: {
          make: 'TOYOTA',
          model: 'LANDCRUISER PRADO',
        },
      },
    ],

    carList: [
      {
        cars: [
          {
            model: 'BMW 3',
            searchKey: {
              make: 'BMW',
              model: '3',
            },
          },
          {
            model: 'Holden Colorado',
            searchKey: {
              make: 'HOLDEN',
              model: 'COLORADO',
            },
          },
          {
            model: 'Holden Commodore',
            searchKey: {
              make: 'HOLDEN',
              model: 'COMMODORE',
            },
          },
          {
            model: 'Honda Civic',
            searchKey: {
              make: 'HONDA',
              model: 'CIVIC',
            },
          },
          {
            model: 'Honda CR-V',
            searchKey: {
              make: 'HONDA',
              model: 'CR-V',
            },
          },
          {
            model: 'Honda HR-V',
            searchKey: {
              make: 'HONDA',
              model: 'HR-V',
            },
          },
          {
            model: 'Hyundai Accent',
            searchKey: {
              make: 'HYUNDAI',
              model: 'ACCENT',
            },
          },
          {
            model: 'Hyundai Tucson',
            searchKey: {
              make: 'HYUNDAI',
              model: 'TUCSON',
            },
          },
        ],
      },
      {
        cars: [
          {
            model: 'Hyundai Kona',
            searchKey: {
              make: 'HYUNDAI',
              model: 'KONA',
            },
          },
          {
            model: 'Hyundai Santa Fe',
            searchKey: {
              make: 'HYUNDAI',
              model: 'SANTA FE',
            },
          },
          {
            model: 'Isuzu D-Max',
            searchKey: {
              make: 'ISUZU',
              model: 'D-MAX',
            },
          },
          {
            model: 'Kia Cerato',
            searchKey: {
              make: 'KIA',
              model: 'CERATO',
            },
          },
          {
            model: 'Kia Sportage',
            searchKey: {
              make: 'KIA',
              model: 'SPORTAGE',
            },
          },
          {
            model: 'Mazda BT-50',
            searchKey: {
              make: 'MAZDA',
              model: 'BT-50',
            },
          },
          {
            model: 'Mazda CX-3',
            searchKey: {
              make: 'MAZDA',
              model: 'CX-3',
            },
          },
          {
            model: 'Mazda CX-5',
            searchKey: {
              make: 'MAZDA',
              model: 'CX-5',
            },
          },
        ],
      },
      {
        cars: [
          {
            model: 'Mercedes C Class',
            searchKey: {
              make: 'MERCEDES-BENZ',
            },
          },
          {
            model: 'Mercedes E Class',
            searchKey: {
              make: 'MERCEDES-BENZ',
            },
          },
          {
            model: 'Mitsubishi ASX',
            searchKey: {
              make: 'MITSUBISHI',
              model: 'ASX',
            },
          },
          {
            model: 'Mitsubishi Outlander',
            searchKey: {
              make: 'MITSUBISHI',
              model: 'OUTLANDER',
            },
          },
          {
            model: 'Mitsubishi Triton',
            searchKey: {
              make: 'MITSUBISHI',
              model: 'TRITON',
            },
          },
          {
            model: 'Nissan Navara',
            searchKey: {
              make: 'NISSAN',
              model: 'NAVARA',
            },
          },
          {
            model: 'Nissan Qashqai',
            searchKey: {
              make: 'NISSAN',
              model: 'QASHQAI',
            },
          },
          {
            model: 'Nissan X-Trail',
            searchKey: {
              make: 'NISSAN',
              model: 'X-TRAIL',
            },
          },
        ],
      },
      {
        cars: [
          {
            model: 'Subaru Forester',
            searchKey: {
              make: 'SUBARU',
              model: 'FORESTER',
            },
          },
          {
            model: 'Subaru XV',
            searchKey: {
              make: 'SUBARU',
              model: 'XV',
            },
          },
          {
            model: 'Toyota Corolla',
            searchKey: {
              make: 'TOYOTA',
              model: 'COROLLA',
            },
          },
          {
            model: 'Toyota Kluger',
            searchKey: {
              make: 'TOYOTA',
              model: 'KLUGER',
            },
          },
          {
            model: 'Toyota Landcruiser',
            searchKey: {
              make: 'TOYOTA',
              model: 'LANDCRUISER',
            },
          },
          {
            model: 'Toyota Landcruiser 70 series',
            searchKey: {
              make: 'TOYOTA',
              model: 'LANDCRUISER 70 SERIES',
            },
          },
          {
            model: 'Toyota RAV4',
            searchKey: {
              make: 'TOYOTA',
              model: 'RAV4',
            },
          },
          {
            model: 'Volkswagen Golf',
            searchKey: {
              make: 'VOLKSWAGEN',
              model: 'GOLF',
            },
          },
        ],
      },
    ],
  }),

  components: { SectionWithContainer, Swiper, SwiperSlide },
})
export default class extends Vue {
  @Prop() public isForSubscription!: boolean;
  @Prop({ default: false }) public showSubscriptionCars!: boolean;
  @Prop({ default: 'Popular used cars for sale' }) public title!: string;

  @FilterModule.Mutation public ClearFilters!: () => void;
  @FilterModule.Mutation public ToggleFilter!: (key: string) => void;

  public get swiperOptions() {
    return {
      spaceBetween: 15,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      breakpoints: {
        640: {
          slidesPerView: 2.2,
          slidesOffsetAfter: 24,
          slidesOffsetBefore: 24,
        },
        320: {
          slidesPerView: 1.2,
          centeredSlides: true,
        },
      },
    };
  }

  public searchFor(key: string) {
    this.ClearFilters();
    this.ToggleFilter(`Model.${key}`);
    this.$router.push({ name: RouteNames.SearchRouteName });
  }

  public SearchFor(make: string, model: string) {
    this.ClearFilters();
    model
      ? this.ToggleFilter(`Model.${make}.${model}`)
      : this.ToggleFilter(`Make.${make}`);
    if (this.showSubscriptionCars) {
      this.ToggleFilter('Subscription.Subcription eligible only');
    }
    return this.$router.push({ name: RouteNames.SearchRouteName });
  }
}
