import { Component, Vue, Prop } from 'vue-property-decorator';
import { WorkProcess } from '../../../global/types';
import SectionWithContainer from '../section-with-container/SectionWithContainer';
import { FilterModule } from '@/store/filter';
import { RouteNames } from '@/router/route-consts';
@Component({
  name: 'section-with-description',
  components: {
    SectionWithContainer,
  },
})
export default class extends Vue {
  @Prop() public process!: WorkProcess[];
  @Prop() public title!: string;
  @Prop({ required: true }) public headerTag!: string;
  @Prop({ default: false }) public showShopNowBtn!: boolean;
  @Prop({ default: false }) public showSubscriptionBtn!: boolean;

  @FilterModule.Mutation public ClearFilters!: () => void;
  @FilterModule.Mutation public ToggleFilter!: (key: string) => void;

  public findSubscriptionCars() {
    this.ClearFilters();
    this.ToggleFilter('Subscription.Subcription eligible only');
    this.$router.push({ name: RouteNames.SearchRouteName });
  }
}
