import 'vuetify/src/components/VImg/VImg.sass';

import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { srcObject } from 'vuetify/lib/components/VImg' // eslint-disable-line
import { Intersect } from 'vuetify/lib/directives/intersect';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { VNode } from 'vue/types/umd';
import { consoleError } from 'vuetify/lib/util/console';

const blankImage = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
const imageResetDuration = 200;

@Component({
  directives: {
    Intersect,
  },
})
export class EaImg extends VResponsive {
  @Prop() public alt!: string;
  @Prop(Boolean) public contain!: boolean;
  @Prop(Boolean) public eager!: boolean;
  @Prop() public gradient!: string;
  @Prop() public lazySrc!: string;
  @Prop() public refId!: string;

  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  @Prop({
    default: () => ({
      root: undefined,
      rootMargin: undefined,
      threshold: undefined,
    }),
  })
  public options!: {
    root?: HTMLElement;
    rootMargin?: string;
    threshold?: number;
  };

  @Prop({ default: 'center center' }) public position!: string;

  @Prop() public sizes!: string;
  @Prop({ default: '' }) public src!: string | srcObject;
  @Prop({ default: '' }) public srcset!: string;
  @Prop({ default: 'fade-transition' }) public transition!: boolean | string;

  private isReset = false;
  private resetTimer = 0;
  private isLoading = true;
  private isReadyToLoad = false;
  private isLazyLoading = false;
  private calculatedAspectRatio: number = 0;
  private naturalWidth: number = 0;
  private isIntersecting: boolean = false;
  private retries: number = 1;

  public get computedAspectRatio(): number {
    return Number(this.normalisedSrc.aspect || this.calculatedAspectRatio);
  }

  public get hasIntersect() {
    if (process.client && window && window.IntersectionObserver) {
      return true;
    }
    return false;
  }

  public get normalisedSrc(): srcObject {
    return typeof this.src === 'string'
      ? {
          src: this.src,
          srcset: this.srcset,
          lazySrc: this.lazySrc,
          aspect: Number(this.aspectRatio),
        }
      : {
          src: this.src.src,
          srcset: this.srcset || this.src.srcset,
          lazySrc: this.lazySrc || this.src.lazySrc,
          aspect: Number(this.aspectRatio || this.src.aspect),
        };
  }

  @Watch('lazySrc') public lazySrcChanged(newSrc: string, oldSrc: string) {
    if (newSrc === oldSrc) {
      return;
    }
    if (newSrc) {
      this.isReset = true;
      if (this.$refs.cachedLazyImage) {
        (this.$refs.cachedLazyImage as HTMLImageElement).src = blankImage;
      }
      this.isLazyLoading = true;
      this.isReadyToLoad = false;
      this.isLoading = true;

      if (this.eager) {
        this.isReset = false;
        return;
      }

      clearTimeout(this.resetTimer);
      this.resetTimer = <any>(
        setTimeout(this.SetReadyToLoad, imageResetDuration)
      );
    } else {
      this.isLazyLoading = false;
    }
  }

  @Watch('src') public srcChanged(newSrc: string, oldSrc: string) {
    if (newSrc === oldSrc) {
      return;
    }
    if (newSrc) {
      this.isReset = true;
      if (this.$refs.cachedImage) {
        (this.$refs.cachedImage as HTMLImageElement).src = blankImage;
      }
      this.isLoading = true;

      clearTimeout(this.resetTimer);
      this.resetTimer = <any>(
        setTimeout(this.SetReadyToLoad, imageResetDuration)
      );
    }
  }

  public created() {
    if (this.normalisedSrc.lazySrc) {
      this.isLazyLoading = true;
    }

    if (!this.hasIntersect) {
      this.isIntersecting = true;
      this.isReadyToLoad = true;
    } else if (!this.isLazyLoading && this.eager) {
      this.isReadyToLoad = true;
    }
  }

  public render(h: (tag: string, data: object, children: any) => VNode): VNode {
    const node = VResponsive.options.render.call(this, h);

    node.data!.staticClass += ' v-image ea-image';

    if (this.hasIntersect) {
      node.data!.directives = [
        {
          name: 'intersect',
          options: this.options,
          value: this.init,
        } as any,
      ];
    } else {
      node.data!.directives = [];
    }

    node.data!.attrs = {
      role: this.alt ? 'img' : undefined,
      'aria-label': this.alt,
    };

    node.children = [
      this.__cachedSizer,
      this.__imageHolder,
      this.__genPlaceholder(),
      this.genContent(),
    ] as VNode[];

    return h(node.tag, node.data, node.children);
  }

  public get __imageHolder(): VNode | (VNode | [])[] {
    // if (!this.transition) {
    return [this.__cachedLazyImage, this.__cachedImage, this.__gradient];
    // }

    // return this.$createElement(
    //   'transition',
    //   {
    //     attrs: {
    //       name: this.transition,
    //       mode: 'in-out',
    //     },
    //   },
    //   [this.__cachedLazyImage, this.__cachedImage, this.__gradient]
    // );
  }

  public get __cachedLazyImage(): VNode | [] {
    if (
      !this.normalisedSrc.lazySrc ||
      this.isReset ||
      (!this.isReadyToLoad && !this.eager)
    ) {
      return [];
    }

    const image = this.$createElement('img', {
      attrs: {
        src: this.normalisedSrc.lazySrc,
        alt: this.alt,
      },
      on: {
        load: this.onLazyLoad,
        error: this.onLazyLoadError,
      },
      staticClass: 'v-image__image',
      class: {
        'v-image__image--preload': true,
      },
      style: {
        'object-fit': this.contain ? 'contain' : 'cover',
      },
      ref: 'cachedLazyImage',
    });

    return image;
  }

  public get __cachedImage(): VNode | [] {
    if (!this.normalisedSrc.src || this.isLazyLoading || !this.isReadyToLoad) {
      return [];
    }

    const image = this.$createElement('img', {
      attrs: {
        src:
          !this.normalisedSrc.src || this.isLazyLoading || !this.isReadyToLoad
            ? blankImage
            : this.normalisedSrc.src,
        alt: this.alt,
      },
      on: {
        load: this.onLoad,
        error: this.onLoadError,
      },
      style: {
        'object-fit': this.contain ? 'contain' : 'cover',
      },
      staticClass: 'v-image__image',
      ref: 'cachedImage',
    });

    return image;
  }

  public get __gradient(): VNode | [] {
    if (!this.gradient) {
      return [];
    }
    const image = this.$createElement('div', {
      staticClass: 'v-image__image ea-image-gradient',
      style: {
        backgroundImage: `linear-gradient(${this.gradient})`,
        backgroundPosition: this.position,
      },
    });
    return image;
  }

  private init(
    _entries?: IntersectionObserverEntry[],
    _observer?: IntersectionObserver,
    isIntersecting?: boolean
  ) {
    this.isIntersecting = isIntersecting !== false;
    if (!this.isReset && this.isIntersecting) {
      this.isReadyToLoad = true;
    }
  }

  private calculateAspectRatio(img: HTMLImageElement) {
    const { naturalHeight, naturalWidth } = img;

    if (naturalHeight && naturalWidth) {
      this.naturalWidth = naturalWidth;
      this.calculatedAspectRatio = naturalWidth / naturalHeight;
    }
  }

  private onLazyLoad(ev: Event) {
    const image = ev.currentTarget as HTMLImageElement;
    if (!image || !image.src.endsWith(this.normalisedSrc.lazySrc)) {
      return;
    }
    this.calculateAspectRatio(image);
    this.isLazyLoading = false;
  }

  private onLazyLoadError() {
    this.isLazyLoading = false;
  }

  private onLoad(ev: Event) {
    const image = ev.currentTarget as HTMLImageElement;
    if (!image || !image.src.endsWith(this.normalisedSrc.src)) {
      return;
    }
    this.calculateAspectRatio(image);
    this.isLazyLoading = false;
    this.isLoading = false;
  }

  private onLoadError(event: Event) {
    consoleError(
      `Image load failed\n\n` + `src: ${this.normalisedSrc.src}`,
      this
    );
    if (this.retries > 0) {
      this.retries--;
      const image = event.target as HTMLImageElement;
      if (image?.src) {
        // TODO: is this intentional?
        image.src = image.src // eslint-disable-line
        return;
      }
    }
    this.isLazyLoading = false;
    this.isLoading = false;
  }

  private genContent() {
    const content: VNode = VResponsive.options.methods.genContent.call(this);
    if (this.naturalWidth) {
      const style: any = {
        width: `${this.naturalWidth}px`,
      };
      this._b(content.data!, 'div', {
        style,
      });
    }

    return content;
  }

  private __genPlaceholder(): VNode | void {
    if (this.$slots.placeholder) {
      const showPlaceholder =
        this.isLoading && (!this.hasIntersect || this.isIntersecting);
      const placeholder = showPlaceholder
        ? [
            this.$createElement(
              'div',
              {
                staticClass: 'v-image__placeholder',
              },
              this.$slots.placeholder
            ),
          ]
        : [];

      if (!this.transition) {
        return placeholder[0];
      }

      return this.$createElement(
        'transition',
        {
          props: {
            appear: true,
            name: this.transition,
          },
        },
        placeholder
      );
    }
  }

  private SetReadyToLoad() {
    this.isReset = false;
    if (this.isIntersecting) {
      this.isReadyToLoad = true;
    }
  }
}
