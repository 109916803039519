import Vue from 'vue';
import Router from 'vue-router';
import { Store } from 'vuex';
import { routerHelper } from '../helpers/router-helper';
import { buyRoutes } from './buy';
import { filterRoutes } from './filter';
import { financeRoutes } from './finance';
import { enquiryRoutes } from './enquiries';
import { serviceRoutes } from './service';
import { sellRoutes } from './sell';
import { otherRoutes } from './other';
import { redirects } from './redirects';
import { landingRoutes } from './landing-pages';
import { sessionWrapper } from '@/tools/session';
Vue.use(Router);

// eslint-disable-next-line import/no-mutable-exports
let router: Router = {} as Router;

let vuexStore: Store<any>;

export function SetStore(store: Store<any>) {
  vuexStore = store;
}

function CreateRouter() {
  const pageNotFound = '/404-error';

  try {
    router = new Router({
      mode: 'history',
      scrollBehavior(to, _, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else if (to.hash) {
          return { selector: to.hash };
        } else {
          return { x: 0, y: 0 };
        }
      },
      routes: [
        {
          path: '/',
          name: 'home',
          component: () =>
            import('@/features/home-page/Home').then((m) => m.default || m),
        },
        ...filterRoutes,
        ...sellRoutes,
        ...financeRoutes,
        ...serviceRoutes,
        ...enquiryRoutes,
        ...otherRoutes,
        ...buyRoutes,
        ...redirects,
        ...landingRoutes,
        {
          path: pageNotFound,
          component: () =>
            import('@/features/404/404.vue').then((m) => m.default || m),
          name: 'page-not-found',
        },
        { path: '**', redirect: pageNotFound },
      ],
    });
  } catch (error) {
    window.location.href = pageNotFound;
  }

  router.beforeEach((to, from, next) => {
    if (
      enquiryRoutes.some((x) => x.name === to.name) &&
      !enquiryRoutes.some((x) => x.name === from.name)
    ) {
      if (vuexStore) {
        vuexStore.commit('enquiries/Init');
      }
    }
    next();
  });

  // NOTE: This can be handled by Nuxt Head Section for individual pages
  // NOTE: Probably most of SEO related code plumbing can go

  // let updateSeoHeadersTimer = 0;

  // function updateSeoHeaders(currentHref: string, countdown: number) {
  //   if (countdown-- < 0) {
  //     ApplySeoHeaders();
  //     return;
  //   }

  //   if (currentHref === location.href) {
  //     updateSeoHeadersTimer = setTimeout(() => updateSeoHeaders(currentHref, countdown), 100);
  //   } else {
  //     ApplySeoHeaders();
  //   }
  // }

  router.afterEach((_, from) => {
    if (!from.name) {
      return;
    }

    const temp = routerHelper;
    const newRouteValue = {
      PreviousRoute: { name: from.name, params: from.params },
      PreviousTitle: temp.CurrentTitle,
    };

    temp.PreviousRoute = newRouteValue.PreviousRoute;
    temp.PreviousTitle = newRouteValue.PreviousTitle;

    delete temp.CurrentTitle;

    sessionWrapper.Write('routerHelper', newRouteValue);

    // const currentHref = location.href

    // clearTimeout(updateSeoHeadersTimer);
    // if (to.path === location.pathname) {
    //   ApplySeoHeaders();
    // } else {
    //   updateSeoHeadersTimer = setTimeout(() => updateSeoHeaders(currentHref, 10), 100);
    // }
  });
}

// This is needed for @nuxt/router module to work.
export function createRouter() {
  return router;
}

CreateRouter();

routerHelper.$router = router;

export default router;
