import { Component, Vue } from 'vue-property-decorator';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
@Component({
  name: 'allianz-insurance',
  components: {
    SectionWithContainer,
  },
  data: () => ({
    list: [
      'You may be able to reduce your premium by increasing your basic excess<sup>1</sup>',
      'Choose your own repairer<sup>3</sup>',
      '24/7 online or phone claims lodgement process',
      'Rental car for up to 30 days<sup>4</sup> if your car is stolen ($100 per day limit)',
      'Safe driver discount for eligible drivers<sup>1,2</sup>',
    ],
  }),
})
export default class AllianzInsurance extends Vue {}
