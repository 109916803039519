import Vue from 'vue';
import { DirectiveOptions } from 'vue/types/umd';

function ScrollIntoView(el: HTMLElement) {
  Vue.nextTick(() => {
    el.scrollIntoView({ block: 'center' });
  });
}

export const ScrollInView: DirectiveOptions = {
  update: (el, binding) => {
    if (binding.value) {
      Vue.nextTick(() => ScrollIntoView(el));
    }
  },
  inserted: (el, binding) => {
    if (binding.value) {
      Vue.nextTick(() => ScrollIntoView(el));
    }
  },
};

Vue.directive('scroll-in-view', ScrollInView);
