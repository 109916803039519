import { Component, Vue, Prop } from 'vue-property-decorator';
import { FAQQuestion } from '../../../../../shared/global/faq';
import FAQAnswerItem from './faq-answer-item';

@Component({
  components: {
    FAQAnswerItem,
  },
})
export default class FAQItem extends Vue {
  @Prop() public Question!: FAQQuestion;
}
