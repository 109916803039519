import { RouteConfig } from 'vue-router/types';
import { RouteNames } from './route-consts';

const buyRedirects: RouteConfig[] = [
  {
    path: '/buy/used-cars/details/share-this-car/:VehicleId',
    name: 'social-share-redirect',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    redirect: {
      name: 'details',
    },
  },
  {
    path: '/buy/details', // https://easyauto123.com.au/buy/details?Year=2005&Make=Suzuki&Model=Liana&VehicleId=JSAERC51S00300898
    name: 'buy-details-redirect',
    redirect: (to) => ({
      name: 'details',
      params: {
        Year: to.query.Year,
        Make: to.query.Make,
        Model: to.query.Model,
        VehicleId: to.query.VehicleId,
        Variant: 'Variant',
        State: 'State',
        Region: 'Region',
        Suburb: 'Suburb',
        Transmission: 'Transmission',
        PaintColour: 'PaintColour',
        BodyType: 'BodyType',
      },
    }),
  },
  {
    path: '/buy-car/:Make/:Model/:Variant/:StockNumber/', // https://easyauto123.com.au/buy-car/nissan/navara/st/h3663/
    name: 'redirect-inventory-1',
    props: (route) => ({ StockNumber: route.params.StockNumber }),
    redirect: {
      name: 'details',
    },
  },
  {
    path: '/buy/details/car-report',
    name: 'redirect-car-report-1',
    redirect: (to) => ({
      name: 'used-car-report',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
  {
    path: '/buy/vehicle-list',
    name: 'redirect-search',
    redirect: {
      name: RouteNames.SearchRouteName,
    },
  },
  {
    path: '/buy/details/sold',
    name: 'sold-details-redirect',
    redirect: (to) => ({
      name: 'sold-details',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
  {
    path: '/buy/details/image-gallery',
    name: 'gallery-redirect',
    redirect: (to) => ({
      name: 'gallery',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
  {
    path: '/buy/details/image-list',
    name: 'gallery-list-redirect',
    redirect: (to) => ({
      name: 'gallery-list',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
  {
    path: '/buy/vehicle-list/details/360-image-viewer',
    name: '360-image-viewer-redirect',
    redirect: (to) => ({
      name: '360-image-viewer',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
  {
    path: '/buy/details/protection-plan-cover-more-info',
    name: 'protection-plan-redirect',
    redirect: {
      name: 'protection-plan',
    },
  },
  {
    path: '/buy/details/protection-plan-cover',
    name: 'protection-plan-overview-redirect',
    redirect: {
      name: 'protection-plan-overview',
    },
  },
  {
    path: '/buy/details/reserve/thank-you',
    name: 'reserve-thank-you-redirect',
    redirect: (to) => ({
      name: 'reserve-thank-you',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
  {
    path: '/buy/details/reserve/check-payment',
    name: 'check-payment-redirect',
    redirect: {
      name: 'check-payment',
    },
  },
  {
    path: '/buy/details/reserve/payment-failed',
    name: 'payment-failed-redirect',
    redirect: (to) => ({
      name: 'payment-failed',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
  {
    path: '/buy/details/reserve',
    name: 'reserve-redirect',
    redirect: (to) => ({
      name: 'reserve',
      params: { VehicleId: to.query.VehicleId },
    }),
  },
];

export const filterRedirects: RouteConfig[] = [
  {
    path: '/buy/filter/kilometres',
    name: 'filterKilometres-redirect',
    redirect: {
      name: 'filterKilometres',
    },
  },

  {
    path: '/buy/filter/safety-rating',
    name: 'filterSafetyRating-redirect',
    redirect: {
      name: 'filterSafetyRating',
    },
  },
  {
    path: '/buy/filter/fuel-type',
    name: 'filterFuelType-redirect',
    redirect: {
      name: 'filterFuelType',
    },
  },
  {
    path: '/buy/filter/transmission',
    name: 'filterTransmission-redirect',
    redirect: {
      name: 'filterTransmission',
    },
  },
  {
    path: '/buy/filter/seats',
    name: 'filterSeats-redirect',
    redirect: {
      name: 'filterSeats',
    },
  },
  {
    path: '/buy/filter/make-model',
    name: 'filterMake-redirect',
    redirect: {
      name: 'filterMake',
    },
  },
  {
    path: '/buy/filter/year',
    name: 'filterYear-redirect',
    redirect: {
      name: 'filterYear',
    },
  },
  {
    path: '/buy/filter/price',
    name: 'filterPrice-redirect',
    redirect: {
      name: 'filterPrice',
    },
  },
  {
    path: '/buy/filter/location',
    name: 'filterLocation-redirect',
    redirect: {
      name: 'filterLocation',
    },
  },
  {
    path: '/buy/filter',
    name: 'filter-redirect',
    redirect: {
      name: 'filter',
    },
  },

  // Vehicle-list filter redirects
  {
    path: '/buy/vehicle-list/filter/kilometres',
    name: 'filterKilometres-vehiclelist-redirect',
    redirect: {
      name: 'filterKilometres',
    },
  },
  {
    path: '/buy/vehicle-list/filter/safety-rating',
    name: 'filterSafetyRating-vehiclelist-redirect',
    redirect: {
      name: 'filterSafetyRating',
    },
  },
  {
    path: '/buy/vehicle-list/filter/fuel-type',
    name: 'filterFuelType-vehiclelist-redirect',
    redirect: {
      name: 'filterFuelType',
    },
  },
  {
    path: '/buy/vehicle-list/filter/transmission',
    name: 'filterTransmission-vehiclelist-redirect',
    redirect: {
      name: 'filterTransmission',
    },
  },
  {
    path: '/buy/vehicle-list/filter/seats',
    name: 'filterSeats-vehiclelist-redirect',
    redirect: {
      name: 'filterSeats',
    },
  },
  {
    path: '/buy/vehicle-list/filter/make-model',
    name: 'filterMake-vehiclelist-redirect',
    redirect: {
      name: 'filterMake',
    },
  },
  {
    path: '/buy/vehicle-list/filter/year',
    name: 'filterYear-vehiclelist-redirect',
    redirect: {
      name: 'filterYear',
    },
  },
  {
    path: '/buy/vehicle-list/filter/price',
    name: 'filterPrice-vehiclelist-redirect',
    redirect: {
      name: 'filterPrice',
    },
  },
  {
    path: '/buy/vehicle-list/filter/location',
    name: 'filterLocation-vehiclelist-redirect',
    redirect: {
      name: 'filterLocation',
    },
  },
  {
    path: '/buy/vehicle-list/filter',
    name: 'filter-vehiclelist-redirect',
    redirect: {
      name: 'filter',
    },
  },
];

const externalRedirects: RouteConfig[] = [
  {
    path: '/used',
    redirect: {
      name: RouteNames.SearchRouteName,
    },
  },
  {
    path: '/stock-specials',
    redirect: {
      name: RouteNames.SearchRouteName,
    },
  },
  {
    path: '/finance',
    redirect: {
      name: RouteNames.FinanceRouteName,
    },
  },
  {
    path: '/selling',
    redirect: {
      name: RouteNames.SellRouteName,
    },
  },
  {
    path: '/servicing',
    redirect: {
      name: RouteNames.ServiceRouteName,
    },
  },
  {
    path: '/roadside-assist',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/contact-us',
    redirect: {
      name: RouteNames.LocationsRouteName,
    },
  },
  {
    path: '/contact',
    redirect: {
      name: RouteNames.LocationsRouteName,
    },
  },
  {
    path: '/about-us',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/used-vehicles',
    redirect: {
      name: RouteNames.SearchRouteName,
    },
  },
  {
    path: '/how-it-works',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/reviews',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/events/black-friday-2021',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/events/birthday-event-2021',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/black-friday-2021',
    redirect: {
      name: 'home',
    },
  },
];

export const redirects: RouteConfig[] = [
  ...buyRedirects,
  ...filterRedirects,
  ...externalRedirects,
];
