import { RouteConfig } from 'vue-router/types';
import { RouteNames } from './route-consts';

// There is an issue in lazy route resolver in @nuxtjs/router Module
// Ref: https://github.com/nuxt-community/router-module/issues/82

export const buyRoutes: RouteConfig[] = [
  {
    path: '/buy/used-cars/:Filter?',
    name: RouteNames.SearchRouteName,
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '@/features/buy/search/BuyCarSearch'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/:city?/:state?',
    name: RouteNames.SearchWithLocationName,
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '@/features/buy/search/BuyCarSearch'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/compare',
    name: 'compare',
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '@/features/buy/compare/Compare.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/favourites',
    name: RouteNames.FavouritesRouteName,
    component: () =>
      import(
        /* webpackChunkName: "buy" */ '@/features/buy/favourites/FavouritesList'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/details/:Year/:Make/:Model/:Variant/:State/:Region/:Suburb/:Transmission/:PaintColour/:BodyType/:VehicleId',
    name: 'details',
    props: (route) => ({
      Year: route.params.Year,
      Make: route.params.Make,
      Model: route.params.Model,
      Variant: route.params.Variant,
      State: route.params.State,
      Region: route.params.Region,
      Suburb: route.params.Suburb,
      Transmission: route.params.Transmission,
      PaintColour: route.params.PaintColour,
      BodyType: route.params.BodyType,
      VehicleId: route.params.VehicleId,
    }),
    component: () =>
      import(
        /* webpackChunkName: "vehicleDetail" */ '@/features/buy/vehicle-details/VehicleDetails'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy-car/search/:StockNumber', // http://easyauto123.com.au/buy-car/search/S10624/
    name: 'email-redirect-inventory',
    props: (route) => ({ StockNumber: route.params.StockNumber }),
    component: () =>
      import('@/features/buy/vehicle-details/RedirectToDetails.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/sold/:VehicleId',
    name: 'sold-details',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import(
        /* webpackChunkName: "vehicleDetail" */ '@/features/buy/vehicle-details/VehicleDetails.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/details/protection-plan/cover',
    name: 'protection-plan-overview',
    component: () =>
      import(
        /* webpackChunkName: "vehicleDetail" */ '@/features/buy/vehicle-details/plans/protection-plan-dialog/ProtectionPlanDialog'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/details/protection-plan/cover/more-info',
    name: 'protection-plan',
    component: () =>
      import(
        /* webpackChunkName: "vehicleDetail" */ '@/features/buy/vehicle-details/plans/protection-plan/ProtectionPlan'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/details/protection-plan/cover/budget',
    name: 'protection-plan-overview-budget',
    props: () => ({
      Budget: 'budget',
    }),
    component: () =>
      import(
        /* webpackChunkName: "vehicleDetail" */ '@/features/buy/vehicle-details/plans/protection-plan-dialog/ProtectionPlanDialog'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/details/used-car-report/:VehicleId',
    name: 'used-car-report',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import(
        /* webpackChunkName: "vehicleDetail" */ '@/features/buy/used-car-report/CarReport'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/used-cars/details/reserve/thank-you/:VehicleId',
    name: 'reserve-thank-you',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('@/features/buy/reserve/ReserveThankYou').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/reserve/check-payment',
    name: 'check-payment',
    component: () =>
      import('@/features/buy/reserve/CheckPayment.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/reserve/payment-failed/:VehicleId',
    name: 'payment-failed',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('@/features/buy/reserve/PaymentFailed.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/reserve/:VehicleId',
    name: 'reserve',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('@/features/buy/reserve/ReserveThisCar').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/reserve-cars-online',
    name: 'reserve-online',
    component: () =>
      import('@/features/reserve-online/ReserveOnline.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/unavailable',
    name: 'unavailable-vehicle',
    component: () =>
      import(
        '@/features/buy/vehicle-details/unavailable-vehicle/UnavailableVehicle.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/sku/:StockNumber',
    name: 'short-vdp-link',
    props: (route) => ({ StockNumber: route.params.StockNumber }),
    component: () =>
      import('@/features/buy/vehicle-details/RedirectToDetails.vue').then(
        (m) => m.default || m
      ),
  },
];
