import { Location, Route } from 'vue-router/types';
import { Dictionary } from '../../../../../shared/global/types';
import { Inventory, StatusBanner } from '../../../../../shared/inventory';
import {
  GetDisclaimerText,
  GetDisclaimerLabel,
  GetDisclaimerTitle,
} from '../../../../../shared/finance';
import { LocationDetails } from '../../../../../shared/inventory/search';
import { PrepareForUri } from '../../../../../shared/global/helpers';
import { IsSold } from './common';
import WebpSupport from '@/components/api-features/webp-support';
import { locationDetails } from '@/features/location/types';

export const SmallImageWidth = 'w21';
export const MediumImageWidth = 'w320';
export const LargeImageWidth = 'w600';
export const FullImage = 's0';
export const SliderImageWidth = 'w480';

export function validateVDPRoute(inventory: Inventory, route: Route): boolean {
  const routeParams = GetVDPRoute(inventory) as Location;

  const { VehicleId, Year, Make, Model, Variant } = route.params;

  return (
    Make?.toLowerCase() === routeParams.params?.Make?.toLowerCase() &&
    Model?.toLowerCase() === routeParams.params?.Model?.toLowerCase() &&
    VehicleId === routeParams.params?.VehicleId &&
    Year?.toLowerCase() === routeParams.params?.Year?.toLowerCase() &&
    Variant?.toLowerCase() === routeParams.params?.Variant?.toLowerCase()
  );
}

export function GetVDPRoute(
  value?: Inventory,
  vehicleLocation?: LocationDetails
): Location | '' {
  const vehicleDetails = value?.GlassVehicle?.VehicleDetails;
  if (!value || !vehicleDetails) {
    return '';
  }

  if (
    value._id &&
    vehicleDetails.Make &&
    vehicleDetails.Model &&
    vehicleDetails.ReleaseYear
  ) {
    return {
      name: IsSold(value) ? 'sold-details' : 'details',
      params: {
        Year: `${vehicleDetails.ReleaseYear}`,
        Make: vehicleDetails.Make,
        Model: PrepareForUri(GetModel(value) || 'Model'),
        Variant: PrepareForUri(GetVariant(value) || 'Variant'),
        State: PrepareForUri(
          value.Location?.Address?.State ||
          vehicleLocation?.Address?.State ||
          'State'
        ),
        Region: PrepareForUri(
          value.Location?.Address?.City ||
          vehicleLocation?.Address?.City ||
          'Region'
        ),
        Suburb: PrepareForUri(
          value.Location?.Address?.Suburb ||
          vehicleLocation?.Address?.Suburb ||
          'Suburb'
        ),
        Transmission: PrepareForUri(
          GetTransmissionSimple(value) || 'Transmission'
        ),
        PaintColour: PrepareForUri(GetPaintColour(value) || 'Colour'),
        BodyType: PrepareForUri(GetBodyTypeForUrl(value) || 'BodyType'),
        VehicleId: value._id,
      },
    };
  }

  return '';
}

export function GetLocationCode(value?: Inventory): string {
  return value?.Search?.LocationCode || '';
}

export function GetFinalPrice(value?: Inventory): number {
  if (value?.Price?.FinalDisplayPrice) {
    return Number.parseFloat(
      value.Price.FinalDisplayPrice.$numberDecimal || '0'
    );
  }
  return 0;
}

export function GetPriceValue(value?: Inventory): number | undefined {
  const price = value?.Price?.FinalDisplayPrice?.$numberDecimal;
  if (price) {
    return Number.parseFloat(price);
  }
}

export function GetPrice(value?: Inventory): string {
  return value?.Price?.FinalDisplayPrice?.$numberDecimal?.toMoney() || '-';
}

export function GetPriceDisclaimerShort(value?: Inventory): string {
  return value?.Price?.Disclaimer || '';
}

export function GetPriceDisclaimer(value?: Inventory): string {
  return GetDisclaimerLabel(value?.Price?.Disclaimer, value?.Location);
}

export function GetPriceDisclaimerTitle(value?: Inventory): string {
  if (value?.Price?.Disclaimer) {
    return GetDisclaimerTitle(value.Price.Disclaimer);
  }
  return '';
}

export function GetPriceDisclaimerDetails(value?: Inventory): string {
  if (value?.Price?.Disclaimer) {
    const locationCode = value.Search?.LocationCode;
    const location =
      (locationDetails.Locations || []).find((x) => x.Key === locationCode) ||
      ({} as LocationDetails);
    const state = location.Address?.State || '';
    return GetDisclaimerText(value.Price.Disclaimer, state);
  }
  return '';
}

export function GetId(value?: Inventory): string {
  return value?._id || '';
}

export function GetStockNumber(value?: Inventory): string {
  return value?.Particulars?.StockNumber || '';
}

export function GetTitle(value?: Inventory): string {
  const details = value?.GlassVehicle?.VehicleDetails;
  return `${details?.Model || ''} ${details?.Variant || ''}`.trim();
}

export function GetMake(value?: Inventory): string {
  return value?.GlassVehicle?.VehicleDetails?.Make || '';
}

export function GetModel(value?: Inventory): string {
  return value?.GlassVehicle?.VehicleDetails?.Model || '';
}

export function GetVariant(value?: Inventory): string {
  return value?.GlassVehicle?.VehicleDetails?.Variant || '';
}

export function GetSeries(value?: Inventory): string {
  return value?.GlassVehicle?.VehicleDetails?.Series || '';
}

export function GetBodyType(value?: Inventory): string {
  return value?.GlassVehicle?.VehicleDetails?.BodyStyle || '';
}

export function GetBodyTypeForUrl(value?: Inventory): string {
  return value?.Search.VehicleType.join(' ') || '';
}

export function GetRegistration(value?: Inventory): string {
  return value?.Vehicle?.Registration?.Number || '';
}

export function GetAncapRating(value?: Inventory): number | undefined {
  const price = value?.GlassVehicle?.GreenDetails?.AncapRating;
  if (price) {
    return Number.parseFloat(price);
  }
}

export function GetOdometer(value?: Inventory): string {
  return `${value?.Vehicle?.Odometer || ''}`;
}

export function GetEngineSize(value?: Inventory): string {
  const engineSize = value?.GlassVehicle?.VehicleDetails?.EngineCapacityLitres;
  return engineSize && engineSize !== '0' ? engineSize : '';
}

export function GetEngineCapacity(value?: Inventory): string {
  const engineSize = value?.GlassVehicle?.VehicleDetails?.EngineCapacityCCM;
  return engineSize && engineSize !== '0' ? `${engineSize}cc` : '';
}

export function GetTransmissionSimple(value?: Inventory): string {
  return value?.Search?.Transmission?.[0] || '';
}

export function GetCylinders(
  lookup: Dictionary<string>,
  value?: Inventory
): string {
  const cylinders = value?.GlassVehicle?.VehicleDetails?.CylinderConfiguration;
  if (cylinders) {
    return lookup[cylinders] || '';
  }
  return '';
}

export function GetTransmission(
  lookup: Dictionary<string>,
  value?: Inventory
): string {
  const transmission =
    value?.GlassVehicle?.VehicleDetails?.TransmissionTypeCode;
  if (transmission) {
    return lookup[transmission] || '';
  }
  return '';
}

export function GetPower(value?: Inventory): string {
  let result =
    value?.GlassVehicle?.VehicleSpecifications?.DriveSpecifications?.KiloWatts;
  if (result) {
    result = `${result}KW`;
  }
  const rpm =
    value?.GlassVehicle?.VehicleSpecifications?.EngineSpecifications
      ?.MaxPowerRPM;
  if (rpm) {
    result = `${result} @ ${rpm}RPM`;
  }
  return result || '';
}

export function GetTorque(value?: Inventory): string {
  let result =
    value?.GlassVehicle?.VehicleSpecifications?.DriveSpecifications?.Torque;
  if (result) {
    result = `${result}Nm`;
  }
  const rpm =
    value?.GlassVehicle?.VehicleSpecifications?.EngineSpecifications
      ?.MaxTorqueRPM;
  if (rpm) {
    result = `${result} @ ${rpm}RPM`;
  }
  return result || '';
}

export function GetDriveTrain(value?: Inventory): string {
  const driveTrain =
    value?.GlassVehicle?.VehicleSpecifications?.DriveSpecifications
      ?.DrivenWheels;
  return driveTrain && driveTrain !== '0' ? driveTrain : '';
}

export function GetPaintColour(value?: Inventory): string {
  return value?.Search?.PaintColour || '';
}

export function GetNumberOfSeats(value?: Inventory): string {
  const seats = `${value?.GlassVehicle?.VehicleSpecifications?.DriveSpecifications?.NumSeats ||
    ''
    }`;
  return seats && seats !== '0' ? seats : '';
}

export function GetUrbanFuelConsumption(value?: Inventory): string {
  const numberDecimal =
    value?.GlassVehicle?.GreenDetails?.ConsumptionCity?.$numberDecimal;
  return numberDecimal && numberDecimal !== '0'
    ? `${numberDecimal} l/100km`
    : '';
}

export function GetCo2Emissions(value?: Inventory): string {
  const emissions = value?.GlassVehicle?.GreenDetails?.CO2Emission;
  return emissions && emissions !== '0' ? `${emissions} g/km` : '';
}

export function GetServiceInterval(value?: Inventory): string {
  let result =
    value?.GlassVehicle?.VehicleSpecifications?.Suspension?.ServiceMonths;
  if (result) {
    result = `Every ${result} months`;
  }
  const kms =
    value?.GlassVehicle?.VehicleSpecifications?.Suspension?.ServiceKMs;
  if (kms) {
    result = `${result}, or every ${kms},000 kms`;
  }
  return result || '';
}

export function GetTowingCapacityBraked(value?: Inventory): string {
  const towing =
    value?.GlassVehicle?.VehicleSpecifications?.EngineSpecifications?.TowingCapacityBraked?.toMoney();
  return towing && towing !== '0' ? `${towing} kg` : '';
}

export function GetYear(value?: Inventory): string {
  return `${value?.GlassVehicle?.VehicleDetails?.ReleaseYear || ''}`;
}

export function GetCountryOfOrigin(
  lookup: Dictionary<string>,
  value?: Inventory
): string {
  const countryCode =
    value?.GlassVehicle?.VehicleSpecifications?.WarrantySpecifications
      ?.CountryOfOrigin;
  if (countryCode) {
    return lookup[countryCode] || '';
  }
  return '';
}

export function Has360Images(inventory?: Inventory): boolean {
  return (
    Has360Internal(inventory) ||
    Has360External(inventory)
  );
}

export function Has360Internal(inventory?: Inventory): boolean {
  return (inventory?.Particulars?.HideInternal360Image !== true)
    && !!inventory?.Particulars?.Image360Url?.Url;
}

export function Has360External(inventory?: Inventory): boolean {
  return (inventory?.Particulars?.HideExternal360Image !== true) &&
    !!inventory?.Particulars?.ExternalImages360?.[1];
}

export function ComposeImageFromUrl(url: string, imageSize: string): string {
  let suffix = imageSize || '';
  suffix += WebpSupport.webp ? '-rw' : '';

  if (url) {
    return `https://lh3.googleusercontent.com/${url}=${suffix}`;
  }

  return `https://lh3.googleusercontent.com/a6-bB-ytcvr_dquNf9DQbDHbYyOqcMi-RlGfbV2Jf5-Bgk_uesmDfpIRi0T202aUjrfCtr3k_MQzVJ8IudTOQEWIMxbDx86S70x94t5Zf5Vi=${suffix}`;
}

export function GetImage(
  value: Inventory | undefined,
  index: number,
  imageSize: string
): string {
  const image = value?.Particulars?.ImageUrls?.[index]?.Url || '';
  return ComposeImageFromUrl(image, imageSize);
}

export function VDPShareTitle(value?: Inventory): string {
  return `Hey, check out this ${GetYear(value)} ${GetMake(value)} ${GetModel(
    value
  )} from easyauto123`;
}

export function GetBannerDescription(
  inventory: Inventory | undefined
): StatusBanner {
  const banner = inventory?.Particulars?.Banner;

  if (banner === 'Featured') {
    return 'Featured';
  }

  if (banner === 'Reserved') {
    return 'Reserved';
  }

  if (banner === 'NewArrival') {
    return 'New Arrival';
  }

  if (banner === 'ArrivingSoon') {
    return 'Arriving Soon';
  }

  if (banner === 'Sold') {
    return 'Sold';
  }

  return '';
}

interface StatusStyle {
  color: string;
  'background-color'?: string;
  width?: string;
  height: string;
  'line-height'?: string;
  transform?: string;
  'transform-origin'?: string;
}

export function GetBannerStyle(
  statusBanner: StatusBanner,
  width: number,
  height: number
) {
  const style: StatusStyle = {
    color: 'var(--v-surface-base)',
    'background-color': '',
    height: `${height}px`,
  };
  if (width) {
    const actualWidth = 1.12 * width;
    style.width = `${actualWidth}px`;
  } else {
    style.width = '110px';
  }

  if (statusBanner === 'Arriving Soon') {
    style['background-color'] = '#00a67c';
  }
  if (statusBanner === 'New Arrival') {
    style['background-color'] = '#28477c';
  }
  if (statusBanner === 'Featured') {
    style['background-color'] = '#55a200';
  }
  if (statusBanner === 'Reserved') {
    style['background-color'] = '#FDB913';
  }
  if (statusBanner === 'Sold') {
    style['background-color'] = '#EB5757';
  }
  return style;
}

export function IsBudgetCar(inventory: Inventory | undefined): boolean {
  return inventory?.Search?.ProductCategory?.includes('Budget') || false;
}

export function IsQualityVerifiedCar(
  inventory: Inventory | undefined
): boolean {
  return (
    inventory?.Search?.ProductCategory?.includes('Quality Verified') || false
  );
}

export function HasFreeStandardProtectionPlan(
  inventory: Inventory | undefined
): boolean {
  if (IsBudgetCar(inventory)) return false;
  if (!IsEligibleForPremium(inventory)) return false;
  return true;
}

const premiumEligibilityMaxOdometer: number = 175000;
const premiumEligibilityMaxAgeYears: number = 10;
export function IsEligibleForPremium(
  inventory: Inventory | undefined
): boolean {
  return (
    (inventory?.Vehicle?.Odometer || 99999999) <=
    premiumEligibilityMaxOdometer &&
    (inventory?.GlassVehicle?.VehicleDetails?.ReleaseYear || -999999999) >=
    new Date().getFullYear() - premiumEligibilityMaxAgeYears &&
    IsProtectionPlanEligible(inventory)
  );
}

const protectionPlanMakes: string[] = [
  'FORD',
  'HOLDEN',
  'HONDA',
  'HYUNDAI',
  'ISUZU',
  'KIA',
  'MAZDA',
  'MITSUBISHI',
  'NISSAN',
  'PROTON',
  'SUZUKI',
  'TOYOTA',
  'FIAT',
  'CITROEN',
  'LEXUS',
  'JEEP',
  'INFINITI',
  'HSV',
  'PEUGEOT',
  'RENAULT',
  'VOLVO',
  'SKODA',
  'SUBARU',
  'VOLKSWAGEN',
];
function IsProtectionPlanEligible(inventory: Inventory | undefined): boolean {
  return protectionPlanMakes.includes(
    inventory?.GlassVehicle?.VehicleDetails?.Make?.toUpperCase() || ''
  );
}
