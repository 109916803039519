import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import * as typ from '../features/header/types';

const name = 'header';

@Module({ stateFactory: true })
export default class Header extends VuexModule {
  public NavOptions: typ.NavOptions = typ.navOptions;
  public LocationPickerOptions: typ.LocationPickerOptions = {};
  public isSearchVisible: boolean = false;

  @Mutation public toggleSearchBar() {
    this.isSearchVisible = !this.isSearchVisible;
  }

  @Mutation public hideDrawers(value?: typ.NavOptions) {
    this.NavOptions.drawer = false;
    this.NavOptions.location = false;
    this.NavOptions.modalLocation = false;
    this.NavOptions = { ...this.NavOptions, ...value };
  }

  @Mutation public showLocationPicker(options?: typ.LocationPickerOptions) {
    this.LocationPickerOptions = { ...options };
    this.NavOptions.drawer = false;
    this.NavOptions.location = false;
    this.NavOptions.modalLocation = true;
  }
}

export const HeaderModule = namespace(name);
