import { Component, Vue } from 'vue-property-decorator';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
@Component({
  name: 'insurance-table',
  components: {
    SectionWithContainer,
  },

  data: () => ({
    keyBenefits: [
      'Cover for accidental loss of or damage to your vehicle',
      `Cover for your legal liability for loss or damage to other people's property and legal liability for supplementary bodily injury - up to $20 million for any one event or series of related events`,
      'Approved legal costs arising from a claim for liability covered by the policy',
      'Cost of emergency repairs after a covered accident – up to $500 for any one incident',
      'Rental car after theft of your vehicle - $100 per day limit, up to 30 days<sup>4</sup>',
      'Temporary cover on replacement vehicle – 14 days',
      'Reasonable cost of towing after a covered incident',
    ],
    moreBenefits: [
      'Trailer and caravan cover - $1,000 or market value, whichever is the lesser',
      'Cover for personal items in your vehicle - Up to $1,000 for any one incident',
      'Cover for reasonable emergency accommodation and travelling expenses after a covered accident, provided that your vehicle was more than 100km from where it is normally parked at night - Up to $1,000 for any one incident',
      'Cover for transportation costs if your vehicle cannot be safely driven home after a covered accident - Up to $500 for any one incident',
      'Legal liability cover when using a substitute vehicle whilst your vehicle is being serviced or repaired',
      'Lock re-keying / re-coding if the keys to your vehicle are stolen - Up to $1,000 for any one incident',
      'Cover for replacement of child seat / baby capsule in your vehicle after a covered accident - Up to $500 for any one incident',
    ],
  }),
})
export default class InsuranceTable extends Vue {
  public isDarkBackground(index: number): boolean {
    return index % 2 !== 0;
  }
}
