import { VBtn, VMenu } from 'vuetify/lib';
import { Component, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { Dictionary } from '../../../../shared/global/types';
import { Inventory } from '../../../../shared/inventory';
import PSAGroup from '../public-service-announcements/PSAGroup.vue';
import { FavDetails } from '../inventory/types';
import { LocationDetails } from '../../../../shared/inventory/search';
import { NavOptions } from './types';
import Logo from './Logo';
import SideMenu from './SideMenu';
import MenuItem from './MenuItem';
import LocationSection from '@/features/location/Location.vue';
import SearchBox from '@/features/filter/search-box-filter/SearchBoxFilter.vue';
import { InventoryModule } from '@/store/inventoryStore';
import { RouteNames } from '@/router/route-consts';
import { getTodayOpeningHoursLabel } from '@/helpers/timezone';
import { LocationModule } from '@/store/location';
import { HeaderModule } from '@/store/header';
import { dataLayerAdd } from '@/helpers/analytics-helper';
import { allowSell } from '@/features/types';
import { statesWithStores, StateWithStores } from '@/constants/States';

@Component({
  components: {
    SearchBox,
    VMenu,
    LocationSection,
    PSAGroup,
    Logo,
    SideMenu,
    MenuItem,
    VBtn,
  },
  methods: {
    onClick() {
      // hack to force the external blog to re-render upon any navigation to its page
      if (this.$router.currentRoute.name === RouteNames.BlogRouteName) {
        window.location.reload();
      }
    },
  },
})
export default class Header extends Vue {
  public currentRoute: number = -1;
  public allowSell: boolean = allowSell;
  public RouteNames = RouteNames;
  public clearScrollPosition: boolean = true;
  public isMoreMenuActive: boolean = false;

  @LocationModule.State private LocalTime!: DateTime;
  @LocationModule.Getter private selectedLocation: LocationDetails | undefined;
  @HeaderModule.State private isSearchVisible!: boolean;
  @HeaderModule.State public NavOptions!: NavOptions;
  @HeaderModule.Mutation protected hideDrawers!: (value?: NavOptions) => void;
  @HeaderModule.Mutation protected toggleSearchBar!: () => void;
  @InventoryModule.State protected Favourites!: Dictionary<FavDetails>;
  @InventoryModule.State protected InventoryList!: Dictionary<Inventory>;
  @LocationModule.State public locations!: LocationDetails[];

  public get LocationLabel() {
    if (this.selectedLocation) {
      return `${this.selectedLocation.Description}, ${this.selectedLocation.Address?.StateShort}`;
    }
    return 'Stores';
  }

  public stateName(state: string): string {
    const stateNames = state.split('-');
    return stateNames
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ');
  }

  public hasStores(state: string): boolean {
    const stores = statesWithStores.find((item) => {
      return item.state === state;
    });

    return stores?.store.length ? stores?.store.length > 1 : false;
  }

  public get states() {
    return statesWithStores.sort((a, b) => a.state.localeCompare(b.state));
  }

  public locationRouter(state: StateWithStores) {
    if (this.hasStores(state.state)) {
      this.$router.push({
        name: 'state-location',
        params: { state: state.state },
      });
    } else {
      const location = this.locations.find(
        (location) => state.store[0] === location.Key
      );
      if (location?.LocationUrl) {
        this.$router.push({
          name: 'locations-details',
          params: {
            locationUrl: location.LocationUrl,
          },
        });
      }
    }
  }

  public get OpeningHours() {
    if (this.selectedLocation) {
      const openingHours = this.selectedLocation.OpeningHours;
      if (openingHours) {
        return getTodayOpeningHoursLabel(openingHours, this.LocalTime);
      }
      return '';
    }
  }

  public get FavouriteLabel() {
    let count = 0;
    for (const key in this.Favourites) {
      if (Object.prototype.hasOwnProperty.call(this.Favourites, key)) {
        const element = this.InventoryList[key];
        if (element) {
          count++;
        }
      }
    }

    if (count) {
      return count;
    }
    return 'Fav';
  }

  public showNavDrawer() {
    this.hideDrawers({ drawer: true });
  }

  public showLocation() {
    this.locationToolAccessed();
    this.hideDrawers({ location: true });
  }

  private locationToolAccessed() {
    dataLayerAdd({
      event: 'toolNavigate',
      toolEvent: 'start',
      toolName: 'setLocation',
    });
  }

  public closeNav() {
    this.hideDrawers();
  }

  public toggleMoreMenu() {
    this.isMoreMenuActive = !this.isMoreMenuActive;
  }
}
