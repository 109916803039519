import {
  SearchHints,
  RangeOptions,
  PriceOptions,
  RepaymentOptions,
} from '../types';
import { FinanceOptions } from '../../../../../shared/inventory/search';
import {
  CalculateTotalValue,
  GetIntervalPriceOptions,
} from '../../finance/helpers/calculators';
import { storageWrapper } from '@/tools/storage';

export interface RangeFormatter {
  Key: string;
  Source: string;
  FullRange: (x: string, y: string) => string;
  MinOnly: (x: string) => string;
  MaxOnly: (x: string) => string;
}

export interface RangeFormatters {
  Seats: RangeFormatter;
  Years: RangeFormatter;
  Price: RangeFormatter;
  PriceCalculated: RangeFormatter;
  Odometer: RangeFormatter;
  RepaymentPerWeek: RangeFormatter;
  RepaymentPerFortnight: RangeFormatter;
  RepaymentPerMonth: RangeFormatter;
  FuelEcon: RangeFormatter;
}

export const rangeFormatters: RangeFormatters = {
  Seats: {
    Key: 'Seats.',
    Source: 'Seats',
    FullRange: (x, y) => `${x} to ${y} seats`,
    MinOnly: (x) => `${x} or more seats`,
    MaxOnly: (x) => `up to ${x} seats`,
  },
  Years: {
    Key: 'Years.',
    Source: 'Years',
    FullRange: (x, y) => `from ${x} to ${y}`,
    MinOnly: (x) => `from ${x}`,
    MaxOnly: (x) => `up to ${x}`,
  },
  RepaymentPerWeek: {
    Key: 'Repayment.',
    Source: 'Repayment',
    FullRange: (x, y) => ` from $${x.toMoney()} to $${y.toMoney()} per week`,
    MinOnly: (x) => `from $${x.toMoney()} per week`,
    MaxOnly: (x) => `up to $${x.toMoney()} per week`,
  },
  RepaymentPerFortnight: {
    Key: 'Repayment.',
    Source: 'Repayment',
    FullRange: (x, y) =>
      `from $${x.toMoney()} to $${y.toMoney()} per fortnight`,
    MinOnly: (x) => `from $${x.toMoney()} per fortnight`,
    MaxOnly: (x) => `up to $${x.toMoney()} per fortnight`,
  },
  RepaymentPerMonth: {
    Key: 'Repayment.',
    Source: 'Repayment',
    FullRange: (x, y) => `from $${x.toMoney()} to $${y.toMoney()} per month`,
    MinOnly: (x) => `from $${x.toMoney()} per month`,
    MaxOnly: (x) => `up to $${x.toMoney()} per month`,
  },
  Price: {
    Key: 'Price.',
    Source: 'Price',
    FullRange: (x, y) => `from $${x},000 to $${y},000`,
    MinOnly: (x) => `from $${x},000`,
    MaxOnly: (x) => `up to $${x},000`,
  },
  PriceCalculated: {
    Key: 'Price.',
    Source: 'Price',
    FullRange: (x, y) => `from $${x},000 to $${y},000`,
    MinOnly: (x) => `from $${x.toMoney()}`,
    MaxOnly: (x) => `up to $${x.toMoney()}`,
  },
  Odometer: {
    Key: 'Odometer.',
    Source: 'Odometer',
    FullRange: () => '',
    MinOnly: () => '',
    MaxOnly: (x) => `${x},000 km or less`,
  },
  FuelEcon: {
    Key: 'FuelEcon.',
    Source: 'FuelEcon',
    FullRange: () => '',
    MinOnly: () => '',
    MaxOnly: (x) => `${x} litre/100km or less`,
  },
};

export function SetRange(
  formatter: RangeFormatter,
  options: RangeOptions,
  filters: string[],
  hints: SearchHints[],
  values?: number[]
) {
  let minValue = values ? values[0] : options.MinValue;
  let maxValue = values ? values[1] : options.MaxValue;
  const newValues = options.Criteria?.Values;
  if (!newValues) {
    return;
  }
  const maxRange = newValues.length - 1;
  if (maxValue > maxRange) {
    maxValue = maxRange;
  }
  if (options.MinValue !== minValue && options.MaxValue !== maxValue) {
    options.MinValue = minValue;
    options.MaxValue = maxValue;
  } else if (options.MinValue !== minValue) {
    options.MinValue = minValue;
    if (minValue > maxValue) {
      options.MaxValue = minValue;
      maxValue = minValue;
    }
  } else if (options.MaxValue !== maxValue) {
    options.MaxValue = maxValue;
    if (maxValue < minValue) {
      options.MinValue = maxValue;
      minValue = maxValue;
    }
  }
  let isSet = false;
  if (minValue) {
    isSet = true;
  }
  if (maxValue < maxRange) {
    isSet = true;
  }
  const currentFilter = filters.findIndex((x) => x.startsWith(formatter.Key));
  const currentHint = hints.findIndex((x) => x.Key.startsWith(formatter.Key));
  if (isSet) {
    if (currentFilter >= 0) {
      filters.splice(currentFilter, 1);
    }
    const minFilter = newValues[minValue].Value;
    const maxFilter = newValues[maxValue].Value;
    const key = `${formatter.Key}[${minFilter}..${maxFilter}]`;
    let description = '';
    if (minValue) {
      if (maxValue < maxRange) {
        description = formatter.FullRange(`${minFilter}`, `${maxFilter}`);
      } else {
        description = formatter.MinOnly(`${minFilter}`);
      }
    } else {
      description = formatter.MaxOnly(`${maxFilter}`);
    }
    filters.push(key);
    if (currentHint >= 0) {
      hints.splice(currentHint, 1);
    }
    hints.push({
      Description: description,
      Key: key,
      Source: formatter.Source,
    });
  } else {
    if (currentFilter >= 0) {
      filters.splice(currentFilter, 1);
    }
    if (currentHint >= 0) {
      hints.splice(currentHint, 1);
    }
  }
  storageWrapper.Write(`filter.SearchHints`, hints);
  storageWrapper.Write(`filter.SelectedFilter`, filters);
}

export function SetPrice(
  financeOptions: FinanceOptions,
  options: PriceOptions,
  filters: string[],
  hints: SearchHints[],
  values?: number[]
) {
  if (options.PriceType === 0) {
    SetRange(
      rangeFormatters.Price,
      options.OverallPriceValue,
      filters,
      hints,
      values
    );
  } else {
    CalculateTotalValue(financeOptions, options);
    const paymentIntervalType = GetIntervalPriceOptions(options);
    if (paymentIntervalType) {
      const range: RangeOptions = {
        Criteria: {
          Values: [{ Value: paymentIntervalType.TotalValue || 0 }],
          Description: '',
        },
        MinValue: 0,
        MaxValue: 0,
      };
      if (paymentIntervalType.TotalValue) {
        range.Criteria.Values?.push({ Value: 10000 });
      }

      SetRange(rangeFormatters.PriceCalculated, range, filters, hints);
    } else {
      // Do some stuff here.
    }
  }
}

export function SetRepaymentFilterValues(
  options: RepaymentOptions,
  filters: string[],
  hints: SearchHints[],
  values?: number[]
) {
  const formatter =
    options.IntervalType === 'week'
      ? rangeFormatters.RepaymentPerWeek
      : options.IntervalType === 'fortnight'
      ? rangeFormatters.RepaymentPerFortnight
      : rangeFormatters.RepaymentPerMonth;

  SetRangeFromValues(formatter, options, filters, hints, values);
}

export function SetRangeFromValues(
  formatter: RangeFormatter,
  options: RangeOptions,
  filters: string[],
  hints: SearchHints[],
  values?: number[]
) {
  if (values) {
    options.MinValue = values[0];
    options.MaxValue = values[1];
  }

  let minValue = options.MinValue;
  let maxValue = options.MaxValue;

  if (minValue && maxValue) {
    minValue =
      options.MaxValue >= options.MinValue
        ? options.MinValue
        : options.MaxValue;
    maxValue =
      options.MaxValue >= options.MinValue
        ? options.MaxValue
        : options.MinValue;
  }

  const range = {
    Criteria: {
      Values: [{ Value: 0 }, { Value: 0 }, { Value: 0 }, { Value: 0 }],
      Description: '',
    },
    MinValue: 0,
    MaxValue: 3,
  };

  if (minValue) {
    range.Criteria.Values[1] = { Value: minValue };
    range.MinValue = 1;
  }

  if (maxValue) {
    range.Criteria.Values[2] = { Value: maxValue };
    range.MaxValue = 2;
  }

  SetRange(formatter, range, filters, hints);
}
