import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterProdCateg(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('ProdCateg.')) {
    const key = filterKey.substring(10);

    if (key) {
      if (filter.Search.ProdCateg) {
        filter.Search.ProdCateg.push(key);
      } else {
        filter.Search.ProdCateg = [key];
      }
    }
    return true;
  }
  return false;
}
