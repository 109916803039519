import { Component, Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router/types';
import { DateTime } from 'luxon';
import { OpeningDays } from '../../../../../shared/global/types';
import { LocationDetails } from '../../../../../shared/inventory/search';
import { LocationModule } from '@/store/location';
import { storageWrapper } from '@/tools/storage';
import { getExceptionDays, ExceptionDayOpeningHours } from '@/helpers/timezone';
import { RouteNames } from '@/router/route-consts';

export const HoursAlertName = 'HoursAlert';

@Component
export default class HoursAlert extends Vue {
  public alert: boolean = false;
  @LocationModule.State private locations: LocationDetails[] | undefined;
  @LocationModule.Getter private selectedLocation: LocationDetails | undefined;
  public LocationsRoute: Location = { name: RouteNames.LocationsRouteName };

  public MessageRef(): HTMLElement | undefined {
    return this.$refs.message as HTMLElement;
  }

  public get MinHeight(): number {
    if (this.alert) {
      return 30;
    }
    return 0;
  }

  public get Height(): number {
    const width = this.$vuetify.breakpoint.width;
    if (width) {
      return this.MessageRef()?.clientHeight || this.MinHeight;
    }
    return this.MinHeight;
  }

  public get AlertBarClass() {
    return { collapsed: !this.alert };
  }

  public get PreferredLocation(): string | undefined {
    return this.selectedLocation?.Key;
  }

  public get LocationDetails(): Location | undefined {
    if (!this.PreferredLocation) return;

    const location = this.selectedLocation;
    const city = location?.Address?.City;
    const description = location?.Description;
    if (city && description) {
      return {
        name: 'locations-details',
        params: {
          LocationCity: city.toLowerCase(),
          LocationDescription: description.toLowerCase(),
        },
      };
    }
  }

  public ShowInfo() {
    this.CloseAlert();
    if (this.PreferredLocation) {
      this.$router.push(this.LocationDetails!);
    } else {
      this.$router.push(this.LocationsRoute);
    }
  }

  public get AlertMessage(): string {
    if (this.PreferredLocation) {
      return 'Altered trading hours may apply to your nearest store. Find hours';
    }
    return "There's a temporary change to our trading hours. Please check out your nearest store's open hours";
  }

  public mounted() {
    this.CalculateVisibility();
  }

  public CloseAlert() {
    this.alert = !this.alert;

    const days = this.FirstDay;
    if (!days) {
      return;
    }

    storageWrapper.Write(
      `${HoursAlertName}-start`,
      days[0].toFormat('yyyy/MM/dd')
    );
    storageWrapper.Write(
      `${HoursAlertName}-end`,
      days[1].toFormat('yyyy/MM/dd')
    );
  }

  public get FirstDay(): DateTime[] | undefined {
    if (!this.locations) {
      return;
    }

    const exceptions: ExceptionDayOpeningHours[] = [];

    for (const location of this.locations) {
      const locationExceptions = this.GetExceptions(location.OpeningHours);
      if (locationExceptions) {
        exceptions.push(...locationExceptions);
      }
    }

    if (exceptions.length === 0) {
      return;
    }

    const sortedExceptions = exceptions.sort(
      (a, b) => a.Date.diff(b.Date, 'minutes').minutes
    );

    return [
      sortedExceptions[0].Date,
      sortedExceptions[exceptions.length - 1].Date,
    ];
  }

  private GetExceptions(
    days?: OpeningDays
  ): ExceptionDayOpeningHours[] | undefined {
    if (!days?.Exception) {
      return;
    }
    const exceptions = getExceptionDays(days.Exception, days.Timezone);
    if (!exceptions.length) {
      return;
    }

    if (exceptions[0].Date.diffNow('days').days > 7) {
      return;
    }

    return exceptions;
  }

  @Watch('FirstDay') public FirstDayChanged() {
    this.CalculateVisibility();
  }

  private CalculateVisibility() {
    const days = this.FirstDay;
    if (!days) {
      storageWrapper.Remove(`${HoursAlertName}-start`);
      storageWrapper.Remove(`${HoursAlertName}-end`);
      return;
    }

    const startDismissedString = storageWrapper.Read<string>(
      `${HoursAlertName}-start`
    );
    if (startDismissedString) {
      const startDismissed = DateTime.fromFormat(
        startDismissedString,
        'yyyy/MM/dd',
        { locale: 'local' }
      );
      if (Math.trunc(startDismissed.diff(days[0], 'days').days) === 0) {
        return;
      } else {
        const endDismissedString = storageWrapper.Read<string>(
          `${HoursAlertName}-end`
        );
        if (endDismissedString) {
          const endDismissed = DateTime.fromFormat(
            endDismissedString,
            'yyyy/MM/dd',
            { locale: 'local' }
          );
          if (Math.trunc(endDismissed.diff(days[1], 'days').days) === 0) {
            return;
          }
        }
      }
    }

    this.alert = true;
  }
}
