import { RouteConfig } from 'vue-router/types';
import { RouteNames } from './route-consts';

export const financeRoutes: RouteConfig[] = [
  {
    path: '/buy/finance',
    name: RouteNames.FinanceRouteName,
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/features/finance/finance/Finance.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/personalised-finance/referral',
    name: 'personalisedFinanceReferral',
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '../features/personalised-finance/Referral/Referral.vue'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/personalised-finance/thank-you/:VehicleId?',
    name: 'personalisedFinanceThankYou',
    props: (route) => ({
      VehicleId: route.params.VehicleId,
    }),
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/features/personalised-finance/ThankYouPreApprovalForm'
      ).then((m) => m.default || m),
  },
  {
    path: '/buy/personalised-finance/:StepNumber(1|2|3|4)?/:VehicleId?',
    name: 'personalisedFinance',
    props: (route) => ({
      StepNumber: route.params.StepNumber,
      VehicleId: route.params.VehicleId,
    }),
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/features/personalised-finance/PreApprovalForm'
      ).then((m) => m.default || m),
  },
];
