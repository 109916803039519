import { Location } from 'vue-router/types';
import {
  CustomerDetails,
  VehicleDetails,
  FinanceDetails,
  AppraisalDetails,
  EnquiryDetails,
  EnquiryData,
} from '../../../../shared/enquiries';
import { Inventory } from '../../../../shared/inventory';
import { ValidatePhone } from '../../../../shared/enquiries/validation.helpers';
import { ValidateEmail } from '../../../../shared/utils/validation/email';

export interface SearchHints {
  Description: string;
  Source: string;
  Key: string;
}

export interface Validate {
  Name: string;
  NameRules: any[];
  Surname: string;
  SurnameRules: any[];
  Email: string;
  EmailRules: any[];
  Phone: string;
  PhoneRules: any[];
  Location: string;
  LocationRules: any[];
}
export const validate: Validate = {
  Name: '',
  NameRules: [(v: string) => !!v || 'Must provide First name'],
  Surname: '',
  SurnameRules: [(v: string) => !!v || 'Must provide Last name (Family name)'],
  Email: '',
  EmailRules: [
    (v: string) => {
      const valRes = ValidateEmail(v);
      return valRes.error || true;
    },
  ],
  Phone: '',
  PhoneRules: [
    (v: string) => {
      const valRes = ValidatePhone(v);
      return valRes.error || true;
    },
  ],
  Location: '',
  LocationRules: [(v: string) => !!v || 'Must select an easyauto123 store'],
};

export interface SubmitEnquiryParams {
  inv: Inventory;
  routeName: string;
  SubmittedFromUrl: string;
  ThankYou: Location;
  Exterior: string;
  Tyres: string;
  Interior: string;
  Service: string;
}

export interface SubmitParams {
  params: SubmitEnquiryParams;
  enquiryData: EnquiryData;
}

export interface SellDetails {
  SellAs: string;
  Exterior: string;
  Tyres: string;
  Interior: string;
  Service: string;
}

export const sellDetails: SellDetails = {
  SellAs: '',
  Exterior: '',
  Tyres: '',
  Interior: '',
  Service: '',
};

export interface OdometerDetails {
  Odometer: string[];
}

export const odometerDetails: OdometerDetails = {
  Odometer: [
    '5,000 km',
    '10,000 km',
    '15,000 km',
    '20,000 km',
    '25,000 km',
    '30,000 km',
    '35,000 km',
    '40,000 km',
    '45,000 km',
    '50,000 km',
    '55,000 km',
    '60,000 km',
    '65,000 km',
    '70,000 km',
    '75,000 km',
    '80,000 km',
    '85,000 km',
    '90,000 km',
    '95,000 km',
    '100,000 km',
    '105,000 km',
    '110,000 km',
    '115,000 km',
    '120,000 km',
    '125,000 km',
    '130,000 km',
    '135,000 km',
    '140,000 km',
    '145,000 km',
    '150,000 km',
    '155,000 km',
    '160,000 km',
    '165,000 km',
    '170,000 km',
  ],
};

export const vehicleDetails: VehicleDetails = {
  VIN: '',
  StockNumber: '',
  Make: '',
  Model: '',
  Variant: '',
  BuildYear: '',
  FuelType: '',
  Odometer: '',
  Registration: '',
  RegistrationState: '',
  LocationKey: '',
};

export const financeDetails: FinanceDetails = {
  Deposit: '',
  LoanTerm: '',
  RepaymentFrequency: '',
  EstimatedTotal: '',
};

export const appraisalDetails: AppraisalDetails = {
  PreferredDate: '',
  PreferredTime: '',
  VIN: '',
  Make: '',
  Model: '',
  Variant: '',
  BuildYear: '',
  FuelType: '',
  Odometer: '',
  Registration: '',
  RegistrationState: '',
  VehicleCondition: '',
  LocationKey: '',
};

const customerDetails: CustomerDetails = {
  Name: '',
  Surname: '',
  Phone: '',
  PhoneIsMobile: false,
  Email: '',
  PostCode: '',
  IsExistingCustomer: false,
};

const enquiryDetails: EnquiryDetails = {
  Comments: '',
  SpecialOffersOptIn: false,
  DesiredTradeInPrice: '',
  CompetitorPrice: '',
  LoanCarRequired: false,
  InterestedToBuy: false,
  InterestedToSell: false,
  InterestedInFinance: false,
  SubmittedFromUrl: '',
  LocationKey: '',
  GoogleClientId: '',
  SeeInPerson: false,
  SeeOverVideoCall: false,
};

export function newEnquiryData(): EnquiryData {
  return {
    EnquiryType: '',
    CustomerDetails: { ...customerDetails },
    EnquiryDetails: { ...enquiryDetails },
    FinanceDetails: { ...financeDetails },
    VehicleDetails: { ...vehicleDetails },
    AppraisalDetails: { ...appraisalDetails },
  };
}
