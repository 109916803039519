import Vue from 'vue';
import { VNode, PropType } from 'vue/types/umd';
import { VBtn } from 'vuetify/lib';

const name = 'primary-button';

export default Vue.extend({
  name,

  props: {
    large: {} as PropType<boolean | undefined>,
  },

  render(h): VNode {
    return h(
      VBtn,
      {
        attrs: { ...this.$attrs },
        scopedSlots: this.$scopedSlots,
        staticClass: 'surface--text',
        on: this.$listeners,
        props: {
          color: 'secondary',
          large: this.large === undefined ? true : this.large,
        },
      },
      this.$slots?.default
    );
  },
});
