import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterVehSize(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('VehSize.')) {
    const vehType = filterKey.substr(8);
    if (vehType) {
      if (!filter.Search.VehicleSize) {
        filter.Search.VehicleSize = [vehType];
      } else {
        filter.Search.VehicleSize.push(vehType);
      }
    }
    return true;
  }
  return false;
}
