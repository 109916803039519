import { NumberDecimal } from '../../../../../shared/global/types';
import { Inventory } from '../../../../../shared/inventory';

export function IsReserved(inv: Inventory | undefined): boolean {
  return !!inv?.Search?.IsReservedDate;
}

export const SoldStatuses = ['Under Offer', 'Sold'];

export function IsSold(inv: Inventory | undefined): boolean {
  if (!inv) {
    return false;
  }

  return (
    !!inv.Calculated?.IsSold || SoldStatuses.includes(inv.Search?.StatusCode)
  );
}

export function GetNumber(value?: NumberDecimal): number | undefined {
  if (value?.$numberDecimal) {
    return Number.parseFloat(value.$numberDecimal);
  }
}
