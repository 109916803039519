import { Component, Vue, Prop } from 'vue-property-decorator';
import { FAQSection } from '../../../../../shared/global/faq';
import FAQItem from './faq-item.vue';

@Component({
  components: {
    FAQItem,
  },
})
export default class FrequentlyAskedQuestions extends Vue {
  @Prop() public FAQs!: FAQSection;
  @Prop({
    type: Boolean,
  })
  public noTitle!: boolean;
}
