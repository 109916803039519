export interface Reviews {
  Image: string;
  Name: string;
  Location: string;
  Review: string;
  Rating: number;
}

export interface ReviewOptions {
  Reviews: Reviews[];
}

export const reviewOptions: ReviewOptions = {
  Reviews: [
    {
      Image: '',
      Name: 'Stu Milne',
      Location: 'Sydney, NSW',
      Rating: 4.5,
      Review:
        'Awesome experience! This is a must if you are buying a second-hand car. The team were incredible!!!',
    },
    {
      Image: '',
      Name: 'Stu Milne',
      Location: 'Sydney, NSW',
      Rating: 4.5,
      Review:
        'Awesome experience! This is a must if you are buying a second-hand car. The team were incredible!!!',
    },
    {
      Image: '',
      Name: 'Stu Milne',
      Location: 'Sydney, NSW',
      Rating: 4.5,
      Review:
        'Awesome experience! This is a must if you are buying a second-hand car. The team were incredible!!!',
    },
    {
      Image: '',
      Name: 'Stu Milne',
      Location: 'Sydney, NSW',
      Rating: 4.5,
      Review:
        'Awesome experience! This is a must if you are buying a second-hand car. The team were incredible!!!',
    },
    {
      Image: '',
      Name: 'Stu Milne',
      Location: 'Sydney, NSW',
      Rating: 4.5,
      Review:
        'Awesome experience! This is a must if you are buying a second-hand car. The team were incredible!!!',
    },
    {
      Image: '',
      Name: 'Stu Milne',
      Location: 'Sydney, NSW',
      Rating: 4.5,
      Review:
        'Awesome experience! This is a must if you are buying a second-hand car. The team were incredible!!!',
    },
  ],
};
