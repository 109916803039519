// Styles
import './_brick-button.scss';

import Vue from 'vue';
import { VNode } from 'vue/types/umd';
import { VRadio } from 'vuetify/lib';
import { ScopedSlot } from 'vue/types/vnode';

const name = 'brick-button';

export default Vue.extend({ _scopeId: 'data-brick-button' } as any).extend({
  name,

  props: {
    large: Boolean,
    color: {
      type: String,
      default: 'accent',
    },
    value: String,
    Label: String,
    UseLabelTemplate: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    removeMargins: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'unset',
    },
  },
  computed: {
    DisplayLabel(): string {
      return this.Label || this.value;
    },

    GetOuterPadding(): string {
      if (this.removeMargins) {
        return '';
      }
      return 'pa-1';
    },

    GetHeight(): string {
      if (this.xLarge) {
        return '56px';
      }
      return '38px';
    },

    GetLabelTemplate(): VNode | undefined {
      if (this.UseLabelTemplate) {
        return this.$createElement(
          'span',
          {
            style: {
              width: '100%',
            },
          },
          this.DisplayLabel
        );
      }
    },

    CreateRadioButton(): VNode {
      const scopedSlots: { [key: string]: ScopedSlot | undefined } = {
        ...this.$scopedSlots,
      };
      if (this.UseLabelTemplate && !scopedSlots.label) {
        scopedSlots.label = () => this.GetLabelTemplate;
      }

      return this.$createElement(
        VRadio,
        {
          attrs: {
            ...this.$attrs,
          },
          scopedSlots,
          staticClass: `body-2 ma-0 text-center`,
          on: this.$listeners,
          props: {
            ...this.$props,
            label: this.DisplayLabel,
          },
          style: {
            height: this.GetHeight,
          },
          directives: [
            {
              name: 'ripple',
              value: { class: 'secondary--text' },
            },
          ],
        },
        this.$slots?.default
      );
    },
  },

  render(h): VNode {
    return h(
      'div',
      {
        staticClass: `${name} ${this.GetOuterPadding}`,
      },
      [this.CreateRadioButton]
    );
  },
});
