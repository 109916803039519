import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterFeatures(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Feat.')) {
    const key = filterKey.substring(5);
    if (!filter.Search.Features) {
      filter.Search.Features = [key];
    } else {
      filter.Search.Features.push(key);
    }
    return true;
  }
  return false;
}
