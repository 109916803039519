import Axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import * as Sentry from '@sentry/browser';
import {
  Inventory,
  InventoryLookupStats,
  SoldInventory,
} from '../../../../shared/inventory';
import { InventorySearchConfig } from '../../../../shared/inventory/search';
import { storageWrapper } from '@/tools/storage';
import { CacheBuckets } from '@/components/cache/types';
import { apiFunctions, DebounceTimer } from '@/helpers/functions';

class InventoryApi {
  private inventoryLoading: boolean = false;
  private filterInventoryToken?: CancelTokenSource;
  private filterInventoryTimer: DebounceTimer = {};
  private detailInventoryToken?: CancelTokenSource;
  private detailInventoryTimer: DebounceTimer = {};
  private inventoryCounterToken?: CancelTokenSource;
  private inventoryCounterTimer: DebounceTimer = {};

  public async FindInventoryItemsDebounced(
    filter: InventorySearchConfig
  ): Promise<string[]> {
    const callUrl: string = 'getFilteredInventory';
    try {
      const res = await apiFunctions.Debounce(
        this.filterInventoryTimer,
        async () => {
          if (this.filterInventoryToken) {
            this.filterInventoryToken.cancel('Filters changed');
          }
          this.filterInventoryToken = Axios.CancelToken.source();
          return await apiFunctions.axios.post(callUrl, filter, {
            cancelToken: this.filterInventoryToken.token,
          });
        }
      );

      return res.data.docs as string[];
    } catch (error) {
      const localError: any = error;
      if (Axios.isCancel(localError) || localError === 'debounce reject') {
        console.info('operation canceled by user');
        return [];
      }
      if (localError?.message === 'Network Error') {
        // this is where we could do a network retry.
        throw localError;
      }
      throw localError;
    }
  }

  public async FindInventoryItems(
    filter: InventorySearchConfig
  ): Promise<string[]> {
    const callUrl: string = 'getFilteredInventory';
    try {
      const res = await apiFunctions.axios.post(callUrl, filter);

      return res.data.docs as string[];
    } catch (error) {
      return [];
    }
  }

  public async *LoadInventory(): AsyncIterableIterator<Inventory[]> {
    if (this.inventoryLoading) {
      return [];
    }
    this.inventoryLoading = true;

    try {
      const callUrl: string = 'getInventory';

      const invBuckets = storageWrapper.Read<CacheBuckets>('inv-buckets') || {
        count: 0,
        parallel: 1,
      };
      const newInvBuckets = { ...invBuckets };

      try {
        const config: AxiosRequestConfig = { headers: {} };

        let next = 0;
        const bucketSize = 200;
        const callCount = newInvBuckets.parallel;
        const calls = Array.from(Array(callCount).keys());

        do {
          const results = await Promise.all(
            calls.map((i) =>
              apiFunctions.axios
                .get(`${callUrl}?next=${next + i * bucketSize}`, config)
                .catch((x) => x)
            )
          );

          for (let index = 0; index < results.length; index++) {
            const error = results[index];
            if (error.isAxiosError && !error.response) {
              // things failed when in parallel so give it up.
              newInvBuckets.parallel = 1;
              const longConfig = error.config as AxiosRequestConfig;
              longConfig.timeout = (longConfig.timeout as number) * 4;
              results[index] = await apiFunctions.axios
                .get(longConfig.url as string, longConfig)
                .catch((x) => x);
            }

            const result = results[index];

            if (!result || result.isAxiosError) {
              continue;
            }

            const res = result as AxiosResponse;

            if (res.data.docs && res.data.count) {
              const invRecord = res.data.docs as Inventory[];

              newInvBuckets.count = Math.ceil(res.data.count / bucketSize);
              newInvBuckets.etag = res.headers.etag;

              const bucketIndex = Math.floor(res.data.current / bucketSize);
              try {
                storageWrapper.Zip(`inv-bucket-${bucketIndex}`, invRecord);
              } catch (error) {
                // If we ran out of quota we should probably report it.
              }
              yield invRecord;

              if (next < res.data.next) {
                next = res.data.next;
              }
              if (res.data.next === 0) {
                next = -1;
              }
            }
          }
        } while (next > 0);
      } catch (error) {
        Sentry.setContext('message', {
          message: 'Error while fetching inventory',
        });
        Sentry.captureException(error);
        // We do not want to stop on error.
      } finally {
        for (
          let index = newInvBuckets.count;
          index < invBuckets.count;
          index++
        ) {
          storageWrapper.Remove(`inv-bucket-${index}`);
        }
        storageWrapper.Write('inv-buckets', newInvBuckets);
      }
    } finally {
      this.inventoryLoading = false;
    }
  }

  public async LoadInventoryDetailsWithoutDebounce(
    ids: string[]
  ): Promise<Inventory[]> {
    const callUrl: string = 'getInventoryDetails';
    const res = await apiFunctions.axios.post(callUrl, ids);
    return res.data;
  }

  public async LoadInventoryDetails(ids: string[]): Promise<Inventory[]> {
    const callUrl: string = 'getInventoryDetails';

    try {
      const res = await apiFunctions.Debounce(
        this.detailInventoryTimer,
        async () => {
          if (this.detailInventoryToken) {
            this.detailInventoryToken.cancel(
              'LoadInventoryDetails invoked again'
            );
          }
          this.detailInventoryToken = Axios.CancelToken.source();
          return await apiFunctions.axios.post(callUrl, ids, {
            cancelToken: this.detailInventoryToken.token,
          });
        }
      );

      return res.data as Inventory[];
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.info('operation canceled by user');
      }
      return [];
    }
  }

  public async LoadSimilarInventory(inv: Inventory): Promise<string[]> {
    const callUrl: string = 'getSimilarInventory';

    try {
      const filter = {
        Lifestyle: inv.Search.Lifestyle,
        VehicleType: inv.Search.VehicleType,
        VehicleSize: inv.Search.VehicleSize,
      };

      const res = await apiFunctions.axios.post(callUrl, filter);

      return res.data.docs as string[];
    } catch (error) {
      return [];
    }
  }

  public async LoadFeaturedInventory(): Promise<string[]> {
    const callUrl: string = 'getFeaturedInventory';

    try {
      const res = await apiFunctions.axios.get(callUrl);

      return res.data as string[];
    } catch (error) {
      Sentry.setContext('message', {
        message: 'Error while fetching Featured Inventory',
      });
      Sentry.captureException(error);
      return [];
    }
  }

  public async UpdateInventoryCounters(counters: Inventory): Promise<any> {
    const callUrl: string = 'updateInventoryCounters';
    try {
      await apiFunctions.axios.post(callUrl, counters);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  public async GetInventoryCounters(
    Id: string
  ): Promise<InventoryLookupStats | undefined> {
    const callUrl: string = 'getInventoryCounters';

    try {
      const res = await apiFunctions.Debounce(
        this.inventoryCounterTimer,
        async () => {
          if (this.inventoryCounterToken) {
            this.inventoryCounterToken.cancel(
              'GetInventoryCounters invoked again'
            );
          }
          this.inventoryCounterToken = Axios.CancelToken.source();
          return await apiFunctions.axios.post(
            callUrl,
            { _id: Id },
            {
              cancelToken: this.inventoryCounterToken.token,
            }
          );
        }
      );

      return res.data as InventoryLookupStats;
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.info('operation canceled by user');
      }

      return undefined;
    }
  }

  public async GetSoldInventory(): Promise<SoldInventory[] | undefined[]> {
    const callUrl: string = 'getSoldInventory';

    const res = await apiFunctions.axios.get(callUrl);
    return res.data;
  }

  public async getInventoryByStock(
    stockNumber: string
  ): Promise<Inventory[] | undefined> {
    try {
      const res = await apiFunctions.axios.get(
        `getInventoryByStockNumber?stockNumber=${stockNumber}`
      );

      return res.data;
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export const inventoryApi = new InventoryApi();
