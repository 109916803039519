import { DateTime } from 'luxon';
import {
  OpeningHours,
  OpeningDays,
  ExceptionDay,
  LookAheadMaxExceptionDays,
  Dictionary,
} from '../../../shared/global/types';
import { LocationDetails } from '../../inventory/search';

export enum weekDaysEnum {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
export const weekDays: weekDaysEnum[] = [
  weekDaysEnum.Monday,
  weekDaysEnum.Tuesday,
  weekDaysEnum.Wednesday,
  weekDaysEnum.Thursday,
  weekDaysEnum.Friday,
  weekDaysEnum.Saturday,
  weekDaysEnum.Sunday,
];
export const noTimePart = { hour: 0, minute: 0, second: 0, millisecond: 0 };
export const maxMonthsAllowedInCalendars = 3;

interface HourMinute {
  Hour: number;
  Minute: number;
}

interface MonthDay {
  Month: number;
  Day: number;
}

export interface ExceptionDayOpeningHours {
  Name: string;
  HoursLabel: string;
  DateLabel: string;
  Date: DateTime;
}

export function getTodayOpeningHours(
  value: OpeningDays | undefined,
  localTime: DateTime | string
): OpeningHours | undefined {
  const time =
    typeof localTime === 'string' ? DateTime.fromISO(localTime) : localTime;

  const dayOfWeek = weekDays[time.weekday - 1];
  return (value as any as Dictionary<OpeningHours>)[dayOfWeek];
}

export function getTodayOpeningHoursLabel(
  value: OpeningDays,
  localTime: DateTime | string
): string {
  return getOpeningHoursLabel(
    getTodayOpeningHours(value, localTime),
    value.Timezone
  );
}

export function getTodayOpeningHoursInTwelveLabel(
  value: OpeningDays,
  localTime: DateTime | string
): string {
  return getOpeningHoursInTwelveHours(
    getTodayOpeningHours(value, localTime),
    value.Timezone
  );
}

export function isOpenToday(value: OpeningDays, localTime: DateTime): boolean {
  return isOpen(getTodayOpeningHours(value, localTime), value.Timezone);
}

export function isOpen(value?: OpeningHours, timezone?: string) {
  if (!value || !timezone) {
    return false;
  }

  const localTime = DateTime.local();

  const openTime = getHourAndMinute(value.Open);
  let isOpen = false;
  if (openTime) {
    const targetOpenTimeToday = DateTime.local()
      .setZone(timezone, { keepLocalTime: true })
      .set({ hour: openTime.Hour, minute: openTime.Minute });

    isOpen = localTime > targetOpenTimeToday;
  }

  const closeTime = getHourAndMinute(value.Close);
  let isClosed = false;
  if (closeTime) {
    const targetCloseTimeToday = DateTime.local()
      .setZone(timezone, { keepLocalTime: true })
      .set({ hour: closeTime.Hour, minute: closeTime.Minute });

    isClosed = localTime > targetCloseTimeToday;
  }

  return isOpen && !isClosed;
}

export function getHoursInTargetTimezone(
  value?: string,
  timezone?: string,
  targetTimezone?: string
): DateTime | undefined {
  if (!value || !timezone) {
    return;
  }

  const hourAndMinute = getHourAndMinute(value);

  if (hourAndMinute) {
    return DateTime.local()
      .set(noTimePart)
      .setZone(timezone, { keepLocalTime: true })
      .set({ hour: hourAndMinute.Hour, minute: hourAndMinute.Minute })
      .setZone(targetTimezone || timezone);
  }
}

export function getExceptionDayHours(
  value: ExceptionDay,
  timezone: string
): ExceptionDayOpeningHours {
  if (!value) {
    return {} as ExceptionDayOpeningHours;
  }

  const result = { Name: value.Name } as ExceptionDayOpeningHours;

  const openTime = getHourAndMinute(value.Open);
  const closeTime = getHourAndMinute(value.Close);
  if (openTime && closeTime) {
    const targetOpenTimeToday = DateTime.local()
      .setZone(timezone, { keepLocalTime: true })
      .set({ hour: openTime.Hour, minute: openTime.Minute });

    const targetCloseTimeToday = DateTime.local()
      .setZone(timezone, { keepLocalTime: true })
      .set({ hour: closeTime.Hour, minute: closeTime.Minute });

    result.HoursLabel =
      `${targetOpenTimeToday.hour}`.padStart(2, '0') +
      ':' +
      `${targetOpenTimeToday.minute}`.padStart(2, '0') +
      ' to ' +
      `${targetCloseTimeToday.hour}`.padStart(2, '0') +
      ':' +
      `${targetCloseTimeToday.minute}`.padStart(2, '0');
  } else {
    result.HoursLabel = 'Closed';
  }

  const day = getDayAndMonth(value.MonthDay);
  if (day) {
    const today = DateTime.local()
      .setZone(timezone)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    result.Date = DateTime.local()
      .setZone(timezone)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .set({ month: day.Month, day: day.Day });
    if (result.Date.diff(today, 'days').days < 0) {
      result.Date = result.Date.set({ year: result.Date.year + 1 });
    }

    result.DateLabel = `${result.Date.day}${getDayOfMonthOrdinalIndicator(
      result.Date.day
    )} ${result.Date.monthLong}`;
  }

  return result;
}

export function getExceptionDate(
  exceptionDay: ExceptionDay
): DateTime | undefined {
  if (!exceptionDay) {
    return;
  }
  let date = DateTime.local().set(noTimePart);
  if (exceptionDay.Year) {
    date = date.set({ year: Number.parseInt(exceptionDay.Year, 10) });
  }
  if (exceptionDay.MonthDay) {
    const day = getDayAndMonth(exceptionDay.MonthDay);
    if (day) {
      date = date.set({ month: day.Month, day: day.Day });
    }
  }
  return date;
}

function getDayOfMonthOrdinalIndicator(value: number): string {
  const digit = value % 10;
  const tens = ((value - digit) % 100) / 10;
  if (digit === 1 && tens !== 1) {
    return 'st';
  } else if (digit === 2 && tens !== 1) {
    return 'nd';
  } else if (digit === 3 && tens !== 1) {
    return 'rd';
  } else {
    return 'th';
  }
}

function getDayAndMonth(value: string): MonthDay | undefined {
  if (!value) {
    return;
  }

  const split = value.indexOf('-');

  const result: MonthDay = {
    Month: 0,
    Day: 0,
  };

  if (split) {
    result.Month = Number.parseInt(value.substr(0, split), 10);
    result.Day = Number.parseInt(value.substr(split + 1), 10);
  }

  return result;
}

export function getExceptionDays(
  value: ExceptionDay[],
  timezone: string
): ExceptionDayOpeningHours[] {
  if (!value) {
    return [];
  }

  return value
    .map((x) => getExceptionDayHours(x, timezone))
    .filter((x) => x.Date)
    .filter((x) => {
      const delta = x.Date.diffNow('days').days;
      return delta <= LookAheadMaxExceptionDays;
    });
}

export function getOpeningHoursLabel(
  value?: OpeningHours,
  timezone?: string
): string {
  if (!value || !timezone) {
    return 'Closed';
  }

  const targetOpenTimeToday = getHoursInTargetTimezone(value.Open, timezone);
  const targetCloseTimeToday = getHoursInTargetTimezone(value.Close, timezone);

  if (targetOpenTimeToday && targetCloseTimeToday) {
    return (
      `${targetOpenTimeToday.hour}`.padStart(2, '0') +
      ':' +
      `${targetOpenTimeToday.minute}`.padStart(2, '0') +
      ' to ' +
      `${targetCloseTimeToday.hour}`.padStart(2, '0') +
      ':' +
      `${targetCloseTimeToday.minute}`.padStart(2, '0')
    );
  }

  return 'Closed';
}

export function getOpeningHoursInTwelveHours(
  value?: OpeningHours,
  timezone?: string
): string {
  if (!value || !timezone) {
    return 'Closed';
  }

  const openTimeToday =
    `${getHoursInTargetTimezone(value.Open, timezone)?.hour}`.padStart(2, '0') +
    ':' +
    `${getHoursInTargetTimezone(value.Open, timezone)?.minute}`.padStart(
      2,
      '0'
    );
  const CloseTimeToday =
    `${getHoursInTargetTimezone(value.Close, timezone)?.hour}`.padStart(
      2,
      '0'
    ) +
    ':' +
    `${getHoursInTargetTimezone(value.Close, timezone)?.minute}`.padStart(
      2,
      '0'
    );

  if (openTimeToday && CloseTimeToday) {
    return (
      `${DateTime.fromISO(openTimeToday).toFormat('t')}` +
      '-' +
      `${DateTime.fromISO(CloseTimeToday).toFormat('t')}`
    );
  }

  return 'Closed';
}

export function getTimezone(value: OpeningDays): string {
  const timezone = value.Timezone;
  const split = timezone.indexOf('/');

  if (split) {
    return timezone.substr(split + 1);
  }

  return '';
}

function getHourAndMinute(value: string): HourMinute | undefined {
  if (!value) {
    return;
  }

  const split = value.indexOf(':');

  const result: HourMinute = {
    Hour: 0,
    Minute: 0,
  };

  if (split) {
    result.Hour = Number.parseInt(value.substr(0, split), 10);
    result.Minute = Number.parseInt(value.substr(split + 1), 10);
  }

  return result;
}

export function hasSpecialTradingHours(
  location: LocationDetails | undefined
): boolean {
  const exceptionHours = location?.OpeningHours?.Exception;
  if (!exceptionHours) return false;

  const exceptionDays = getExceptionDays(
    exceptionHours,
    location?.OpeningHours?.Timezone || ''
  );

  return exceptionDays.length > 0;
}
