import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router/types';
import './SideMenu.scss';
import {
  VBtn,
  VIcon,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
} from 'vuetify/lib';
import MenuItem from './MenuItem';
import Logo from './Logo';
import { RouteNames } from '@/router/route-consts';
import { statesWithStores, StateWithStores } from '@/constants/States';
import { LocationModule } from '@/store/location';
import { LocationDetails } from '~/../shared/inventory/search';

@Component({
  name: 'side-menu',
  components: {
    MenuItem,
    VBtn,
    VIcon,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
  },
})
export default class extends Vue {
  @Prop({ required: true }) public closeNav: undefined | Function;
  public clearScrollPosition: boolean = true;
  @LocationModule.State public locations!: LocationDetails[];

  public get states() {
    return statesWithStores.sort((a, b) => a.state.localeCompare(b.state));
  }

  public hasStores(state: string): boolean {
    const stores = statesWithStores.find((item) => {
      return item.state === state;
    });

    return stores?.store.length ? stores?.store.length > 1 : false;
  }

  public locationRouter(state: StateWithStores): Location {
    if (this.hasStores(state.state)) {
      return {
        name: 'state-location',
        params: {
          state: state.state,
        },
      };
    } else {
      const location = this.locations.find(
        (location) => state.store[0] === location.Key
      );
      return {
        name: 'locations-details',
        params: {
          locationUrl: location?.LocationUrl || '',
        },
      };
    }
  }

  public stateName(state: string): string {
    const stateNames = state.split('-');
    return stateNames
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ');
  }

  public render() {
    return (
      <div class="side-menu">
        <div class="px-4 pt-3 d-flex flex-column align-start">
          <v-btn class="px-0 mb-2" icon text on={{ click: this.closeNav }}>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <Logo />
        </div>

        <menu-item
          class="py-6"
          block
          color="primary"
          icon="car"
          to={RouteNames.SearchRouteName}
          title="Shop"
          clearScrollPosition={this.clearScrollPosition}
        />
        <menu-item
          class="py-6"
          icon="bank"
          block
          color="primary"
          to={RouteNames.SellRouteName}
          title="Sell/Trade"
        />
        <menu-item
          class="py-6"
          icon="wrench"
          block
          color="primary"
          to={RouteNames.ServiceRouteName}
          title="Service"
        />
        <menu-item
          class="py-6"
          icon="currency-usd"
          block
          color="primary"
          to={RouteNames.FinanceRouteName}
          title="Finance"
        />
        <menu-item
          class="py-6"
          icon="heart"
          block
          color="primary"
          to={RouteNames.FavouritesRouteName}
          title="Favourites"
        />
        <menu-item
          class="py-6"
          icon="book-open-page-variant"
          block
          color="primary"
          to={RouteNames.BlogRouteName}
          title="Blog"
        />
        <v-expansion-panels accordion flat tile color="var(--v-primary-base)">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="black" class="mr-2">
                mdi-map-marker
              </v-icon>
              <span class="text-capitalize font-normal black--text font-weight-bold store-menu">
                Stores
              </span>
            </v-expansion-panel-header>
            {this.states.map((state) => (
              <v-expansion-panel-content>
                <v-btn plain to={this.locationRouter(state)}>
                  <span class="text-capitalize font-normal">
                    {this.stateName(state.state)}
                  </span>
                </v-btn>
              </v-expansion-panel-content>
            ))}
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels accordion flat tile color="var(--v-primary-base)">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="text-capitalize font-normal black--text font-weight-bold">
                More
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn plain href="/buy-used-cars-online">
                <span class="text-capitalize font-normal">
                  Buy used cars online
                </span>
              </v-btn>
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <v-btn plain href="/car-insurance">
                <span class="text-capitalize font-normal">Car insurance</span>
              </v-btn>
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <v-btn plain href="/faqs">
                <span class="text-capitalize font-normal">
                  FAQs and support
                </span>
              </v-btn>
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <v-btn plain href="/car-subscription">
                <span class="text-capitalize font-normal">
                  Car Subscription
                </span>
              </v-btn>
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <v-btn plain href="/buy/used-cars/under-10000">
                <span class="text-capitalize font-normal">
                  Used cars under $10,000
                </span>
              </v-btn>
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <v-btn plain href="/buy/used-cars/under-15000">
                <span class="text-capitalize font-normal">
                  Used cars under $15,000
                </span>
              </v-btn>
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <v-btn plain href="/buy/used-cars/under-20000">
                <span class="text-capitalize font-normal">
                  Used cars under $20,000
                </span>
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    );
  }
}
