import { FinanceOptions } from '../../../../../shared/inventory/search';
import { PresentValue } from '../../../../../shared/finance';
import {
  PriceOptions,
  IntervalPriceOptions,
  priceOptions as defaultPriceOptions,
} from '@/features/filter/types';

export function GetTermLabel(y: number): string {
  const years = Math.trunc(y / 12);

  function getYears(x: number) {
    if (x === 1) {
      return '1 year';
    }
    return `${x} years`;
  }

  function getMonths(x: number) {
    if (x === 1) {
      return '1 month';
    }
    return `${x} months`;
  }

  if (y % 12 === 0) {
    return getYears(years);
  }

  const months = y - years * 12;
  return `${getYears(years)} ${getMonths(months)}`;
}

export function CalculateTotalValue(
  financeOptions: FinanceOptions,
  priceOptions: PriceOptions
) {
  const paymentType = GetIntervalPriceOptions(priceOptions);
  if (paymentType) {
    paymentType.TotalValue = GetEstimatedTotalValue(
      financeOptions,
      priceOptions
    );
  }
}

export function GetEstimatedPresentValue(
  financeOptions: FinanceOptions,
  priceOptions: PriceOptions,
  intervalPriceOptions?: IntervalPriceOptions
): number | undefined {
  const paymentType =
    intervalPriceOptions || GetIntervalPriceOptions(priceOptions);
  if (!paymentType) {
    return;
  }
  let index = paymentType?.LoanLength;
  if (index === undefined) {
    index = defaultPriceOptions.LoanLength;
  }
  const loanOptions = priceOptions?.LoanLengthOptions;
  const payment = paymentType.Payment;

  if (index === undefined || !loanOptions || !payment) {
    return undefined;
  }

  const months = loanOptions[index];
  if (!months) {
    return undefined;
  }

  const periodsInYear = GetPeriodsInYear(priceOptions, intervalPriceOptions);
  const periods = (months / 12) * periodsInYear;
  const loanServiceFee =
    ((financeOptions.MonthlyLoanFee || 0) * 12) / periodsInYear;
  const loanPayment = payment - loanServiceFee;

  const presentValue = PresentValue(
    financeOptions.DefaultInterestRate / periodsInYear,
    periods,
    -loanPayment
  );

  return presentValue;
}

export function GetEstimatedTotalValue(
  financeOptions: FinanceOptions,
  priceOptions: PriceOptions,
  intervalPriceOptions?: IntervalPriceOptions
): number | undefined {
  const estimatedPresentValue = GetEstimatedPresentValue(
    financeOptions,
    priceOptions,
    intervalPriceOptions
  );

  if (estimatedPresentValue) {
    let finalValue = estimatedPresentValue;

    intervalPriceOptions =
      intervalPriceOptions || GetIntervalPriceOptions(priceOptions);
    if (!intervalPriceOptions) {
      return;
    }

    const downPayment = intervalPriceOptions.UpfrontDeposit;
    if (downPayment) {
      finalValue += downPayment;
    }

    const purchaseFees = financeOptions.PurchaseFees;
    if (purchaseFees) {
      finalValue -= purchaseFees;
    }

    if (finalValue > 4000) {
      return finalValue;
    }
  }
}

export function GetIntervalPriceOptions(
  priceOptions: PriceOptions
): IntervalPriceOptions | undefined {
  const priceType = priceOptions?.PriceType;

  if (priceType === 1) {
    return priceOptions.Monthly;
  }

  if (priceType === 2) {
    return priceOptions.Fortnightly;
  }

  if (priceType === 3) {
    return priceOptions.Weekly;
  }
}

export function GetPeriodsInYear(
  priceOptions: PriceOptions,
  intervalPriceOptions?: IntervalPriceOptions
): number {
  if (!priceOptions) {
    return 52;
  }

  const priceType = priceOptions.PriceType;

  if (intervalPriceOptions) {
    if (priceOptions.Monthly === intervalPriceOptions) {
      return 12;
    }
    if (priceOptions.Fortnightly === intervalPriceOptions) {
      return 26;
    }
    if (priceOptions.Weekly === intervalPriceOptions) {
      return 52;
    }
  }

  if (priceType === 1) {
    return 12;
  }

  if (priceType === 2) {
    return 26;
  }

  return 52;
}
