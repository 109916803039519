import { Context } from '@nuxt/types';

const UNSUPPORTED_PAGE = `/unsupported.html`;

export default function ({ req, redirect }: Context) {
  const isIE = (req?.headers?.['user-agent'] || '').match(
    /Trident.*rv:11./
  )?.length;

  if (isIE) {
    return redirect(UNSUPPORTED_PAGE);
  }
}
