import { Dictionary } from '../../../../shared/global/types';

export interface WebpSupport {
  webp: boolean;
  'webp.alpha': boolean;
  'webp.animation': boolean;
  'webp.lossless': boolean;
}

export const webpSupport: WebpSupport = {
  webp: false,
  'webp.alpha': false,
  'webp.animation': false,
  'webp.lossless': false,
};

interface ImageType {
  uri: string;
  type: keyof WebpSupport;
}

export function TestImageCapability(
  image: ImageType,
  callback: (x: string, supported: boolean) => void
) {
  if (!process.client) {
    callback(image.type, false);
    return;
  }

  const t = new Image();
  function onLoad(ev: string | Event) {
    if (!ev || typeof ev === 'string' || ev instanceof String) {
      callback(image.type, false);
    } else {
      const success = ev.type === 'load' ? t.width === 1 : false;
      callback(image.type, success);
    }
    t.onerror = null;
    t.onload = null;
  }
  t.onerror = onLoad;
  t.onload = onLoad;
  t.src = image.uri;
}

export const images: ImageType[] = [
  {
    uri: 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=',
    type: 'webp',
  },
  {
    uri: 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==',
    type: 'webp.alpha',
  },
  {
    uri: 'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    type: 'webp.animation',
  },
  {
    uri: 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=',
    type: 'webp.lossless',
  },
];

for (const image of images) {
  TestImageCapability(image, (imageType: string, supported: boolean) => {
    (webpSupport as any as Dictionary<boolean>)[imageType] = supported;
  });
}

export default webpSupport;
