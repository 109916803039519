import { Component } from 'vue-property-decorator';
import { PagesWithMetaTagsFromTheServer } from '@/components/pages-with-metatags-from-the-server/PagesWithMetaTagsFromTheServer';
import PopularQuestions from '@/features/faqs-landing-page/popular-questions/PopularQuestions';
import Faqs from '@/components/Faqs/Faqs';
import FindAService from '@/features/faqs-landing-page/find-a-service/FindAService';
import LocateUs from '@/features/home-page/locate-us/LocateUs';
@Component({
  name: 'faqs-landing-page',
  components: {
    PopularQuestions,
    Faqs,
    FindAService,
    LocateUs,
  },
})
export default class FaqsLandingPage extends PagesWithMetaTagsFromTheServer {}
