import { VNode, CreateElement } from 'vue/types/umd';
import { FAQAnswer } from '../../../../../../shared/global/faq';

export function faqCreateBulletItem(
  h: CreateElement,
  answer: FAQAnswer
): VNode {
  return h(
    'p',
    {
      class: 'body-1-new ink-medium--text',
      style: {
        'text-indent': '-35px',
        'padding-left': '35px',
        'margin-bottom': '0',
      },
    },
    [
      h(
        'span',
        {
          style: {
            'padding-left': '11px',
            'padding-right': '18px',
          },
        },
        ['•']
      ),
      answer.content,
    ]
  );
}
