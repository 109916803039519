import { RouteConfig } from 'vue-router/types';

export const enquiryRoutes: RouteConfig[] = [
  {
    path: '/buy/finance/buy-enquiry-finance',
    name: 'financeenquiry',
    component: () =>
      import('@/features/enquiries/finance-enquire/FinanceEnquire.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/sell/sell-enquiry-get-appraisal/:StepNumber?',
    name: 'sellenquire',
    props: (route) => ({ StepNumber: route.params.StepNumber }),
    component: () =>
      import('@/features/enquiries/sell-enquire/SellEnquire.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/sell/sell-enquiry-get-appraisal/thank-you',
    name: 'thankyousell',
    component: () =>
      import('@/features/enquiries/sell-enquire/thank-you/ThankYou.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/thank-you',
    name: 'thank-you-general',
    component: () =>
      import('@/features/enquiries/thank-you/ThankYouGeneral.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/thank-you/:Type?/:VehicleId/',
    name: 'thank-you',
    props: (route) => ({
      VehicleId: route.params.VehicleId,
      Type: route.params.Type,
    }),
    component: () =>
      import('@/features/enquiries/thank-you/ThankYou').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/buy-enquiry-price-match/:VehicleId',
    name: 'pricematchenquire',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('@/features/enquiries/price-match-enquire/PriceMatchEnquire').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/buy-enquiry-finance/:VehicleId',
    name: 'financeenquire',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('@/features/enquiries/finance-enquire/FinanceEnquire.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/buy-enquiry-in-person/:VehicleId',
    name: 'seethiscar',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('../features/enquiries/see-this-car/SeeThisCar.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/buy-enquiry-car-question/:VehicleId',
    name: 'generalenquire',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('@/features/enquiries/general-enquire/GeneralEnquire.vue').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/buy/used-cars/details/sold/buy-enquiry-car-question/:VehicleId',
    name: 'generalsoldenquire',
    props: (route) => ({ VehicleId: route.params.VehicleId }),
    component: () =>
      import('@/features/enquiries/sold-enquiry/SoldEnquiry.vue').then(
        (m) => m.default || m
      ),
  },
];
