import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { setContext } from '@sentry/browser';
import { Dictionary } from '../../../shared/global/types';
import { Inventory } from '../../../shared/inventory';
import { storageWrapper } from '@/tools/storage';
import { CacheBuckets } from '@/components/cache/types';
import { trackLikeButton } from '@/helpers/analytics-helper';
import { CreateFilters } from '@/features/inventory/filterBuilders/CreateFilters';
import { inventoryApi } from '@/features/inventory/api';
import { Selections } from '@/features/filter/types';
import {
  FavDetails,
  SimilarCars,
  inventoryControl,
  InventoryDetailStats,
} from '@/features/inventory/types';
import {
  LoadNewInventory,
  UpdateInventoryDetails,
  EnsureInventoryStructure,
  ReadInventoryFromStorage,
} from '@/features/inventory/helpers/load.new.inventory';

export const name = 'inventoryStore';

const inventoryDetails: Inventory[] = [];
const cacheDuration = 5 * 60 * 1000;
if (process.client) window.cacheDuration = cacheDuration;

@Module({ stateFactory: true })
export default class InventoryStore extends VuexModule {
  public InventoryList: Dictionary<Inventory> = {};
  public InventoryDetails: Inventory[] = inventoryDetails;
  public InventoryDetailStats: InventoryDetailStats[] = [];
  public SearchResult: string[] = [];
  public RecentlyBought: string[] = [];
  public Favourites: Dictionary<FavDetails> = {};
  public TestForBuckets = 0;
  public LocalStorageSize = 0;
  public SimilarCars: Dictionary<string[]> = {};
  public FeaturedCars: string[] = [];
  public FilterIsLoading: boolean = true;
  public LastInventoryDownload = 0;

  public get compareCount() {
    let count = 0;
    for (const key in this.Favourites) {
      if (
        this.Favourites &&
        Object.prototype.hasOwnProperty.call(this.Favourites, key)
      ) {
        const favourite = this.Favourites[key];
        if (favourite.Compare) {
          count++;
        }
      }
    }
    return count;
  }

  @Mutation public UpdateLastInventoryDownload(lastInventoryDownload: number) {
    this.LastInventoryDownload = lastInventoryDownload;
  }

  @Mutation public Init() {
    const searchResult = storageWrapper.Read<string[]>(`${name}.SearchResult`);
    if (searchResult) {
      this.SearchResult = searchResult;
    }

    const inventoryDetails = storageWrapper.Unzip<Inventory[]>(
      `${name}.InventoryDetails`
    );
    if (inventoryDetails) {
      this.InventoryDetails.push(...inventoryDetails);
    }

    const invList = ReadInventoryFromStorage();
    if (invList) {
      LoadNewInventory(invList, this.InventoryList);
    }

    const invDetails = storageWrapper.Unzip<Inventory[]>(
      `${name}.InventoryDetails`
    );
    if (invDetails) {
      this.InventoryDetails.push(...invDetails);
      for (const inv of invDetails) {
        // Now update summary bits with data from the detailed inventory
        if (!this.InventoryList[inv._id]) {
          Vue.set(this.InventoryList, inv._id, inv);
        } else {
          UpdateInventoryDetails(this.InventoryList[inv._id], inv);
        }
      }
    }

    const storedFavourites = storageWrapper.Read<Dictionary<FavDetails>>(
      `${name}.Favourites`
    );

    if (storedFavourites) {
      this.Favourites = storedFavourites;
      Object.entries(storedFavourites).forEach(([id, value]) => {
        if (this.InventoryList[id]) {
          const fav: FavDetails = {
            Compare: value.Compare,
            IsFavourite: value.IsFavourite,
          };
          Vue.set(this.Favourites, id, fav);
        } else {
          Vue.delete(this.Favourites, id);
        }
      });

      if (Object.keys(this.Favourites).length !== 0) {
        storageWrapper.Write(`${name}.Favourites`, this.Favourites);
      } else {
        storageWrapper.Remove(`${name}.Favourites`);
      }
    }

    this.RecentlyBought = this.SearchResult;
    this.LocalStorageSize = storageWrapper.GetStorageSize();
  }

  @Mutation public UpdateFilteredInventory(searchResult: string[]) {
    if (searchResult) {
      this.SearchResult = searchResult;
    } else {
      this.SearchResult.splice(0, this.SearchResult.length);
    }
    storageWrapper.Write(`${name}.SearchResult`, this.SearchResult);
  }

  @Mutation public ToggleFavourite(id: string) {
    if (this.Favourites[id]) {
      Vue.delete(this.Favourites, id);
      inventoryApi.UpdateInventoryCounters({
        _id: id,
        Stats: { Favourite: -1 },
      } as Inventory);
    } else {
      const fav: FavDetails = { Compare: false, IsFavourite: true };
      Vue.set(this.Favourites, id, fav);
      inventoryApi.UpdateInventoryCounters({
        _id: id,
        Stats: { Favourite: 1 },
      } as Inventory);

      trackLikeButton(this.InventoryList[id]);
    }

    storageWrapper.Write(`${name}.Favourites`, this.Favourites);
  }

  @Mutation public ToggleCompare(id: string) {
    if (this.Favourites[id]) {
      this.Favourites[id].Compare = !this.Favourites[id].Compare;
    } else {
      const fav: FavDetails = { Compare: true, IsFavourite: true };
      Vue.set(this.Favourites, id, fav);
    }

    storageWrapper.Write(`${name}.Favourites`, this.Favourites);
  }

  @Mutation public AddList(invs: Inventory[]) {
    if (!invs) {
      return;
    }

    const invBuckets = storageWrapper.Read<CacheBuckets>('inv-buckets') || {
      count: 0,
    };
    this.TestForBuckets = invBuckets.count;

    LoadNewInventory(invs, this.InventoryList);

    this.LocalStorageSize = storageWrapper.GetStorageSize();
  }

  // TODO is this Remove ever called at all??
  @Mutation public Remove(inv: Inventory) {
    delete this.InventoryList[inv._id];
    delete this.Favourites[inv._id];
  }

  @Mutation public UpdateInventory(invs: Inventory[]) {
    if (!invs) {
      return;
    }
    const oldInvs = this.InventoryDetails.map((x) => x);
    this.InventoryDetails.splice(0, oldInvs.length);
    for (const inv of invs) {
      EnsureInventoryStructure(inv);

      const existing = oldInvs.find((x) => x._id === inv._id);
      const newInv = existing || inv;

      // DO NOT PUSH THIS TO PROD
      // inv.Particulars.ImageUrls.push({
      //   Url: 'Z2-V664QnAU8rhs4_TSK4LwbMEReL1xBwUTeFh356X6twO8QOKKuPTaexybFVdIjnViWE1tgrc74s1ILjJNQ_n7NtMNfitpQ',
      //   Index: inv.Particulars.ImageUrls.length + 1,
      //   Is360Image: true,
      // } as ImageUrl);

      UpdateInventoryDetails(newInv, inv);
      this.InventoryDetails.push(newInv);

      // Now update summary bits with data from the detailed inventory
      if (!this.InventoryList[newInv._id]) {
        Vue.set(this.InventoryList, newInv._id, newInv);
      } else {
        UpdateInventoryDetails(this.InventoryList[newInv._id], newInv);
      }
    }
    storageWrapper.Zip(`${name}.InventoryDetails`, this.InventoryDetails);
  }

  @Mutation public UpdateSimilarInventory(similarCars: SimilarCars) {
    if (
      similarCars &&
      similarCars.Id &&
      Array.isArray(similarCars.Similar) &&
      similarCars.Similar.length
    ) {
      Vue.set(this.SimilarCars, similarCars.Id, similarCars.Similar);
    }
  }

  @Mutation public SetFeaturedInventory(featuredCars: string[]) {
    this.FeaturedCars.splice(0, this.FeaturedCars.length);
    this.FeaturedCars.push(...featuredCars);
  }

  @Mutation public SetFilterIsLoading(value: boolean) {
    this.FilterIsLoading = value;
  }

  @Action({ rawError: true })
  public async FetchAll(): Promise<any> {
    this.context.commit('SetFilterIsLoading', true);
    try {
      for await (const x of inventoryApi.LoadInventory()) {
        this.context.commit('AddList', x);
        this.context.commit('UpdateLastInventoryDownload', Date.now());
      }
    } finally {
      this.context.commit('SetFilterIsLoading', false);
    }
  }

  @Action({ rawError: true })
  public async LoadInventory(input: string | string[]) {
    try {
      const invs: string[] = [];
      inventoryDetails.forEach((x) => {
        if (!invs.includes(x._id)) {
          invs.push(x._id);
        }
      });
      let refreshNeeded = false;

      const ids: string[] = [];
      if (Array.isArray(input)) {
        ids.push(...input);
      } else {
        ids.push(input);
      }

      for (const id of ids) {
        if (invs.includes(id)) {
          const threshold = Date.now() - 5 * 60 * 1000;
          if (inventoryControl.lastInvDetailsUpdate < threshold) {
            refreshNeeded = true;
          }
        } else {
          invs.splice(29);
          invs.unshift(id);
          refreshNeeded = true;
        }
      }

      if (refreshNeeded) {
        inventoryControl.lastInvDetailsUpdate = Date.now();
        const detailedInvs = await inventoryApi.LoadInventoryDetails(invs);
        this.context.commit('UpdateInventory', detailedInvs);
      }
    } catch (error) {
      // TODO: It doesn't supposed to throw an error? since it is like a 404?
      // In case we cannot get the detailed inventory we just do nothing.
      setContext('apiError', { error });
      throw error;
    }
  }

  @Action({ rawError: true }) public async FilterInventory(
    selections: Selections
  ) {
    // Prepare filters for API call
    const filter = CreateFilters(selections);
    this.context.commit('SetFilterIsLoading', true);

    try {
      const newFilter = await inventoryApi.FindInventoryItemsDebounced(filter);
      this.context.commit('SetFilterIsLoading', false);
      this.context.commit('UpdateFilteredInventory', newFilter);
    } catch {
      // TODO: Should I capture this exception? is that an expected exception?
      // In case we cannot get the filtered inventory we just do nothing.
    }

    if (this.LastInventoryDownload < Date.now() - cacheDuration) {
      this.context.dispatch('FetchAll');
    }
  }

  @Action({ rawError: true, commit: 'UpdateSimilarInventory' })
  public async LoadSimilarInventory(
    inv: Inventory
  ): Promise<SimilarCars | undefined> {
    try {
      const similar = await inventoryApi.LoadSimilarInventory(inv);
      const res: SimilarCars = { Id: inv._id, Similar: similar };
      return res;
    } catch {
      // TODO: Should I capture this exception? is that an expected exception?
      // In case we cannot get similar inventory, do we just do nothing?.
    }
  }

  @Action({ rawError: true, commit: 'SetFeaturedInventory' })
  public async LoadFeaturedInventory(): Promise<string[]> {
    try {
      return await inventoryApi.LoadFeaturedInventory();
    } catch {
      // TODO: Should I capture this exception? is that an expected exception?
      // In case we cannot get similar inventory do we just do nothing?.
      return [];
    }
  }
}

export const InventoryModule = namespace(name);
