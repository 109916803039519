import { LocationDetails } from '../../../../shared/inventory/search';

// TODO: remove LocationSelection and LocationDetails.
export interface LocationSelection {
  Selected: string | undefined;
  Locations: LocationDetails[];
}

export const locationDetails: LocationSelection = {
  Selected: undefined,
  Locations: [],
};

export interface MapOptions {
  zoomControl: boolean;
  attributionControl: boolean;
  zoomSnap: boolean;
}

export interface Markers {
  id: string;
  tooltip: string;
  draggable: boolean;
  visible: boolean;
}

export interface LocationsMap {
  mapOptions: MapOptions;
  zoom: number;
  minZoom: number;
  maxZoom: number;
  zoomPosition: string;
  attributionPosition: string;
  layersPosition: string;
  attributionPrefix: string;
  imperial: boolean;
  Positions: string[];
  markers: Markers[];
  name: string;
  visible: boolean;
  attribution: string;
  url: string;
  center: number[];
}

export const locationsMap: LocationsMap = {
  mapOptions: {
    zoomControl: true,
    attributionControl: false,
    zoomSnap: true,
  },
  zoom: 3,
  minZoom: 1,
  maxZoom: 20,
  zoomPosition: 'topleft',
  attributionPosition: 'bottomright',
  layersPosition: 'topright',
  attributionPrefix: 'Vue2Leaflet',
  imperial: false,
  Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
  name: 'OpenStreetMap',
  visible: true,
  attribution:
    '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  center: [-25.274399, 133.775131],
  markers: [
    {
      id: 'Brooklyn',
      tooltip: 'Brooklyn',
      draggable: false,
      visible: true,
    },
    {
      id: 'Cannington',
      tooltip: 'Cannington',
      draggable: false,
      visible: true,
    },
    {
      id: 'Hendra',
      tooltip: 'Hendra',
      draggable: true,
      visible: true,
    },
    {
      id: 'Joondalup',
      tooltip: 'Joondalup',
      draggable: true,
      visible: true,
    },
    {
      id: 'Penrith',
      tooltip: 'Penrith',
      draggable: true,
      visible: true,
    },
  ],
};
