export interface Promo {
  PromoID: string;
  Image: string;
  Title: string;
  Text: string;
  Text2: string;
  Link: string;
  LinkText: string;
  SecondaryLink: string;
  SecondaryLinkText: string;
}

export const promoOptions: (string | Promo)[] = [
  {
    PromoID: 'QualityVerified',
    Image: '',
    Title: 'easyauto123 Quality Verified\u2122',
    Text: 'All easyauto123 cars undergo a comprehensive 100-point safety and quality check, so you only get the best.',
    Text2: '',
    Link: '',
    LinkText: 'How does it work?',
    SecondaryLink: '',
    SecondaryLinkText: '',
  },
  {
    PromoID: 'BestPrice',
    Image: '/img/promo/bcpg-mark.png',
    Title: "The Best Car Price. That's our guarantee.",
    Text: 'Wouldn’t it be great if you didn’t have to argue the price of your car and just got the best price upfront? Yeah, we think so too.',
    Text2:
      'And if we get it wrong – no worries, our <a href="/terms-of-use/easyauto123_BestCarPriceGuarantee_Terms.pdf" target="_blank">Best Car Price Guarantee*</a> is there to make sure we make it right.',
    Link: '',
    LinkText: '',
    SecondaryLink: '/terms-of-use/easyauto123_BestCarPriceGuarantee_Terms.pdf',
    SecondaryLinkText: '*Best Car Price Guarantee terms and conditions (PDF)',
  },
];
