export interface NavOptions {
  drawer?: boolean;
  location?: boolean;
  modalLocation?: boolean;
}

export interface LocationPickerOptions {
  ShowSellPhoneNumber?: boolean;
  ShowPreferredLocation?: boolean;
}

export const navOptions: NavOptions = {
  drawer: false,
  location: false,
  modalLocation: false,
};
