import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterSubscription(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Subscription.')) {
    const key = filterKey.substring(13);
    if (key) {
      if (filter.Search.Subscription) {
        filter.Search.Subscription.push(key);
      } else {
        filter.Search.Subscription = [key];
      }
    }
    return true;
  }
  return false;
}
