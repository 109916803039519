










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'svg-icon-print',
})
export default class Registration extends Vue {
  @Prop({ default: '--v-icon-base' }) public color!: string;
}
