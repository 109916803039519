export interface NumberDecimal {
  $numberDecimal: string;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface ValuePair {
  Value: number;
  Description?: string;
}

export function IsValuePair(o: any): o is ValuePair {
  return o instanceof Object && 'Value' in o;
}

export interface UniqueKey {
  _id: string;
  NextKey: number;
}

export interface Identifier {
  _id: string;
  CreatedTime: Date;
  ModifiedTime: Date;
}

export interface Coordinates {
  Latitude: number;
  Longitude: number;
}

export interface Address {
  StreetNumber?: string;
  Street?: string;
  Suburb?: string;
  City?: string;
  State?: string;
  StateShort?: string;
  PostCode?: string;
  Coordinates?: Coordinates;
}

export interface OpeningHours {
  Open: string;
  Close: string;
}

export interface PhoneNumbers {
  CustomerConnectCenter?: string;
  Store?: string;
  ServiceCentre?: string;
}

export const LookAheadMaxExceptionDays = 14;

export interface ExceptionDay {
  Name: string;
  Open: string;
  Close: string;
  Year?: string;
  MonthDay: string;
}

export interface OpeningDays {
  Timezone: string;
  Exception?: ExceptionDay[];
  Monday?: OpeningHours;
  Tuesday?: OpeningHours;
  Wednesday?: OpeningHours;
  Thursday?: OpeningHours;
  Friday?: OpeningHours;
  Saturday?: OpeningHours;
  Sunday?: OpeningHours;
}

export const GlobalExternalUrls = {
  PrivacyPolicy: {
    Url: 'https://www.eagersautomotive.com.au/privacy-policy',
    Name: 'www.eagersautomotive.com.au',
  },
};

export interface ErrorDetail {
  Code: string;
  Message: string;
}

export interface ProblemDetails {
  Errors: Dictionary<ErrorDetail[]>;
}

export interface WorkProcess {
  Step: string;
  ImageUrl: string;
  Title: string;
  Description: string;
}

export interface PageMetaData {
  Title?: string;
  Description?: string;
  Keywords?: string;
  Slug?: string;
  NoIndex?: boolean;
  Canonical?: string;
}
