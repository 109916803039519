import { InventoryLookupStats } from '../../../../shared/inventory';

export interface InventoryControl {
  lastInvDetailsUpdate: number;
}

export const inventoryControl: InventoryControl = {
  lastInvDetailsUpdate: 0,
};

export interface FavDetails {
  IsFavourite: boolean;
  Compare: boolean;
}

export interface SimilarCars {
  Id: string;
  Similar: string[];
}

export interface InventoryDetailStats extends InventoryLookupStats {
  lastUpdated: number;
}
