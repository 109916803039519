import { Location } from 'vue-router/types';
import {
  MakesWithModels,
  RangeData,
  FinanceOptions,
  LocationDetails,
  KeyValuePair,
} from '../../../../shared/inventory/search';

export interface BodyTypeList {
  BodyType: string[];
}

export interface CylindersOptions {
  Cylinders: string[];
}

export const cylinders: string[] = [
  'Cylinder one',
  'Cylinder two',
  'Cylinder three',
  'Cylinder four',
];

export interface DoorsOptions {
  Doors: number[];
}

export const doors: number[] = [1, 5];

export interface FeaturesOptions {
  Features: string[];
}

export const features: string[] = [
  'Feature one',
  'Feature two',
  'Feature three',
  'Feature four',
  'Feature five',
  'Feature six',
];

export interface RangeOptions {
  MinValue: number;
  MaxValue: number;
  Criteria: RangeData;
}

export const fuelEconomyOptions: RangeOptions = {
  MaxValue: 14,
  MinValue: 0,
  Criteria: {
    Values: [
      { Value: 1, Description: '1' },
      { Value: 2, Description: '2' },
      { Value: 3, Description: '3' },
      { Value: 4, Description: '4' },
      { Value: 5, Description: '5' },
      { Value: 6, Description: '6' },
      { Value: 7, Description: '7' },
      { Value: 8, Description: '8' },
      { Value: 9, Description: '9' },
      { Value: 10, Description: '10' },
      { Value: 11, Description: '11' },
      { Value: 12, Description: '12' },
      { Value: 13, Description: '13' },
      { Value: 14, Description: '14' },
      { Value: 15, Description: 'Max' },
    ],
  } as RangeData,
};

export interface FuelTypeOptions {
  FuelType: string[];
}

export const kilometresOptions: RangeOptions = {
  MinValue: 0,
  MaxValue: 20,
  Criteria: {} as RangeData,
};

export interface RepaymentOptions extends RangeOptions {
  IntervalType: 'week' | 'fortnight' | 'month';
}

export const repaymentOptions: RepaymentOptions = {
  MinValue: 0,
  MaxValue: 0,
  Criteria: {
    Values: [
      {
        Value: 0,
        Description: 'Min',
      },
      {
        Value: 10000,
        Description: 'Max',
      },
    ],
  } as RangeData,
  IntervalType: 'week',
};

export interface LifestyleOptions {
  Lifestyle: string[];
}

export const lifestyle: KeyValuePair[] = [
  {
    Key: 'OutdoorAdventure',
    Value: 'Outdoor & adventure',
  },
  {
    Key: 'Family',
    Value: 'Family life',
  },
  {
    Key: 'Luxury',
    Value: 'Commute in luxury',
  },
  {
    Key: 'Tradie',
    Value: 'Tradies',
  },
  {
    Key: 'City',
    Value: 'Inner city commuter',
  },
  {
    Key: 'EcoFriendly',
    Value: 'Eco-friendly',
  },
];

export interface LocationOptions {
  Location: string[];
}

export interface ModelOptions {
  Top: number;
  Makes: MakesWithModels;
  ModelTab: number;
  VariantTab: number;
}

export interface PPlateApprovedOptions {
  PPlateApproved: string[];
}

export const pPlateApproved: string[] = [
  'P-plate approved one',
  'P-plate approved two',
  'P-plate approved three',
  'P-plate approved four',
  'P-plate approved five',
  'P-plate approved six',
];

export interface IntervalPriceOptions {
  LoanLength?: number;
  Payment?: number;
  UpfrontDeposit?: number;
  TotalValue?: number;
}

export interface PriceOptions {
  OverallPriceValue: RangeOptions;
  PriceType: number;
  Monthly: IntervalPriceOptions;
  Weekly: IntervalPriceOptions;
  Fortnightly: IntervalPriceOptions;
  PriceDialog: boolean;
  LoanLengthOptions: number[];
  LoanLength: number;
}

export const financeOptions: FinanceOptions = {
  DefaultInterestRate: 0.079,
  MonthlyLoanFee: 8.1,
  PurchaseFees: 1_425,
};

export const priceOptions: PriceOptions = {
  OverallPriceValue: {
    MinValue: 0,
    MaxValue: 200,
    Criteria: {},
  },
  Fortnightly: {},
  Monthly: {},
  PriceType: 0,
  Weekly: {},
  PriceDialog: false,
  LoanLengthOptions: [12, 24, 36, 48, 60, 72, 84],
  LoanLength: 4,
};

export interface QuickLinks {
  Text: string;
  Link: Location;
  _id: string;
}

export interface QuickOptions {
  QuickLinks: QuickLinks[];
}

export const quickOptions: QuickOptions = {
  QuickLinks: [
    {
      _id: 'Make',
      Text: 'Make/Model',
      Link: { name: 'filterMake' },
    },
    {
      _id: 'Years',
      Text: 'Year',
      Link: { name: 'filterYear' },
    },
    {
      _id: 'Price',
      Text: 'Price',
      Link: { name: 'filterPrice' },
    },
    {
      _id: 'Location',
      Text: 'Location',
      Link: { name: 'filterLocation' },
    },
    {
      _id: 'Seats',
      Text: 'Seats',
      Link: { name: 'filterSeats' },
    },
    {
      _id: 'Odometer',
      Text: 'KMs',
      Link: { name: 'filterKilometres' },
    },
    {
      _id: 'Trans',
      Text: 'Transmission',
      Link: { name: 'filterTransmission' },
    },
    {
      _id: 'FuelType',
      Text: 'Fuel Type',
      Link: { name: 'filterFuelType' },
    },
    {
      _id: 'Ancap',
      Text: 'ANCAP Safety Rating',
      Link: { name: 'filterSafetyRating' },
    },
  ],
};

export const seatsOptions: RangeOptions = {
  MinValue: 0,
  MaxValue: 8,
  Criteria: {} as RangeData,
};

export interface TransmissionOptions {
  Transmissions: string[];
}

export const transmissionOptions: string[] = [
  'Transmissions one',
  'Transmissions two',
  'Transmissions three',
  'Transmissions four',
  'Transmissions five',
  'Transmissions six',
];

export const yearOptions: RangeOptions = {
  Criteria: {} as RangeData,
  MinValue: 0,
  MaxValue: 0,
};

export interface SearchHints {
  Description: string;
  Source: string;
  Key: string;
}

export interface SortOptions {
  Selected: number;
  PreviousSelected: number;
  Options: string[];
}

export interface Selections {
  filters: string[];
  seats: RangeOptions;
  years: RangeOptions;
  price: PriceOptions;
  odometer: RangeOptions;
  fuelEcon: RangeOptions;
  preferredLocation?: LocationDetails;
  sort?: SortOptions;
  finance: RepaymentOptions;
}
