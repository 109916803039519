import { FinanceOptions, LocationDetails } from '../inventory/search';

export interface FinanceChoices {
  LoanAmount: number;
  InterestRate: number;
  Deposit?: number;
  Payment?: number;
  PeriodType: 1 | 2 | 3;
  TermYears: number;
}

export interface LoanDetailsWithPresentValue {
  FinanceChoices: FinanceChoices;
  FinanceOptions: FinanceOptions;
  PresentValue: number;
  TransportCost?: number;
}

export const repaymentPeriods: number[] = [3, 2, 1];

export function GetDisclaimerLabel(disclaimer?: string, location?: LocationDetails): string {
  if (disclaimer === 'EGC') {
    switch (location?.Address?.StateShort) {
      case 'NSW':
      case 'WA':
      case 'TAS':
        return 'Excluding government charges';
      default:
        return 'Excluding fees & charges';
    }
  }
  if (disclaimer === 'DAP') {
    return 'Drive away price';
  }
  return '';
}

export function GetDisclaimerText(disclaimer: string, state: string): string {
  if (disclaimer === 'EGC') {
    switch (state) {
      case 'New South Wales':
        return `The price does not include government fees & charges payable directly to the state of ${state}.`
      case 'Western Australia':
        return `The price does not include government fees & charges payable directly to the state of ${state}.`
      default:
        return 'The price does not include additional costs such as stamp duty and other fees & government charges.';
    }
  }
  if (disclaimer === 'DAP') {
    return (
      'The price includes additional costs such as the balance of registration, Customer Third ' +
      'Party Insurance (CTP), Stamp Duty and any other statutory or government charges as may be applicable to the state of ' +
      `${state}.`
    );
  }
  return '';
}

export function GetDisclaimerTitle(disclaimer: string): string {
  if (disclaimer === 'EGC') {
    return 'Excluding government charges';
  }
  if (disclaimer === 'DAP') {
    return 'Drive away price (DAP)';
  }
  return '';
}

export function GetDisclaimerLabelLower(disclaimer: string): string {
  if (disclaimer === 'EGC') {
    return 'excl. govt. charges';
  }
  if (disclaimer === 'DAP') {
    return 'drive away';
  }
  return '';
}

export function GetDepositDisclaimerText(): string {
  return 'Making a bigger down payment up front, means you’ll pay less in interest down the road. A down payment is typically made up of a cash deposit and/or the payout on your car if you traded it in. For a used car loan, 10% is a good place to start.';
}

export function GetTermDisclaimerText(): string {
  return 'While costing less in scheduled repayments, longer term lengths means you’ll pay more in interest down the road. Choose the shortest loan term length that you know you can comfortably afford.';
}

export function GetPeriodsInYear(periodType: number): number {
  const intervals = [0, 12, 26, 52];

  return intervals[periodType] || intervals[3];
}

export function GetPeriodTypeSimpleLabel(periodType: number): string {
  const intervals = ['', 'Month', 'Fortnight', 'Week'];

  return intervals[periodType] || intervals[3];
}

export function GetPeriodTypeLabel(periodType: number): string {
  const intervals = ['', 'Monthly', 'Fortnightly', 'Weekly'];

  return intervals[periodType] || intervals[3];
}

export function GetPerPeriodTypeLabel(periodType: number): string {
  const intervals = ['', 'per month', 'per fortnight', 'per week'];

  return intervals[periodType] || intervals[3];
}

export function GetPaymentFromPresentValue(
  loan: LoanDetailsWithPresentValue
): number | '-' {
  const periodsInYear = GetPeriodsInYear(
    (loan.FinanceChoices || {}).PeriodType || 3
  );
  const choices = loan.FinanceChoices;
  const extraPayment =
    Math.round(
      ((loan.FinanceOptions.MonthlyLoanFee * 12) / periodsInYear || 0) * 100
    ) / 100;
  const deposit = loan.FinanceChoices.Deposit || 0;
  const presentValue = loan.PresentValue - deposit;

  if (loan.PresentValue - deposit < 4000) {
    return '-';
  }

  const price = PMT(
    choices.InterestRate / periodsInYear,
    choices.TermYears * periodsInYear,
    presentValue
  );
  if (price < 0) {
    return extraPayment - price;
  }
  return '-';
}

export function PMT(
  interestRatePerPeriod: number,
  numPeriods: number,
  presentValue: number,
  futureValue?: number,
  type?: number
): number {
  /*
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  function calculatePMT(): number {
    const safeFutureValue = futureValue || 0;
    const endOfPerion = (type || 0) === 0;

    if (interestRatePerPeriod === 0) {
      return -(presentValue + safeFutureValue) / numPeriods;
    }

    const pvif = Math.pow(1 + interestRatePerPeriod, numPeriods);
    const pmt =
      (-interestRatePerPeriod * (presentValue * pvif + safeFutureValue)) /
      (pvif - 1);

    if (endOfPerion) {
      return pmt;
    }

    return pmt / (1 + interestRatePerPeriod);
  }

  return Math.round(calculatePMT() * 100) / 100;
}

export function PresentValue(
  interestRatePerPeriod: number,
  numPeriods: number,
  periodicPayment: number,
  futureValue?: number
) {
  let value: number = 0;
  const fv = futureValue || 0;
  if (interestRatePerPeriod === 0) {
    // Interest rate is 0
    value = -(fv + periodicPayment * numPeriods);
  } else {
    const x = Math.pow(1 + interestRatePerPeriod, -numPeriods);
    const y = Math.pow(1 + interestRatePerPeriod, numPeriods);
    value =
      -(
        x *
        (fv * interestRatePerPeriod - periodicPayment + y * periodicPayment)
      ) / interestRatePerPeriod;
  }
  value = Math.round(value);
  return value;
}
