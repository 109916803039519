import { render, staticRenderFns } from "./PSAGroup.vue?vue&type=template&id=65c2c7c2&lang=pug&"
import script from "./PSAGroup.ts?vue&type=script&lang=js&"
export * from "./PSAGroup.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ToastGroup: require('/opt/atlassian/pipelines/agent/build/website/src/components/toast/toast-group.vue').default})
