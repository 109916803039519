import { VBtn } from 'vuetify/lib';
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component({
  components: {
    VBtn,
  },
})
export default class FormDatePicker extends Vue {
  @Prop() public value!: string;
  @Prop() public weekDays!: DateTime[];
  @Prop() public minAllowedDate!: string;
  @Prop() public maxAllowedDate!: string;
  @Prop() public isDateAllowed!: (value: string) => boolean;
  @Prop() public label!: string;
  @Prop({ default: 'cccc, dd/MM' }) public displayDateFormat!: string;
  @Prop({ default: '' }) public prependIcon!: string;
  @Prop({ default: false, type: Boolean }) public showOutlined!: boolean;
  @Prop({ default: false, type: Boolean }) public showDaySelection!: boolean;

  public menu: boolean = false;

  public get Value() {
    return this.value;
  }

  public set Value(value: string) {
    this.$emit('input', value);
  }

  public get WeekDays() {
    return this.weekDays?.map((x) => x.toFormat(this.displayDateFormat)) || [];
  }

  public get ComputedDateFormatted() {
    const date = this.value;
    if (!date) {
      return '';
    }
    const parsedDate = DateTime.fromFormat(date, this.displayDateFormat);
    if (!parsedDate.isValid) {
      return '';
    }
    const formattedDate = parsedDate.toFormat('yyyy-MM-dd');
    return formattedDate;
  }

  public set ComputedDateFormatted(value: string) {
    const parsedDate = DateTime.fromFormat(value, 'yyyy-MM-dd');
    if (!parsedDate.isValid) {
      return;
    }
    const formattedDate = parsedDate.toFormat(this.displayDateFormat);
    this.Value = formattedDate;
  }
}
