import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'car-subscription-benefits',
  data: () => ({
    benefits: [
      {
        imageSrc: '/img/home-page/accessories.svg',
        title: 'Your car, your terms',
        description:
          'The alternative and cost-inclusive way for your next car.',
      },
      {
        imageSrc: '/img/simplr/test-drive.svg',
        title: 'Total convienience',
        description:
          'Fast, flexible and fuss-free. Get approved within 24 hours.',
      },
      {
        imageSrc: '/img/simplr/sell_get_paid.svg',
        title: 'No hidden costs',
        description:
          'All  fees are bundled into one monthly payment. Did somebody say ‘easyauto’?',
      },
    ],
  }),
})
export default class CarSubscriptionBenefits extends Vue {}
