import { Module, VuexModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import * as typ from '@/features/promo/types';

const name = 'promo';

@Module({ stateFactory: true })
export default class Promo extends VuexModule {
  public PromoOptions: (string | typ.Promo)[] = typ.promoOptions;
}

export const PromoModule = namespace(name);
