import Vue from 'vue';
import { VNode } from 'vue/types/umd';
import ButtonWithIcons from './button-with-icon';

const name = 'secondary-button';

export default Vue.extend({
  name,

  render(h): VNode {
    return h(
      ButtonWithIcons,
      {
        attrs: {
          outlined: true,
          ...this.$attrs,
        },
        scopedSlots: this.$scopedSlots,
        on: this.$listeners,
        props: {
          border: 'ink-low',
          ...this.$attrs,
        },
      },
      this.$slots?.default
    );
  },
});
