











import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ColourButton extends Vue {
  @Prop() public Label!: string;
  @Prop() public value!: boolean;
  @Prop() public colour!: string;
  @Prop() public Toggle!: () => void;

  public get IsSelected(): boolean {
    return this.value;
  }

  public set IsSelected(_: boolean) {
    this.Toggle();
  }

  public get GetStyleVariables(): any {
    return {
      '--colour-button-background': this.colour || 'transparent',
    };
  }
}
