import { ValidatePhone } from '../../../../../shared/enquiries/validation.helpers';
import { ValidateEmail } from '../../../../../shared/utils/validation/email';
import { PayerDetails } from '../../../../../shared/payments';
import { validate } from '@/features/enquiries/types';

function IsDataValid(
  value: string | undefined,
  validators: ((value?: string) => any)[]
): boolean {
  for (const validator of validators) {
    const result = validator?.(value);
    if (result !== true) {
      return false;
    }
  }
  return true;
}

export function ValidatePayerDetails(value: PayerDetails): boolean {
  if (!IsDataValid(value.Forename, validate.NameRules)) {
    return false;
  }

  if (!IsDataValid(value.Surname, validate.SurnameRules)) {
    return false;
  }

  const validEmail = ValidateEmail(value.Email);
  if (validEmail.error) {
    return false;
  }
  value.Email = validEmail.formatted;

  const validPhone = ValidatePhone(value.Phone);
  if (validPhone.error) {
    return false;
  }
  value.Phone = validPhone.formatted;

  return true;
}
