










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'svg-icon-360-view',
})
export default class SVGIcon360View extends Vue {
  @Prop({ default: '#28477C' }) public fill!: string;
}
