import { Component, Vue, Prop } from 'vue-property-decorator';
import { LocationDetails } from '../../../../shared/inventory/search';
import { NavOptions } from '../header/types';
import LocationItem from './LocationItem/LocationItem';
import { LocationModule } from '@/store/location';
import { HeaderModule } from '@/store/header';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
import states from '@/constants/States';

@Component({
  components: {
    LocationItem,
    SectionWithContainer,
  },
})
export default class LocationSelector extends Vue {
  @Prop() public closeDrawer!: boolean;
  @LocationModule.State public locations: LocationDetails[] | undefined;
  @HeaderModule.State public NavOptions!: NavOptions;

  public CloseLocation() {
    // TODO: is dispatch not necessary to update global state? (throws error when this is called)
    this.NavOptions.drawer = false;
    this.NavOptions.location = false;
    this.NavOptions.modalLocation = false;
  }

  public get states(): string[] {
    return states.sort();
  }

  public getStoresByState(state: string): LocationDetails[] | undefined {
    return this.locations?.filter((location: LocationDetails) => {
      return location.Address?.State === state;
    });
  }
}
