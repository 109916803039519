import { InventorySearchConfig } from '../../../../../shared/inventory/search';
import { PriceOptions } from '../../filter/types';
import { GetIntervalPriceOptions } from '@/features/finance/helpers/calculators';

export function FilterPrice(
  filter: InventorySearchConfig,
  filterKey: string,
  price: PriceOptions
): boolean {
  if (filterKey.startsWith('Price.')) {
    if (!filter.Search.Price) {
      filter.Search.Price = {};
    }

    if (price.PriceType === 0) {
      if (price.OverallPriceValue.MinValue) {
        const minValue =
          price.OverallPriceValue.Criteria.Values?.[
            price.OverallPriceValue.MinValue
          ];
        if (minValue) {
          const filterValue = minValue.Value as number;
          if (Number.isInteger(filterValue)) {
            filter.Search.Price.MinValue = filterValue * 1000;
          }
        }
      }

      if (
        price.OverallPriceValue.Criteria.Values &&
        price.OverallPriceValue.MaxValue <
          price.OverallPriceValue.Criteria.Values.length - 1
      ) {
        const maxValue =
          price.OverallPriceValue.Criteria.Values[
            price.OverallPriceValue.MaxValue
          ];
        if (maxValue) {
          const filterValue = maxValue.Value as number;
          if (Number.isInteger(filterValue)) {
            filter.Search.Price.MaxValue = filterValue * 1000;
          }
        }
      }
    }

    if (price.PriceType > 0) {
      const intervalPriceOptions = GetIntervalPriceOptions(price);
      if (intervalPriceOptions) {
        filter.Search.Price.MaxValue = intervalPriceOptions.TotalValue;
      }
    }

    return true;
  }
  return false;
}
