import { Vue, Component } from 'vue-property-decorator';
import PopularQuestionsFaqs from '@/constants/Faqs';

@Component({
  name: 'popular-questions',
})
export default class PopularQuestions extends Vue {
  public get popularQuestionsFaqs() {
    return PopularQuestionsFaqs;
  }

  public isLastItem(index: number): boolean {
    return index === Object.keys(PopularQuestionsFaqs).length - 1;
  }

  public isFirstItem(index: number) {
    return index === 0;
  }

  public className(index: number): string {
    if (index === 0) {
      return 'first-item';
    }
    if (index !== Object.keys(PopularQuestionsFaqs).length - 1) {
      return 'middle-item';
    }

    return 'last-item';
  }
}
