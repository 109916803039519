import { Makes } from '../../../../shared/inventory/search';

export * from './const.models';

export const makeOptions: Makes = {
  Top: 8,
  List: [
    'TOYOTA',
    'FORD',
    'HOLDEN',
    'MAZDA',
    'NISSAN',
    'VOLKSWAGEN',
    'MITSUBISHI',
    'HYUNDAI',
  ],
};

export const FILTER_ITEMS = [
  {
    key: 'location',
    label: 'Location',
  },
  {
    key: 'make',
    label: 'Make',
  },
  {
    key: 'model',
    label: 'Model',
  },
  {
    key: 'variant',
    label: 'Variant',
  },
  {
    key: 'vehicleType',
    label: 'Vehicle Type',
  },
  {
    key: 'price',
    label: 'Price',
  },
  {
    key: 'year',
    label: 'Year',
  },
  {
    key: 'transmissionsFilter',
    label: 'Transmission',
  },
  {
    key: 'kilometres',
    label: 'Kilometres',
  },
  {
    key: 'seats',
    label: 'Seats',
  },
  {
    key: 'fuelType',
    label: 'Fuel Type',
  },
  {
    key: 'safetyRating',
    label: 'ANCAP Safety Rating',
  },
  {
    key: 'cylinders',
    label: 'Cylinders',
  },
  {
    key: 'paintColour',
    label: 'Colour',
  },
  {
    key: 'lifestyle',
    label: 'Lifestyle',
  },
  {
    key: 'features',
    label: 'Features',
  },
  {
    key: 'fuelEconomy',
    label: 'Fuel Economy',
  },
  {
    key: 'repayment',
    label: 'Repayments',
  },
  {
    key: 'vehicleSize',
    label: 'Vehicle Size',
  },
  {
    key: 'productCategories',
    label: 'easyauto123 car type',
  },
  {
    key: 'subscription',
    label: 'Subscription',
  },
];

export const noVariantLabel = '(no variant)';
