import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterModels(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Model.')) {
    const modelIndex = filterKey.lastIndexOf('.');
    const model = filterKey.substr(modelIndex + 1);
    if (model) {
      if (!filter.Search.Model) {
        filter.Search.Model = [model];
      } else {
        filter.Search.Model.push(model);
      }
    }
    return true;
  }
  return false;
}
