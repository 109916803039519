import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { ValuePair } from '../../../../../shared/global/types';

@Component
export default class NumberDropDown extends Vue {
  @Prop() public items!: ValuePair[];
  @Prop() public value!: number;
  @Prop() public label!: string;
  @Prop() public placeholder!: string;

  public get Value() {
    return this.value;
  }

  public set Value(value: number) {
    this.$emit('input', value);
  }
}
