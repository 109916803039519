import { ungzip, gzip } from 'pako';
import { Dictionary } from '../../../shared/global/types';

class StorageWrapper {
  private localState: Dictionary<string> = {};

  public Read<T>(key: string): T | null {
    const value =
      !process.client || !window.localStorage
        ? this.localState[key]
        : window.localStorage.getItem(key);

    if (value) {
      return JSON.parse(value) as T;
    }

    return null;
  }

  public Unzip<T>(key: string): T | null {
    const rawBuffer =
      !process.client || !window.localStorage
        ? this.localState[key]
        : window.localStorage.getItem(key);

    if (!rawBuffer) {
      return null;
    }

    return JSON.parse(ungzip(rawBuffer, { to: 'string' }));
  }

  public Write(key: string, value: any) {
    if (!process.client || !window.localStorage) {
      this.localState[key] = JSON.stringify(value);
      return;
    }

    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // If we exceeded quota there is not much we can do about it.
    }
  }

  public Zip(key: string, value: any) {
    const buffer = JSON.stringify(value);

    try {
      const compressedValue = gzip(buffer, { to: 'string' });

      if (!process.client || !window.localStorage) {
        this.localState[key] = compressedValue;
        return;
      }

      window.localStorage.setItem(key, compressedValue);
    } catch (error) {
      // If we exceeded quota there is not much we can do about it.
    }
  }

  public Remove(key: string) {
    if (!process.client || !window.localStorage) {
      delete this.localState[key];
      return;
    }

    window.localStorage.removeItem(key);
  }

  public GetStorageSize(): number {
    if (!process.client || !window.localStorage) {
      return -1;
    }

    let storageSize = 0;
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (key) {
        const value = window.localStorage.getItem(key);
        if (value) {
          storageSize += value.length;
        }
      }
    }
    return storageSize;
  }
}

export const storageWrapper = new StorageWrapper();
