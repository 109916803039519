// Styles
import './_simple-checkbox.scss';

import Vue from 'vue';
import { VNode } from 'vue/types/umd';
import { VCheckbox } from 'vuetify/lib';

const name = 'simple-checkbox';

export default Vue.extend({ _scopeId: 'data-simple-checkbox' } as any).extend({
  name,
  props: {
    value: Boolean,
    color: {
      type: String,
      default: 'error',
    },
    'hide-details': {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasError() {
      return (this.$refs.input as any)?.hasError;
    },
  },

  methods: {
    onInput(v: boolean) {
      this.$emit('input', v);
    },
    validate(value: boolean = false) {
      (this.$refs.input as any)?.validate?.(value);
    },
    focus() {
      return (this.$refs.input as any)?.focus?.();
    },
  },

  render(h): VNode {
    return h(VCheckbox, {
      staticClass: name,
      attrs: this.$attrs,
      ref: 'input',
      props: {
        trueValue: true,
        falseValue: false,
        ...this.$props,
        ...this.$attrs,
      },
      directives: [
        {
          name: 'ripple',
          value: { class: 'error--text' },
        },
      ],
      on: {
        change: this.onInput,
      },
    });
  },
});
