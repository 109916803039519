import { EnquiryData } from '../../../../shared/enquiries';
import { apiFunctions } from '@/helpers/functions';

class EnquiryApi {
  public async SubmitEnquiry(enquiryData: EnquiryData): Promise<string> {
    const callUrl: string = 'submitEnquiry';
    try {
      const res = await apiFunctions.axios.post(callUrl, enquiryData);

      return 'Enquiry submitted - Success.\n' + res;
    } catch (error) {
      return 'An error has occurred.';
    }
  }
}

export const enquiryApi = new EnquiryApi();
