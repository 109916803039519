import { render, staticRenderFns } from "./ShopByType.vue?vue&type=template&id=35e13256&scoped=true&lang=pug&"
import script from "./ShopByType.ts?vue&type=script&lang=js&"
export * from "./ShopByType.ts?vue&type=script&lang=js&"
import style0 from "./ShopByType.vue?vue&type=style&index=0&id=35e13256&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e13256",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionWithContainer: require('/opt/atlassian/pipelines/agent/build/website/src/components/section-with-container/SectionWithContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
