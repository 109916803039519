import { InventorySearchConfig } from '../../../../../shared/inventory/search';
import { RangeOptions } from '../../filter/types';

export function FilterOdometer(
  filter: InventorySearchConfig,
  filterKey: string,
  odometer: RangeOptions
): boolean {
  if (filterKey.startsWith('Odometer.')) {
    if (!filter.Search.Odometer) {
      filter.Search.Odometer = {};
    }
    if (odometer.MinValue) {
      const minValue = odometer.Criteria.Values?.[odometer.MinValue];
      if (minValue) {
        const filterValue = minValue.Value as number;
        if (Number.isInteger(filterValue)) {
          filter.Search.Odometer.MinValue = filterValue;
        }
      }
    }
    if (
      odometer.Criteria.Values &&
      odometer.MaxValue < odometer.Criteria.Values.length - 1
    ) {
      const maxValue = odometer.Criteria.Values?.[odometer.MaxValue];
      if (maxValue) {
        const filterValue = maxValue.Value as number;
        if (Number.isInteger(filterValue)) {
          filter.Search.Odometer.MaxValue = filterValue;
        }
      }
    }
    return true;
  }
  return false;
}
