











import { Component, Vue } from 'vue-property-decorator';
import Header from '@/features/header/Header.vue';
import Footer from '@/features/site-footer/SiteFooter.vue';
import { NavOptions } from '@/features/header/types';
import { HeaderModule } from '@/store/header';
import {
  routesWithoutFooter,
  routesWithoutHeader,
  isLandingPage,
} from '@/router/types';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {
  @HeaderModule.State public NavOptions!: NavOptions;

  public mounted() {
    if (!navigator?.serviceWorker) {
      return;
    }

    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .catch(function (err) {
        // eslint-disable-next-line no-console
        console.info('Service Worker registration failed: ', err);
      });
  }

  public get location(): string | boolean {
    return process.client && window.location.href;
  }

  public get ShouldShowFooter(): boolean {
    return !routesWithoutFooter.includes(this.$route.name as string);
  }

  public get shouldShowHeader(): boolean {
    return routesWithoutHeader.includes(this.$route.name as string);
  }

  public get isLandingPage(): boolean {
    return isLandingPage.includes(this.$route.name as string);
  }
}
