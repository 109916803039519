// Styles
import './_input-element.scss';

import Vue from 'vue';
import { PropType } from 'vue/types';
import { VNode } from 'vue/types/umd';
import { VTextField } from 'vuetify/lib';

const name = 'ea-input-element';

export default Vue.extend({ _scopeId: 'data-ea-enquiry' } as any).extend({
  name,
  props: {
    value: String,
    label: String,
    rules: {} as PropType<any[] | undefined>,
    color: {
      type: String,
      default: 'ink-high',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasError() {
      return (this.$refs.input as any)?.hasError;
    },
  },

  methods: {
    onInput(v: string) {
      this.$emit('input', v);
    },
    validate(value: boolean = false) {
      (this.$refs.input as any)?.validate?.(value);
    },
    focus() {
      return (this.$refs.input as any)?.focus?.();
    },
  },

  render(h): VNode {
    return h(VTextField, {
      staticClass: name,
      attrs: this.$attrs,
      ref: 'input',
      props: { ...this.$props, ...this.$attrs },
      on: {
        input: this.onInput,
      },
    });
  },
});
