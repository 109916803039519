import { ValidationStatusFormatted } from './types';

export function IsValidEmail(value: string | undefined): boolean {
  const emailValue = value?.trim() || '';

  const re = new RegExp(
    '' +
      // email name part
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))/.source +
      // server part
      /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source
  );

  if (!re.test(emailValue.toLowerCase())) {
    return false;
  }

  return true;
}

export function ValidateEmail(value: string | undefined): ValidationStatusFormatted {
  const emailValue = value?.trim() || '';

  const result: ValidationStatusFormatted = {
    error: '',
    formatted: emailValue,
  };

  if (!IsValidEmail(emailValue)) {
    result.error = 'Please enter a valid email address. Email address requires an @ sign and a dot after the @.';
  }

  return result;
}
