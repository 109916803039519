import { render, staticRenderFns } from "./HoursAlert.vue?vue&type=template&id=33b487d8&scoped=true&lang=pug&"
import script from "./HoursAlert.ts?vue&type=script&lang=js&"
export * from "./HoursAlert.ts?vue&type=script&lang=js&"
import style0 from "./HoursAlert.vue?vue&type=style&index=0&id=33b487d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b487d8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toast: require('/opt/atlassian/pipelines/agent/build/website/src/components/toast/toast.vue').default})
