import { allowSell } from '../types';
export interface BannerCTA {
  Link: string;
  LinkText: string;
}

export interface BannerOptions {
  Image: string;
  Text: string;
  PrimaryCTA: BannerCTA;
  SecondaryCTA: BannerCTA;
}

const _bannerOptions: BannerOptions = {
  Image: '/img/hero/buy/buy.webp',
  Text: 'Car buying made easy',
  PrimaryCTA: {
    Link: '/buy/used-cars',
    LinkText: 'Find your car',
  },
  SecondaryCTA: {
    Link: '/buy/personalised-finance',
    LinkText: 'Get Finance Pre-Approved',
  },
};

export interface Slides {
  Image: string;
  Text: string;
  TextTwo: string;
  Link: string;
  LinkText: string;
  LinkTwo: string;
  LinkTextTwo: string;
  Align: string;
}

export interface CarouselOptions {
  CarouselTab: null;
  Items: string[];
  Slide: Slides[];
}

const _carouselOptions: CarouselOptions = {
  CarouselTab: null,
  Items: ['Shop', 'Finance', 'Service'],
  Slide: [
    {
      Image: '/img/hero/buy/buy.webp',
      Text: 'Car buying made easy',
      TextTwo: '',
      Link: '/buy/vehicle-list',
      LinkText: 'Shop all cars',
      LinkTwo: '#lifestyles',
      LinkTextTwo: 'Need help choosing?',
      Align: 'center',
    },
    {
      Image: '/img/hero/finance/finance.webp',
      Text: 'Finance to suit your budget and lifestyle',
      TextTwo: '',
      Link: '/buy/finance',
      LinkText: 'Get finance',
      LinkTwo: '',
      LinkTextTwo: '',
      Align: 'left',
    },
    {
      Image: '/img/hero/service/service.webp',
      Text: 'Speedy service',
      TextTwo: 'Set prices',
      Link: '/service',
      LinkText: 'Service my car',
      LinkTwo: '',
      LinkTextTwo: '',
      Align: 'center',
    },
  ],
};

if (allowSell) {
  _carouselOptions.Items.splice(2, 0, 'Sell');

  _carouselOptions.Slide.splice(2, 0, {
    Image: '/img/hero/sell/sell.webp',
    Text: 'Get the best price, stress-free',
    TextTwo: '',
    Link: '/sell',
    LinkText: 'Sell or trade my car',
    LinkTwo: '',
    LinkTextTwo: '',
    Align: 'left',
  });
}

export const carouselOptions = _carouselOptions;
export const bannerOptions = _bannerOptions;
