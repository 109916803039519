import { InventorySearchConfig } from '../../../../../shared/inventory/search';

export function FilterCylinder(
  filter: InventorySearchConfig,
  filterKey: string
): boolean {
  if (filterKey.startsWith('Cyl.')) {
    const cylinder = filterKey.substr(4);
    if (cylinder) {
      if (!filter.Search.Cylinder) {
        filter.Search.Cylinder = [cylinder];
      } else {
        filter.Search.Cylinder.push(cylinder);
      }
    }
    return true;
  }
  return false;
}
