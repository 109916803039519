import { render, staticRenderFns } from "./SimplrHeader.vue?vue&type=template&id=bb5e8202&scoped=true&lang=pug&"
import script from "./SimplrHeader.ts?vue&type=script&lang=js&"
export * from "./SimplrHeader.ts?vue&type=script&lang=js&"
import style0 from "./SimplrHeader.vue?vue&type=style&index=0&id=bb5e8202&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb5e8202",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionWithContainer: require('/opt/atlassian/pipelines/agent/build/website/src/components/section-with-container/SectionWithContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VRow})
